// LaborDetails.js
import React from 'react';
import { TextField, Grid, Typography, Paper, makeStyles, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3)
  },
  sectionTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    fontWeight: 500,
    fontSize: '1.2rem'
  },
  categoryTitle: {
    fontWeight: 500,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main
  },
  laborTypeContainer: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  laborTypeTitle: {
    fontWeight: 500,
    marginBottom: theme.spacing(2)
  },
  inputGroup: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(3, 0)
  },
  emptyRow: {
    width: '100%',
    // backgroundColor: 'blue'
  }
}));

const laborTypes = [
  { 
    id: 'labor',
    label: 'General Labor',
    defaultRate: 60,
    defaultSize: 3
  },
  {
    id: 'install',
    label: 'Install',
    defaultRate: 70,
    defaultSize: 4
  },
  {
    id: 'supervisor',
    label: 'Supervisor',
    defaultRate: 95,
    defaultSize: 1
  },
  {
    id: 'tech',
    label: 'Tech',
    defaultRate: 95,
    defaultSize: 1
  },
  {
    id: 'sprayTech',
    label: 'Spray Tech',
    defaultRate: 90,
    defaultSize: 1
  },
  {
    id: 'arborist',
    label: 'Arborist',
    defaultRate: 95,
    defaultSize: 1
  },
  {
    id: 'treeWorker',
    label: 'Tree Worker',
    defaultRate: 85,
    defaultSize: 2
  },
  {
    id: 'operator',
    label: 'Operator',
    defaultRate: 90,
    defaultSize: 1
  },
  {
    id: 'irrigationTech',
    label: 'Irrigation Tech',
    defaultRate: 150,
    defaultSize: 1
  },
  {
    id: 'lightingTech',
    label: 'Lighting Tech',
    defaultRate: 95,
    defaultSize: 2
  }
];

const equipmentOperators = [
  {
    id: 'bobcat',
    label: 'Bobcat',
    defaultRate: 300,
    defaultSize: 1,
    hideSize: true
  },
  {
    id: 'bobcatOperator',
    label: 'Bobcat Operator',
    defaultRate: 300,
    defaultSize: 1
  },
  
  {
    id: 'tractor',
    label: 'Tractor',
    defaultRate: 125,
    defaultSize: 1,
    hideSize: true
  },
  {
    id: 'tractorOperator',
    label: 'Tractor Operator',
    defaultRate: 125,
    defaultSize: 1
  },
  {
    id: 'crane',
    label: 'Crane',
    defaultRate: 900,
    defaultSize: 1,
    hideSize: true
  },
  {
    id: 'craneOperator',
    label: 'Crane Operator',
    defaultRate: 900,
    defaultSize: 1
  },
  {
    id: 'truck',
    label: 'Truck (Semi/Dump)',
    defaultRate: 85,
    defaultSize: 1,
    hideSize: true
  },
  {
    id: 'truckDriver',
    label: 'Truck Driver',
    defaultRate: 85,
    defaultSize: 1
  }
];

const LaborDetails = ({ entity, handleFocus, updateOurEntity }) => {
  const classes = useStyles();

  const handleUpdate = (category, type, field, value) => {
    const data = {
      [category]: {
        [type]: {
          [field]: value
        }
      }
    };

    updateOurEntity({
      entityId: entity._id,
      data: data
    });
  };

  const getValue = (category, type, field, defaultValue) => {
    return entity?.[category]?.[type]?.[field] || defaultValue;
  };

  const renderLaborType = (type, category) => (
    <Grid item xs={12} md={6} key={type.id}>
      <Paper className={classes.laborTypeContainer} elevation={0}>
        <Typography variant="subtitle1" className={classes.laborTypeTitle}>
          {type.label}
        </Typography>
        <div className={classes.inputGroup}>
          <TextField
            label="Rate per Hour"
            variant="outlined"
            type="number"
            size="small"
            fullWidth
            value={getValue(
              category === 'labor' ? 'laborRates' : 'equipmentRates',
              type.id,
              'rate',
              type.defaultRate
            )}
            onChange={(e) => handleUpdate(
              category === 'labor' ? 'laborRates' : 'equipmentRates',
              type.id,
              'rate',
              e.target.value
            )}
            onFocus={handleFocus}
          />
          {
            type.hideSize ? <div className={classes.emptyRow}>&nbsp;</div> : (
              <TextField
              label="Default Crew Size"
              variant="outlined"
              type="number"
              size="small"
              fullWidth
              value={getValue(
                category === 'labor' ? 'laborRates' : 'equipmentRates',
                type.id,
                'defaultCrewSize',
                type.defaultSize
              )}
              onChange={(e) => handleUpdate(
                category === 'labor' ? 'laborRates' : 'equipmentRates',
                type.id,
                'defaultCrewSize',
                e.target.value
              )}
              onFocus={handleFocus}
            />
            )
          }
         
        </div>
      </Paper>
    </Grid>
  );

  return (
    <Paper className={classes.container}>
      <Typography variant="h6" className={classes.sectionTitle}>
        Labor & Equipment Rates
      </Typography>

      <Typography variant="h6" className={classes.categoryTitle}>
        Labor Types
      </Typography>
      <Grid container spacing={2}>
        {laborTypes.map(type => renderLaborType(type, 'labor'))}
      </Grid>

      <Divider className={classes.divider} />

      <Typography variant="h6" className={classes.categoryTitle}>
        Equipment Operators
      </Typography>
      <Grid container spacing={2}>
        {equipmentOperators.map(type => renderLaborType(type, 'equipment'))}
      </Grid>
    </Paper>
  );
};

export default LaborDetails;
