import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  gridItem: {
    padding: '5px'
  },
  textField: {
    width: '100%'
  }
}));

const handleFocus = (event) => event.target.select();

// Separate labor and equipment types for clearer organization
const laborTypes = [
  { id: 'labor', label: 'Labor Rate', helperText: 'What is the Standard Labor Rate?', defaultRate: 60 },
  { id: 'install', label: 'Install Labor Rate', helperText: 'What is the Install Labor Rate?', defaultRate: 70 },
  { id: 'supervisor', label: 'Supervisor Rate', helperText: 'What is the Supervisor Rate?', defaultRate: 95 },
  { id: 'sprayTech', label: 'Spray Tech Rate', helperText: 'What is the Spray Tech Rate?', defaultRate: 90 },
  { id: 'tech', label: 'Tech Rate', helperText: 'What is the Tech Rate?', defaultRate: 95 },
  { id: 'arborist', label: 'Arborist Rate', helperText: 'What is the Arborist Rate?', defaultRate: 95 },
  { id: 'treeWorker', label: 'Tree Worker Rate', helperText: 'What is the Tree Worker Rate?', defaultRate: 85 },
  { id: 'operator', label: 'Operator Rate', helperText: 'What is the Operator Rate?', defaultRate: 90 },
  { id: 'irrigationTech', label: 'Irrigation Tech Rate', helperText: 'What is the Irrigation Tech Rate?', defaultRate: 150 },
  { id: 'lightingTech', label: 'Lighting Tech Rate', helperText: 'What is the Lighting Tech Rate?', defaultRate: 95 }
];

const equipmentTypes = [
  { id: 'bobcat', label: 'Bobcat Equipment Rate', helperText: 'What is the Bobcat Hourly Rate?', defaultRate: 300 },
  { id: 'crane', label: 'Crane Equipment Rate', helperText: 'What is the Crane Hourly Rate?', defaultRate: 900 },
  { id: 'tractor', label: 'Tractor Equipment Rate', helperText: 'What is the Tractor Hourly Rate?', defaultRate: 125 },
  { id: 'bobcatOperator', label: 'Bobcat Operator Rate', helperText: 'What is the Bobcat Operator Rate?', defaultRate: 300 },
  { id: 'craneOperator', label: 'Crane Operator Rate', helperText: 'What is the Crane Operator Rate?', defaultRate: 900 },
  { id: 'tractorOperator', label: 'Tractor Operator Rate', helperText: 'What is the Tractor Operator Rate?', defaultRate: 125 },
  { id: 'truckDriver', label: 'Truck Driver Rate', helperText: 'What is the Truck Driver Rate?', defaultRate: 85 }
];

const LaborRates = ({ laborRates, equipmentRates, updateParent }) => {
  const classes = useStyles();

  const getRate = (type, rateType) => {
    // Use the nested structure with separate labor and equipment rates
    return rateType[type.id]?.rate ?? type.defaultRate;
  };

  const updateRates = (type, value, rateCategory) => {
    const val = Number(value);
    const updateData = {
      [rateCategory]: {
        [type.id]: { rate: val }
      }
    };
    console.log('Updated data', updateData)
    updateParent(updateData);
  };

  return (
    <Grid container>
      <Grid item lg={12}>
        <h4>Labor Rates</h4>
      </Grid>
      {laborTypes.map((laborType) => (
        <Grid item lg={6} md={6} sm={12} xs={12} key={laborType.id} className={classes.gridItem}>
          <TextField
            className={classes.textField}
            label={laborType.label}
            type="number"
            value={getRate(laborType, laborRates)}
            onChange={(e) => updateRates(laborType, e.target.value, 'laborRates')}
            InputProps={{
              inputProps: { 
                min: 0,
                step: 1
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onFocus={handleFocus}
            helperText={laborType.helperText}
          />
        </Grid>
      ))}
      
      <Grid item lg={12}>
        <h4>Equipment Rates</h4>
      </Grid>
      {equipmentTypes.map((equipmentType) => (
        <Grid item lg={6} md={6} sm={12} xs={12} key={equipmentType.id} className={classes.gridItem}>
          <TextField
            className={classes.textField}
            label={equipmentType.label}
            type="number"
            value={getRate(equipmentType, equipmentRates)}
            onChange={(e) => updateRates(equipmentType, e.target.value, 'equipmentRates')}
            InputProps={{
              inputProps: { 
                min: 0,
                step: 1
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onFocus={handleFocus}
            helperText={equipmentType.helperText}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default LaborRates;
