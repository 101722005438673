import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { CircularProgress, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Button,
  DialogContentText} from '@material-ui/core';
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch';
import { setSnoutWeevilButtonActive, setFertilizationButtonActive, setWinterFrostProtectionButtonActive, setConfigs } from '../../actions/connectionActions'
import axios from 'axios'
import moment from 'moment';
// import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import handleEnterKeyPress from '../Common/HandleKeyPress'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import { handleFocus } from '../../services/common'
import HolidayModule from './HolidayModule'
import EquipmentTypes from './EquipmentTypes'
import CloseBooks from './CloseBooksModal';

// let defaultStartTimeData = [
//   {
//     division: 'Landscape',
//     startTime: '06:00'
//   },
//   {
//     division: 'Maintenance',
//     startTime: '06:15'
//   },
//   {
//     division: 'Hardscape',
//     startTime: '06:30'
//   },
//   {
//     division: 'Specialty',
//     startTime: '06:30'
//   },
//   {
//     division: 'Irrigation and Lighting',
//     startTime: '06:30'
//   }
// ]
const ButtonComponent = (props) => {

  const setButtonStatus = () => {
    let newStatus = !props.active
    props.updateBackEnd(props.label, newStatus)
    // setState({...state, active: newStatus})
  }

  return (
    <>
      {props.label}
        <Switch
            checked={props.active}
            onChange={setButtonStatus}
            name="TheButton"
            color="primary"
            label={props.active ? 'Active' : 'Inactive'}
        />
    </>
  )
}

let defaultState = {
  envSurcharge: 0,
  fuelSurcharge: 0,
  requiresSave: false,
  startTimes: [],
  configsId: '',
  activeEntity: {},
  holidays: []
}

const Settings = (props) => {
  console.log('Settings PROPS', props)
  const [fertilizationButtonActive, setFertilizationButtonActive] = React.useState(false)
  const [snoutWeevilButtonActive, setSnoutWeevilButtonActive] = React.useState(false)
  const [winterFrostProtectionButtonActive, setWinterFrostProtectionButtonActive] = React.useState(false)
  const [state, setState] = React.useState(defaultState)
  const [loading, setLoading] = React.useState(false)
  const [holidays, setHolidays] = useState([])
  const activeEntity = props.activeEntity || {}
  const configsList = props.configsList || []

  const crewTypes = props.crewTypes

  React.useEffect(() => {
    setTheConfigs(configsList, activeEntity)
  }, [activeEntity, configsList])

  const updateBackEnd = (type, status) => {
    // console.log('POST REquest to Back end Type: %s --- Status: %s', type, status)
    if (type === 'Fertilization Button') {
      setFertilizationButtonActive(status)
      // props.setFertilizationButtonActive(status)
    }
    if (type === 'Snout Weevil Button') {
      setSnoutWeevilButtonActive(status)
      // props.setSnoutWeevilButtonActive(status)
    }
    if (type === 'Winter Frost Protection Button') {
      setWinterFrostProtectionButtonActive(status)
      // props.setWinterFrostProtectionButtonActive(status)
    }
    let startTimes = state.startTimes
    let fuelSurcharge = state.fuelSurcharge
    let envSurcharge = state.envSurcharge
    let obj = { type, status, startTimes, fuelSurcharge, envSurcharge, configsId: state.configsId, holidays: holidays }
    // console.log('Obj...', obj)
    axios({
      method: 'post',
      url:  `${props.url}/api/settings/updateConfigs`,
      data: obj
    }).then(resp => {
      // console.log('Update backend:', resp.data.configs)
      props.notification({
        type: 'success',
        title: 'Configurations Updated',
        message: 'We Have Saved Those Changes!'
      })
      if (resp.data && resp.data.length) props.setConfigs(resp.data)
      // console.log('Updated start times', updatedStartTimes)
      setLoading(false)
      setState({...state, requiresSave: false })
    })
  }

  const handleUpdate = (type, value) => {
    setState({...state, [type]: value, requiresSave: true })
  }

  const handleSave = () => {
    // setLoading(true)
    // console.log('Handle save...')
      let obj = {
        ...state,
        winterFrostProtectionButtonActive,
        snoutWeevilButtonActive,
        fertilizationButtonActive
      }
      // console.log('OBJE:::', obj)
      // updateBackEnd()
      axios({
        method: 'post',
        url:  `${props.url}/api/settings/updateConfigs`,
        data: obj
      }).then(resp => {
        props.notification({
          type: 'success',
          title: 'Configurations Updated',
          message: 'We Have Saved Those Changes!'
        })
        setLoading(false)
        setState({...state, requiresSave: false })
        if (resp.data && resp.data.length) props.setConfigs(resp.data)
      })
  }

  const handleBooksClosing = (data) => {
    console.log('Close the books...', data)
    setLoading(true)
    axios(
      {
        method: 'post',
        url: `${props.url}/api/settings/closethebooks`,
        data: data
      }
    ).then(resp => {
      console.log(resp)
      setLoading(false)
      const respData = resp.data
      if (respData.success) {
        props.notification({
          type: 'success',
          title: 'Success',
          message: `The books are now closed.`
        })
        
        if (resp.data && resp.data?.updatedSettings?.length) {
          // props.setConfigs(resp.data.updatedSettings)
          // setConfigs(resp.data.updatedSettings)
          props.updateConfigs(resp.data.updatedSettings)
        }
      }

      if (respData.error) {
        props.notification({
          type: 'warning',
          title: 'Error Closing The Books',
          message: `We failed to close the books due to a server error. ${respData.message}`
        })
      }
    }).catch((err) => {
      setLoading(false)
      console.log('err', err)
      props.notification({
        type: 'warning',
        title: 'Error Closing The Books',
        message: `We failed to close the books due to a server error.`
      })
    })
  }
  const setTheConfigs = (configsList, newEntity) => {
    console.log('Set the configs', configsList)
    console.log('state', state)
    console.log('Entity passed', newEntity)
    console.log('active entyt...', activeEntity)
      let configsToSet = configsList.find(config => (config.entity === newEntity._id))
    console.log('Set these', configsToSet)
    if (configsToSet) {
      setFertilizationButtonActive(configsToSet.fertilizationButtonActive)
      setSnoutWeevilButtonActive(configsToSet.snoutWeevilButtonActive)
      setWinterFrostProtectionButtonActive(configsToSet.winterFrostProtectionButtonActive)
      let startTimes = configsToSet.startTimes || []
      // console.log('Start times to set:', startTimes)
      if (startTimes && startTimes.length) {

      } else {
        console.log('Crew types', crewTypes)
        crewTypes.forEach((item, index) => {
          startTimes.push({
            division: item,
            startTime: '06:00'
          })
        })
      }
      setHolidays(configsToSet?.holidays || [])
      setState({...state, envSurcharge: configsToSet.envSurcharge, fuelSurcharge: configsToSet.fuelSurcharge, startTimes: startTimes, configsId: configsToSet._id })
    } else {
      console.log('....what do we do???')
    }
  }

  let booksClosedDate = ''
  if (configsList?.length) {
    if (configsList[0]['booksClosedDate']) {
      let dt = configsList[0]['booksClosedDate']
      console.log(dt)
      booksClosedDate = new Date(dt).toLocaleDateString('EN-US')
    }
  }

  return (
    <div style={{padding: '5px', margin: '10px'}}>
  
          <Grid container spacing={4} style={{ padding: '10px' }}>

            <Grid item lg={12} xs={12}>
                <h3>General Configurations:</h3>
            </Grid>
              <Grid item lg={4} xs={12}>
                <ButtonComponent label={'Fertilization Button'} active={fertilizationButtonActive} updateBackEnd={updateBackEnd} />
              </Grid>
              <Grid item lg={4} xs={12}>
                <ButtonComponent label={'Snout Weevil Button'} active={snoutWeevilButtonActive} updateBackEnd={updateBackEnd} />
              </Grid>
              <Grid item lg={4} xs={12}>
                <ButtonComponent label={'Winter Frost Protection Button'} active={winterFrostProtectionButtonActive} updateBackEnd={updateBackEnd} />
              </Grid>
              <Grid item lg={6} xs={12}>
                {
                  loading ? <CircularProgress /> : 
                    <TextField
                    id="fuelSurcharge"
                    label="Fuel Surcharge"
                    type="text"
                    // fullWidth
                    // multiline
                    // rows={4}
                    value={state.fuelSurcharge}
                    // className={classes.textField}
                    variant="outlined"
                    onBlur={handleSave}                   
                    onFocus={handleFocus}
                    onChange={e => handleUpdate('fuelSurcharge', e.target.value)}
                    helperText="How Much is Our Monthly Fuel Surcharge?"
                    // onChange={ e => setState({...state, fuelSurcharge: e.target.value}) }
                    // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /> 
                }
                
              </Grid>
              <Grid item lg={6} xs={12}>
              {
                  loading ? <CircularProgress style={{textAlign: 'center'}} /> : 
                  <TextField
                  id="envSurcharge"
                  label="Environmental Surcharge"
                  type="text"
                  // fullWidth
                  // multiline
                  // rows={4}
                  value={loading ? <CircularProgress /> : state.envSurcharge}
                  // className={classes.textField}
                  helperText="How Much is Our Monthly Environmental Surcharge?"
                  variant="outlined"
                  onBlur={handleSave}
                  onFocus={handleFocus}
                  onChange={e => handleUpdate('envSurcharge', e.target.value)}
                  // onChange={ e => setState({...state, envSurcharge: e.target.value}) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

              }

                
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ padding: '10px' }}>
              <h4>Holidays:</h4>
              <Grid item lg={12} xs={12} md={12} sm={12}>
                <HolidayModule updateBackEnd={updateBackEnd} currentHolidays={state.holidays} />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ padding: '10px' }}>
              <h4>Start Times:</h4>
              <Grid item lg={12} xs={12} md={12} sm={12}>
                <StartTimes updateBackEnd={updateBackEnd} startTimeData={state.startTimes} />
              </Grid>
            </Grid>
            <Grid container spacing={4} style={{ padding: '10px' }}>
              <Grid item lg={12} xs={12}>
                  <h4>Equipment Configurations:</h4>
              
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <EquipmentTypes url={props.url} notification={props.notification} />
                    </Grid>
                   
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={4} style={{ padding: '10px' }}>
            <Grid item lg={12} xs={12}>
                <h4>Inventory Configurations:</h4>
             
              <Grid container spacing={2}>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <a href={`/sizes`} >Sizes</a>
                  </Grid>
              </Grid>
            </Grid>
              <Grid item lg={12} xs={12}>
                <h4>Tax Configurations:</h4>
              </Grid>
              <Grid item lg={12} xs={12}>
                <a href={`/tax-details`} >Tax Details</a>
              </Grid>
              <Grid item lg={12} xs={12}>
                <h4>Proposal Configs:</h4>
                <Grid container spacing={2}>

                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <a href={`/scopes`} >Scopes</a>
                  </Grid>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <a href={`/services`} >Services</a>
                  </Grid>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <a href={`/proposaloptions/introductions`} >Introductions</a>
                  </Grid>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <a href={`/proposaloptions/objectives`} >Objectives</a>
                  </Grid>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <a href={`proposaloptions/proposalNotes`} >Notes</a>
                  </Grid>
                  {/* <Grid item lg={3} md={4} sm={6} xs={12}>
                    <a href={`/proposalsow`} >Scope of Work Options</a>
                  </Grid> */}
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <a href={`/proposaloptions/disclosures`} >Disclosures</a>
                  </Grid>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <a href={`proposaloptions/finalParagraphs`} >Final Paragraphs</a>
                  </Grid>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <a href={`proposaloptions/closings`} >Closings</a>
                  </Grid>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <a href={`proposaloptions/paymentSchedules`} >Payment Schedules</a>
                  </Grid>
                </Grid>
               
              </Grid>
              <Grid item lg={12} xs={12}>
                <h4>Maintenance Configurations:</h4>
              </Grid>
              <Grid item lg={12} xs={12}>
                <a href={`maintenanceoptions/checklistitems`} >Checklist Items</a>
              </Grid>
              <Grid item lg={12} xs={12}>
                <h4>Books Closed Date: {booksClosedDate} </h4>
              </Grid>
              <Grid item lg={12} xs={12}>
                <CloseBooks notification={props.notification} configs={props?.configs || []} updateParent={handleBooksClosing} />
              </Grid>
            </Grid>
            
      
  </div>
  )
}

const StartTimes = (props) => {
  const [open, setOpen] = React.useState(false)
  const [selectedTime, setSelectedTime] = React.useState(moment().format('HH:mm'));
  const [state, setState] = React.useState({
    itemToEdit: {}
  })
  const startTimeData = props.startTimeData || []
  // console.log('Start TIme Data:', startTimeData)
  
  const editTime = (item) =>{
    console.log(item)
    let startTime = item.startTime
    // console.log('Start Time:', startTime)

    // setSelectedTime('12:30')
    setSelectedTime(item.startTime)
    setOpen(true)
    setState({...state, itemToEdit: item})
  }

  const handelCloseModal = () => {
    setOpen(false)
  }
  const handleSave = () => {
    // console.log('Save', selectedTime)
    console.log(state)
    let obj = {
      updatedTime: selectedTime,
      division: state.itemToEdit.division
    }
    setOpen(false)
    let newStartTimes = startTimeData.map(item => {
      if (item.division === state.itemToEdit.division) {
        console.log('this one..')
        let newTime = {
          startTime: selectedTime,
          division: state.itemToEdit.division
        }
        return newTime
      } else {
        return item
      }
    })
    console.log('set The start times', newStartTimes)
    props.updateBackEnd('StartTimes', newStartTimes)
  }

  return (
    <div style={{ marginLeft: '10px' }}>
      <TimeEditModal open={open} handleSave={handleSave} handleClose={handelCloseModal} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
      {
        startTimeData.map((item, index) => {
          
          return (
            <div key={index} >{item.division} - <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => editTime(item)} >{item.startTime}</span></div>
          )
        })
      }
    </div>
  )
}

const TimeEditModal = ({ open, handleClose, selectedTime, setSelectedTime, handleSave }) => {
  const timeInputRef = React.useRef(null)

  const handleTimeChange = (event) => {
    // console.log('Change Time', itemToEdit)
    // setSelectedTime('07:30');
    // console.log("Updated Time", time)
    const newTime = event.target.value;
    setSelectedTime(newTime);
    // If you want to work with the moment object directly:
    const momentTime = moment(newTime, 'HH:mm');
    console.log(momentTime.format('hh:mma'));
    // timeInputRef.current.time = time
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={handleClose}
      fullWidth>
        <DialogTitle>Start Time</DialogTitle>
        <DialogContent>
          <DialogContentText>Please Adjust the Start Time - This Will Be The Default Time Folks Will Be Clocked-In.</DialogContentText>
          <TextField
              id="time"
              label="Alarm clock"
              type="time"
              fullWidth
              // ref={timeInputRef}
              value={selectedTime}
              defaultValue="06:00"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleTimeChange}
              inputProps={{
                step: 300, // 5 min
              }}
            />
        </DialogContent>
        <DialogActions>
          <Button
          onClick={handleClose}>CLOSE</Button>
          <Button
          onClick={handleSave}>SAVE</Button>
        </DialogActions>
      </Dialog>
  )
}

const SettingsComponent = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [activeEntity, setActiveEntity] = React.useState({})
  const [configsList, setConfigsList] = React.useState([])

  const crewTypes = [...new Set(props.crews.map(item => item.division ? item.division : 'Other' ))]

  React.useEffect(() => {
    let isMounted = true;
    
    // Define async function inside useEffect
    async function fetchConfigs() {
      if (configsList.length === 0) { // Fetch only if config list is empty
        console.log("Fetching Configs...");
        setLoading(true)
        let resp = await axios.get(`${props.url}/api/settings/listConfigs`);
        setLoading(false)
        const configs = resp.data;
        if (configs && configs.length && isMounted) {
          setConfigsList(configs);
        }
      }
    }
  
    // Execute the fetch function
    fetchConfigs();
  
    // Cleanup function to prevent actions on unmounted component
    return () => {
      isMounted = false;
    };
  }, []); // Empty dependency array ensures this effect only runs once after the component mounts
  
  const handleUpdateVisibleEntity = (newEntity) => {
    console.log('Active Entity...', newEntity)
    setActiveEntity(newEntity)
  }

  const handleUpdateConfigs = (newConfigs) => {
    setConfigsList(newConfigs)
  }

  return (
    <div style={{padding: '5px', margin: '10px'}}>
      <Grid container>
        <Grid item lg={6} xs={12}>
          <h1>Settings</h1>
        </Grid>
        <Grid item lg={6} xs={12}>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Paper style={{ minHeight: '80vh' }}>
          <Grid container spacing={4} style={{ padding: '10px' }}>
            <Grid item lg={12} xs={12}>
              <ChooseEntityComponent allowAll={false} title={'Visible Entities'} user={props.user} updateParent={handleUpdateVisibleEntity} />
            </Grid>
            <Grid item lg={12} xs={12}>
              <Settings {...props} updateConfigs={handleUpdateConfigs} activeEntity={activeEntity} configsList={configsList} crewTypes={crewTypes} />
            </Grid>
          </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

  const mapStateToProps = (state) => {
    // console.log('STATE to PROPS SETTINGS: ', state)
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      fertilizationButtonActive: state.fertilizationButtonActive,
      snoutWeevilButtonActive: state.snoutWeevilButtonActive,
      crews: state.crews,
      configs: state.configs
    }
  }

  const mapDispatchToProps = (dispatch) => {
    // console.log('DISTPACTH: ', dispatch)
    return {
      setFertilizationButtonActive: (payload) => { dispatch(setFertilizationButtonActive(payload)) },
      setSnoutWeevilButtonActive: (payload) => { dispatch(setSnoutWeevilButtonActive(payload)) },
      setWinterFrostProtectionButtonActive: (payload) => { dispatch(setWinterFrostProtectionButtonActive(payload)) },
      setConfigs: (configs) => { dispatch(setConfigs(configs)) }
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(SettingsComponent)