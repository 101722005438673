import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { green } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import AddNewItemModal from './services/AddNewItemModal'
import ConfigureItem from './ConfigurationInputs'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  top: 100;
  left: 100;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  min-height: 250px;
  width: 95%;
  background-color: #ddd;
  justify-content: space-between;
`;

const TitleArea = styled.div`
  font-size: 18px;
  color: #555;
  margin: 5px;
`;

const InputContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex-grow: 1;
  width: 100%;
  margin: 4px;
`;

const InputArea = styled.div`
  display: flex;
  align-self: stretch;
  flex-grow: 1;
`;

const InputButtonArea = styled.div`
  display: flex;
  align-self: stretch;
`;

const TopArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  border-radius: 10px;
  border: 2px solid #eee;
`;

const TabsChoice = styled.div`
  max-width: 200px;
  min-width: 100px;
  overflow: auto;
  display: flex;
  background-color: #fff;
`;

const TabDisplay = styled.div`
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  background-color: #fff;
`;

const ConfigurationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  border-radius: 15px;
  width: 100%;
  margin-top: 15px;
  padding: 15px;
  width: 100%;
`;

const TopRowOfConfigurator = styled(Grid)`
display: flex;
flex-direction: row;
`;

const QuantityArea = styled(Grid)`
display: flex;
`;

const UnitArea = styled(Grid)`
display: flex;
`;

const DescriptionArea = styled(Grid)`
display: flex;
flex-grow: 2;
`;

const PriceArea = styled(Grid)`
display: flex;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{width: '100%'}}>
          <Typography  component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  }
}));

const filter = createFilterOptions();

const defaultValues = {
  description: '',
  activeLineItem: {},
  qty: '',
  unit: '',
  totalPrice: 0
}

const ModifyTakeOffComponent = (props) => {
// console.log('Modify Takeoffs:: ', props)

  const [state, setState] = React.useState(defaultValues)
  const [item, setItem] = React.useState({
    name: ''
  })
  const [value, setValue] = React.useState(null)
  const [tabOpenValue, setTabOpenValue] = React.useState(0)
  const [takeOffList, setTakeOffList] = React.useState(props.takeOffList)
  // window.onkeypress = function (e) {
  //   // console.log('Press: ', e)
  //   let ourVal = window.document.getElementById('lineItemSearch')
  //   // console.log('Our Val', ourVal)
  //   // setState({...state, description: ourVal})
  //   if (e.code === "Enter") {
  //     // console.log(state)
  //     setItem({
  //       name: 'DID IT WORK'
  //     })
  //   }
  // }

  React.useEffect(() => {
    if (props.state) {
      // console.log('Set descriptiopn: ', props.state)
      setState({...state, descriptiopn: props.state.description, qty: props.state.qty, unit: props.state.unit, totalPrice: props.state.totalPrice })
    }
    if (props.item) {
      // console.log('Takeoff or Shed?? ', props.item)
      // takeoff or shedInventory
      if (props.item.shedInventory) {
        setItem(props.item.shedInventory)
        setValue(props.item.shedInventory)
      } else if (props.item.takoff) {
        setItem(props.item.takeoff)
        setValue(props.item.takeoff)
      } else {
        setItem(props.item)
        // setValue(props.item)
      }
    }
    setTakeOffList(props.takeOffList)
  }, [props])

  React.useEffect(() => {
    // console.log('Value has changed- lets modify the totalPrice too:', value)
    // console.log('Item too: ', item)
  }, [value])

  
  React.useEffect(() => {
    // console.log('Value has changed- lets modify the totalPrice too:', value)
  // console.log('Item changed on Modify Takeoff: ', item)
  }, [item])

  const handleDescriptionChange = (e, chosenLineItem) => {
    // console.log("Desc", e.target.value)
    setSelectedOptions(chosenLineItem)
    // setState({...state, description: chosenLineItem.name})
    // setItem(chosenLineItem)
  }
  const classes = useStyles();
  // const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabOpenValue(newValue);
  }
  const takeoffs = [
    {
      name: 'Test',
      title: 'Title This',
      _id: '453452345'
    },
    {
      name: 'Number 2',
      title: 'Ddaf kasdf kajsf lksdf ',
      _id: '45345345345'
    }
  ]

  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const handleAddNewLineItem = () => {
    // console.log('STATE: ', state)
    // console.log(value)
    // setState({...state, description: value.name })
  }

  const handleSearchChange = (e) => {
  
    setState({...state, test: e.target.value})
  }

  const handleSaveNewLineItem = (d) => {
  // console.log('Save Line Item', d)
    // Add this to our Estimate
    setValue(null)
    props.addLineItemToEstimate(d, state)
  }

  const InputItem = () => {
    return (
      <InputContainer>
       
      <InputArea>
      <Autocomplete
        freeSolo
        limitTags={5}
        id={`description-input`}
        // value={state.description}
        options={takeOffList}
        value={value}
        // getOptionLabel={(option) => option.name}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return 'Save ' + option.inputValue + ' as a New Item';
          }
          if (option._id && option.sku) {
            return option.name + ' - ' + option.sku + ' - ' + option.notes;
          }
          // Regular option
          return option.name + ' - ' + option.description + ' - ' + option.notes;
        }}
        // style={{ width: 200 }}
        // onChange={handleDescriptionChange}
        onChange={(event, newValue) => {
        // console.log('Change in Line Item Value: 300:', newValue)
          event.preventDefault()
          if (newValue && newValue._id) {
            // console.log('AN Active object was selected')
            setItem(newValue)
            setState({...state, ...newValue})
          } else {
            toggleOpen(true);
            setItem({
              title: newValue,
              name: newValue
            })
          }
          if (typeof newValue === 'string') {
            setValue({
              title: newValue,
              name: newValue
            });
            // setItem({
            //   title: newValue,
            //   name: newValue
            // })
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              title: newValue.inputValue,
              name: newValue.inputValue
            });
            setItem({
              title: newValue.inputValue,
              name: newValue.inputValue,
              description: newValue.inputValue
            })
            // toggleOpen(true);
            setDialogValue({
              title: newValue.inputValue,
              year: '',
            });
          } else {
            // console.log('NEWV AL: ', newValue)
            setValue(newValue);
            if (newValue && newValue.name) {
              // setItem(newValue)
            }
          
          }
        }}
        filterOptions={(options, params) => {
          // console.log('Filtered Options', options)
          // console.log('Params', params)
          // console.log('Input value: ', params.inputValue)
          const filtered = filter(options, params);
  
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
              name: 'Test This'
            });
          }
  
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
        // value={state.activeLineItem}
        fullWidth
        // InputProps={{ style: { fontSize: 20, color: '#fff', backgroundColor: '#333' } }}
        PaperComponent={({ children }) => (
          <Paper style={{ padding: 5, fontSize: 20, color: '#bbb', backgroundColor: '#fff' }}>{children}</Paper>
        )}
        // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
        renderInput={(params) => <TextField {...params}  fullWidth label="Take Off/Inventory List" variant="outlined" />}
      />
        {/* <TextField
          label="Line Item Description"
          variant="outlined"
          type="Text"
          value= {state.description}
          onChange={handleDescriptionChange}
          fullWidth
          style={{backgroundColor: '#fff'}}
          // defaultValue="Default Value"
        /> */}
      </InputArea>
      {/* <InputButtonArea>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={handleAddNewLineItem}
        >Add Line Item</Button>
      </InputButtonArea> */}
    </InputContainer>
    )
  }

  const TestStuff = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', border: '3px solid red'}}>
        <div style={{display: 'flex', border: '3px solid red'}}>1</div>
        <div style={{display: 'flex',  border: '3px solid red'}}>2</div>
        <div style={{display: 'flex', flexGrow: 2, border: '3px solid red'}}>3</div>
        <div style={{display: 'flex', border: '3px solid red'}}>4</div>
      </div>
    )
  }

  const [dialogValue, setDialogValue] = React.useState({
    title: '',
    year: '',
  });

  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      title: '',
      year: '',
    });

    toggleOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      title: dialogValue.title,
      year: parseInt(dialogValue.year, 10),
    });

    handleClose();
  };

  const addItem = (d) => {
    // Add the new item to our list
  // console.log('Add our item: ', d)
    setValue('')
    let newTakeoffs = takeOffList
    newTakeoffs.push(d)
    setTakeOffList(newTakeoffs)
    setItem(d)
  }

  return (
    <Container>
      <AddNewItemModal open={open} handleCloseModal={handleClose} item={item} addItem={addItem} />
        {/* <TitleArea>Please Enter In The Line Items Below:</TitleArea> */}
        
        <TopArea>
        
        <TabDisplay>
        <TabPanel value={tabOpenValue} index={0} style={{width: '100%'}}>
        <InputItem />
        <ConfigureItem state={state} takeOffList={props.takeOffList} client={props.client} addLineItemToEstimate={handleSaveNewLineItem} inventoryItem={item} estimate={props.estimate} />

      </TabPanel>
        </TabDisplay>

        </TopArea>
    </Container> 
  )
}

export default ModifyTakeOffComponent