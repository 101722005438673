import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    border: '1px solid #ccc',
    borderRadius: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
  denseField: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  totals: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  },
  totalText: {
    fontWeight: 500,
    fontSize: '0.9rem',
  }
}));

const laborTypes = [
  { value: 'labor', display: 'General Labor', defaultRate: 60, defaultSize: 3 },
  { value: 'install', display: 'Install', defaultRate: 70, defaultSize: 4 },
  { value: 'supervisor', display: 'Supervisor', defaultRate: 95, defaultSize: 1 },
  { value: 'sprayTech', display: 'Spray Tech', defaultRate: 90, defaultSize: 1 },
  { value: 'tech', display: 'Tech', defaultRate: 95, defaultSize: 1 },
  { value: 'operator', display: 'Operator', defaultRate: 90, defaultSize: 1 },
  { value: 'treeWorker', display: 'Tree Worker', defaultRate: 85, defaultSize: 2 },
  { value: 'arborist', display: 'Arborist', defaultRate: 95, defaultSize: 1 },
  { value: 'irrigationTech', display: 'Irrigation Tech', defaultRate: 150, defaultSize: 1 },
  { value: 'lightingTech', display: 'Lighting Tech', defaultRate: 95, defaultSize: 2 },
  { value: 'bobcatOperator', display: 'Bobcat Operator', defaultRate: 300, defaultSize: 1 },
  { value: 'tractorOperator', display: 'Tractor Operator', defaultRate: 300, defaultSize: 1 },
  { value: 'craneOperator', display: 'Crane Operator', defaultRate: 900, defaultSize: 1 }
];

const timeUnits = [
  { value: 'days', display: 'Days', hoursMultiplier: 8 },
  { value: 'hours', display: 'Hours', hoursMultiplier: 1 }
];

const LaborDetailsComponent = ({
  initialValues = {},
  onChange,
  client = {},
  showRates = true
}) => {
  const classes = useStyles();
  
  const [laborDetails, setLaborDetails] = useState({
    laborType: '',
    numberOfMen: '',
    quantity: '',
    unit: 'days',
    ratePerHour: '',
    ...initialValues
  });

  const handleFocus = (event) => event.target.select();

  const getRate = (laborType) => {
    const type = laborTypes.find(t => t.value === laborType);
    if (client.laborRates?.[laborType]) {
      return client.laborRates[laborType];
    }
    return type?.defaultRate || 0;
  };

  const getDefaultCrewSize = (laborType) => {
    const type = laborTypes.find(t => t.value === laborType);
    return type?.defaultSize || 3
  }

  const calculateTotals = (details) => {
    // const unit = timeUnits.find(u => u.value === details.unit);
    const totalHours = 
      Number(details.numberOfMen || 0) * 
      Number(details.numberOfDays || 0) * 8
      // (unit?.hoursMultiplier || 8);
      
    const totalCost = totalHours * Number(details.ratePerHour || 0);
    
    return {
      totalManHours: totalHours,
      totalCost: totalCost
    };
  };

  const handleChange = (field) => (event) => {
    const newValue = event.target.type === 'number' ? 
      Number(event.target.value) : 
      event.target.value;

    const updatedDetails = {
      ...laborDetails,
      [field]: newValue
    };

    if (field === 'laborType') {
      updatedDetails.ratePerHour = getRate(newValue);
      updatedDetails.numberOfMen = getDefaultCrewSize(newValue)
    }

    setLaborDetails(updatedDetails);

    const totals = calculateTotals(updatedDetails);
    onChange?.({ ...updatedDetails, ...totals });
  };

  const totals = calculateTotals(laborDetails);

  return (
    <Paper elevation={0} className={classes.container}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" size="small" className={classes.formControl}>
            <InputLabel>Labor Type</InputLabel>
            <Select
              value={laborDetails.laborType}
              onChange={handleChange('laborType')}
              label="Labor Type"
              className={classes.denseField}
            >
              <MenuItem value="">Select Type</MenuItem>
              {laborTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={2}>
          <TextField
            label="Workers"
            variant="outlined"
            size="small"
            type="number"
            value={laborDetails.numberOfMen}
            onChange={handleChange('numberOfMen')}
            onFocus={handleFocus}
            fullWidth
            className={classes.denseField}
            // helperText={`Size of Crew`}
          />
        </Grid>

        <Grid item xs={4} sm={2}>
          <TextField
            label="# of Days"
            variant="outlined"
            size="small"
            type="number"
            value={laborDetails.numberOfDays}
            onChange={handleChange('numberOfDays')}
            onFocus={handleFocus}
            fullWidth
            className={classes.denseField}
            // helperText={`You can do fractional values (0.5 etc) if needed`}
          />
        </Grid>

        {/* <Grid item xs={4} sm={2}>
          <FormControl variant="outlined" size="small" className={classes.formControl}>
            <InputLabel>Unit</InputLabel>
            <Select
              value={laborDetails.unit}
              onChange={handleChange('unit')}
              label="Unit"
              className={classes.denseField}
            >
              {timeUnits.map((unit) => (
                <MenuItem key={unit.value} value={unit.value}>
                  {unit.display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}

        {showRates && (
          <Grid item xs={12} sm={3}>
            <TextField
              label="Rate/Hr"
              variant="outlined"
              size="small"
              type="number"
              value={laborDetails.ratePerHour}
              onChange={handleChange('ratePerHour')}
              onFocus={handleFocus}
              fullWidth
              className={classes.denseField}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <div className={classes.totals}>
            <Typography className={classes.totalText}>
              Total Hours: {totals.totalManHours}
            </Typography>
            {showRates && (
              <Typography className={classes.totalText}>
                Total: ${totals.totalCost.toFixed(2)}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LaborDetailsComponent;
