import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  makeStyles,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { 
  ExpandMore as ExpandMoreIcon,
  Schedule as ScheduleIcon,
  Person as PersonIcon,
  Group as GroupIcon,
  Assignment as AssignmentIcon,
  Check as CheckIcon
} from '@material-ui/icons';
import axios from 'axios';
import ProfilePhoto from '../Employees/ProfilePhoto';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  header: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
  },
  accordion: {
    marginBottom: theme.spacing(2),
  },
  accordionSummary: {
    backgroundColor: theme.palette.grey[50],
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  profilePhotoContainer: {
    width: '40px',
    height: '40px', 
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'inline-block'
  },
  timingGrid: {
    marginBottom: theme.spacing(2),
  },
  timeLabel: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  timeValue: {
    marginLeft: theme.spacing(1),
  },
  employeeCard: {
    padding: theme.spacing(2),
    height: 80,
    marginBottom: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  visitDuration: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  taskList: {
    backgroundColor: theme.palette.background.paper,
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
  dialogContent: {
    padding: theme.spacing(2),
    minWidth: '80vw',
    maxHeight: '85vh'
  }
}));

const formatDateTime = (dateString, type = null) => {
  if (!dateString) return 'N/A';
  let dateToReturn = ''
  if (type) {
    if (type === 'short') {
      dateToReturn = `${new Date(dateString).toLocaleDateString('en-US', {year: '2-digit', month: '2-digit'})} ${new Date(dateString).toLocaleTimeString('en-US', {timeStyle: 'short'})}`
    }
    return dateToReturn
  } else {
    return new Date(dateString).toLocaleString('en-US', {
      dateStyle: 'medium',
      timeStyle: 'short'
    });
  }
};

const calculateDuration = (start, end) => {
  if (!start || !end) return 'N/A';
  const duration = (new Date(end) - new Date(start)) / (1000 * 60); // minutes
  if (duration < 60) {
    return `${Math.round(duration)}m`;
  }
  const hours = Math.floor(duration / 60);
  const minutes = Math.round(duration % 60);
  return `${hours}h ${minutes}m`;
};

const VisitDetails = ({ visit, workorder, expanded, onChange, crews }) => {
  const classes = useStyles();
  console.log("Workorder', workorder", workorder)
  const renderEmployees = (shift) => {
    if (!shift.employees?.length) return null;
    // console.log('Add shfit', shift)
    return (
      <Grid container spacing={2}>
        {shift.employees.map((employee) => (
          <Grid item xs={12} sm={6} md={4} key={employee._id}>
            <Paper variant="outlined" className={classes.employeeCard}>
              <Typography variant="subtitle1">
                {
                  employee?.profile_photo_url ? (
                    <ProfilePhoto employee={employee} size={32} />
                  ) : (
                    <PersonIcon className={classes.icon} size={32} />
                  )
                }
               
                {employee.displayName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {employee.type}
              </Typography>
              {/* {employee.skills?.length > 0 && (
                <Box mt={1}>
                  {employee.skills.map((skill) => (
                    <Chip
                      key={skill}
                      label={skill}
                      size="small"
                      className={classes.chip}
                    />
                  ))}
                </Box>
              )} */}
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Accordion 
      expanded={expanded} 
      onChange={onChange}
      className={classes.accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.accordionSummary}
      >
        <Typography variant="subtitle1">
          Visit on {formatDateTime(visit.jobStarted)}
          <span className={classes.visitDuration}>
            Duration: {calculateDuration(visit.jobStarted, visit.jobFinished)}
          </span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.root}>
          <div className={classes.section}>
            <Typography variant="h6" className={classes.sectionTitle}>
              <ScheduleIcon /> Timing Details
            </Typography>
            <Grid container spacing={2} className={classes.timingGrid}>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.timeLabel}>
                  Started: 
                  <span className={classes.timeValue}>
                    {formatDateTime(visit.jobStarted)}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.timeLabel}>
                  Finished: 
                  <span className={classes.timeValue}>
                    {formatDateTime(visit.jobFinished)}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.timeLabel}>
                  Total Man Hours: 
                  <span className={classes.timeValue}>
                    {visit.totalManHours?.toFixed(2) || '0'} hours
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </div>

          <Divider />

          {visit.shifts?.map((shift, index) => {
            // console.log('Find the crew', shift)
              let crew = crews.find(item => (item._id === shift.crew))
              // console.log('Crew:', crew)
              let crewName = `${crew?.name}${crew?.foremanName ? ` (${crew.foremanName})` : ''}`
            
            return (
            <div key={index} className={classes.section}>
              <Typography variant="h6" className={classes.sectionTitle}>
                <GroupIcon /> Crew Details - Shift {index + 1}
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {crewName}
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Shift Duration: {calculateDuration(shift.started, shift.paused)}
                {shift.numberOfMen && ` • ${shift.numberOfMen} crew members`}
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {formatDateTime(shift.started, 'short')} - {formatDateTime(shift.paused, 'short')}
              </Typography>
              {renderEmployees(shift)}
            </div>
          )})}

          {workorder?.tasksCompleted?.length > 0 && (
            <>
              <Divider />
              <div className={classes.section}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  <AssignmentIcon /> Tasks Completed
                </Typography>
                <List className={classes.taskList}>
                  {workorder.tasksCompleted.map((task) => (
                    <ListItem key={task.id}>
                      <ListItemIcon>
                        <CheckIcon className={classes.checkIcon} />
                      </ListItemIcon>
                      <ListItemText primary={task.message} />
                    </ListItem>
                  ))}
                </List>
              </div>
            </>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const ServiceVisitModal = ({ url, workorder, open, handleClose, crews }) => {
  const classes = useStyles();
  const [serviceVisits, setServiceVisits] = React.useState([]);
  const [expandedVisit, setExpandedVisit] = React.useState(0);

  React.useEffect(() => {
    let isMounted = true;
    const fetchServiceVisits = async (id) => {
      try {
        const response = await axios({
          method: 'get',
          url: `${url}/api/jobs/getAssociatedServiceVisits?id=${id}`
        });
        
        if (response.data && isMounted) {
          if (response.data.visits && response.data.visits.length) {
            setServiceVisits(response.data.visits);
          }
        }
      } catch (error) {
        console.error('Error fetching service visits:', error);
      }
    };

    if (workorder?._id) {
      fetchServiceVisits(workorder._id);
    }

    return () => {
      isMounted = false;
    };
  }, [workorder?._id, url]);

  const handleAccordionChange = (index) => (event, isExpanded) => {
    setExpandedVisit(isExpanded ? index : false);
  };

  let crew = crews.find(item => (item._id === workorder?.crew))
  // console.log('Crew:', crew)
  let crewName = `${crew?.name}${crew?.foremanName ? ` (${crew?.foremanName})` : ''}`

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h6">{workorder?.jobName}</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Work Order #{workorder?.number} • Job #{workorder?.jobNumber}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
         {crewName}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {serviceVisits.length > 0 ? (
          <div className={classes.root}>
            <div className={classes.header}>
              <Typography variant="subtitle1">
                {serviceVisits.length} service visit{serviceVisits.length !== 1 ? 's' : ''} recorded
              </Typography>
            </div>
            {serviceVisits.map((visit, index) => (
              <VisitDetails
                key={visit._id}
                visit={visit}
                workorder={workorder}
                expanded={expandedVisit === index}
                onChange={handleAccordionChange(index)}
                crews={crews}
              />
            ))}

            {/* Service Information */}
            {workorder && (
              <>
                <Divider />
                <div className={classes.section}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    Service Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        <strong>Service:</strong> {workorder.serviceName}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Category:</strong> {workorder.serviceCategory}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        <strong>Price:</strong> ${workorder.price?.toFixed(2)}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Status:</strong> {workorder.status}
                      </Typography>
                    </Grid>
                    {workorder.serviceNotes && (
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          <strong>Notes:</strong> {workorder.serviceNotes}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </>
            )}
          </div>
        ) : (
          <Typography>No service visits recorded for this work order</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ServiceVisitModal;
