import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  MenuItem,
  FormControl,
  Select,
  makeStyles,
  Divider,
  Tooltip,
  FormHelperText
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ErrorIcon from '@material-ui/icons/Error';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { currencyFormat, handleFocus, unitsOfMeasureAll } from '../../services/common';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: '90vw',
    width: '90vw'
  },
  modalContent: {
    padding: theme.spacing(3),
  },
  formSection: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  errorText: {
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  errorIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  addItemSection: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  totalSection: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(2),
  },
  validationError: {
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  errorSummary: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const calculateTotal = (item) => {
  return (parseFloat(item.qty) || 0) * (parseFloat(item.pricePer) || 0);
};

export const CreatePOModal = ({
  open,
  onClose,
  onConfirm,
  vendors = [],
  materials = [],
}) => {
  const classes = useStyles();

  // Form state
  const [formData, setFormData] = useState({
    vendor: null,
    requestedDeliveryDate: '',
    supplierNotes: '',
    internalNotes: '',
    status: 'draft',
    type: 'Material',
  });

  // Validation state
  const [formErrors, setFormErrors] = useState({
    vendor: '',
    requestedDeliveryDate: '',
    supplierNotes: '',
    type: '',
  });

  // Line items state
  const [lineItems, setLineItems] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [currentItem, setCurrentItem] = useState({
    description: '',
    qty: '',
    pricePer: '',
    unit: 'Each',
    materialId: '',
    optionId: '',
  });

  // Reset form when modal opens
  useEffect(() => {
    if (!open) return;
    setFormData({
      vendor: null,
      requestedDeliveryDate: '',
      supplierNotes: '',
      internalNotes: '',
      status: 'draft',
      type: 'Material',
    });
    setFormErrors({
      vendor: '',
      requestedDeliveryDate: '',
      supplierNotes: '',
      type: '',
    });
    setLineItems([]);
    resetCurrentItem();
  }, [open]);

  const resetCurrentItem = () => {
    setCurrentItem({
      description: '',
      qty: '',
      pricePer: '',
      unit: 'Each',
      materialId: '',
      optionId: '',
    });
    setSelectedMaterial(null);
    setMaterialOptions([]);
    setErrorMessage('');
  };

  const validateForm = () => {
    const errors = {
      vendor: !formData.vendor ? 'Please select a vendor' : '',
      requestedDeliveryDate: !formData.requestedDeliveryDate ? 'Please select a delivery date' : '',
      supplierNotes: !formData.supplierNotes ? 'Please add supplier notes' : '',
    };

    setFormErrors(errors);
    return !Object.values(errors).some(error => error);
  };

  const handleFormChange = (field) => (event) => {
    const newValue = event.target.value;
    setFormData(prev => ({
      ...prev,
      [field]: newValue
    }));
    
    // Clear field-specific error when user makes a change
    setFormErrors(prev => ({
      ...prev,
      [field]: '',
    }));

    // Reset line items when type changes
    if (field === 'type') {
      setLineItems([]);
      resetCurrentItem();
    }
  };

  const handleVendorChange = (event, newValue) => {
    setFormData(prev => ({
      ...prev,
      vendor: newValue
    }));
    setFormErrors(prev => ({
      ...prev,
      vendor: '',
    }));
  };

  const handleItemChange = (field) => (event) => {
    setErrorMessage("");
    setCurrentItem(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleMaterialSelect = (event, newValue) => {
    setErrorMessage("");
    setSelectedMaterial(newValue);
    
    if (newValue && newValue.options) {
      setMaterialOptions(newValue.options);
      setCurrentItem(prev => ({
        ...prev,
        materialId: newValue._id,
        description: newValue.description || newValue.name,
        optionId: '',
        unit: 'Each',
        pricePer: '',
      }));
    } else if (newValue) {
      setMaterialOptions([]);
      setCurrentItem(prev => ({
        ...prev,
        materialId: newValue._id,
        description: newValue.description || newValue.name,
        unit: newValue.unit || 'Each',
        pricePer: newValue.defaultPrice || '',
        optionId: null,
      }));
    }
  };

  const handleOptionChange = (e) => {
    setErrorMessage("");
    const selectedOption = materialOptions.find(opt => opt._id === e.target.value);
    if (selectedOption) {
      const costPer = parseFloat(selectedOption.price) || 0;
      const pricePer = parseFloat(selectedOption.msrp) || (costPer * 1.4);

      setCurrentItem(prev => ({
        ...prev,
        optionId: selectedOption._id,
        size: selectedOption.size,
        unit: selectedOption.size,
        pricePer,
        description: `${selectedMaterial.name} - ${selectedOption.name || selectedOption.size}`
      }));
    }
  };

  const getValidationMessage = () => {
    if (!currentItem.qty) {
      return "Please enter a quantity";
    }
    if (!currentItem.pricePer) {
      return "Please enter a price per unit";
    }

    if (formData.type === 'Material') {
      if (!currentItem.materialId) {
        return "Please select a material";
      }
      if (materialOptions.length > 0 && !currentItem.optionId) {
        return "Please select a size/option for this material";
      }
    } else {
      if (!currentItem.description) {
        return "Please enter a description";
      }
    }

    return "";
  };

  const handleAddLineItem = () => {
    const validationMessage = getValidationMessage();
    if (!validationMessage) {
      setLineItems([...lineItems, { ...currentItem, id: Date.now(), type: formData.type }]);
      resetCurrentItem();
    } else {
      setErrorMessage(validationMessage);
    }
  };

  const handleRemoveLineItem = (id) => {
    setLineItems(lineItems.filter(item => item.id !== id));
  };

  const calculateGrandTotal = () => {
    return lineItems.reduce((sum, item) => sum + calculateTotal(item), 0);
  };

  const handleSubmit = () => {
    if (validateForm() && lineItems.length > 0) {
      onConfirm({
        ...formData,
        lineItems,
        total: calculateGrandTotal(),
        createdAt: new Date().toISOString(),
      });
    }
  };

  const getMissingFieldsMessage = () => {
    const missing = [];
    if (!formData.vendor) missing.push('Vendor');
    if (!formData.requestedDeliveryDate) missing.push('Delivery Date');
    if (!formData.supplierNotes) missing.push('Supplier Notes');
    if (lineItems.length === 0) missing.push('Line Items');
    return missing;
  };

  const missingFields = getMissingFieldsMessage();

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>Create Purchase Order</DialogTitle>
      <DialogContent className={classes.modalContent}>
        {missingFields.length > 0 && (
          <Paper className={classes.errorSummary} elevation={0}>
            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
              <ErrorIcon className={classes.errorIcon} />
              Missing required information: {missingFields.join(', ')}
            </Typography>
          </Paper>
        )}

        <div className={classes.formSection}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Purchase Order Details
          </Typography>
          
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth className={classes.formField}>
                <TextField
                  select
                  label="PO Type"
                  value={formData.type}
                  onChange={handleFormChange('type')}
                  variant="outlined"
                  error={!!formErrors.type}
                  helperText={formErrors.type}
                >
                  <MenuItem value="Material">Material</MenuItem>
                  <MenuItem value="Subcontractor">Subcontractor</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Autocomplete
                value={formData.vendor}
                onChange={handleVendorChange}
                options={vendors}
                getOptionLabel={(option) => option?.name || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Vendor"
                    variant="outlined"
                    required
                    error={!!formErrors.vendor}
                    helperText={formErrors.vendor}
                    className={classes.formField}
                  />
                )}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.formField}
                label="When Is This Needed"
                type="date"
                fullWidth
                variant="outlined"
                value={formData.requestedDeliveryDate}
                onChange={handleFormChange('requestedDeliveryDate')}
                error={!!formErrors.requestedDeliveryDate}
                helperText={formErrors.requestedDeliveryDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                className={classes.formField}
                label="Supplier Notes"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                value={formData.supplierNotes}
                onChange={handleFormChange('supplierNotes')}
                error={!!formErrors.supplierNotes}
                helperText={formErrors.supplierNotes}
                onFocus={handleFocus}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                className={classes.formField}
                label="Internal Notes"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={formData.internalNotes}
                onChange={handleFormChange('internalNotes')}
                onFocus={handleFocus}
              />
            </Grid>
          </Grid>
        </div>

        <Divider className={classes.divider} />

        <Typography variant="h6" className={classes.sectionTitle}>
          Line Items
        </Typography>

        <Paper className={classes.addItemSection}>
          <Grid container spacing={2}>
            {formData.type === 'Material' ? (
              <>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    value={selectedMaterial}
                    onChange={handleMaterialSelect}
                    options={materials}
                    getOptionLabel={(option) => option?.name || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Material"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {materialOptions.length > 0 ? (
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        select
                        label="Size/Option"
                        value={currentItem.optionId || ''}
                        onChange={handleOptionChange}
                        variant="outlined"
                      >
                        {materialOptions.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.name || option.size}
                            {option.msrp ? ` - ${option.msrp}` : ''}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                    ) : (
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          select
                          label="Unit"
                          value={currentItem.unit}
                          onChange={handleItemChange('unit')}
                          variant="outlined"
                          fullWidth
                        >
                          {unitsOfMeasureAll.map((unit) => (
                            <MenuItem key={unit.value} value={unit.value}>
                              {unit.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    )}
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    value={currentItem.description}
                    onChange={handleItemChange('description')}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              )}
  
              <Grid item xs={6} md={3}>
                <TextField
                  type="number"
                  label="Quantity"
                  value={currentItem.qty}
                  onChange={handleItemChange('qty')}
                  variant="outlined"
                  fullWidth
                  onFocus={handleFocus}
                />
              </Grid>
  
              <Grid item xs={6} md={3}>
                <Tooltip title="How Much Does Each Unit Cost?">
                  <TextField
                    type="number"
                    label="Cost Per Unit"
                    value={currentItem.pricePer}
                    onChange={handleItemChange('pricePer')}
                    variant="outlined"
                    fullWidth
                    onFocus={handleFocus}
                  />
                </Tooltip>
              </Grid>
  
              <Grid item xs={12}>
                {errorMessage && (
                  <Typography className={classes.validationError}>
                    <ErrorIcon className={classes.errorIcon} />
                    {errorMessage}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleAddLineItem}
                  className={classes.addButton}
                  startIcon={<AddIcon />}
                >
                  Add Item
                </Button>
              </Grid>
            </Grid>
          </Paper>
  
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Price Per</TableCell>
                  <TableCell align="right">Total</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lineItems.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.description}</TableCell>
                    <TableCell align="right">
                      {item.qty} {item.unit}
                    </TableCell>
                    <TableCell align="right">
                      {currencyFormat(item.pricePer)}
                    </TableCell>
                    <TableCell align="right">
                      {currencyFormat(calculateTotal(item))}
                    </TableCell>
                    <TableCell>
                      <IconButton 
                        size="small"
                        onClick={() => handleRemoveLineItem(item.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
  
          <Paper className={classes.totalSection}>
            <Typography variant="h6" align="right">
              Total: {currencyFormat(calculateGrandTotal())}
            </Typography>
          </Paper>
        </DialogContent>
        
        <DialogActions>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={missingFields.length > 0}
          >
            Create Purchase Order
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default CreatePOModal;
  