import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Grid,
  Paper,
  Typography,
  Divider,
  Box
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  headerSection: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    width: '100%'
  },
  jobHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  jobDetails: {
    marginTop: theme.spacing(2),
  },
  detailRow: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
  },
  label: {
    fontWeight: 500,
    marginRight: theme.spacing(1),
    minWidth: 120,
    color: theme.palette.text.secondary,
  },
  value: {
    color: theme.palette.text.primary,
  },
  followingWrapper: {
    marginBottom: theme.spacing(2),
  },
  financialInfo: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
  },
  depositAmount: {
    color: theme.palette.success.main,
    fontWeight: 500,
  },
  depositBalance: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  }
}));

// const calculateOverallProgress = (lineItems, invoices) => {
//   console.log('Calculate our progress...', lineItems)
//   console.log('invoices', invoices)
//   const totals = lineItems.reduce((acc, item) => {
//     const originalAmount = parseFloat(item.totalPrice) || 0;
//     const originalQty = parseFloat(item.qty) || 0;
//     const usedQty = 0 // calculateUsedQuantity(item._id, workorders);
//     const invoicedAmount = calculateInvoicedAmount(item._id, invoices);

//     return {
//       totalAmount: acc.totalAmount + originalAmount,
//       invoicedAmount: acc.invoicedAmount + invoicedAmount,
//       totalQty: acc.totalQty + originalQty,
//       allocatedQty: acc.allocatedQty + usedQty
//     };
//   }, { totalAmount: 0, invoicedAmount: 0, totalQty: 0, allocatedQty: 0 });

//   return {
//     invoiceProgress: (totals.invoicedAmount / totals.totalAmount) * 100,
//     workorderProgress: (totals.allocatedQty / totals.totalQty) * 100,
//     totalAmount: totals.totalAmount,
//     invoicedAmount: totals.invoicedAmount,
//   };
// };

// const calculateInvoicedAmount = (lineItemId, invoices = []) => {
//   return invoices.reduce((total, invoice) => {
//     const lineItem = invoice.estimateLineItems?.find(i => i._id === lineItemId);
//     return total + (lineItem ? parseFloat(lineItem.invoiceAmount) || 0 : 0);
//   }, 0);
// };

const JobHeaderSection = ({ job, FollowingComponent, JobName, currencyFormat, progress }) => {
  const classes = useStyles();

 
  console.log('Progress', progress)
  const DetailRow = ({ label, value }) => (
    <div className={classes.detailRow}>
      <Typography variant="body1" className={classes.label}>
        {label}:
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {value}
      </Typography>
    </div>
  );

  return (
    <Paper elevation={1} className={classes.headerSection}>
      <Grid container spacing={3}>
        {/* Following Component Section */}
        <Grid item xs={12} className={classes.followingWrapper}>
          <FollowingComponent data={job} type={'Job'} _id={job._id} />
        </Grid>
        
        <Grid item xs={12} md={6}>
          {/* Job Name Section */}
          <div className={classes.jobHeader}>
            <JobName job={job} />
          </div>
          
          <Divider />
          
          {/* Main Details Section */}
          <div className={classes.jobDetails}>
            <DetailRow 
              label="Address" 
              value={job.jobSiteAddress} 
            />
            <DetailRow 
              label="Sales Rep" 
              value={job.salesRepName} 
            />
            <DetailRow 
              label="Scheduled" 
              value={moment(job.startDate).format('MM/DD/YYYY (ddd)')} 
            />
          </div>
        </Grid>

        {/* Financial Information Section */}
        <Grid item xs={12} md={6}>
          <Box className={classes.financialInfo}>
            <Typography variant="h6" gutterBottom>
              Financial Summary
            </Typography>
            <DetailRow 
              label="Deposit Amount" 
              value={
                <span className={classes.depositAmount}>
                  {currencyFormat(job.depositAmount)}
                </span>
              } 
            />
            <DetailRow 
              label="Deposit Balance" 
              value={
                <span className={classes.depositBalance}>
                  {currencyFormat(job.depositBalance)}
                </span>
              } 
            />
             <DetailRow 
              label="Invoiced Amount" 
              value={
                <span className={classes.depositBalance}>
                  {currencyFormat(progress.invoicedAmount)}
                </span>
              } 
            />
             <DetailRow 
              label="Remaining Amount to Invoice" 
              value={
                <span className={classes.depositBalance}>
                  {currencyFormat(progress.totalAmount - progress.invoicedAmount)}
                </span>
              } 
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default JobHeaderSection;
