import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetailList from './ServiceDetailList'
import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import JobPicker from './JobPicker'
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const paymentTypes = [
  {
    text: 'Cash Collected',
    value: '1'
  },
  {
    text: 'Paper Check Received',
    value: '2'
  }
  // {
  //   text: 'Creating Deposit Invoice',
  //   value: '3'
  // }
]

const defaultStateValues = {
  date: new Date(),
  amount: 0,
  notes: '',
  paymentTypes: paymentTypes,
  paymentType: {text: '', value: ''},
  showCheckNumber: 'none',
  checkNumber: '',
  howToApply: 'Manually',
  depositAmount: 0,
  showJobPicker: false,
  saved: false,
  jobs: [],
  jobId: ''
}

const DepositModal = (props) => {
  // console.log('DepositModal  Props: ', props)
  const classes = useStyles();

  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  // const [paymentTypes, setPaymentTypes] = React.useState([{ text: '', value: ''}])
 
  // const [invoice, setInvoice] = React.useState(null)
  const [state, setState] = React.useState(defaultStateValues)

  // let paymentProfiles = props.paymentProfiles
  // if (paymentProfiles && paymentProfiles.length > 0) {
  //   // console.log('Add our payment profiles!!!')
  //   let profiles = []
  //   paymentProfiles.map(pp => {
  //     let obj = {}
  //     if (pp.payment.creditCard) {
  //       obj.Text = 'Charge ' + pp.payment.creditCard.cardType + ' ' + pp.payment.creditCard.cardNumber
  //       obj.value = '3'
  //       obj.id = pp.customerPaymentProfileId
  //     } else {
  //       obj.Text = 'Charge ' + pp.payment.bankAccount.bankName + ' ' + pp.payment.bankAccount.accountNumber
  //       obj.value = '4'
  //       obj.id = pp.customerPaymentProfileId
  //     }
  //     profiles.push(obj)
  //   })
  //   profiles.push({ Text: ' -------- Offline Collection -----------  '})
  //   let newArr = profiles.concat(paymentTypes)
  //   // console.log('NEW PAYMENT TYPES:: ', newArr)
  //   // console.log('Payment Options', newArr)
  //   setPaymentTypes(newArr)

  React.useEffect(() => {
    if (props && props.paymentProfiles && props.paymentProfiles.length) {
      // console.log('Build otu our profile options')
      let paymentProfiles = paymentTypes
      for (const pp of props.paymentProfiles) {
        let obj = {}
        if (pp.payment.creditCard) {
          obj.text = 'Charge ' + pp.payment.creditCard.cardType + ' ' + pp.payment.creditCard.cardNumber
          obj.value = '3'
          obj.id = pp.customerPaymentProfileId
        } else {
          obj.text = 'Charge ' + pp.payment.bankAccount.bankName + ' ' + pp.payment.bankAccount.accountNumber
          obj.value = '4'
          obj.id = pp.customerPaymentProfileId
        }
        paymentProfiles.push(obj)
        
      }
      // console.log('Paymetn Profiles to use: ', paymentProfiles)
      setState({...state, paymentTypes: paymentProfiles, jobs: props.jobs}) 
    } else {
      setState({...state, jobs: props.jobs})
    }
  }, [props])

const handleClose = () => {
  // console.log('Closing DepositModal Modal')
  setState(defaultStateValues)
  // props.closeModal()
  props.updateParent('Close')
}

const handleSave = (e, passedData) => {
  setLoading(true)
  // console.log('Save this Deposit!!!', state)
    // console.log('Send this Deposit to The Server')
    let dataToPass = {
      ...state,
      ...passedData
    }
    // console.log('Data to pass', dataToPass)
    let obj = {
      client: props.client,
      data: dataToPass,
      user: props.user
    }
    // console.log('Accept Depsot', obj)
    axios({
      method: 'post',
      url:  `${props.url}/api/payment/acceptDeposit`,
      data: obj
    }).then(response => {
      setLoading(false)
      // console.log('Axios response from acceptDeposit: ', response)
      // console.log(response.data)
      if (response && response.data && response.data.deposit) {
        // console.log('We have a DepositAccepted issued!!', response.data)
        props.notification({
          type: 'success',
          title: 'Deposit Accepted!',
          message: 'Deposit Was Created Successfully'
        })
        // handleClose('success', response.data)
        if (state.howToApply === 'Job' && !dataToPass?.jobId) {
          setState({...state, saved: true, deposit: response.data.deposit, showJobPicker: true})
        } else {
          props.updateParent(response.data)
        }
        setState(defaultStateValues)
      } else {
        props.notification({
          type: 'warning',
          title: 'Unknown Error',
          message: 'We Do Not Know Why This Shows Up...'
        })
      }
  }).catch(e => {
    console.log('Error issuing deposit', e)
  })
}
const updatePaymentType = (a, b) => {
  // console.log('Update payment TYPE!: ', b)
  // console.log(a)
  if (b.value === '2') {
    setState({...state, showCheckNumber: 'inline', paymentType: b})
  } else {
    setState({...state, showCheckNumber: 'none', paymentType: b})
  }
}
// const updateFromJobPicker = (type, d) => {
//   // console.log('Upate from job picker; ', type)
//   // console.log(d)
//   if (d && d._id) setState({...state, deposit: d})
// }
const updateFromJobPicker = (type, d, jobs) => {
  // console.log('Upate from job picker; ', type)
  // console.log(d)
  if (d && d._id && jobs && jobs.length) {
    setState({...state, jobs: jobs, deposit: d})
  } else if (d && d._id) {
    setState({...state, deposit: d})
  }
}
const changeApplicationMethod = (e) => {
  // console.log('Change method', e)
  let fakeDeposit = {
    _id: 'pending',
    balance: state.amount
  }
  let toShowIt = false
  if (e.target.value === 'Job') {
    toShowIt = true
  }
  let newState = {
    howToApply: e.target.value,
    showJobPicker: toShowIt,
    deposit: toShowIt ? fakeDeposit : {}
  }
  setState(prevState => ({ ...prevState, ...newState }))
}

const handleInvoiceSaving = (d) => {
  console.log('Generate a Deposit Invoice with this', d)
  
  const newState = {
    jobId: d?.job?._id,
    depositAmount: d?.amount,
    showJobPicker: false
  }
  setState(prevState => ({...prevState, ...newState }))
  handleSave(null, newState)
}

return (
  <div className={classes.root}>
    <Dialog open={props.open} fullWidth={false} onClose={handleClose} maxWidth='sm' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Generate Deposit</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please Provide Details On This Deposit
          </DialogContentText>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <TextField
                  id="amount"
                  label="Deposit Amount"
                  type="number"
                  className={classes.paddedStuff}
                  fullWidth
                  disabled={state.saved}
                  value={state.amount || ''} // "2017-05-24"}
                  // className={classes.textField}
                  onChange={ e => setState({...state, amount: parseFloat(e.target.value)}) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
                <Autocomplete
                id="PaymentType"
                label="Payment Type"
                options={state.paymentTypes}
                getOptionLabel={(option) => option.text}
                onChange={updatePaymentType}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {state.paymentType}
                // onChange={ e => setState({...state, paymentType: e.target.value }) }
                renderInput={(params) => <TextField className={classes.paddedStuff} InputLabelProps={{shrink: true}} {...params} label="Payment Type" />}
              />
            </Grid>
            <Grid item xs={12} lg={12} style={{display: state.showCheckNumber}}>
              <TextField
                  id="checknumber"
                  label="Check Number"
                  // type="number"
                  className={classes.paddedStuff}
                  fullWidth
                  value={state.checkNumber } // "2017-05-24"}
                  onChange={ e => setState({...state, checkNumber: e.target.value}) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
                  id="notes"
                  label="Notes"
                  type="text"
                  className={classes.paddedStuff}
                  fullWidth
                  value={state.notes || ''} // "2017-05-24"}
                  // className={classes.textField}
                  onChange={ e => setState({...state, notes: e.target.value}) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Grid>
            <Grid item className={classes.paddedStuff} xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">How Should We Apply This Deposit?</FormLabel>
                <RadioGroup value={state.howToApply} onChange={changeApplicationMethod} aria-label="Deposit Application" name="howToApply" defaultValue="Manually">
                  <FormControlLabel value="Manually" control={<Radio color="primary" />} label="Manually" />
                  <FormControlLabel
                    value="All"
                    control={<Radio color="primary" />}
                    label="All"
                    // labelPlacement="bottom"
                  />
                  <FormControlLabel value="Mtc" control={<Radio color="primary" />} label="Mtc Only" />
                  <FormControlLabel value="Job" control={<Radio color="primary" />} label="Specific Job" />
                </RadioGroup>
              </FormControl>
        
            </Grid>
          <Grid item lg={12}>
            { state.showJobPicker ? <JobPicker jobs={state.jobs} deposit={state.deposit} client={props.client} updateParent={updateFromJobPicker} forInvoiceOnly={handleInvoiceSaving} /> : '' }
          </Grid>
          </Grid>
          
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading || state.saved || state.showJobPicker || !state.notes || !state.amount} onClick={handleSave}>
            {
              loading ? <CircularProgress /> : 'Save'
            }
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(DepositModal))