import React from 'react';
import styled from 'styled-components';
import { Button, CircularProgress, IconButton, TextField, TextareaAutosize } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import NewSizeModal from '../../Settings/ProposalSettings/GenericOptionModal'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import { connect } from 'react-redux'

const filter = createFilterOptions();

const defaultState = {
  size: '',
  name: '',
  notes: '',
  msrp: '',
  _id: '',
  barcode: ''
}

const Modal = (props) => {
  // Sizes, Prices etc
  // console.log('Add Option MODAL PROPS:', props)
  const [sizes, setSizes] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState(defaultState)
  const scope = props.scope || {}

  React.useEffect(() => {
    if (props.optionToEdit && props.optionToEdit._id) {
      const item = props.optionToEdit
      let sizeOpt = props.sizes.find(size => (size._id.toString() === item.sizeId)) || { name: '', sizeId: ''}
      // console.log('Item to edit', item)
      // console.log('Side otpio', sizeOpt)
      let newState = {
        ...state,
        msrp: item.msrp,
        name: item.name,
        size: sizeOpt,
        sizeId: sizeOpt._id,
        notes: item.notes,
        barcode: item.barcode,
        _id: item._id
      }
      // console.log('Set this ', newState)
      setState(newState)
    } else {
      setState(defaultState)
    }
  }, [props])

  const handleClose = () => {

    props.closeModal()
  }

  const handleSave = async () => {
    if (isEditing) {
      // console.log('Update our item')
      setLoading(true)
      let obj = {
        itemId: props.item._id,
        ...state
      }
      // console.log('Update this option', obj)
      setLoading(false)
      props.closeModal(obj)
    } else {
      // console.log('Save our new options:', state)
      setLoading(true)
      let obj = {
        itemId: props.item._id,
        ...state
      }
      // console.log('Save this option', obj)
      const resp = await axios.post(`${props.url}/api/company/modifyTakeoffOptions?addOnly=true`, obj)
      // console.log('Saved new option', resp.data)
      setLoading(false)
      props.closeModal('newOptionAdded', resp.data)
    }
  }

  // Fitler our sizes
  // console.log('Inventory item options', props.item)
  console.log('Sizes to filter', props.sizes)
  const filteredSizes = props.sizes
  // .filter(size => {
  //   // console.log('Size to filter...', size)
  //   if (props.item && props.item.options && props.item.options.length) {
  //     let index = props.item.options.findIndex(item => (item.sizeId === size._id))
  //     // console.log('Index', index)
  //     // console.log('47 ', size)
  //     if (index < 0) return size
  //   } else {
  //     return size
  //   }
  // })
  let isEditing = (props.optionToEdit && props.optionToEdit._id) ? true : false
// console.log("Filtered sizes", filteredSizes)
  return (
    <Dialog
    open={props.open}
    onClose={handleClose}
    fullWidth
    maxWidth='md'
    
    >
      <DialogTitle>{ isEditing ? 'Edit Option' : 'Add New Option' } ({props.item ? props.item.name : ''}) - {props.scope.name} </DialogTitle>
      <DialogContent>
        <NewOptionConfiguration item={props.item} sizes={filteredSizes} setParentState={setState} parentState={state} />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={handleSave}
          disabled={!state.name || !state.size || loading}
          >
            {
              loading ? <CircularProgress size={18} /> : ''
            }
            {
              isEditing ? 'Update' : 'Save'
            }
          </Button>
      </DialogActions>
    </Dialog>
  )
}


const NewOptionConfiguration = ({ sizes, setParentState, parentState, item }) => {
  // console.log('NEW OPTION CONFIGS::', item)
  // console.log('Parent State', parentState)
// console.log('Sizes::', sizes)
  const [state, setState] = React.useState({
    size: {},
    name: '',
    notes: '',
    option: ''
  })
  const [showNewSizeModal, setShowNewSizeModal] = React.useState(false)
  const type = 'size'

  const handleUpdateSize = (e, newSize) => {
  console.log('Update size', newSize)
    if (!newSize) return
    if (typeof newSize === 'string') {
    // console.log('User typed this shit!!')
    } else if (newSize && newSize.inputValue) {
    console.log('We hvae an input value')
      // setState({...state, size: newSize })
      setShowNewSizeModal(true)
    } else {
    console.log('using an existing size')
      // setState({...state, size: newSize })
      let newName = parentState.name
      console.log('parentstate', parentState)
      if (parentState && parentState.name === '') newName = newSize.name
      setParentState({...parentState, size: newSize, name: newName })
    }
    // setState({...state, size: newSize })
    // setParentState({...parentState, size: newSize})
  }

  const updateOption = (data) => {
  // console.log("192", data)
    setShowNewSizeModal(false)
    setState({...state, size: data })
    setParentState({...parentState, size: data})
  }

  const handleCloseOptionModal = () => {
    setShowNewSizeModal(false)
  }

  const sizeObj = {
    name: 'Size'
  }

  const handleDeleteOption = (d) => {
  // console.log('Delete this bitch')
  }

  return (
    <div>
      <NewSizeModal open={showNewSizeModal} type={'size'} dataToUse={sizeObj} closeModal={handleCloseOptionModal} deleteOption={handleDeleteOption} updateParent={updateOption} />
      <Grid container spacing={3} style={{ paddingBottom: 15}}>
        
        <Grid item lg={12} md={12} sm={12} xs={12}>
        <Autocomplete
            freeSolo
            id="size-list"
            fullWidth
            options={sizes}
            onChange={handleUpdateSize}
            value={parentState.size}
            // disableCloseOnSelect={props.single ? false : true }
            style={{marginTop: 25}}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return 'Save ' + option.inputValue + ' as a New Size';
              }
              // Regular option
              return option.name;
            }}
                  
            filterOptions={(options, params) => {

              const filtered = filter(options, params);
      
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                  name: 'Add New Size'
                });
              }
      
              return filtered;
            }}

            renderInput={(params) => (
              <TextField {...params} label={'Size'} placeholder={`8' x 4' or 12"...`} variant="outlined" helperText={'What Is the Size of This Option?'} />
            )}
          />
            {/* <TextField
              labelId="size-select"
              id="select-size"
              value={parentState.size}
              select
              fullWidth
              variant="outlined"
              onChange={e=>setParentState({...parentState, size: e.target.value})}
              // onBlur={handleUpdateParent}
              label="Size"
            >
              {
                sizes.map(unit => {
                  // console.log('Size option:', unit)
                  return (
                  <MenuItem key={unit.name} value={unit} >{unit.name}</MenuItem>
                )})
              }
            </TextField> */}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="name"
            value={parentState.name}
            onChange={e=>setParentState({...parentState, name: e.target.value})}
            fullWidth
            helperText='What Should We Call This Option?'
            variant="outlined"
            // onBlur={handleUpdateParent}
            label="Option Name"
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="msrp"
            value={parentState.msrp}
            rows={4}
            fullWidth
            variant="outlined"
            onChange={e=>setParentState({...parentState, msrp: e.target.value})}
            // onBlur={handleUpdateParent}
            helperText='What Should We Charge For This Item?'
            label="Option MSRP"
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="barcode"
            value={parentState.barcode}
            rows={4}
            fullWidth
            variant="outlined"
            onChange={e=>setParentState({...parentState, barcode: e.target.value})}
            // onBlur={handleUpdateParent}
            helperText='Does this item have a barcode on it?'
            label="Option Barcode/SKU/UPC"
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="notes"
            value={parentState.notes}
            rows={4}
            multiline
            fullWidth
            variant="outlined"
            onChange={e=>setParentState({...parentState, notes: e.target.value})}
            // onBlur={handleUpdateParent}
            label="Option Notes"
          />
        </Grid>
      </Grid>
     
    </div>
  )
}

const mapStateToProps = (state) => {
  // console.log('MAP PROPS: ,', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(Modal)