import React, { useEffect, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux'
import { setClient, setContacts, setProjects } from '../../actions/connectionActions'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
import AddCustomerEcheck from './AddCustomerEcheck'
import JobList from './JobList'
import JobSiteList from './JobSiteList'
import InvoiceList from './InvoiceList'
import PaymentList from './PaymentListWithDetails'
import DepositHistory from './DepositHistory'
import StatementModal from './StatementModal'
import IconButton from '@material-ui/core/IconButton';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, CircularProgress } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import SnoutWeevilModal from './SnoutWeevilModal'
import StatementOptions from './StatementOptions'
import moment from 'moment'
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import EditCustomerModal from './EditCustomerModal'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import IssueCreditModal from './IssueCreditModal'
import DepositModal from './DepositModal'
import ModifyDepositModal from './ModifyDepositModal'
import SmsIcon from '@material-ui/icons/Sms'
import SendIcon from '@material-ui/icons/Send'
import CustomerNotes from './CustomerNotes'
import MaintenanceDetails from './MtcDetails'
import BulkPaymentModal from './BulkPaymentModal'
import ContactList from './ContactList'
import Box from '@material-ui/core/Box';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import {// eslint-disable-next-line
//   socket// eslint-disable-next-line
//   // eslint-disable-next-line
// } from '../SocketIo/Socket'
// import styles from './customer.module.scss'
import { socket, send } from '../SocketIo/Socket'
import ProtectedButton from '../../actions/protectedButtons'
import RecentActivityDetails from './RecentActivityDetails'
import ProposalList from '../Proposals/ProposalList'
import ServiceVisitList from '../ServiceVisits/ServiceVisitList'
import UploadsList from './UploadsList'
import TaskList from '../Tasks/TaskListTable'
import CreditHistory from './CreditDetailsModal'
import ProjectList from '../Projects/ProjectList'
import SendSMSModal from './SendSMSModal'
import HMIMaintenanceContainer from '../HMI/MaintenanceStuff/MtcContainer'
// import LinearProgress from '@material-ui/core/LinearProgress';
import DaysToPayBar from './DaysToPay'
import ActivityDrawer from '../ActivityComponent/ActivityDrawer'
import ClientUploadComponent from './ClientUploadComponent'
import ActivityDetailsContainer from '../ActivityComponent/ActivityDetails'
import PoolMaintenanceContainer from '../Pools/PoolMaintenanceContainer'
import SendToCustomerComponent from '../Common/SendToCustomer/SendToCustomerComponent'
import { useLocation, useHistory } from 'react-router-dom';
import UploadsAccordion from './UploadsAccordion';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    backgroundColor: '#ddd'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    // margin: '10px',
    // padding: '10px',
    // width: '95%'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid #eee',
    // marginRight: '5px',
    // padding: '5px'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  statusModal: {
    overflow: 'hidden'
  },
  wrapper: {
    maxWidth: '260px',
    minWidth: '100px',
    whiteSpace: 'nowrap',
    display: 'inline'
  },
  box: {
    borderLeft: '7px solid #333',
    padding: '7px',
    backgroundColor: '#234543',
    width: '100%',
    color: '#aaa',
    fontSize: '10px'
  },
  bigText: {
   color: '#777',
   fontSize: '16px'
   },
   invoiceBox: {
    borderLeft: '7px solid #ad8409',
    padding: '7px',
    backgroundColor: '#fdf7e7',
    width: '100%',
    color: '#444',
    fontSize: '10px'
  },
  paymentsBox: {
    borderLeft: '7px solid #56b349',
    padding: '7px',
    backgroundColor: '#eef8ed',
    width: '100%',
    color: '#444',
    fontSize: '10px'
  },
  depositsBox: {
    borderLeft: '7px solid #56b349',
    padding: '7px',
    backgroundColor: '#eef9ed',
    width: '100%',
    color: '#444',
    fontSize: '10px'
  },
  creditsBox: {
    borderLeft: '7px solid #e6b730',
    padding: '7px',
    backgroundColor: '#fbe59c',
    // width: '100%',
    color: '#444',
    fontSize: '10px',
    display: 'inlineBlock'
  },
  balanceBox: {
    borderLeft: '7px solid #d7393f',
    padding: '7px',
    backgroundColor: '#f6d5d6',
    // width: '100%',
    color: '#444',
    fontSize: '10px',
    display: 'inlineBlock'
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  }
}));

const PaymentTerms = (props) => {
  const [terms, setTerms] = React.useState(props.client.paymentTerms || '')
  return (
    <TextField
      variant='outlined'
      value={terms}
      label="Payment Terms"
      fullWidth
      onChange={e => setTerms(e.target.value)}
      helperText="What Are This Customers Payment Terms?"
      onBlur={props.updatePaymentTerms}
      />
  )
}

const urlPath = `https://dflportal.com`
// console.log('PROCESS.env', process.env)
const CustomerDetail = (props) => {
  // console.log('Customer Details 274: ', props)
  const classes = useStyles();
  const [client, setActiveClient] = React.useState(props?.client || null)
  const [jobs, setJobs] = React.useState([])
  const [crewList, setCrewList] = React.useState([])
  const [serviceList, setServiceList] = React.useState([])
  const [paymentProfiles, setPaymentProfiles] = React.useState([])
  const [snoutWeevilModalOpen, setSnoutWeevilModalOpen] = React.useState(false);
  const [statementModalOpen, setStatementModalOpen] = React.useState(false)
  // const [job, setJob] = React.useState();
  const [loading, setLoading] = React.useState(false)
  const [statementOptsModalShow, setStatementOptsModalShow] = React.useState(false)
  const [customerDetailModalOpen, setCustomerDetailModalOpen] = React.useState(false)
  const [expanded, setExpanded] = React.useState('panel5')
  const [jobSites, setJobSites] = React.useState([])
  const [showStatementStatus, setShowStatementStatus] = React.useState(false)
  const [statementMessage, setStatementMessage] = React.useState([])
  const [pdf, setPdf] = React.useState({})
  const [editActive, setEditActive] = React.useState(false)
  const [showCreditModal, setShowCreditModal] = React.useState(false)
  const [showDepositModal, setShowDepositModal] = React.useState(false)
  const [credits, setCredits] = React.useState([])
  const [deposits, setDeposits] = React.useState([])
  const [deposit, setDeposit] = React.useState({})
  const [payments, setPayments] = React.useState([])
  const [uploads, setUploads] = React.useState([])
  const [showModifyDepositModal, setShowModifyDepositModal] = React.useState(false)
  const [showPostBulkPayments, setShowPostBulkPayments] = React.useState(false)
  const [entity, setEntity] = React.useState({})
  const [arSummary, setArSummary] = React.useState({
    thirtyOneToSixty: 0,
    sixtyOneToNinety: 0,
    ninetyPlus: 0
  })
  const [contacts, setContacts] = React.useState([])
  const [state, setState] = React.useState({
    openTheModal: false,
    counter: 0,
    itemToOpen: null,
    openId: null,
    activities: []
  })
  const [taxLocales, setTaxLocales] = React.useState([])
  const [estimates, setEstimates] = React.useState([])
  const [tasks, setTasks] = React.useState([])
  const [projects, setProjects] = React.useState([])
  const [sendSMSModalOpen, setSendSMSModalOpen] = React.useState(false)
  const [showSendToCustomerModal, setShowSendToCustomerModal] = React.useState(false)
  const [numberOfUploads, setNumberOfUploads] = useState(0)
  const [numberOfPhotos, setNumberOfPhotos] = useState(0)
  const [takeOffs, setTakeOffs] = useState([])
  const location = useLocation();
  const history = useHistory() 
  const { customer, type, item, stuff } = location.state || {}
  const actionRef = React.useRef(null)

  const clientIdRef = useRef(null);  // Track clientId subscriptions
  const userNameRef = useRef(null);  // Track userName subscriptions
  // console.log('330 client', client)

  const [clientId, setClientId] = useState(null);
  const [userName, setUserName] = useState('none');

  const listeners = useRef({});

  // useEffect(() => {
  //   const clientId = props.client?._id;
  //   const userName = props.user?.username || 'none';

  //   console.log('Setting up socket listeners for:', { clientId, userName });
  //   if (props?.client?._id) {
  //     setActiveClient(props.client)
  //     getJobSitesForClient(props.client?._id)
  //   }
  //   if (clientId && !listeners.current[clientId]) {
  //     console.log('Subscribing to client socket:', clientId);
  //     listeners.current[clientId] = (data) => handleClientSocket(data, clientId);
  //     socket.on(`${clientId}`, listeners.current[clientId]);
  //   }

  //   if (userName && !listeners.current[userName]) {
  //     console.log('Subscribing to user socket:', userName);
  //     listeners.current[userName] = (msg) => handleUserSocket(msg, userName);
  //     socket.on(userName, listeners.current[userName]);
  //   }

  //   // Cleanup function to remove listeners when component unmounts
  //   return () => {
  //     if (clientId && listeners.current[clientId]) {
  //       console.log('Unsubscribing from client socket:', clientId);
  //       socket.off(`${clientId}`, listeners.current[clientId]);
  //       delete listeners.current[clientId]; // Remove from refs
  //     }
      
  //     if (userName && listeners.current[userName]) {
  //       console.log('Unsubscribing from user socket:', userName);
  //       socket.off(userName, listeners.current[userName]);
  //       delete listeners.current[userName]; // Remove from refs
  //     }
  //   };
  // }, [props.client, props.user]);

  useEffect(() => {
    const clientId = props.client?._id;
    const userName = props.user?.username || 'none';
  
    console.log('Setting up socket listeners for:', { clientId, userName });
    
    let isSubscribed = true; // For cleanup
  
    if (props?.client?._id) {
      setActiveClient(props.client);
      if (props.client?.customerProfileId) {
        console.log('Get our payment profiles...', props.client.customerProfileId)
        getPaymentProfiles(props.client.customerProfileId)
      }
      // Wrap in async IIFE to handle loading states
      (async () => {
        try {
          // Set loading state if you have one
          // setLoading(true);
          await getJobSitesForClient(props.client._id);
        } finally {
          // Only update state if component is still mounted
          if (isSubscribed) {
            // setLoading(false);
          }
        }
      })();
    }
  
    // Socket setup
    if (clientId && !listeners.current[clientId]) {
      console.log('Subscribing to client socket:', clientId);
      listeners.current[clientId] = (data) => handleClientSocket(data, clientId);
      socket.on(`${clientId}`, listeners.current[clientId]);
    }
  
    if (userName && !listeners.current[userName]) {
      console.log('Subscribing to user socket:', userName);
      listeners.current[userName] = (msg) => handleUserSocket(msg, userName);
      socket.on(userName, listeners.current[userName]);
    }
  
    // Cleanup function
    return () => {
      isSubscribed = false;
      if (clientId && listeners.current[clientId]) {
        console.log('Unsubscribing from client socket:', clientId);
        socket.off(`${clientId}`, listeners.current[clientId]);
        delete listeners.current[clientId];
      }
      
      if (userName && listeners.current[userName]) {
        console.log('Unsubscribing from user socket:', userName);
        socket.off(userName, listeners.current[userName]);
        delete listeners.current[userName];
      }
    };
  }, [props.client, props.user]);

  React.useEffect(() => {
      console.log('Customer Details Use Effect', props)
      console.log(props?.sentData)
    //   // join(props.client._id)
      if (props.sentData && props.sentData._id && props.sentData.openItem) {
        // console.log('Open this item: 333 ', props.sentData)
        if (props.sentData.openItem === 'Proposal Line Items' || props.sentData.openItem === 'Estimates') {
          // console.log('Open Estiamte', props.sentData._id )
          // setLoading(true)
          setState(prevState => ({...prevState, openId: props.sentData._id , itemToOpen: 'Proposal', openTheModal: true }))
        }
        if (props.sentData.openItem === 'Invoices') {
          // console.log('Open Invoice', props.sentData._id )
          // setLoading(true)
          setState(prevState => ({...prevState, openId: props.sentData._id , itemToOpen: 'Invoice' }))
        }
        if (props.sentData.openItem === 'Job') {
            // console.log('Open Workorders', props.sentData )
          // setLoading(true)
          // history.replace({
          //   pathname: location.pathname,
          //   search: '' // or any other search parameters you want to keep
          // });
          setState(prevState => ({...prevState, openId: props.sentData._id , itemToOpen: 'Job' }))
        }
  
        if (props.sentData.openItem?.toLowerCase() === 'mtcagreement') {
        // console.log('Open MTC AGREEMENT', props.sentData)
        setState(prevState => ({...prevState, openId: props.sentData._id , itemToOpen: 'MtcAgreement' }))
        }
      }
  }, [props])
  
  // Handle socket events for clients
  const handleClientSocket = (data, clientId) => {
    console.log(`Received data on client socket [${clientId}]:`, data);
    if (data?.updatedAR) {
      props.updateParent(data);
      setCredits(data.credits);
      setDeposits(data.deposits);
      setActiveClient(data.client);
      props.notification({
        type: 'success',
        title: 'Customer Data Refreshed',
        message: 'You\'re looking at the latest information',
      });
    } else {
      setStatementMessage(data.message);
    }
  };

  // Handle socket events for users
  const handleUserSocket = (msg, userName) => {
    console.log(`Received message on user socket [${userName}]:`, msg);
    setStatementMessage(msg.message);
  };


  // const handleClientSocket = useCallback((data) => {
  //   console.log('Received SOCKET DATA for Client ID:', data);
  //   if (data?.updatedAR) {
  //     props.updateParent(data);
  //     setCredits(data.credits);
  //     setDeposits(data.deposits);
  //     setActiveClient(data.client);
  //     props.notification({
  //       type: 'success',
  //       title: 'Customer Data Refreshed',
  //       message: 'You\'re looking at the latest information',
  //     });
  //   } else {
  //     setStatementMessage(data.message);
  //   }
  // }, [props]);

  // // Memoized event handler for user socket events
  // const handleUserSocket = useCallback((msg) => {
  //   console.log('Received SOCKET MESSAGE for User:', msg);
  //   setStatementMessage(msg.message);
  // }, []);

  // Update the state when props change (but avoid unnecessary re-renders)
  // useEffect(() => {
  //   if (props.client?._id && props.client._id !== clientId) {
  //     console.log('Setting new clientId:', props.client._id);
  //     setClientId(props.client._id);
  //     setActiveClient(props.client)
  //   }
  //   if (props.user?.username && props.user.username !== userName) {
  //     console.log('Setting new userName:', props.user.username);
  //     setUserName(props.user.username);
  //   }
  // }, [props.client, props.user]);

  // // Handle client socket subscription (only if clientId changes)
  // useEffect(() => {
  //   if (!clientId || clientId === clientIdRef.current) return;

  //   console.log('Subscribing to client socket:', clientId);
  //   clientIdRef.current = clientId; // Track the current subscription

  //   socket.on(clientId, handleClientSocket);

  //   return () => {
  //     console.log('Unsubscribing from client socket:', clientId);
  //     socket.off(clientId, handleClientSocket);
  //   };
  // }, [clientId, handleClientSocket]);

  // // Handle user socket subscription (only if userName changes)
  // useEffect(() => {
  //   if (!userName || userName === userNameRef.current) return;

  //   console.log('Subscribing to user socket:', userName);
  //   userNameRef.current = userName; // Track the current subscription

  //   socket.on(userName, handleUserSocket);

  //   return () => {
  //     console.log('Unsubscribing from user socket:', userName);
  //     socket.off(userName, handleUserSocket);
  //   };
  // }, [userName, handleUserSocket]);

  // React.useEffect(() => {
  //   // console.log('LOCATION 327.....', location)
  // }, [location])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  }
  
  // React.useEffect(() => {
  //   // console.log('Get Contacts: ')
  //   async function getContacts() {
  //     // console.log('Get our CONTACTS')
  //     const response = await axios.get(`${props.url}/api/customer/getContacts?id=${props.client._id}`)
  //   // console.log('CONTACTS LOADED!!!', response)
  //     if (response && response.data) {
  //       setContacts(response.data)
  //       props.setContacts(response.data)
  //       setState({...state, contacts: response.data})
  //       // props.updateContacts(response.data)

  //     }
  //   }
  //   if (props.client && props.client._id) getContacts()
  // }, [props.client])

  React.useEffect(() => {
    // console.log('Customer Details Use Effect', props)
    // join(props.client._id)
    if (props.sentData && props.sentData._id && props.sentData.openItem) {
      // console.log('Open this item: 333 ', props.sentData)
      if (props.sentData.openItem === 'Proposal Line Items' || props.sentData.openItem === 'Estimates') {
        // console.log('Open Estiamte', props.sentData._id )
        // setLoading(true)
        setState(prevState => ({...prevState, openId: props.sentData._id , itemToOpen: 'Proposal', openTheModal: true }))
      }
      if (props.sentData.openItem === 'Invoices') {
        // console.log('Open Invoice', props.sentData._id )
        // setLoading(true)
        setState(prevState => ({...prevState, openId: props.sentData._id , itemToOpen: 'Invoice' }))
      }
      if (props.sentData.openItem === 'Job') {
          // console.log('Open Workorders', props.sentData )
        // setLoading(true)
        // history.replace({
        //   pathname: location.pathname,
        //   search: '' // or any other search parameters you want to keep
        // });
        setState(prevState => ({...prevState, openId: props.sentData._id , itemToOpen: 'Job' }))
      }

      if (props.sentData.openItem === 'mtcAgreement') {
      // console.log('Open MTC AGREEMENT', props.sentData)
      setState(prevState => ({...prevState, openId: props.sentData._id , itemToOpen: 'MtcAgreement' }))
      }
    }
    // let newClient = props.client
    // setActiveClient(props.client)
    // props.setClient(props.client)
    if (props.client && props.client.pastDue) {
      // console.log('Past Due get a Summary')
      let summary = props.client.arSummary
      let newSum = {}
      if (summary && summary.thirtyOneToSixty && summary.thirtyOneToSixty.totalValue) {
        // console.log('Got 31-60', summary.thirtyOneToSixty)
        newSum.thirtyOneToSixty = summary.thirtyOneToSixty.totalValue
      }
      if (summary && summary.sixtyOneToNinety && summary.sixtyOneToNinety.totalValue) newSum.sixtyOneToNinety = summary.sixtyOneToNinety.totalValue
      if (summary && summary.ninetyPlus && summary.ninetyPlus.totalValue) newSum.ninetyPlus = summary.ninetyPlus.totalValue
      // console.log('New AR Summary: ', newSum) 
      setArSummary(newSum)
    }
    if (props && props.client && props.client.customerProfileId) {
      // console.log('Props client has Customer Profile Id')
      getPaymentProfiles(props.client.customerProfileId)
    } else {
      // console.log('Props client has no payment profile id - set them as []')
      setPaymentProfiles([])
    }

  }, [props.client])
  //   31-60 Days: {currencyFormat(client.arSummary.thirtyOneToSixty.totalValue)}
  //   </div>
  // </Grid>   
  // <Grid item lg={4} xs={12} >
  //   <div className={classes.wrapper} >
  //     61-90 Days: {currencyFormat(client.arSummary.sixtyOneToNinety.totalValue)}
  //   </div>
  // </Grid>   
  // <Grid item lg={4} xs={12} >
  //   <div className={classes.wrapper} >
  //     91+ Days:  {currencyFormat(client.arSummary.ninetyPlus.totalValue)}

  //   setCustomerDetailModalOpen(props.customerDetailModalOpen)
  //   // console.log('Payment porfiles right now:', paymentProfiles)
  //   if (props && props.client && props.client.customerProfileId) {
  //     // console.log('Props client has Customer Profile Id')
  //     getPaymentProfiles(props.client.customerProfileId)
  //   } else {
  //     // console.log('Props client has no payment profile id - set them as []')
  //     setPaymentProfiles([])
  //   }

  //   let userName = 'none'
  //   if (props && props.user && props.user.username) {
  //     userName = props.user.username
  //   }
  //   socket.on(userName, function (msg) {
  //     console.log('GOT A SOCKET MESSAGE: ', msg)
  //     setStatementMessage(msg.message)
  //     // props.notification({
  //     //   type: 'success',
  //     //   title: 'Statement Update',
  //     //   message: msg.message
  //     // })
  //   })

  //   // console.log('CLIENT CHANGED!!', props.client)
  //   // console.log(props.openSnoutWeevil)
  //   if (props.openSnoutWeevil) {
  //     // console.log('Open snout weevil modal!!')
  //     setSnoutWeevilModalOpen(true)
  //   }
  //   // if (props && props.client && props.client._id) {
  //   //   refreshCustomer(props.client)
  //   // }
  //   if (props.client && props.client._id) getJobSitesForClient(props.client)
  //   let clientId = props?.client?._id || null
  //   // if (clientId && clientId.toString()) {
  //     console.log('Client ID to receive socket: ', clientId)
  //     if (clientId) {
  //       console.log('Subscribe to socket...')
  //       socket.on(clientId, function(d){
  //         console.log('Receiving SOCKET DATA on Client ID', d)
  //         if (d && d.updatedAR) {
  //           // console.log('UPdated Custoemr AR INCOMING!!!', d)
  //           props.updateParent(d)
  //           // let accountBalance = d.totalOwed
  //           let newClient = client
  //           // console.log('NEw Client: ', d.client)
  //           // console.log('Current Client: ', newClient)
  //           setCredits(d.credits)
  //           setDeposits(d.deposits)
  //           // newClient.accountBalance = accountBalance
  //           setActiveClient(d.client)
  //           props.notification({
  //             type: 'success',
  //             title: 'Customer Data Refreshed',
  //             message: 'You\'re looking at the latest information'
  //           })
  //         } else {
  //           setStatementMessage(d.message)
  //         }
  //       })
  //     }
  //   return () => {
  //     socket.off(clientId)
  //     let userName = props.user.username || 'none'
  //     socket.off(props.user.username)
  //   }
  // }, [props.client])

  
  React.useEffect(() => {
    // console.log('Credits/Deposits changed: ', credits)
    // console.log(deposits)
  }, [credits, deposits])

  function currencyFormat(num) {
    if (!num) num = 0
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

//  const getClientUploads = async (clientId) => {
//   try {
//     const response = await axios.get(`${props.url}/api/customer/getClientUploads?id=${clientId}`);
//     if (response?.data) {
//       const projUploads = response.data.projectUploads || [];
//       const otherUploads = response.data.uploads || [];
//       setUploads([...projUploads, ...otherUploads]);
//     }
//   } catch (error) {
//     console.error('Error fetching uploads:', error);
//     props.notification({
//       type: 'warning',
//       title: 'Upload Loading Issue',
//       message: 'Some uploads may not have loaded completely. You can refresh to try again.'
//     });
//   }
// };

// Main data fetching function
const getJobSitesForClient = async (clientId) => {
  try {
    // Start uploads fetch in parallel but don't await it yet
    // const uploadsPromise = getClientUploads(clientId);
    
    // Fetch main data
    const response = await axios.get(`${props.url}/api/customer/getAllJobSites?id=${clientId}`);
    
    if (response?.data) {
      // Destructure all the data we need
      const {
        activities,
        jobSites,
        client,
        crewList,
        serviceList,
        payments,
        taxLocales,
        credits,
        deposits,
        entity,
        estimates,
        tasks,
        projects,
        uploads,
        projectUploads,
        totalPhotos,
        totalNonPhotoFiles,
        takeoffs
      } = response.data;
      // console.log('Got our stuff...', response.data)
      // Batch our state updates using a reducer or context if possible
      // For now, group related states together
      setActiveClient(client);
      props.setClient(client)
      // Job-related states
      setJobSites(jobSites);
      setProjects(projects);
      props.setProjects(projects);
      
      // Service-related states
      setCrewList(crewList);
      setServiceList(serviceList);
      
      // Financial states
      setPayments(payments);
      setTaxLocales(taxLocales);
      setCredits(credits);
      setDeposits(deposits);
      
      // Other states
      setEntity(entity);
      setEstimates(estimates);
      setTasks(tasks);
      setState(prevState => ({ ...prevState, activities }));
      let totalUploads = Number(uploads) + Number(projectUploads) || 0
      setNumberOfUploads(totalNonPhotoFiles)
      setNumberOfPhotos(totalPhotos)
      console.log('Total Photos:', totalPhotos)
      console.log('Set takeoffs', takeoffs)
      setTakeOffs(takeoffs)
      // let totalImages = 0

      // setImages()
      // setNumberOfPhotos
      // Now wait for uploads to finish in the background
      // const uploadsPromise = getClientUploads(clientId);
      // await uploadsPromise;
      
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error) {
    console.error('Error fetching client data:', error);
    props.notification({
      type: 'warning',
      title: 'We Ran Into A Problem!',
      message: 'An error occurred trying to get job sites - please try again.'
    });
  }
};

//  const getJobSitesForClient = async (clientId) => {
//   // setLoading(true)
//   console.log('Get job sites for this guy: ', clientId)
//   const response = await axios.get(`${props.url}/api/customer/getAllJobSites?id=${clientId}`)
  
//    if (response && response.data) {
//     let activities = response.data.activities
//     // console.log('Job Sites wiht Crews/Services/Salespeople', response.data)
//     let jobSites = response.data.jobSites
//     setActiveClient(response.data.client)
//     setCrewList(response.data.crewList)
//     setServiceList(response.data.serviceList)
//     setPayments(response.data.payments)
//     setTaxLocales(response.data.taxLocales)
//     // setContacts(response.data.contacts)
//     // console.log('Contacts: ', response.data.contacts)
//     // let cleanJobSites = []
//     // jobSites.map(site => {
//     //   cleanJobSites.push({
//     //     address: site.Address.Address1,
//     //     htmlNotes: site.SiteNotes,
//     //     notes: cleanNotes(site.SiteNotes),
//     //     id: site.Id
//     //   })
//     // })
//     // console.log('JOb SITES: ', jobSites)
//     setJobSites(jobSites);
//     setCredits(response.data.credits)
//     setDeposits(response.data.deposits)
//     let projUploads = response.data.projectUploads
//     // console.log('Project Uploads: ', projUploads)
//     // console.log('Other Uploads:', response.data.uploads)
//     let allUploads = projUploads.concat(response.data.uploads)
//     // console.log(allUploads)
//     setUploads(allUploads)
//     setEntity(response.data.entity)
//     setEstimates(response.data.estimates)
//     setTasks(response.data.tasks)
//     setProjects(response.data.projects)
//     props.setProjects(response.data.projects)
//     setState({...state, activities: activities})
//     // setLoading(false)
//    } else {
//     //  // console.log('Something went wrong')
//      props.notification({
//       type: 'warning',
//       title: 'We Ran Into A Problem!',
//       message: 'An Error Occurred Trying to Get Job Sites - please hang tight while we attempt to resolve it.'
//     })
//     // setTimeout(() => {
//     //   // console.log('Lets try again...')
//     //   getJobSitesForClient(client)
//     // }, 8500)
//    }
// }

 const refreshCustomer = async (client) => {
   // console.log('Refresh this customer')
   if (client) {
    const response = await axios.get(`${props.url}/api/customer/refresh?id=${client.agId}`)
    if (response && response.data) {
      // console.log('Got refreshed client: ', response.data)
    //   agId: client.agId,
    //   accountBalance: totalBalance,
    //   totalPaymentsPaid: totalPaymentsPaid,
    //   totalDepositsReceived: totalDepositsReceived,
    //   totalAmountInvoiced: totalAmountInvoiced
    // }
    setActiveClient(response.data.client)
      if (response.data.credits) {
        // console.log('We have credits!!')
        setCredits(response.data.credits)
      }
      if (response.data.deposits) {
        // console.log('We have deposits!!')
        setDeposits(response.data.deposits)
      }
      if (response.data.payments) {
        // console.log('We have payments!!')
        setPayments(response.data.payments)
      }
      if (response.data.jobSites) {
        // console.log('We have payments!!')
        setJobSites(response.data.jobSites)
      }
      if (response.data.estimates) {
        // console.log('We have payments!!')
        setEstimates(response.data.estimates)
      }
      if (response.data.tasks) {
        // console.log('We have payments!!')
        setTasks(response.data.tasks)
      }
      if (response.data.activities) setState(prevState => ({...prevState, activities: response.data.activities }))
    }
   }
 }
//  const getJobsForClient = async (client) => {
//    // console.log('Get jobs for this guy: ', client)
//    setLoading(true)
//    const response = await axios.get(`${props.url}/api/customer/getAllJobs?id=${client.agId}`)
//     // console.log('Jobs!!', response)
//     setJobs(response.data)
//     setLoading(false)
//  }
 const getPaymentProfiles = async (id) => {
  // console.log('Use this id to get payment profiles', id)
  const response = await axios.get(`${props.url}/api/customer/paymentProfiles?id=${id}`)
  // console.log('PAYMENT PROFIELS!!!', response)
  if (response && response.data && response.data.profile && response.data.profile.paymentProfiles) {
    // console.log('Set this payment profile')
    // console.log(response.data.profile.paymentProfiles)
    if (response.data.profile.paymentProfiles.length) {
      setPaymentProfiles(response.data.profile.paymentProfiles)
    } else {
      setPaymentProfiles([])
    }
  
  }
}

const cleanNotes = (note) => {
  // console.log('HTML Notes: ', note)
  var temp = document.createElement("div");
  temp.innerHTML = note
  var cleanNotes = temp.textContent || temp.innerText;
  return cleanNotes
}

const jobSiteResponse = (type, data) => {
  // console.log('Resonse from JOB SITES...?', type)
  // if (type === 'newSite') {
  //   // console.log('Our new site', data)
  //   let sites = jobSites
  //   // console.log('Sites before new one', sites)
  //   sites.unshift(data)
  //   // console.log('New Sites: ', sites)
  //   setJobSites(sites)
  // }
  if (type === 'Update') {
    // getJobSitesForClient(props.client)
    let newJobSites = jobSites
    let index = newJobSites.findIndex(item => (data._id === item._id))
    if (index > -1) {
      newJobSites[index] = data
      setJobSites(newJobSites)
    }
  }
}
const updatePmtMethodResponse = (message, paymentProfileId) => {
  // console.log('Update form payment methods: ', message)
  if (message === 'refreshClientDetails') getPaymentProfiles(paymentProfileId)
}

const makePrimaryPaymentProfile = (item, index) => {
  // console.log('Make this the primary profile!!')
  let obj = {
    customerPaymentProfileId: item.customerPaymentProfileId,
    clientId: client._id
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/customer/makePrimaryPaymentMethod`,
    data: obj,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response to make primary payment method payment method: ', response)
    // getPaymentProfiles(client.customerProfileId)
    if (response && response.data && response.data.client) {
      // setClient(response.data.client)
      props.notification({
        type: 'success',
        title: 'Payment Profile Updated',
        message: 'Great Job!!'
      })
    }
    if (response && response.data && response.data.error) {
      props.notification({
        type: 'warning',
        title: 'We Ran Into A Problem!',
        message: response.data.error.messages.message[0]['text']
      })
    }
  })
}
const handleDeletePaymentProfile = (item, index) => {
  // console.log('Delete this profile', item)
  let obj = {
    customerProfileId: client.customerProfileId,
    customerPaymentProfileId: item.customerPaymentProfileId,
    clientPaymentProfileId: client.paymentProfileId,
    clientId: client._id,
    entity: client.entity
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/customer/deletePaymentMethod`,
    data: obj,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response to delete payment method: ', response)
    // console.log(paymentProfiles)
    let newPmtProfiles = [...paymentProfiles]
    // console.log('New: ', newPmtProfiles)
    newPmtProfiles.splice(index,1)
    // console.log('After splce',newPmtProfiles)
    setPaymentProfiles(newPmtProfiles)
  })
}

const handleClose = () => {
  // console.log('Close the customer detail modal', snoutWeevilModalOpen)
  // console.log(snoutWeevilModalOpen)
  // console.log(id)
  // Clear our Job Sites
  // setJobSites([])
  // setJobs([])
  setCustomerDetailModalOpen(false)
  setCredits([])
  setDeposits([])
  setTasks([])
  props.closeModal(true)
}

const handleSyncClient = () => {
  axios({
    method: 'post',
    url:  `${props.url}/api/customer/syncClient`,
    data: client,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Synced client - use this now', response)
    if (response && response.data && response.data.savedClient) {
      setActiveClient(response.data.savedClient)
    }
  })
}
const onSWModalClose = (d) => {
  // console.log('Snout Weevil Modal closed...', d)
  props.updateParent('onSWModalClose')
  setSnoutWeevilModalOpen(false)
}

const generateStatement = (start, end, newWay) => {
  // console.log('Make Customer Statement now', newWay)
  setLoading(true)
  setShowStatementStatus(true)
  let ourUrl = `${props.url}/api/customer/createStatement`
  if (newWay && newWay > 0) ourUrl = `${props.url}/api/invoice/createStatement`
  // console.log('Our URL: ', ourUrl)
  axios({
    method: 'post',
    url:  ourUrl,
    data: {
      client,
      start,
      end,
      user: props.user
    }
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Made statement response!!', response)
    setShowStatementStatus(false)
    setPdf(response.data)
    setStatementModalOpen(true)
    setLoading(false)
  })
}

const closeStatementModal = (d) => {
  setStatementModalOpen(false)
}

const updateFromStatementOptions = (start, end, newWay) => {
  // console.log('UPdate from statement options: ', start)
  // console.log(end)
  setStatementOptsModalShow(false)
  if (start && end) generateStatement(start, end, newWay)
}
const modifyDeposit = (deposit) => {
  // console.log('Open our Deposit Detail Modal about this: ', deposit)
  setDeposit(deposit)
  setShowModifyDepositModal(true)
}
const DepositList = (props) => {
  // console.log('DEPOST::: ', props)
  let deposits = props.data
  let html = null
  let depositLeft = 0
  // console.log('Deposits: ', deposits)
  if (deposits && deposits.length) {
    html = (
      <ul>
      {deposits.map(item => (
        (item.balance > 0 && (item.status === 'Paid' || item.status === 'Authorized')) ? (
          <li key={item._id}>
          {moment(item.created_at).format('MM/DD/YYYY')} - Deposit Amount: {currencyFormat(item.amount)} ---- Balance Remaining: <span onClick={() => modifyDeposit(item)} className={classes.fakeLink}>{currencyFormat(item.balance)}</span> ({item.notes})
        </li>
        ) : ''
      ))
      }
    </ul>
    )
  }
  return html
}

const CreditList  = (props) => {
  // console.log('Credits::: ', props)
  let credits = props.data
  let html = null
  let creditBalance = 0
  // console.log('Credits: ', credits)
  if (credits && credits.length) {
    html = (
      <ul>
      {credits.map((item, index) => (
        item.balance > 0 ? (
          <li key={item._id}>
            {moment(item.created_at).format('MM/DD/YYYY')} - Credit Amount: {currencyFormat(item.amount)} ---- Balance Remaining: {currencyFormat(item.balance) } ({item.notes})  
              <ProtectedButton type="Credits" kind="delete" perms={props.perms}>
                <IconButton edge='end' aria-label="delete" >
                  <DeleteIcon id={item._id} onClick={e => props.handleDeleteCredit(item, index)} />
                </IconButton>
              </ProtectedButton>
          </li>
        ) : ''
        
      ))
      }
    </ul>
    )
  }
  return html
}
const CloseButton = () => {
  return (
    <IconButton onClick={handleClose} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
      <CloseIcon />
    </IconButton>
  )
}

const handleEditClient = () => {
  // console.log('Edit Customer')
  setEditActive(true)
}

const updatedClient = (close, client) => {
  if (client) {
    // console.log('Our client: ', client)
    setActiveClient(client)
    setEditActive(false)
  } else {
    setEditActive(false)
  }
}

const handleDeleteCredit = async (item, index) => {
  // console.log('Delete this: ', item)
  if (window.confirm(`Are you sure you wish to delete this credit?`)) {
    let newCredits = credits
    // console.log(creditId)
    // console.log('Index: ', index)
    // console.log(newCredits[index])
    newCredits.splice(index, 1)
    // console.log(newCredits)
    setCredits(newCredits)
    setState({...state, counter: state.counter++ })
    let obj = {
      creditId: item._id
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/customer/deleteCredit`,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Axios response to delete credit ', response)
      // getPaymentProfiles(client.customerProfileId)
      if (response && response.data && response.data.success) {
        // setClient(response.data.client)
        props.notification({
          type: 'success',
          title: 'Credit Deleted',
          message: 'We Have Deleted That Credit From The System!'
        })
      }
    })
  }
}

const creditModalUpdate = (d) => {
  // console.log('Credit: ', d)
  let ourCredits = credits
  ourCredits.push(d)
  let ourClient = client
  let creditBalance = ourClient.creditBalance
  let newBal = parseFloat(d.amount) + parseFloat(creditBalance)
  ourClient.creditBalance = newBal
  ourClient.balanceIncludingCredits = ourClient.balanceIncludingCredits - d.amount
  setActiveClient(ourClient)
  setShowCreditModal(false)
  setCredits(ourCredits)
}

const closeCreditModal = () => {
  setShowCreditModal(false)
}

const showInvoiceOptions = (d) => {
  console.log('Open up PDF INvoice...', d)
  setState(prevState => ({...prevState, openId: d?.depositInvoice?._id, depositInvoice: d?.depositInvoice, pdf: d?.pdf, itemToOpen: 'PDF' }))
}

const depositModalUpdate = (d) => {
  console.log('Deposit Saved...: ', d)
  if (d?.pdf?.length) showInvoiceOptions(d)
  // let ourCredits = deposits
  // ourCredits.push(d)
  // let ourClient = client
  // let creditBalance = ourClient.depositBalance
  // let newBal = parseFloat(d.amount) + parseFloat(creditBalance)
  // ourClient.depositBalance = newBal
  // ourClient.balanceIncludingCredits = ourClient.balanceIncludingCredits - d.amount
  // // ourClient.balanceIncludingCredits = ourClient.balanceIncludingCredits - d.amount
  // setClient(ourClient)
  setShowDepositModal(false)
  // setDeposits(ourCredits)
  getJobSitesForClient(props.client?._id)
}

const closeDepositModal = () => {
  setShowDepositModal(false)
}
const getJobsFromJobList = (jobs) => {
  // console.log('Getting jobs from our Job List', jobs)
  setJobs(jobs)
}
const modifyDepositModalUpdate = (type, d) => {
  // console.log('Modify Deposti Modal update: ', d)
  getJobSitesForClient(props.client?._id)
  setShowModifyDepositModal(false)
}
const handleRefresh = () => {
  refreshCustomer(client)
}

const modifyPostBulkPaymentResponse = (t, d) => {
  // console.log('POst Bulk Payments Response', t)
  // console.log(d)
}

const postConversionProcess = (d) => {
  // console.log('POst COnversion - CUSTOMER DETAILS!!, ', d)
  let job = d
  // let wo = d.wo
  // // setState({...state, openTheModal: true, activeJob: job })
  // // setActiveJob(job)
  // // console.log('Job: ', job)
  // // Insert our JOb and Workorder into our lists
  let newJobs = jobs
  // console.log('Jobs to startJobs to start: ', newJobs.length)
  newJobs.unshift(job)
  setJobs(newJobs)
  // Then OpEN them up
  // console.log('New Jobs: ', newJobs)
  // send('jobListUpdate', job)
  setExpanded('panel1')
  // console.log('Now open the modal..somehow')
  // handleRefresh()
}

const updateClientContacts = (d) => {
  setContacts(d)
}

const updatePaymentTerms = (e) => {
  // console.log('UPdat ePaymetn Terms: ', e.target.value)
  let newClient = client
  newClient.paymentTerms = e.target.value
  setActiveClient(newClient)
  let updateObject = {
    client: newClient,
    paymentTerms: e.target.value
  }
  // console.log('TO SAVE: ', updateObject)
  axios({
    method: 'post',
    url:  `${props.url}/api/customer/updateClient`,
    data: updateObject,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Response ', response.data)
  })
}

const handleSendSMS = () => {
  // alert('Send SMS')
  setSendSMSModalOpen(true)
}
const handleCloseSMSModal = () => {
  setSendSMSModalOpen(false)
}

const updateParentWithNewActivity = (data) => {
// console.log('New Actiivty:', data)
  let existingActivities = state.activities
  existingActivities.unshift(data)
// console.log('New Activities:', existingActivities)
  setState({...state, activities: existingActivities, counter: state.counter++ })
}

const responseFromUpload = (data) => {
// console.log('Upload Response: ', data)
  let currentUploads = uploads
// console.log('Current uploads', currentUploads)
  if (data && data.filesUploaded && data.filesUploaded.length) {
    let newUploads = data.filesUploaded.concat(currentUploads)
  // console.log('Make these our uplaods!!', newUploads)
    setUploads(newUploads)
    props.notification({
      type: 'success',
      title: 'Files Uploaded',
      message: `We Uploaded ${data.filesUploaded.length} File(s) and Saved Them To This Profile`
    })
    setState({...state, counter: state.counter++ })
  }
  if (data && data.duplicateFiles && data.duplicateFiles.length) {
    props.notification({
      type: 'warn',
      title: 'Duplicate Files Detected',
      message: `We Detected ${data.duplicateFiles.length} Duplicate File(s)`
    })
  }
}

const updateProposalList = (d) => {}

const handleSendSomething = () => {
  // console.log('Send Something')
  setShowSendToCustomerModal(true)
}

const handleCloseSendToCustoemrModal = () => {
  setShowSendToCustomerModal(false)
}

// console.log('Customer Details State', contacts)
  return (
    <div className={classes.root} style={{'backgroundColor': '#444'}}>
      <SendToCustomerComponent
        open={showSendToCustomerModal}
        handleCloseModal={handleCloseSendToCustoemrModal}
        type='Something'
        client={client}
        entity={entity}
      />

      <SendSMSModal open={sendSMSModalOpen} client={client} contacts={contacts} handleCloseModal={handleCloseSMSModal} />
      <EditCustomerModal updatedClient={updatedClient} open={editActive} client={client} entity={entity} />
      <IssueCreditModal closeModal={closeCreditModal} client={client} updateParent={creditModalUpdate} open={showCreditModal} />
      <DepositModal jobs={jobs} closeModal={closeDepositModal} paymentProfiles={paymentProfiles} client={client} updateParent={depositModalUpdate} open={showDepositModal} />
      <ModifyDepositModal jobs={jobs} closeModal={() => setShowModifyDepositModal(false)} deposit={deposit} client={client} updateParent={modifyDepositModalUpdate} open={showModifyDepositModal} />
      <BulkPaymentModal jobs={jobs} closeModal={() => setShowPostBulkPayments(false)} client={client} updateParent={modifyPostBulkPaymentResponse} open={showPostBulkPayments} />
      <Dialog
        // fullScreen={true}
        maxWidth='sm'
        open={showStatementStatus}
        onClose={handleClose}
        className={classes.statusModal}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              overflow: 'hidden',
              height: '400px'
            }}}
      >
        {/* <DialogTitle id="responsive-dialog-title">Customer Details</DialogTitle> */}
        <DialogContent style={{textAlign: 'center', color: '#fff', overflow: 'hidden'}}>
        <DialogTitle id="responsive-dialog-title">Statement Status</DialogTitle>
          <Grid container >
            <Grid item lg={12}>
                <CircularProgress color="primary" size={ 96 } /> 
            </Grid>
            <Grid item lg={12}>
             <br /> {statementMessage}
            </Grid>
          </Grid>
         
         
        </DialogContent>
      </Dialog>
      <StatementOptions open={statementOptsModalShow} onUpdate={updateFromStatementOptions} />
      <StatementModal pdf={pdf} open={statementModalOpen} closeStatementModal={closeStatementModal} />
      {/* <SnoutWeevilModal open={snoutWeevilModalOpen} jobSites={jobSites} crewList={crewList} serviceList={serviceList} closeModal={onSWModalClose} client={client} /> */}
      <Dialog
        fullScreen={true}
        maxWidth='xl'
        fullWidth
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle id="responsive-dialog-title">Customer Details</DialogTitle> */}
        <DialogContent>
            <CloseButton />
            <Grid container className={classes.grid}>
            <Grid item lg={12} xs={12} >
              <img alt="Logo" src={`${process.env.PUBLIC_URL}/${props.client.entity}_Logo.png`} style={{margin: '5px', padding: '5px', maxWidth: 220, maxHeight: 100}} />
            </Grid>

              <Grid item lg={4} xs={6} >
                <Grid container className={classes.grid}>
                  
                  <Grid item lg={7} xs={5} >
                    <h2>{client.displayName} <span style={{fontSize: '14px', color: 'rgba(160,150,150,.6)'}}>({client.agId})</span></h2>
                  </Grid>
                  <Grid item lg={2} xs={5} >
                    <ProtectedButton type="Customers" kind="update" perms={props.perms}>
                      <IconButton edge='end' onClick={handleEditClient} alt="Edit Client" aria-label="Edit Client">
                        <EditIcon />
                      </IconButton>
                    </ProtectedButton>
                    
                  </Grid>
                  <Grid item lg={2} md={3} sm={4} xs={5} style={{ marginTop: '12px' }}>
                    <ActivityDrawer client={client} updateParentWithNewActivity={updateParentWithNewActivity} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={2} md={3} sm={4} xs={4} >
                <Tooltip title="Sync Client">
                  <IconButton edge='end' onClick={() => refreshCustomer(props.client)} alt="Sync Client" aria-label="Sync Client">
                    <SyncIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item lg={2} xs={4} md={3} sm={4}>
                {
                  client.averageDaysToPay ? <DaysToPayBar value={client.averageDaysToPay} /> : null
                }
              </Grid>
              <Grid item lg={3} xs={12} md={3} sm={12}>
                <ClientUploadComponent client={client} updateParent={responseFromUpload} />
              </Grid>
              <Grid item lg={4} xs={4} >
                Last updated {moment(client.updated_at).fromNow()}
              </Grid>
              <Grid item lg={12} xs={12}>
                {client.HOA ? <Chip style={{color: '#fff', background: '#f1c40f' }} size="small" label="HOA" /> : ''}
                {client.contractor ? <Chip style={{color: '#fff', background: '#f7d509' }} size="small" label="Contractor" /> : ''}
                {client.paperInvoice ? <Chip style={{color: '#fff', background: '#013466' }} size="small" label="Paper Invoice" /> : ''}
                {client.doNotEmail ? <Chip style={{color: '#fff', background: '#ef0000' }} size="small" label="Do NOT Email" /> : ''}
                {client.autopayMTC ? <Chip style={{color: '#fff', background: '#4cb929' }} size="small" label="Autopay (Maintenance)" /> : ''}
                {client.autopayAll ? <Chip style={{color: '#fff', background: '#33a330' }} size="small" label="Autopay (All)" /> : ''}
              </Grid>
              { client.active ? '' : (
                <Grid item lg={12} xs={12} style={{width: '100%', color: '#fff', fontSize: '2em', backgroundColor: 'rgba(40, 40, 40, .4)'}} align="center">
                  Inactive Client No Workorders Allowed
                </Grid>
              )}
              { client.pastDue ? (
                <Grid item lg={12} xs={12}>
                   <Grid container >
                 <Grid lg={12} xs={12} item style={{width: '100%', color: '#fff', fontSize: '2em', backgroundColor: 'rgba(223, 40, 40, .8)'}} align="center" >
                   <div style={{margin: '5px'}}>
                    Past Due: {currencyFormat(client.pastDueAmount)}
                   </div>
                   </Grid>
                   <Grid lg={12} xs={12} align="center" item  style={{width: '100%', color: '#fff', fontSize: '1.2em', backgroundColor: 'rgba(223, 40, 40, .8)'}}>
                   <Grid container >
                  <Grid item lg={4} xs={12}  >
                    <div className={classes.wrapper} >
                      31-60 Days: {(arSummary && arSummary.thirtyOneToSixty) ? currencyFormat(arSummary.thirtyOneToSixty) : '$0.00'}
                    </div>
                  </Grid>   
                  <Grid item lg={4} xs={12} >
                    <div className={classes.wrapper} >
                      61-90 Days: {(arSummary && arSummary.sixtyOneToNinety) ? currencyFormat(arSummary.sixtyOneToNinety) : '$0.00'}
                    </div>
                  </Grid>   
                  <Grid item lg={4} xs={12} >
                    <div className={classes.wrapper} >
                      91+ Days:  {(arSummary && arSummary.ninetyPlus) ? currencyFormat(arSummary.ninetyPlus) : '$0.00'}
                    </div>
                  </Grid>       
                   </Grid>
                  </Grid>
                  </Grid>
                </Grid>
              ) : ''}
              <Grid item lg={12} xs={12} style={{margin: '10px'}}>
                <Grid container >
                  <Grid item lg={2} xs={12} >
                    <div className={classes.wrapper} >
                      <div className={classes.invoiceBox}>
                        <div className={classes.bigText}>
                          Invoiced
                        </div>
                        <div className={classes.actuaLItem}>
                          Amount: <span className={classes.bigText}>{ currencyFormat(parseFloat(client.totalAmountInvoiced))} </span>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          Balance: <span className={classes.bigText}>{ currencyFormat(parseFloat(client.accountBalance))}</span>
                        </div>
                        
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={12} >
                    <div className={classes.wrapper} >
                      <div className={classes.paymentsBox}>
                        <div className={classes.bigText}>
                          Payments Received
                        </div>
                        <div className={classes.actuaLItem}>
                          Amount: <span className={classes.bigText}>{ currencyFormat(parseFloat(client.totalPaymentsPaid))}</span>
                        </div>
                        
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={12} >
                    <div className={classes.wrapper} >
                      <div className={classes.depositsBox}>
                        <div className={classes.bigText}>
                          Deposits Received
                        </div>
                        <div className={classes.actuaLItem}>
                          Amount: <span className={classes.bigText}>{ currencyFormat(parseFloat(client.totalDepositsReceived))}</span>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          Balance: <span className={classes.bigText}>{ currencyFormat(parseFloat(client.depositBalance))}</span>
                        </div>
                        
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={12} >
                    <div className={classes.wrapper} >
                      <div className={classes.creditsBox}>
                        <div className={classes.bigText}>
                         Credits Applied
                        </div>
                        <div className={classes.actuaLItem}>
                          Amount: <span className={classes.bigText}>{ currencyFormat(parseFloat(client.totalCreditsApplied))}</span>
                        </div>
                        
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={12} >
                    <div className={classes.wrapper} >
                      <div className={classes.invoiceBox}>
                        <div className={classes.bigText}>
                          Write-Offs
                        </div>
                        <div className={classes.actuaLItem}>
                          Amount: <span className={classes.bigText}>{ currencyFormat(parseFloat(client.writeOffs))} </span>
                        </div>
                        
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={12} >
                    <div className={classes.wrapper} >
                      <div className={classes.balanceBox}>
                        <div className={classes.bigText}>
                         Account Balance
                        </div>
                        <div className={classes.actuaLItem}>
                          Balance: <span className={classes.bigText}>{ currencyFormat(parseFloat(client.accountBalance))}</span>
                        </div>
                        
                      </div>
                    </div>
                  </Grid>
                </Grid>


              </Grid>              
            </Grid>
              <Grid container  className={classes.grid}>
              <Grid style={{padding: '10px', fontSize: '18px'}} item lg={3} xs={6} >
                <Grid container>
                  <Grid item lg={12} xs={12}>
                    {client.displayName}
                  </Grid>
                  <Grid item lg={12} xs={12} >
                    {client.address} <br/> {client.address2} <br/> {client.city}, {client.state} {client.zip}
                  </Grid>
                  <Grid item lg={12} xs={12} >
                   {client.phone}&nbsp;&nbsp;&nbsp; {client.phone ? (
                      <Tooltip title="Send SMS To Client">
                        <IconButton edge={false} size="small" alt="Send SMS" aria-label="Send SMS" onClick={handleSendSMS}>
                          <SmsIcon />
                        </IconButton>
                      </Tooltip>
                    ) : '' }
                    
                  </Grid>
                  <Grid item lg={12} xs={12} >
                    {client.email}&nbsp;&nbsp;&nbsp;
                    <Tooltip title="Send Email To Client">
                      <IconButton edge={false} size="small" alt="Send Email" aria-label="Send Email" onClick={handleSendSomething}>
                        <SendIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {/* <Grid item lg={12} xs={12} >
                    <ContactList client={client} style={{width: '100%'}} />
                  </Grid> */}
                </Grid>
              </Grid>
                <Grid item align="center" lg={8} xs={12} >
                  <Grid container style={{marginTop: '50px'}}
                    alignItems="center"
                    justifyContent="center">
                  <Grid item lg={3} xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      className={classes.button}
                      startIcon={<ReceiptIcon />}
                      size="small"
                      onClick={() => setStatementOptsModalShow(true)}
                    >
                    { loading ? <span><CircularProgress style={{color: '#fff'}} size={ 16 } />Generate Statement</span> : '      Generate Statement'}
                    </Button> 
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        className={classes.button}
                        startIcon={<AttachMoneyIcon />}
                        size="small"
                        onClick={() => setShowCreditModal(true)}
                      >
                      Issue Credit
                      </Button>   
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        className={classes.button}
                        startIcon={<AttachMoneyIcon />}
                        size="small"
                        onClick={() => setShowDepositModal(true)}
                      >
                      Deposit
                      </Button>  
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        className={classes.button}
                        startIcon={<AttachMoneyIcon />}
                        size="small"
                        onClick={() => setShowPostBulkPayments(true)}
                      >
                      Post Payment(s)
                      </Button>  
                  </Grid>
                  </Grid>
                 
                </Grid>
                <Grid container className={classes.grid}>
                <Grid item lg={6} sm={12}>
                    <PaymentTerms client={client} updatePaymentTerms={updatePaymentTerms} />
                  </Grid>
                  <Grid item lg={12}>
                    <ContactList client={client} contacts={contacts} style={{width: '100%'}} updateContacts={updateClientContacts} />
                  </Grid>
                </Grid>
                <Grid container  style={{marginTop: '20px', margin: '10px', padding: '10px'}}>
                  <Grid item lg={6} xs={12}>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <h3>Credits ({currencyFormat(client.creditBalance || 0)})</h3>
                        { (credits && credits.length) ? <CreditList data={credits} perms={props.perms} handleDeleteCredit={handleDeleteCredit} /> : 'No Credits'}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12} style={{padding: '5px'}}>
                        <h3>Deposits ({currencyFormat(client.depositBalance || 0)})</h3>
                        { (deposits && deposits.length) ? <DepositList data={deposits} /> : 'No Deposits'}
                      </Grid>
                    </Grid>
                
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Grid container >
                      <Grid item lg={12} xs={12} >
                        <AddCustomerPaymentMethods updateParent={updatePmtMethodResponse} client={client} />
                        &nbsp;&nbsp;&nbsp;
                        <AddCustomerEcheck updateParent={updatePmtMethodResponse} client={client} />
                      </Grid>
                      <Grid item lg={12} xs={12} >
                        Customer Payment Profile: { client.customerProfileId }<br />
                        Payment Profiles: { paymentProfiles.length }
                        <ul>
                        { paymentProfiles.map((item, ppIndex) => {
                          // console.log('payment profile', item.customerPaymentProfileId)
                          // console.log(client.paymentProfileId)
                          return <Grid container key={ppIndex} className={classes.paymentProfiles} >
                            { item.payment.creditCard ? 
                            <Grid item lg={6} xs={12} style={{ padding: '15px' }}>
                              Credit Card <br/>
                              Card Type: {item.payment.creditCard.cardType}<br/>
                              Card Number: {item.payment.creditCard.cardNumber}
                            </Grid>
                            :
                            <Grid item lg={6} xs={12} style={{ padding: '15px' }}>
                              eCheck <br/>
                              Bank Name: {item.payment.bankAccount.bankName}<br/>
                              Account Number: {item.payment.bankAccount.accountNumber}
                            </Grid>
                          }
                            <Grid item lg={6} xs={12} >
                              <Grid container>
                                <Grid item lg={12} xs={12} >
                                  {
                                    item.customerPaymentProfileId === client.paymentProfileId ?
                                    '' :
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className={classes.button}
                                      startIcon={<CreditCardIcon />}
                                      size="small"
                                      onClick={() => makePrimaryPaymentProfile(item, ppIndex)}
                                    >
                                      Make Primary
                                    </Button>   
                                  }
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <Button
                                      variant="contained"
                                      style={{backgroundColor: '#ed4545'}}
                                      className={classes.button}
                                      size="small"
                                      startIcon={<DeleteIcon />}
                                      onClick={() => { if (window.confirm('Are you sure you wish to delete this payment profile?')) handleDeletePaymentProfile(item, ppIndex) }}
                                    >
                                      Delete
                                    </Button>                        
                                </Grid>
                                {/* <Grid item lg={6} xs={12} >
                                <IconButton edge='end' aria-label="delete" onClick={() => handleDeletePaymentProfile(item, ppIndex)}>
                                  <DeleteIcon />
                                </IconButton>
                                </Grid> */}
                              </Grid>
                              
                            </Grid>
                                            
                              
                          </Grid>
                        })}
                        </ul>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
          <Grid container className={classes.grid}>
            <Grid item lg={12} xs={12} style={{padding: '5px'}}>
      <Accordion square expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content"  expandIcon={<ExpandMoreIcon />} id="panel5d-header">
          <Typography>Customer Notes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomerNotes client={client} />
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'tasks'} onChange={handleChange('tasks')}>
        <AccordionSummary aria-controls="tasks-content"  expandIcon={<ExpandMoreIcon />} id="tasks-header">
          <Typography>Tasks ({tasks.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TaskList tasks={tasks} client={ client } />
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panelActivities'} onChange={handleChange('panelActivities')}>
        <AccordionSummary aria-controls="panelActivities-content"  expandIcon={<ExpandMoreIcon />} id="panelActivities-header">
          <Typography>Activities (Phone Calls, Text Messages, Emails, Etc)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ActivityDetailsContainer activities={state.activities} client={client} count={state.counter} />
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'estimates'} onChange={handleChange('estimates')}>
        <AccordionSummary aria-controls="estimates-content"  expandIcon={<ExpandMoreIcon />} id="estimates-header">
          <Typography>Estimates/Proposals/Bids</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProposalList stateData={ state } client={ client } entity={entity} contacts={contacts} jobSites={jobSites} postConversionProcess={postConversionProcess} updateProposalList={updateProposalList} />
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content"  expandIcon={<ExpandMoreIcon />} id="panel3d-header">
          <Typography>Job Sites</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <JobSiteList client={ client } jobSites={jobSites} parentResponse={jobSiteResponse} />
        </AccordionDetails>
      </Accordion>
            <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content"  expandIcon={<ExpandMoreIcon />} id="panel2d-header">
          <Typography>Invoices</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <InvoiceList stateData={ state } contacts={contacts} paymentProfiles={paymentProfiles} client={ client } deposits={deposits} credits={credits} entity={entity} />
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content"  expandIcon={<ExpandMoreIcon />} id="panel1d-header">
          <Typography>Work Orders (Jobs)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <JobList stateData={ state } takeOffs={takeOffs} uploads={uploads} client={ client } deposits={deposits} jobSites={jobSites} projects={projects} updateParent={getJobsFromJobList} taxLocales={taxLocales} />
        </AccordionDetails>
      </Accordion>
      {/* <Accordion square expanded={expanded === 'panelProjects'} onChange={handleChange('panelProjects')}>
        <AccordionSummary aria-controls="panelProjects-content"  expandIcon={<ExpandMoreIcon />} id="panelProjects-header">
          <Typography>Projects</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProjectList projects={projects} />
        </AccordionDetails>
      </Accordion> */}

      
      <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content"  expandIcon={<ExpandMoreIcon />} id="panel4d-header">
          <Typography>Payment History</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <PaymentList client={ client } payments={payments} />
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panelCredit'} onChange={handleChange('panelCredit')}>
        <AccordionSummary aria-controls="panelCredit-content"  expandIcon={<ExpandMoreIcon />} id="panelCredit-header">
          <Typography>Credits History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CreditHistory client={ client } credits={credits} />    
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary aria-controls="panel8d-content"  expandIcon={<ExpandMoreIcon />} id="panel8d-header">
          <Typography>Deposit History</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <DepositHistory client={ client } deposits={deposits} />
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel7d-content"  expandIcon={<ExpandMoreIcon />} id="panel7d-header">
          <Typography>Maintenance Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {
            (client.entity === '640a56404965b71a9edd0e7d' || client.entity === '647251447960023890be6a19') ? (
              <HMIMaintenanceContainer client={client} contacts={contacts} crewList={crewList} jobSites={jobSites} />
            ) : (
              <MaintenanceDetails stateData={ state } client={client} crewList={crewList} jobSites={jobSites} refreshCustomer={handleRefresh} />
            )
          }
          
          
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'poolMtc'} onChange={handleChange('poolMtc')}>
        <AccordionSummary aria-controls="poolMtc-content"  expandIcon={<ExpandMoreIcon />} id="poolMtc-header">
          <Typography>Pool Maintenance Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PoolMaintenanceContainer client={client} jobSites={jobSites} contacts={contacts} entity={entity} />
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panelProjects'} onChange={handleChange('panelProjects')}>
        <AccordionSummary aria-controls="panelProjects-content"  expandIcon={<ExpandMoreIcon />} id="panelProjects-header">
          <Typography>Projects ({projects.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProjectList projects={projects} client={client} />
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary aria-controls="panel9d-content"  expandIcon={<ExpandMoreIcon />} id="panel9d-header">
          <Typography>Service Visits</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ServiceVisitList client={client}  />
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content"  expandIcon={<ExpandMoreIcon />} id="panel6d-header">
          <Typography>Recent Activity</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RecentActivityDetails client={ client } />
        </AccordionDetails>
      </Accordion>
      <UploadsAccordion
        client={client}
        projects={projects}
        url={props.url}
        numberOfUploads={numberOfUploads}
        numberOfPhotos={numberOfPhotos}
        notification={props.notification}
        handleChange={handleChange}
        expanded={expanded}
      />
      {/* <Accordion square expanded={expanded === 'panelUploads'} onChange={handleChange('panelUploads')}>
        <AccordionSummary aria-controls="panelUploads-content"  expandIcon={<ExpandMoreIcon />} id="panelUploads-header">
          <Typography>Uploads (Files/Photos {uploads.length}) </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UploadsList client={ client } uploads={ uploads } projects={projects} />
          </AccordionDetails>
      </Accordion> */}

          </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  )
  }

  const mapStateToProps = (state) => {
    // console.log('Mat to customer: ', state)
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      perms: state.perms,
      contacts: state.contacts
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setClient: (client) => { dispatch(setClient(client)) },
      setContacts: (contacts) => { dispatch(setContacts(contacts)) },
      setProjects: (projects) => { dispatch(setProjects(projects)) }
    }
  }

  export default React.memo(connect(mapStateToProps, mapDispatchToProps)(CustomerDetail))
