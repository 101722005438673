import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import UploadDetailsInputArea from './UploadDetailsInputArea'
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import heic2any from 'heic2any'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const defaultInputValues = {
  projectName: ''
}

const categoryOptions = [
  'Vendor Quotes',
  'Vendor Invoice',
  'Customer Invoice',
  'Purchase Order',
  'Photos',
  'Plans',
  'Estimates',
  'Other'
]

const EditUploadDetails = (props) => {
// console.log('Bulk UPload Details: ', props)
  // console.log('NewProjectModal  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [client, setClient] = React.useState({})
  const [pdf, setPDF] = React.useState({})
  const [upload, setUpload] = React.useState({})
  const [clients, setClientList] = React.useState([])
  const [vendors, setVendors] = React.useState([])
  const [purchaseOrders, setPOs] = React.useState([])
  const [purchaseOrder, setPO] = React.useState({})
  const [sizes, setSizes] = React.useState([])
  const [state, setState] = React.useState({
    status: 'New',
    projectName: '',
    notes: '',
    name: '',
    uploadDetails: {}
  })

  const inputRef = React.useRef({  })
  const uploadDetailsRef = React.useRef({ })

  React.useEffect(() => {
  // console.log('Upload changed', props.upload)
    setUpload(props.upload)
  }, [props.upload])

  React.useEffect(() => {
    let isMounted = true
    const fetchPDF = async (url, type) => {
      // console.log('FETCH: ', url)
      let cleanURL = ''
      if (url.includes('https')) {
        cleanURL = url
      } else {
        cleanURL = `https://${url}`
      }
      // console.log('CLEAN: ', cleanURL)
      await axios.get(cleanURL, {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/pdf, application-octet-stream'
        },
        responseType: 'blob'
      }).then((response) => {
      // }).then(response => response.arrayBuffer())
      //   .then(blob => {
      //   // console.log('Blob: ', blob)
          // let body = response.data
          // let base64Version = Buffer.from(body).toString('base64')
          // console.log('Base 64', base64Version)
          // // let blob = response.data.blob()
          // let newStuff = 'data:application/pdf;base64,' + base64Version
          // console.log(newStuff)
          // console.log('response:', response)
          if (type === 'pdf') {
            let newBlob = new Blob([response.data], {type: "application/pdf"})
            // console.log(newBlob)
            const data = window.URL.createObjectURL(newBlob);
            // console.log(data)
            // var link = document.createElement('a');
            // link.href = data;
            // link.download="file.pdf";
            // link.click();
            setPDF(data)
          }
          if (type === 'heic') {
          // console.log('WE got our data...now we need to get a buffer', response.data)
            let newBlob = new Blob([response.data], {type: "image/heic"})
            heic2any({
              blob: newBlob,
              toType: "image/jpeg",
              quality: 0.7
            })
            .then((result) => {
            // console.log('HEIC Response...')
                let reader = new FileReader();
                reader.onload = function(event) {
                    let base64String = event.target.result;
                  // console.log('Base 64...', base64String)
                    setPDF(base64String)
                    // Set as src of img tag
                    // document.getElementById("myImage").src = base64String;
                };
                reader.readAsDataURL(result);
            })
            .catch((error) => {
                console.error(error);
            });
            // console.log('Got a blob!!!!', newBlob)
            // let base64Version = Buffer.from(newBlob).toString('base64')
            // console.log('Base64:', base64Version)
           
            // // console.log(newBlob)
            // const data = window.URL.createObjectURL(newBlob);
            // setPDF(data)
          }
        })
      // console.log(response)
      // if (response && response.data) {
      // }
    }
    if (props.upload && props.upload._id) {
      // console.log('Set our inptu ref; ', inputRef, props.upload)
      let name = props.upload.name
      let notes = props.upload.notes
      let category = props.upload.category
      // var newBlob = new Blob([blob], {type: "application/pdf"})
      if (props.upload && props.upload.type === 'application/pdf') {
        // console.log('Display our PDF')
        fetchPDF(props.upload.url, 'pdf')
      } else if (props.upload && props.upload.name && (props.upload.name.toLowerCase().includes('heic'))) {
      // console.log('fetch the HEIC!!')
        fetchPDF(props.upload.url, 'heic')
      }
      setState({...state, name, notes, category})
    }

    return () => {
      isMounted = false
    }
  }, [props])

  React.useEffect(() => {
    let isMounted = true
    const fetchClients = async () => {
      setLoading(true)
      // ?byEntity=610a290f45ef7427f94b2e78
      let url = `${props.url}/api/customer/clients`
      axios.get(url).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setClientList(response.data)
        }
      })
    }

    const fetchVendors = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/company/getVendors`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setVendors(response.data)
        }
      })
    }

    const fetchPOs = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/projects/getOpenPOs`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          // console.log('Go Purchase Orders: ', response.data)
          if (isMounted) setPOs(response.data)
        }
      })
    }

    const fetchSizes = async () => {
      setLoading(true)
      axios.get(
        `${props.url}/api/settings/getStuffForNewItem`
        )
      .then(({ data }) => {
        setLoading(false)
        if (isMounted) {
          if (data && data.sizes) {
            setSizes(data.sizes)
          }
        }
      })
    }

    if (props.open) {
      fetchClients()
      fetchVendors()
      fetchPOs()
      fetchSizes()
    }

    return () => {
      isMounted = false
    }
  }, [props.open])

  const completeTheSave = async (data) => {
  // console.log('Complete the save 267:', data)
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/tagOurUpload`,
      data: data
    }).then(response => {
      setLoading(false)
      if (response.data && response.data._id) {
        props.notification({
          type: 'success',
          title: 'Uploads Saved',
          message: 'That Upload Has Been Saved. Great Job!'
        })
        // inputRef.current = {
        //   vendor: null,
        //   client: null,
        //   notes: null,
        //   amount: null,
        //   number: null,
        //   project: null
        // }
        console.log('Upload has been tagged!!!', response.data)
        // Remove PO if used
        if (data && data.po) {
          let poList = purchaseOrders
          let index = poList.findIndex(item => (item._id === data.po))
          if (index > -1) {
            poList.splice(index, 1)
            setPOs(poList)
          }
        }
        setUpload({})
        props.updateUpload(response.data)
        setState({...state, status: '', notes: ''})
        // setState({...state, name: '', notes: ''})
        // props.updateParent(response.data)
        // props.closeModal()
      }
    })
  }

  const handleSave = () => {
    // console.log(inputRef)
    // console.log('Handle save: ', state)
    // console.log(inputRef.current)
    // console.log('Upload Details:', uploadDetailsRef.current)
    setLoading(true)
    // setClient({})
    // let data = state // inputRef.current
    let data = uploadDetailsRef.current
    // console.log('Data: ', data)
    if (data?.documentType === 'Vendor Invoice') {
      // console.log('Deal w/ this...', data)
      if (!data.purchaseOrder) {
        props.notification({
          type: 'warning',
          title: 'Cannot Save',
          message: 'Vendor Invoices Must Have an Associated Purchase Order'
        })
        setLoading(false)
        return
      }
    }
    let vendor = data.vendor
    let client = data.client
    data.upload = props.upload._id
    if (client === null && vendor === null) {
      if (window.confirm(`There is no Client or Vendor Associated - Are You Sure You Wish To Proceed?`)) {
        // console.log('Do it...')
        completeTheSave(data)
      } else {
        setLoading(false)
      }
    } else {
      completeTheSave(data)
    }
    // setLoading(false)
  }

  const updateCustomer = (e, customer) => {
    if (customer && customer._id) {
      setClient(customer)
      inputRef.current.client = customer
    }
  }

  const updateSalesRep = (e, rep) => {
    if (rep && rep._id) {
      inputRef.current.salesRep = rep
    }
  }
  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
    // console.log(e.target)
      if (e.target.id === 'customername') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        props.clients.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          setClient(newArr[0])
        }
      }
    }
  }

  const updateStatus = (e, status) => {
    inputRef.current.status = status
  }
  const updateRef = (e) => {
    inputRef.current[e.target.id] = e.target.value
  }
  const handleFocus = (e) => {
    e.target.select()
  }

  const handleClose = () => {
    props.closeModal()
  }

  const updateFromUploadDetails = (data) => {
  // console.log('Update From Parent', data)
  if (data?.type === 'ignore') {
    console.log('Ignore this one', data)
    completeTheSave(data)
  } else {
    uploadDetailsRef.current = data
  }

    // setState({ ...state, uploadDetails: data })
  }

  // const handleSave = () => {
  // // console.log('Handle Save', state)

  // }

  const handlePrevious = () => {
    props.handlePrevItem()
  }
  const handleNext = () => {
    props.handleNextItem()
  }

  const handleDeleteUpload = (data) => {
    console.log('Delete this upload', data)
    props.handleDeleteUpload(data)
    props.handleNextItem()
  }

  const handleSwapUpload = (po, upload) => {
    console.log('Swap Upload no this PO', po)
    console.log(upload)
    let obj = {
      poId: po._id,
      data: {
        vendorInvoice: upload._id,
        updated_at: new Date()
      },
      addUpload: upload._id
    }
    // setLoading(true)
    // setClient({})
    // setState({...state, status: 'New', projectName: ''})
    axios({
      method: 'post',
      url:  `${props.url}/api/purchaseorders/updatePurchaseRequest`,
      data: obj
    }).then(response => {
      // setLoading(false)
      // console.log('Response', response.data)
      if (response.data && response.data._id) {
      //   props.closeModal()
      //   setState({...state, ...defaultValues})
        props.notification({
          type: 'success',
          title: `Purchase Request Updated`,
          message: 'Thank you for your help!'
        })
        if (props && props.history) props.history.push('/')
      } else if (response && response.data && response.data.error) {
          props.notification({
            type: 'warning',
            title: 'Upload Error',
            message: 'Error Updating PO - Error Code 477'
          })
        }
      //   // if (response.data && response.data.po && response.data.po._id) inputRef.current._id = response.data.po._id
      //   // inputRef.current.poNumber = response.data.poNumber
      //   // setState({...state, poNumber: response.data.poNumber})
      //   // props.updateParent(response.data)
      //   // handleClose()
      //   // props.closeModal()


    })
  }

  let isValid = true
  // if ()


  // if (loading || !state.uploadDetails.documentType || (state.uploadDetails.documentType === 'Vendor Invoice' && (!state.uploadDetails.purchaseOrder || !state.uploadDetails.vendor || !state.uploadDetails.amount || !state.uploadDetails.number || (state.uploadDetails.invoiceType === 'Client Project/Job' && !state.uploadDetails.client) ))) {
  //   console.log('DO NOT ALLOW A SAVE!!!', state)
  //   // isValid = false
  // }
  // console.log('Upload Details state:', state)
  // console.log('UPloadREf:', uploadDetailsRef.current)
  let theURL = ''
  if (props.upload && props.upload.url) {
      theURL = props.upload.url
    if (theURL && theURL.includes('https://')) {
      // console.log('Hooray!!', theURL)
    } else {
      theURL = `https://${props.upload.url}`
    }
    // console.log('Url to use:', theURL)
  }

 

  return (
    <div className={classes.root}>
      <Dialog open={props.open} fullWidth={true} onClose={handleClose} fullScreen aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            
            Tag Upload / Reconcile Invoice
            <div style={{cursor: 'pointer', padding: 3, display: 'flex', alignSelf: 'flex-end', alignContent: 'flex-end', justifyContent: 'flex-end', alignItems: 'center'}}>
              <div onClick={handlePrevious}
                style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}
                >
              <SkipPreviousIcon  />
              {props.index - 0 + 1} 
              </div>&nbsp;/&nbsp; 
              <div onClick={handleNext}
                 style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}
                >
              {props.totalFiles} <SkipNextIcon  />
              </div>
            </div>
          </DialogTitle>
          <DialogContent >
          
            <DialogContentText>
              Please Help Us Identify What This Upload Is ({props.upload.name})
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                Uploaded: {moment(props.upload.created_at).format('MM/DD/YY hh:mma')}
              {
                  (props.upload && props.upload.type && props.upload.type.includes('pdf')) ? 
                    <object style={{padding: '15px'}} aria-label="PDF" data={ pdf } type="application/pdf" width="100%" height="900px" /> : 
                      (props.upload && props.upload.type && props.upload.type.includes('image')) ?  <img src={theURL} style={{ width: '100%' }} /> : null
                      
                }
                {
                  (props.upload && props.upload.name && (props.upload.name.toLowerCase().includes('heic'))) ?  <img src={pdf} style={{ width: '100%' }} /> : null
                }
                {
                  (props.upload && props.upload.type && (props.upload.type.includes('csv') || props.upload.type.includes('office'))) ? <iframe width="100%" height="900px" src={`https://docs.google.com/gview?url=${props.upload.url}&embedded=true`}></iframe> : null
                }
                
                
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <UploadDetailsInputArea upload={upload} sizes={sizes} clients={clients} vendors={vendors} purchaseOrders={purchaseOrders} reconciledPOs={props.reconciledPOs} swapUploadOnPO={handleSwapUpload} updateParent={updateFromUploadDetails} handleDeleteUpload={handleDeleteUpload} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button color="primary" disabled={loading || !isValid } onClick={handleSave}>
            {/* || !uploadDetailsRef.current.documentType || (uploadDetailsRef.current.documentType === 'Vendor Invoice' && (!uploadDetailsRef.current.vendor || !uploadDetailsRef.current.amount || !uploadDetailsRef.current.number)) || (uploadDetailsRef.current.invoiceType === 'Client Project/Job' && !uploadDetailsRef.current.client)  */}
              {
                loading ? <CircularProgress /> : 'Save'
              }
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  )
}


const getOurBuffer = (theURL) => {
// console.log('Return a base64 buffer...')
  let buf = Buffer.from(theURL).toString('base64')
// console.log('Buffer: ', buf)

  let ourString = `data:image/png;base64,${buf}`
// console.log('OUR STRING::', ourString)
  return ourString
}

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(EditUploadDetails)