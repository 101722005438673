// TaxContext.js
import React, { createContext, useState, useContext, useCallback } from 'react';
import axios from 'axios';

const TaxContext = createContext();

export function TaxProvider({ children, url }) {
  const [taxLocales, setTaxLocales] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastFetched, setLastFetched] = useState(null);

  // Cache duration in milliseconds (e.g., 1 hour)
  const CACHE_DURATION = 60 * 60 * 1000;

  const fetchTaxLocales = useCallback(async (force = false) => {
    // If we have data and it's not stale, use cached data
    console.log('Fetch Tax locales', taxLocales)
    if (!force && taxLocales.length > 0 && lastFetched && 
        (Date.now() - lastFetched) < CACHE_DURATION) {
      return taxLocales;
    }

    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(`${url}/api/settings/listTaxLocales`);
      
      if (response.data && response.data.length > 0) {
        setTaxLocales(response.data);
        setLastFetched(Date.now());
        return response.data;
      }
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [url, taxLocales, lastFetched]);

  const calculateTaxableAmount = useCallback(async ({ workorder, estimate, client, lineItems = [], fetchLocales = false, selectedOption, chosenLocale }) => {
    console.log('Calculate Taxable amount', workorder, estimate, client, lineItems)
    if (client?.nonTaxable) return { totalCost: 0, materialsCost: 0, option1Amount: 0, option2Amount: 0 };
    const obj = {
      workorderId: workorder?._id,
      estimateId: estimate?._id,
      // client,
      lineItems,
      updateType: 'calculateTaxes',
      fetchLocales,
      selectedOption,
      chosenLocale
    }
    let postURL = `${url}/api/jobs/updateWorkorder?lineItemStyle=true`
    const resp = await axios.post(postURL, obj);
    console.log('Got our tax able amount!!', resp.data)
    if (resp.data?.success && resp.data?.details) {
      let formattedData = resp.data.details
      return formattedData
    }
  }, []);

  // const calculateTaxableAmountOLD = useCallback(async ({ workorder, client, lineItems = [] }) => {
  //   console.log('Calculate Taxable amount', workorder, client, lineItems)
  //   if (client?.nonTaxable) return { totalCost: 0, materialsCost: 0, option1Amount: 0, option2Amount: 0 };

  //   const totalCost = lineItems?.reduce((sum, item) => {
  //     if (item.totalPrice) {
  //       return sum + Number(item.totalPrice);
  //     }
  //     return sum + (Number(item?.qty || 0) * Number(item?.pricePer || 0));
  //   }, 0);

  //   const materialsCost = lineItems
  //     .filter(item => item?.lineItemType?.toLowerCase() === 'materials')
  //     .reduce((sum, item) => {
  //       if (item.totalPrice) {
  //         return sum + Number(item.totalPrice);
  //       }
  //       return sum + (Number(item?.qty || 0) * Number(item?.pricePer || 0));
  //     }, 0);
  //     // let taxLocales
  //     console.log('Deal w/ tax locales', taxLocales)
  //     if (!taxLocales?.length) {
  //       console.log('Fetch those tax locales..')
  //       let locs = await fetchTaxLocales()
  //       console.log('Now we have them!!', locs)

  //     }
  //   return {
  //     totalCost,
  //     materialsCost,
  //     option1Amount: totalCost * 0.65,
  //     option2Amount: materialsCost
  //   };
  // }, []);

  const value = {
    taxLocales,
    loading,
    error,
    fetchTaxLocales,
    calculateTaxableAmount,
    lastFetched
  };

  return <TaxContext.Provider value={value}>{children}</TaxContext.Provider>;
}

// Custom hook to use the tax context
export function useTax() {
  const context = useContext(TaxContext);
  if (!context) {
    throw new Error('useTax must be used within a TaxProvider');
  }
  return context;
}
