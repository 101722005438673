import React from 'react'
import { connect } from 'react-redux'
import { Button, CircularProgress, IconButton, Chip } from '@material-ui/core';
import axios from 'axios'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import styled from 'styled-components'
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import NewLineItemComponent from './NewLineItemArea'
import TextField from '@material-ui/core/TextField';
import EditLineItemModal from './EditLineItemModal';
import EstimateListComponent from './LineItemList';
import EditIcon from '@material-ui/icons/Edit';
import EditEstimateDetails from './EditEstimateDetails';
import DeleteIcon from '@material-ui/icons/Delete';
import ConvertToWO from './ConvertToWO'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import SendToCustomerModal from '../Common/SendToCustomer/SendToCustomerModal'
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DepositComponent from './DepositComponent'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import VisibilityIcon from '@material-ui/icons/Visibility';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Grid from '@material-ui/core/Grid';
import { socket } from '../SocketIo/Socket'
import moment from 'moment'
import LastUpdateByDetails from '../Common/LastUpdatedByDetails'
import TrackProjectModal from './TrackProjectModal'
import EstimateConversionModal from './ChooseConverstionType'
// const Container = styled.div`
//   position: flex;
//   top: 100;
//   left: 100;
//   padding: 20px;
//   border: 3px solid #ccc;
//   border-radius: 10px;
//   overflow: hidden;
//   min-height: 250px;
//   width: 95%;
//   background-color: #bbb;
// `;

const TopRow = styled(Grid)`
  display: flex;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  align-tems: center;
`;

const TopRowItem = styled(Grid)`
  display: flex;
  align-self: ${props => (props.align === 'start' ? 'flex-start' : 'flex-end')};
`;

const ControlRow = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 15px;
`;

const ShowPDFTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content; space-between;
`;


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflow:'hidden'
  },
  modal: {
    position:'absolute',
    top:'10%',
    left:'10%',
    overflow:'hidden',
    height:'100%',
    display:'block'
  },
  estimateList: {
    marginTop: '375px'
  },
  estimateListRow: {
    cursor: 'pointer'
  },
  paddedStuff: {
    paddingBottom: 20
  }
}));

const defaultValues = {
  name: '',
  estimateName: '',
  list: [],
  job: null,
  estimate: null,
  counter: 0,
  depositPercent: 50,
  salesRep: {},
  workorders: []
}

const formatOption = (opt) => {
  // console.log('Format this: ', opt)
  if (opt && opt.address) {
    return `${opt.name} - ${opt.address} ${opt.address2 ? opt.address2 : ''} ${opt.city}, ${opt.state} ${opt.zip}`
  } else {
    return ''
  }
}

function currencyFormat(num) {
  // console.log('The Num: ', num)
  if (!num) num = 0
  // console.log('NUM: ', num)
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const NewEstimateModal = (props) => {
  // console.log('New Estimate Modal 129', props)
  const classes = useStyles();

  const [state, setState] = React.useState(defaultValues)
  const [takeOffList, setTakeOffList] = React.useState([])
  const [ourList, setOurList] = React.useState([])
  const [pdf, setPDF] = React.useState({})
  const [showPdf, setShowPDF] = React.useState(false)
  const [estimate, setEstimate] = React.useState({})
  const [showEditLineItemModal, setShowEditLineItemModal] = React.useState(false)
  const [itemToEdit, setItemToEdit] = React.useState({})
  const [activeIndex, setActiveIndex] = React.useState(null)
  const [showEditEstimateModal, setShowEditEstimateModal] = React.useState(false)
  const [conversionModalOpen, setConversionModalOpen] = React.useState(false)
  const [jobSite, setJobSite] = React.useState({})
  const [billingAddress, setBillingAddress] = React.useState({})
  const [showSendToCustomerModal, setShowSendToCustomerModal] = React.useState(false)
  const [associatedProject, setAssociatedProject] = React.useState({})
  const [projectList, setProjectList] = React.useState([{ name: 'Create New Project', _id: 'new' }])
  const [loading, setLoading] = React.useState(false)
  const [estimateConversionModalOpen, setEstimateConversionModalOpen] = React.useState(false)
  // const [jobSites, setJobSites] = React.useState([])
  // const [allAddresses, setAllAddresses] = React.useState([])

  const fetchTakeOffs = async () => {
    const response = await axios.get(`${props.url}/api/company/listTakeOffs?all=1`)
    if (response && response.data && response.data.length) {
      setTakeOffList(response.data)
    }
  }

  React.useEffect(() => {
    socket.on(`estimateUpdate`, (data) => {
      // console.log('Estimate Update Socket shit::::', data)
      // console.log('We got an estimate???', props)
      dynamicEstimateUpdate(data)
    })

    return () => {
      // console.log('New Estimate Modal Closing')
      socket.off('estimateUpdate')
    }
  }, [props])

  React.useEffect(() => {

    // let ourList = response.data
    // ourList.unshift({ name: 'Create New Project', _id: 'new' })
    // setProjectList(ourList)
    fetchTakeOffs()

  }, [])

  const dynamicEstimateUpdate = (updatedEstimate) => {
  // console.log('Updating estimates from socket data!!!', updatedEstimate)
  // console.log('Estimate today:', props)
  // console.log('StATE:', state)
  // console.log('PROPS.estimate:', props.estimate)
    if (updatedEstimate && updatedEstimate._id === props.estimate._id) {
    // console.log('This is OUR Estimate!!!', updatedEstimate)
      if (updatedEstimate.updated_by === props.user._id){
      // console.log('Ignore it - we did it...')
      } else {
        setEstimate(updatedEstimate)
        setState({...state, estimate: updatedEstimate })
        let newList = updatedEstimate.lineItems
      // console.log('New List after update!', newList)
        setOurList(newList)
      }
    } else {
    // console.log('No match', props.estimate)
    }
  }

  // const updateAccessList = () => {
  // // console.log('THis fucker is leaving!', props.user)
  //   let obj = {
  //     estimate: estimate._id
  //   }
  // // console.log(obj)
  //   axios({
  //     method: 'post',
  //     url:  `${props.url}/api/proposal/userClosedEstimate`,
  //     data: obj,
  //     // validateStatus: (status) => {
  //     //   // console.log('Validate status: ', status)
  //     //   if (status && status === 500) {
  
  //     //   }
  //     // },
  //   }).then(response => {
  //   // console.log('User closed estimate response', response.data)
  //   })
  // }
  // React.useEffect(() => {
  //   // console.log("CREATE ALL ADDRESS LIST FOR ESTIMATE", props.client)
  //   setJobSites(props.jobSites)
  //   let addys = []
  //   for (const addy of props.jobSites) {
  //     addys.push({
  //       name: addy.name,
  //       address: addy.address,
  //       address2: addy.address2,
  //       city: addy.city,
  //       state: addy.state,
  //       zip: addy.zip
  //     })
  //   }
  //   // console.log('Addys so far ', addys)
  //   addys.push({
  //     name: 'Client Billing Address',
  //     address: props.client.address,
  //     address2: props.client.address2,
  //     city: props.client.city,
  //     state: props.client.state,
  //     zip: props.client.zip
  //   })
  //   // console.log('Addys: ', addys)
  //   // console.log('And props.jobsites:', props.jobSites)
  //   // console.log(jobSites)
  //   setAllAddresses(addys)
  // }, [props.jobSites])


  React.useEffect(() => {
    // console.log('Get Projects for this client', props.client)
    let isMounted = true
    const fetchProjectsAssociatedToEstimate = async (_id) => {
      axios.get(`${props.url}/api/projects/listProjects?clientId=${_id}`).then(response => {
        if (response && response.data && response.data.projects && response.data.projects.length && isMounted) {
          // console.log('Asociated projects', response.data)
          let ourList = response.data.projects
          ourList.unshift({ name: 'Create New Project', _id: 'new' })
          setProjectList(ourList)
          if (props.estimate && props.estimate.project) {
            let index = ourList.findIndex(item => (item._id === props.estimate.project))
            if (index > -1) {
              setAssociatedProject(ourList[index])
              setState({...state, associatedProject: ourList[index], estimate: props.estimate })
            }
          }
          setState({...state, counter: state.counter++, estimate: props.estimate})
        }
      })
    }

    if (props.client && props.client._id) fetchProjectsAssociatedToEstimate(props.client._id)
    if (props.project && props.project._id) {
      // console.log('Set Associatie dproject; ', props.project)
      setAssociatedProject(props.project)
      setState({...state, associatedProject: props.project })
    } 
    return () => {
      isMounted = false
    }
  }, [props.client])
  
  const fetchEstimateDetails = async (id) => {
  // console.log('Get the estimate details AND let everyone know Im in it!')
    axios.get(`${props.url}/api/proposal/whosInItWithMe?userId=${props.user._id}&estimateId=${id}`).then(response => {
    // console.log('Got the estimate details!!', response.data)
      setEstimate(response.data)
      setState({...state, estimate: response.data })
      if (response.data.lineItems.length) {
        // console.log('Set the list: ', props.estimate.lineItems)
        let lineItems = response.data.lineItems
        // lineItems.map((item, index) => {
        //   item._id = `${new Date()}_${index}`
        // })
        setOurList(lineItems)
      }
    })
  }

  React.useEffect(() => {
    console.log("NEW ESTIMATE PROPS", props)
    if (props.estimate && props.estimate._id) {
      fetchEstimateDetails(props.estimate._id)
      // setEstimate(props.estimate)
      // setState({...state, estimate: props.estimate })
      // if (props.estimate.lineItems.length) {
      //   // console.log('Set the list: ', props.estimate.lineItems)
      //   let lineItems = props.estimate.lineItems
      //   // lineItems.map((item, index) => {
      //   //   item._id = `${new Date()}_${index}`
      //   // })
      //   setOurList(lineItems)
        
      // }
    } else {
      setEstimate({})
      setOurList([])
      setState({...state, estimate: {}})
    }
    // if (props.addys) setAllAddresses(props.addys)
  }, [props.estimate])

  const handleClose = () => {
    // console.log('Close Estimate Modal')
    if (showPdf) {
      setShowPDF(false)
    } else {
      // props.saveEstimate(estimate)
      // setEstimate({})
      // setOurList([])
      // setState(defaultValues)
    // console.log('Closing out estimate!!!!')
      // updateAccessList()
      let newEstimate = {
        ...estimate,
        created_by: props.estimate.created_by
      }
      props.handleCloseModal(newEstimate)
    }
  }

  const handleOpenItem = (item, index) => {
  // console.log('Open Item: ', item)
    item.editing = true
    setItemToEdit(item)
    setActiveIndex(index)
    setShowEditLineItemModal(true)
  }

  // const EstimateListComponent = () => {
  //   // let list = ourList
  //   // const getRandomUnit = () => {
  //   //   let unit = '5 Gallon'
  //   //   let num = Math.floor(Math.random() * 5)
  //   //   if (num === 1) unit = '5 Gallon'
  //   //   if (num === 2) unit = '1 Gallon'
  //   //   if (num === 3) unit = '48" Box'
  //   //   if (num === 4) unit = '24" Box'
  //   //   if (num === 5) unit = '15 Gallon'
  //   //   return unit
  //   // }
  
  //   // for (var i = 0; i < 35; i++) {
  //   //   let min = Math.ceil(1)
  //   //   let max = Math.floor(85)
  //   //   let qty = Math.floor(Math.random() * (max - min) + min)
  //   //   let unit = getRandomUnit()
  //   //   let desc = 'Description'
  //   //   min = Math.ceil(1)
  //   //   max = Math.floor(855455)
  //   //   let price = parseFloat(Math.floor(Math.random() * (max - min) + min) / qty).toFixed(2)
  //   //   list.push({
  //   //     qty,
  //   //     unit,
  //   //     desc,
  //   //     price
  //   //   })
  //   // }
  //   // setOurList(list)
  //   return (
  //     <ListContainer>
  //       <ListItemHeaderRow key={'number1'}>
  //       <ListHeaderItem>
  //           QUANTITY
  //         </ListHeaderItem>
  //         <ListHeaderItem>
  //           UNIT
  //         </ListHeaderItem>
  //         <ListHeaderItem>
  //           DESCRIPTION
  //         </ListHeaderItem>
  //         <ListHeaderItem>
  //           PRICE
  //         </ListHeaderItem>
  //       </ListItemHeaderRow>
  //       {
  //         ourList.map((item, index) => (
  //           <ListItemRow onClick={e => handleOpenItem(item)} key={index} className={classes.estimateListRow}>
  //             <ListItem>
  //               {item.qty}
  //             </ListItem>
  //             <ListItem>
  //               {item.unit} 
  //             </ListItem>
  //             <ListItem>
  //               {item.description} 
  //             </ListItem>
  //             <ListItem>
  //               {item.totalPrice}
  //             </ListItem>
              
  //           </ListItemRow>
  //         ))}
  //     </ListContainer>
  //   )
  // }
      // <>
    //   <Dialog 
    //     open={props.open}
    //     onClose={handleClose}
    //     aria-labelledby="form-dialog-title"
    //     fullScreen
    //     contentStyle={{width: "100%", maxWidth: "none"}}
    //   >
  const addLineItemToEstimate = (lineItem, inventoryItem, isBulk) => {
    // Add a new line item
    console.log('Add Line Item to Estimate Item', lineItem)
    let newList = ourList
    if (isBulk) {
    // console.log('Is Bulk!!!')
      newList = newList.concat(lineItem)
    } else {
    // console.log('Inventory Item: ', inventoryItem)

      // lineItem._id = inventoryItem._id
      newList.push(lineItem)
    }
  // console.log('New List:', newList)
    // setOurList(newList)
    // setState({...state, name: 'UPdated'})
    updateEstimate(newList)
  }

  const updateEstimate = (list) => {
    console.log('Update Estimate: now!!', list)
    let obj = {
      estimate: estimate._id,
      lineItems: list
    }
    console.log(obj)
    axios({
      method: 'post',
      url:  `${props.url}/api/proposal/updateEstimate`,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Autosaved Proposal - update totals', response.data)
      // setEstimate(response.data)
      setState({...state, estimate: response.data })
      let newList = response.data.lineItems
    // console.log('New List', newList)
      setOurList(newList)
      props.saveEstimate(response.data)
    }) 
  }

  const handleGeneratePDF = (e, doInternal) => {
    let internalStyle = 0
    setLoading(true)
    if (doInternal) internalStyle = 1
  // console.log('Internal Style', internalStyle)
    // Make OuR PDF
    axios({
      method: 'get',
      url:  `${props.url}/api/proposal/handleGeneratePDF?estimateId=${estimate._id}&internal=${internalStyle}`
      // data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Axios response to new proposal ', response)
      setLoading(false)
      setPDF(response.data)
      setShowPDF(true)
    })
  }

  const handleNewEstimate = () => {
    // Save the estimate now
    // console.log('save estimate', state)
    setLoading(true)
    let obj = {
      client: props.client,
      name: state.estimateName,
      depositPercent: state.depositPercent,
      jobSite: jobSite,
      billingAddress: billingAddress
    }
    // console.log('Assoicated Project', associatedProject)
    if (associatedProject && associatedProject._id) obj.project = associatedProject._id
    if (associatedProject && associatedProject._id === 'new') {
      obj.projectName = state.projectName
      obj.salesRep = state.salesRep
    }
    if (state.salesRep) obj.salesRep = state.salesRep
    // console.log(obj)
    axios({
      method: 'post',
      url:  `${props.url}/api/proposal/addNew`,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Axios response to new proposal ', response)
      setLoading(false)
      let est = response.data
      est.salesRep = state.salesRep
      setEstimate(est)
      setState(defaultValues)
      setAssociatedProject({})
      setBillingAddress({})
      setJobSite({})
    }).catch(e => {
      // console.log('Errors:: ', e)
    })
  }

  const handleCloseEditLineItemModal = () => {
    setShowEditLineItemModal(false)
  }

  const updateLineItem = (d) => {
  // console.log('Update this line item: ', d)
  // console.log('Active INdex:', activeIndex)
  // console.log('Proper item??? ', ourList[activeIndex])
    // Use id to find it
    let list = ourList
    let index = list.findIndex(item => (item._id === d._id))
  // console.log('The eindex', index)
    let itemToEdit = list[index]
  // console.log('This should be our item', itemToEdit)
    d.itemToEdit = d.item
    d.item = null
  // console.log('D::', d)
    setShowEditLineItemModal(false)
    // let list = ourList
    list[index] = d
  // console.log('List to set: ', list)
    // setOurList(list)
    updateEstimate(list)
    // setState({...state, name: 'Update Item'})
  }

  const deleteItem = (d) => {
    // console.log('Delete: ', d)
    setShowEditLineItemModal(false)
    let list = ourList
    let item = list[activeIndex]
    // console.log('Item to delete: ', item)
    // let index = list.findIndex(item => (item._id === d._id))
    // if (index > -1) {
    list.splice(activeIndex, 1)
    // }
    // setOurList(list)
    updateEstimate(list)
    // setState({...state, name: 'Delete ITem'})
  }
  
  const updateList = (d) => {
    // console.log('Update proposal list: ', d)
    updateEstimate(d)
  }

  const handleEditEstimateInfo = () => {
    // console.log('Edit this estimate')
    setShowEditEstimateModal(true)
  }
  const closeEditEstimateModal = () => {
    setShowEditEstimateModal(false)
  }

  const updateEstiamteDepositAndTaxInfo = (d) => {
    // console.log('UPdate Deposit and tax', d)
    // if (d && d._id)
    // saveProposal(d, (newEstimate) => {
    //   if (newEstimate && newEstimate._id) {
        // props.saveEstimate(d)
      // }
    // })
  }

  const updateEstimateDetails = (d) => {
    console.log('Update estimate details:', d)
    if (d && d._id) {
      let est = estimate
      est.name = d.name
      est.accepted = d.accepted
      est.taxable = d.taxable
      est.taxLocale = d.taxLocale
      est.taxAmount = d.taxAmount
      est.jobSite = d.jobSite
      est.project = d.project
      est.status = d.status
      est.taxableAmount = d.taxableAmount
      if (d?.taxLocales?.length) {
        est.taxLocale = d.taxLocales[0]
      }
      // Sales Rep is the object
      let salesRep = null
      if (d && d.salesRep && d.salesRep._id) {
        est.salesRep = d.salesRep._id
        salesRep = d.salesRep
      }
    // console.log('Estimate w/ Sales REp:', est)
      if (d.depositData) {
        est.depositData = d.depositData
        // console.log('D.DepositData: ', d.depositData)
      }
      if (d.depositAmount) est.depositAmount = d.depositAmount
      if (d.depositPercent) est.depositPercent = d.depositPercent
      // if (d.associatedProject && d.associatedProject._id && d.associatedProject._id !== 'new') est.project = d.associatedProject._id
      if (d && d.billingAddress) est.billingAddressObject = d.billingAddress
      if (d.chosenLocales) {
        // console.log('Got LOcales', d.chosenLocales)
        est.chosenLocales = d.chosenLocales
      }
      if (d.waiveDeposit) {
        est.waiveDeposit = true
      } else {
        est.waiveDeposit = false
      }
      est.summaryPricing = d.summaryPricing
      if (d.duration) est.duration = d.duration
      if (d.confidence) est.confidence = d.confidence
      if (d.expectedCloseDate) est.expectedCloseDate = d.expectedCloseDate
      // if (d?.taxAmount) est.taxAmount = d.taxAmount
      // if (d?.taxableAmount) est.taxableAmount = d.taxableAmount
      // if (d.taxable) {
      //   est.taxable = true
      // } else {
      //   est.taxable = false
      // }
      // console.log('ETIMATE UPDATED: ', est)
      // setEstimate(est)
      setShowEditEstimateModal(false)
      saveProposal(est, (newEstimate) => {
        // console.log('517 new Esimate: ', newEstimate)
        if (newEstimate && newEstimate._id) {
          if (salesRep) newEstimate.salesRep = salesRep
          props.saveEstimate(newEstimate)
        }
      })
    }
  }

  const saveProposal = async (data, callback) => {
  // console.log('Save this proposal', data)
    axios({
      method: 'post',
      url:  `${props.url}/api/proposal/updateProposalDetails`,
      data: data,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('SAVED PROPOSAL FROM SERVER:', response.data)
      // props.saveEstimate(response.data)
      return callback(response.data)
    })
  }

  const handleDeleteEstimate = async () => {
    // console.log('Delete it!', estimate)
    let confirmationMessage = `Are you sure you wish to delete this estimate?: ${estimate.name}\n This cannot be reversed once deleted.`
    if (window.confirm(confirmationMessage)) {
      handleClose()
      props.deleteEstimate(estimate)
    }

  }

  const handleConvertToWOConfirmation = () => {
    setEstimateConversionModalOpen(true)
  }

  const handleConvertToWO = async (d) => {
    console.log('Convert to WO::', d)
    // if (window.confirm('Are you sure you wish to turn this Estimate into a Job?')) {
      // console.log('Convert to Workorder')
      axios({
        method: 'get',
        url:  `${props.url}/api/proposal/handleConvertToWorkorder?estimateId=${estimate._id}&conversionType=${d}`
        // data: obj,
        // validateStatus: (status) => {
        //   // console.log('Validate status: ', status)
        //   if (status && status === 500) {
        //   }
        // },
      }).then(response => {
        // console.log('Axios response to CONVERT to WO ', response)
        // setPDF(response.data)
        if (response && response.data && response.data.job && response.data.job._id) {
          // console.log('We Good')
          let job = response.data.job
          // let wo = response.data.wo
          // console.log('Open this WO', wo)
          // props.postConversionProcess({ job, wo })
          let wos = response.data.workorders || []
          setState({...state, job: job, estimate: response.data.estimate, workorders: wos })
          // setOurList([])
          // setConversionModalOpen(true)
          if (job?._id) {
            props.postConversionProcess(job, response.data.estimate)
          }
        }
        if (response && response.data && response.data.error && response.data.message) {
          props.notification({
            type: 'danger',
            message: response.data.message,
            title: 'Error Converting Estimate to Workorder'
          })
        }
        // setShowPDF(true)
      })
    // }
  }

  const updateFromConversionModal = (d) => {
    console.log('CONVERSION MODAL UPDATE: ', d)
    if (d=== 'closeModal') setConversionModalOpen(false)
    if (d === 'finished') {
      console.log('All Done with our estimates -- close this')
      setConversionModalOpen(false)
      props.postConversionProcess(state.job)
      setState(defaultValues)
      setEstimate({})
      setOurList([])
    }
  }

  const handleDuplicateEstimate = () => {
    let confirmationMessage = `Are you sure you wish to duplicate this estimate? ${estimate.name}`
    if (window.confirm(confirmationMessage)) {
      // console.log('Duplicate this Estimate')
      axios({
        method: 'post',
        url:  `${props.url}/api/proposal/duplicateEstimate?estimateId=${estimate._id}`
        // data: obj,
        // validateStatus: (status) => {
        //   // console.log('Validate status: ', status)
        //   if (status && status === 500) {
        //   }
        // },
      }).then(response => {
        // console.log('Axios response to Duplicate Estimate ', response)
        if (response && response.data && response.data._id) {
          props.notification({
            type: 'success',
            title: 'Estimate Duplicated!',
            message: `We have made a copy of this Estimate.`
          })
          props.saveEstimate(response.data)
          // handleClose()
        }
      })
    }
  }

  const handleSendEstimate = () => {
    setShowSendToCustomerModal(true)
    // let confirmationMessage = `Are you sure you wish to email this estimate to the customer for acceptance?`
    // if (window.confirm(confirmationMessage)) {
    //   // console.log('Email Out this Estimate')
    //   axios({
    //     method: 'post',
    //     url:  `${props.url}/api/proposal/emailEstimate?estimateId=${estimate._id}`
    //     // data: obj,
    //     // validateStatus: (status) => {
    //     //   // console.log('Validate status: ', status)
    //     //   if (status && status === 500) {
    //     //   }
    //     // },
    //   }).then(response => {
    //     // console.log('Axios response to Email Estimate ', response)
    //     if (response.data._id) {
    //       props.notification({
    //         type: 'success',
    //         title: 'Estimate Emailed!',
    //         message: `We have emailed a copy of this estimate to the client.`
    //       })
    //       // props.saveEstimate(response.data)
    //       handleClose()
    //     }
    //   })
    // }
  }

  const updateJobSiteAddress = (e, js) => {
    setJobSite(js)
  }

  const updateBillingAddress = (e, addy) => {
    setBillingAddress(addy)
  }

  const handleCloseSendModal = () => {
    setShowSendToCustomerModal(false)
  }
  const formatOptionForProject = (opt) => {
    // console.log('Format this: ', opt)
    if (opt && opt.name) {
      return `${opt.name}`
    } else {
      return ''
    }
  }

  const updateProject = (e, proj) => {
    // console.log('Prev associated: ', associatedProject)
    // console.log('state.associated', state)
    if (proj && proj._id === 'new') {
      // console.log('NEW PROJECT', proj)
      setAssociatedProject(proj)
      setState({...state, associatedProject: proj })
    } else {
      // console.log(proj)
      setAssociatedProject(proj)
      setState({...state, associatedProject: proj })
    }
  }
  
  const updateSalesRep = (e, rep) => {
    if (rep && rep._id) {
      // inputRef.current.salesRep = rep
      setState({...state, salesRep: rep})
    }
  }

  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
      // console.log(e.target)
      if (e.target.id === 'salesperson') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        props.salesReps.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          setState({...state, salesRep: newArr[0]})
        }
      }
    }
  }

  const handleExportEstimate = () => {
    // console.log('Export estimate')
    axios({
      method: 'post',
      url:  `${props.url}/api/proposal/exportToCSV`,
      data: estimate,
      responseType: 'arraybuffer'
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Export response: ', response.data)
      // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
      // text/csv
      let newBlob = new Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
      // console.log(newBlob)
      const data = window.URL.createObjectURL(newBlob);
      // console.log(data)
      var link = document.createElement('a');
      link.href = data;
      let fileName = estimate.name.replace(/[^a-z0-9]/gi, '_').toLowerCase()
      link.download=`${fileName}.xlsx`;
      link.click();
    }).catch(error => {
      console.log('ERror exporting estimate', error)
    })
  }


  const handleUpdateDepositPercentage = (data) => {
    setState({...state, depositPercent: data})
  }

  const saveClonedEstimateLineItems = (proposal) => {
    // console.log('This is our new proposal with the line items on it...', proposal)
    // setEstimate(proposal)
    props.saveEstimate(proposal)
  }

  const handlePlantCount = () => {
  // console.log('Run our plant count', estimate)
    axios({
      method: 'get',
      url:  `${props.url}/api/proposal/getPlantCount?id=${estimate._id}`,
      // data: estimate,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Plant Count:', response.data)
      // Add the line item
      let newList = ourList
      let totalLaborCost = response.data.totalLaborCost
      let laborLineItem = response.data.laborLineItem
      let equipmentLineItem = response.data.equipment
      // console.log('Total Labor Cost:', totalLaborCost)
      // // lineItem._id = inventoryItem._id
      // console.log('Line items:', newList)
      // let laborLineItem = {
      //   qty: "1",
      //   pricePer: totalLaborCost,
      //   internalNotes: 'Internal Stuff',
      //   description: 'Labor to Install Trees, Plants, and Cacti:\n- Total Trees: 44'
      // }
      if (laborLineItem) {
        newList.push(laborLineItem)
      // console.log('Labor ite:', laborLineItem)
      // console.log(newList)
      }
      if (equipmentLineItem)  {
      // console.log('Equipment::', equipmentLineItem)
        newList.push(equipmentLineItem)
      }
      // setOurList(newList)
      // setState({...state, counter: state.counter++ })
      updateEstimate(newList)
    })
  }
  const responseFromTrackProjectModal = (d) => {
    console.log('Do we add a project??', d)
    setConversionModalOpen(false)
  }

  console.log('963...', estimate)
  return (
    <div className={classes.root}>
      { props.entity ? 
        <SendToCustomerModal type="Estimate" open={showSendToCustomerModal} client={props.client} entity={props.entity} contacts={props.contacts} estimate={estimate} handleCloseModal={handleCloseSendModal} />
        : null
      }
       <ConvertToWO open={conversionModalOpen} job={state.job} workorders={state.workorders} estimate={estimate} updateParent={updateFromConversionModal} />
      <Dialog className={classes.modal} open={props.open} fullScreen fullWidth={true} onClose={handleClose} maxWidth='lg' aria-labelledby="form-dialog-title" >
        <EditLineItemModal estimate={estimate} takeOffList={takeOffList} open={showEditLineItemModal} item={itemToEdit} closeModal={handleCloseEditLineItemModal} updateLineItem={updateLineItem} deleteItem={deleteItem} />
        <EditEstimateDetails open={showEditEstimateModal} configs={props.configs} jobSites={props.jobSites} addys={props.addys} estimate={estimate} client={props.client} closeModal={closeEditEstimateModal} updateEstimateDetails={updateEstimateDetails} saveClonedEstimateLineItems={saveClonedEstimateLineItems} />
         <EstimateConversionModal
          open={estimateConversionModalOpen}
          onClose={() => setEstimateConversionModalOpen(false)}
          onConfirm={handleConvertToWO}
        />
        {
            showPdf ? (
              <>
                      <DialogTitle id="form-dialog-title">
                        <TopRow container>
                        <TopRowItem align="start" lg={2} md={2} sm={3} xs={4}>
                            Preview Estimate PDF
                        </TopRowItem>
                        <TopRowItem align="start" lg={2} md={2} sm={3} xs={4}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              disabled={loading}
                              startIcon={<VisibilityIcon />}
                              style={{ marginLeft: -200 }}
                              onClick={e => handleGeneratePDF(e, true)}
                            >
                            { loading ? <CircularProgress size={18} /> : null } Display Internal Style</Button>
                        </TopRowItem>
                        <TopRowItem align="end" lg={2} md={2} sm={3} xs={4}>
                            <Tooltip title="Close" >
                          <IconButton edge='end' onClick={handleClose} alt="Close" aria-label="Close">
                            <CloseIcon  />
                          </IconButton>
                        </Tooltip>
                        </TopRowItem>
                        </TopRow>  
                     
                      
                      </DialogTitle>
                      <object style={{padding: '15px'}} aria-label="PDF" data={ pdf } type="application/pdf" width="100%" height="100%" />
              </>
              
              
              ) : (!estimate || !estimate._id) ? (
                <>
                  <DialogTitle id="form-dialog-title">
                        Create New Estimate 
                      </DialogTitle>
                      <DialogContent >
                        <ControlRow>
                        <DialogContentText>
                            What Is This Estimate Name?
                        </DialogContentText>
                        </ControlRow>
                        <TextField
                          label="Estimate Name"
                          variant="outlined"
                          type="text"
                          value={state.estimateName}
                          onChange={e => setState({...state, estimateName: e.target.value})}
                          fullWidth
                        />
                        <Autocomplete
                          id="JobSite"
                          options={props.jobSites || []}
                          getOptionLabel={(option) => (formatOption(option) || '')}
                          // style={{ width: 200 }}
                          className={classes.paddedStuff}
                          style={{marginBottom: 10, marginTop: 15}}
                          onChange={updateJobSiteAddress}
                          // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                          value = {jobSite || {}}
                          // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                          renderInput={(params) => <TextField {...params} variant="outlined" label="Job Site Address" />}
                        />
                        <Autocomplete
                          id="Billing Address"
                          options={props.addys}
                          getOptionLabel={(option) => (formatOption(option) || '')}
                          // style={{ width: 200 }}
                          className={classes.paddedStuff}
                          style={{marginBottom: 10, marginTop: 15}}
                          onChange={updateBillingAddress}
                          // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                          value = {billingAddress || {}}
                          // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                          renderInput={(params) => <TextField {...params} variant="outlined" label="Billing Address" />}
                        />
                        <Autocomplete
                            id={`salesperson`}
                            options={props.salesReps || []}
                            className={classes.paddedStuff}
                            getOptionLabel={(option) => {
                              // console.log('Options: ', option)
                              return option.displayName || ''
                            }}
                            // style={{ width: 200 }}
                            // style={{marginBottom: '20px'}}
                            onChange={updateSalesRep}
                            disabled={loading}
                            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                            value = {state.salesRep}
                            // ref={inputRef.salesperson}
                            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                            renderInput={(params) => <TextField {...params} label="Salesperson" helperText="Who Is The Assigned Salesperson?" onKeyDown={handleTabInteraction} variant="outlined" />}
                          />
                        {
                          props.project ? null : (
                            <Autocomplete
                              id="Associated Project"
                              options={projectList || []}
                              getOptionLabel={(option) => (formatOptionForProject(option) || '')}
                              // style={{ width: 200 }}
                              className={classes.paddedStuff}
                              style={{marginBottom: 10, marginTop: 15}}
                              onChange={updateProject}
                              // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                              value = {associatedProject || ''}
                              // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                              renderInput={(params) => <TextField {...params} variant="outlined" label="Project" />}
                            />
                          )
                        }
                        
                        {
                          (associatedProject && associatedProject._id === 'new') ? (
                            <>
                            <TextField
                              label="Project Name"
                              variant="outlined"
                              className={classes.paddedStuff}
                              type="text"
                              value={state.projectName}
                              onChange={e => setState({...state, projectName: e.target.value})}
                              fullWidth
                            />
                            
                            
                          </>
                          ) : null
                        }
                        <ControlRow>
                          <DepositComponent updatePercentage={handleUpdateDepositPercentage} style={{width: '100%'}} />
                        </ControlRow>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{marginBottom: 10, marginTop: 15}}
                          onClick={handleNewEstimate}
                          disabled={!state.estimateName || !jobSite._id || loading || (!state.salesRep || !state.salesRep._id)}
                          fullWidth
                        >Create</Button>
                     
                        </DialogContent>
                </>
              ) : 
              (
                <>
                          <DialogTitle id="form-dialog-title">
                            <TopRow>
                            <TopRowItem align="start" lg={2} md={2} sm={3} xs={4}>
                                Update Estimate #{props.estimate.number} - {estimate.name}
                              </TopRowItem>
                              <TopRowItem align="start" lg={2} md={2} sm={3} xs={4}>
                                { estimate.taxable ? <Chip style={{color: '#fff', backgroundColor: 'green'}} label={`Taxable ($${parseFloat(estimate.taxAmount).toFixed(2)})`} /> : null }
                              
                              </TopRowItem>
                              <TopRowItem align="start" lg={2} md={2} sm={3} xs={4}>
                              <Tooltip title="Edit Estimate" >
                                <IconButton edge='end' onClick={handleEditEstimateInfo} alt="Edit Estimate" aria-label="Edit Estimate">
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              </TopRowItem>
                              <TopRowItem align="end" lg={2} md={2} sm={3} xs={4}>
                              <Tooltip title="Export to CSV" >
                                <IconButton edge='end' onClick={handleExportEstimate} alt="Export Estimate to Excel" aria-label="Export Estimate to Excel">
                                  <ImportExportIcon />
                                </IconButton>
                              </Tooltip>
                              </TopRowItem>
                              <TopRowItem align="end" lg={2} md={2} sm={3} xs={4}>
                                <Tooltip title="Duplicate Estimate" >
                                  <IconButton edge='end' onClick={handleDuplicateEstimate} alt="Duplicate Estimate" aria-label="Duplicate Estimate">
                                    <FileCopyIcon />
                                  </IconButton>
                                </Tooltip>
                              </TopRowItem>
                              <TopRowItem align="end" lg={2} md={2} sm={3} xs={4}>
                                <Tooltip title="Plant Count" >
                                  <IconButton edge='end' onClick={handlePlantCount} alt="Plant Count" aria-label="Plant Count">
                                    <AssessmentIcon />
                                  </IconButton>
                                </Tooltip>
                              </TopRowItem>
                              <TopRowItem align="end" lg={2} md={2} sm={3} xs={4}>
                                  <Tooltip title="Delete Estimate" >
                                <IconButton edge='end' onClick={handleDeleteEstimate} alt="Delete Estimate" aria-label="Delete Estimate">
                                  <DeleteIcon  />
                                </IconButton>
                              </Tooltip>
                              </TopRowItem>
                              <TopRowItem align="end" lg={2} md={2} sm={3} xs={4}>
                                  <Tooltip title="Close" >
                                <IconButton edge='end' onClick={handleClose} alt="Close" aria-label="Close">
                                  <CloseIcon  />
                                </IconButton>
                              </Tooltip>
                              </TopRowItem>
                            </TopRow>
                            <TopRow>
                            <TopRowItem align="start" lg={2} md={2} sm={3} xs={4} style={{fontSize: '.75em', color: '#aaa'}}>
                                Estimate Sub-Total - {currencyFormat(estimate.totalPrice)}
                                
                              </TopRowItem>
                             
                            </TopRow>
                                {
                                  (estimate.taxable && estimate.taxAmount > 0) ?
                                    (
                                      <TopRow>
                                        <TopRowItem align="start" lg={2} md={2} sm={3} xs={4}  style={{fontSize: '.75em', color: '#aaa'}}>
                                          Total With Tax - {currencyFormat(estimate.totalPrice - 0 + estimate.taxAmount)}
                                          
                                        </TopRowItem>
                                     
                                    </TopRow>
                                    ) : null
                                }
                            <TopRow>
                            <TopRowItem align="start" lg={2} md={2} sm={3} xs={4} style={{fontSize: '.6em', color: '#bbb'}}>
                               { estimate.waiveDeposit ? null : `Deposit Required - ${estimate.depositPercent}% (${currencyFormat(estimate.depositAmount)})` }
                              </TopRowItem>
                            </TopRow>
                            {/* <TopRow>
                            <TopRowItem align="start" lg={2} md={2} sm={3} xs={4} style={{fontSize: '.6em', color: '#bbb'}}>
                               <AccessedByDetails estimate={estimate} />
                              </TopRowItem>
                            </TopRow> */}
                            <TopRow>
                            <TopRowItem align="end" lg={2} md={2} sm={3} xs={4} style={{fontSize: '.6em', color: '#bbb', position: 'absolute', right: 150 }}>
                                {
                                  (estimate && estimate.updated_by && estimate.updated_by._id) ? <LastUpdateByDetails estimate={estimate} /> : null
                                }
                              </TopRowItem>
                            </TopRow>
                      
                      </DialogTitle>
                      
                      <DialogContent >
                      <NewLineItemComponent client={props.client} takeOffList={takeOffList} addLineItemToEstimate={addLineItemToEstimate} estimate={estimate} />
                        <ControlRow>
                        <DialogContentText>
                            Your Line Items Will Appear Below:
                        </DialogContentText>
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{marginBottom: 10}}
                          onClick={handleGeneratePDF}
                          disabled={!ourList.length > 0 || loading}
                        >
                          { loading ? <CircularProgress size={18} /> : null } Preview PDF
                          </Button>
                           <Button
                          variant="contained"
                          color="primary"
                          style={{marginBottom: 10}}
                          onClick={handleSendEstimate}
                          disabled={!ourList.length > 0 || estimate.accepted}
                        >Send to Customer</Button>
                       
                              <Button
                                  variant="contained"
                                  color="primary"
                                  style={{marginBottom: 10}}
                                  onClick={handleConvertToWOConfirmation}
                                  // disabled={!ourList.length || estimate?.job}
                                >
                                  { 
                                    (estimate && estimate.job) ? 'Generate Workorders' : 'Convert to Job'
                                  }
                                </Button>
                          
                        </ControlRow>
                        <EstimateListComponent notification={props.notification} updateList={updateList} estimate={estimate} ourList={(estimate && estimate._id) ? ourList : []} className={classes.estimateList} handleOpenItem={handleOpenItem} />
                        </DialogContent>
                </>
              
              )
            
          }
          <DialogActions>
            <Button onClick={handleClose}>CLOSE</Button>
          </DialogActions>
   
      </Dialog>
    </div>
  )
}

const AccessedByDetails = ({ estimate }) => {
// console.log('Whos in this bitch??', estimate)
  if (estimate && estimate.currentlyAccessedBy && estimate.currentlyAccessedBy.length) {
    return (
      <React.Fragment>
        {
          estimate.currentlyAccessedBy.map((user) => {
            return (
              <>{user.name}</>
            )
          })
        }
      </React.Fragment>
    )
  } else {
    return null
  }
}

const mapStateToProps = (state) => {
  // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms,
    client: state.client,
    salesReps: state.salesReps,
    configs: state.configs
  }
}

export default connect(mapStateToProps)(NewEstimateModal)