import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BuildIcon from '@material-ui/icons/Build';
import LocalShipping from '@material-ui/icons/LocalShipping';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import NoteIcon from '@material-ui/icons/Note';
import StraightenIcon from '@material-ui/icons/Straighten';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  moveIcon: {
    '&:hover': {
      background: "#FFFF00",
      cursor: 'pointer',
      borderRadius: 20,
      color: 'red'
   }
  },
  table: {
    width: '100%',
    padding: 0,
    margin: 0
  },
  // tableheadRow: {
  //   padding: 10
  // },
  tableHeadCell: {
    fontSize: 14,
    color: '#bbb'
  },
  tableRow: {
    height: 25,
    padding: 5,
    margin: 5
  },
  tableCell: {
    height: 25,
    margin: 5,
    color: '#888',
    padding: 0
  },
  chip: {
    margin: theme.spacing(0.75),
    padding: theme.spacing(1),
  },
  materials: {
    backgroundColor: '#E6F2F3', // blue
    color: '#294345'
  },
  equipment: {
    backgroundColor: '#F4E8DC', // orange
    color: '#fff'
  },
  labor: {
    backgroundColor: '#7B9EA0', // green
    color: '#fff'
  },
  subcontractor: {
    backgroundColor: '#FFE6B3', // purple
    color: '#fff'
  },
  notes: {
    backgroundColor: '#B58986', // blue grey
    color: '#fff'
  },
  measurements: {
    backgroundColor: '#4A6C6E', // brown
    color: '#fff'
  },
  fee: {
    backgroundColor: '#ACCACD', // brown
    color: '#fff'
  }
}));


const CustomLineItemTypeChip = ({ item }) => {
  const classes = useStyles();

  const getChipConfig = (type) => {
    const config = {
      Materials: {
        icon: <BuildIcon className={classes.materials} />,
        className: classes.materials
      },
      Equipment: {
        icon: <LocalShipping className={classes.equipment} />,
        className: classes.equipment
      },
      Labor: {
        icon: <PeopleIcon className={classes.labor} />,
        className: classes.labor
      },
      Subcontractor: {
        icon: <BusinessIcon className={classes.subcontractor} />,
        className: classes.subcontractor
      },
      Notes: {
        icon: <NoteIcon className={classes.notes} />,
        className: classes.notes
      },
      Measurements: {
        icon: <StraightenIcon className={classes.measurements}  />,
        className: classes.measurements
      },
      Fee: {
        icon: <MonetizationOnIcon className={classes.fee} />,
        className: classes.fee
      }
    };

    return config[type] || {};
  };
  let lineItemType = item?.lineItemType ? formatLabel(item?.lineItemType?.toLowerCase())  : formatLabel(item?.toLowerCase() || 'materials') // item.lineItemType === 'Labor' ? item.laborType === 'subContractor' ? 'Subcontractor' : 'Labor' : item.lineItemType
  const chipConfig = getChipConfig(lineItemType);

  return (
    <Chip
      label={lineItemType}
      size="small"
      icon={chipConfig.icon}
      className={`${classes.chip} ${chipConfig.className}`}
    />
  );
};

const formatLabel = (camelCase) => {
  if (!camelCase) return ''
  return camelCase
    .replace(/([A-Z])/g, ' $1') // Add space before capital letters
    .replace(/^./, str => str.toUpperCase()); // Capitalize first letter
};

export default CustomLineItemTypeChip