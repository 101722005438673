import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import NewMtcAgreementModal from './NewMtcAgreement'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import EditContractDetails from './EditContractDetails'
import Chip from '@material-ui/core/Chip'; 

const MtcDetails = (props) => {
  // console.log('MtcDetails: ', props)
  const [showNewNoteModal, setShowNewNoteModal] = React.useState(false)
  const [mtcAgreement, setMtcAgreement] = React.useState([])
  const [activeContract, setActiveContract] = React.useState({})
  const [editModalOpen, setEditModalOpen] = React.useState(false)
  
  React.useEffect(() => {
    // console.log('Use Effect MTC Agreement Details')
    if (props.client && props.client._id) getMtcAgreements(props.client)
  }, [])

  React.useEffect(() => {
    if (props && props.stateData) {
    // console.log('Props.state data -- do we open this bitch up??', props.stateData)
      if (props.stateData && props.stateData.itemToOpen && props.stateData.itemToOpen === 'MtcAgreement') {
      // console.log('Open this fucker!!', props.stateData)
        if (props.stateData.openId) {
          openAgreement(props.stateData.openId)
        }
      }
    }
  }, [props.stateData])

  const getMtcAgreements = (client) => {
    // console.log('Get Mtc Agreemetnsf or client: ', client)
    axios({
      method: 'get',
      url:  `${props.url}/api/customer/getMtcAgreements?id=${client._id}`,
    }).then(response => {
      // console.log('REsponse from getting Mtc Agreemnts: ', response)
      setMtcAgreement(response.data)
      if (props.stateData.openId) {
      // console.log('Its time to open this mother fucker!!!')
        let index = response.data.findIndex(item => (item._id === props.stateData.openId)) 
        if (index > -1) {
          setActiveContract(response.data[index])
          setEditModalOpen(true)
        }
        // if (props.stateData.openId === response.data)
      }
    })
  }

  const openAgreement = (id) => {
  // console.log('Open Agreement:', id)
    // setMtcAgreement
  }
  const addNewMtcAgreement = () => {
    // console.log('Add a MTC Agreement')
    setShowNewNoteModal(true)
  }

  const newMtcAgreementResponse = (type, data) => {
    // console.log('Response from new mtcagreement ', type)
    if (type === 'Close') setShowNewNoteModal(false)
    if (type === 'Saved') {
      let agreements = mtcAgreement
      agreements.unshift(data)
      setMtcAgreement(agreements)
      // Update Job List to Include this
      // props.refreshCustomer()
      setShowNewNoteModal(false)
    }
  }

  const openDetailsModal = (row) => {
    // console.log('Open this: ', row)
    setActiveContract(row)
    setEditModalOpen(true)
  }
  const editMtcAgreementResponse = (d, ag) => {
    // console.log('Edited our mtc agreement!!!', d)
    if (d === 'Close') setEditModalOpen(false)
    if (d === 'Saved') {
      // console.log('Update our agreement to this one!', ag)
      let agreements = mtcAgreement
      let index = agreements.findIndex(agrreement => (ag._id === agrreement._id));
      // console.log('Agreemnent index: ', index)
      // console.log('AGreement to update: ', agreements[index])
      if (index > -1) agreements[index] = ag
      // console.log('Updted agreements: ', agreements)
      setMtcAgreement(agreements)
      setEditModalOpen(false)
    }
  }
  return (
    <div style={{minHeight: '80px'}}>
      <EditContractDetails open={editModalOpen} crewList={props.crewList} contract={activeContract} client={props.client} jobSites={props.jobSites} updateParent={editMtcAgreementResponse} />
      <div>
        <Button
            variant="contained"
            color="secondary"
            disabled={props.jobSites.length < 1}
            style={{margin: '5px', position: 'absolute', right: '20px'}}
            // className={classes.button}
            // startIcon={<CreditCardIcon />}
            size="small"
            onClick={addNewMtcAgreement}
          >
           
            {props.jobSites.length < 1 ? <span> New Mtc Agreement<br/><span style={{fontSize: '8px'}}>Add a Job Site</span></span> : 'New Mtc Agreement'}
          </Button>
          
        </div>
      <NewMtcAgreementModal client={props.client} crewList={props.crewList} jobSites={props.jobSites} updateParent={newMtcAgreementResponse} open={showNewNoteModal} />
      {/* {JSON.stringify(mtcAgreement)} */}
      <Grid container>
        {mtcAgreement.map(row => (
          <div key={row._id} style={{padding: '10px', margin: '5px'}}>
            <Grid item lg={12}>
              <Grid container>
              <Grid item lg={12}>
                 { row.isCurrent ? (
                   <Chip key={'active'} style={{background: `rgba(63,192,63, .7)` }} size="small" label="Active" />
                 ) : (
                   <Chip key={'deactive'} style={{background: `rgba(244,92,11, .6)` }} size="small" label="Inactive" />
                 ) }
                 { row?.servicePlan === 'ASP' ? (
                   <Chip key={'advantage'} style={{ marginLeft: 10, background: `#accacd` }} size="small" label="Advantage Service Plan" />
                 ) : (
                   <Chip key={'basic'} style={{marginLeft: 10, background: `#eee` }} size="small" label="Basic Service Plan" />
                 ) }
                </Grid>
              <Grid item lg={12}>
                  Job Details:  <span style={{ cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'}} onClick={() => openDetailsModal(row)}>{row.jobName} ({row.jobNumber})</span>
                </Grid>
                {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                  Service Plan: 
                </Grid> */}
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Contract Number: {row.contractNumber}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Start Date: {moment(row.firstPaymentDate).format('MM/DD/YYYY')}
                </Grid>
               
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Job Number: {row.jobNumber}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Visit Frequency: {row.frequency}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Assigned Day: {row.preferredDay}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Estimated Minutes: {row.estMinutes}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Monthly Payment: {row.paymentAmount}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Assigned Crew: {row.crewName}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Fuel Surcharge:
                  { row.gasSurcharge ? (
                    <Chip key={'active'} style={{background: `rgba(63,192,63, .7)`, marginLeft: 10 }} size="small" label="Active" />
                  ) : (
                    <Chip key={'deactive'} style={{background: `rgba(244,92,11, .6)`, marginLeft: 10 }} size="small" label="Waived" />
                  ) 
                  }
                 {/* {row.gasSurcharge ? 'Active' : 'Waived'} */}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
              
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Notes: {row.notes}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Site Notes: {row.siteNotes}
                </Grid>
                { row.variableBilling ? (
                   <Grid item lg={12}>
                      Variable Billing
                    </Grid>
                ) : ''}
              </Grid>
            </Grid>
          </div>
        ))}
        
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(MtcDetails))