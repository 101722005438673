// Utility function to check if a value contains the search term
const containsSearchTerm = (value, searchTerm) => {
  if (!value) return false;
  
  // Handle different types of values
  if (typeof value === 'string') {
      return value.toLowerCase().includes(searchTerm.toLowerCase());
  }
  if (typeof value === 'number') {
      return value.toString().includes(searchTerm);
  }
  if (value instanceof Date) {
      return value.toISOString().includes(searchTerm);
  }
  return false;
};

// Function to deeply search an object
const deepSearch = (obj, searchTerm, visited = new Set()) => {
  // Prevent circular references
  if (visited.has(obj)) return false;
  if (typeof obj === 'object' && obj !== null) {
      visited.add(obj);
  }

  // Base cases for primitive values
  if (!obj) return false;
  if (typeof obj !== 'object') {
      return containsSearchTerm(obj, searchTerm);
  }

  // Handle arrays
  if (Array.isArray(obj)) {
      return obj.some(item => deepSearch(item, searchTerm, visited));
  }

  // Handle objects
  return Object.values(obj).some(value => deepSearch(value, searchTerm, visited));
};

// Main search function
const searchRecords = (records, searchQuery, options = {}) => {
  const {
      fields = [], // Specific fields to search, empty array means search all
      exact = false, // Exact match or partial match
      caseSensitive = false // Case sensitive search
  } = options;

  // Normalize search query if not case sensitive
  const normalizedQuery = caseSensitive ? searchQuery : searchQuery.toLowerCase();

  return records.filter(record => {
      // If specific fields are provided, only search those fields
      if (fields.length > 0) {
          return fields.some(field => {
              const value = field.split('.').reduce((obj, key) => obj?.[key], record);
              if (!value) return false;

              if (exact) {
                  return caseSensitive
                      ? String(value) === normalizedQuery
                      : String(value).toLowerCase() === normalizedQuery;
              }
              
              return caseSensitive
                  ? String(value).includes(normalizedQuery)
                  : String(value).toLowerCase().includes(normalizedQuery);
          });
      }

      // If no specific fields provided, perform deep search
      return deepSearch(record, normalizedQuery);
  });
};

module.exports = {
  searchRecords,
}

// // Example usage:
// const records = [/* your array of records */];

// // Search all fields
// const allFieldsResults = searchRecords(records, "Rosedale");

// // Search specific fields
// const specificFieldResults = searchRecords(records, "Rosedale", {
//   fields: ['client.displayName', 'jobName']
// });

// // Exact match search
// const exactMatchResults = searchRecords(records, "Rosedale HOA", {
//   fields: ['client.displayName'],
//   exact: true
// });

// // Case sensitive search
// const caseSensitiveResults = searchRecords(records, "ROSEDALE", {
//   caseSensitive: true
// });