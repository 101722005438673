import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  Paper,
  Typography,
  IconButton,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TextField,
  InputAdornment
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  CheckCircle as CheckCircleIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon
} from '@material-ui/icons';
import moment from 'moment';
import { connect } from 'react-redux'
import axios from 'axios'
import {
  searchRecords
} from '../../services/searchHandler'
import SearchIcon from '@material-ui/icons/Search';
import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  statusChip: {
    marginLeft: theme.spacing(2),
  },
  clientChip: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
  },
  crewChip: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  shiftsTable: {
    marginTop: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ServiceVisitsList = (props) => {
  const classes = useStyles();
  const [visits, setVisits] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [searchValue, setSearchValue] = useState('')
  const [filteredRows, setFilteredRows] = useState([])

  useEffect(() => {
    fetchServiceVisits();
  }, []);

  const fetchServiceVisits = async () => {
    try {
      const response = await axios.get(`${props.url}/api/mobileapp/getGenericInfo?type=SERVICE_VISITS`)
      const data = response.data
      console.log('data', data)
      if (data.success) {
        setVisits(data.data);
        filterOutVisits(data.data, searchValue)
      }
     
    } catch (error) {
      console.error('Error fetching service visits:', error);
    }
  };

  const filterOutVisits = async (list, searchQuery) => {
    let searchedRecords = await searchRecords(list, searchQuery)
    console.log('Turned this', list)
    console.log('Into this:', searchedRecords)
    setFilteredRows(searchedRecords)
  }

  const handleExpand = (visitId) => {
    setExpanded(prev => ({
      ...prev,
      [visitId]: !prev[visitId]
    }));
  };

  const getStatusChip = (visit) => {
    if (visit.jobFinished) {
      return <Chip 
        icon={<CheckCircleIcon />} 
        label="Completed" 
        color="primary" 
        className={classes.statusChip}
      />;
    }
    if (visit.jobPaused) {
      return <Chip 
        icon={<PauseIcon />} 
        label="Paused" 
        color="secondary" 
        className={classes.statusChip}
      />;
    }
    return <Chip 
      icon={<PlayArrowIcon />} 
      label="In Progress" 
      color="default" 
      className={classes.statusChip}
    />;
  };

  const formatDuration = (start, end) => {
    if (!start || !end) return 'N/A';
    const duration = moment.duration(moment(end).diff(moment(start)));
    return `${Math.floor(duration.asHours())}h ${duration.minutes()}m`;
  };

  const handleSearch = (e) => {
    console.log(e.target.value)
    setSearchValue(e.target.value)
    filterOutVisits(visits, e.target.value)
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" className={classes.header}>
        Service Visits
      </Typography>
      <div className={classes.search}>
        <TextField
            name="search" 
            value={ searchValue } 
            onChange={ handleSearch }
            label="Search"
            type="search"
            variant="outlined"
            margin="normal"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
        />
        <div>{filteredRows.length} / {visits.length} Matching Visits</div>
      </div>
      <List className={classes.root}>
        {filteredRows.map((visit) => {
          let crewLabel = visit.crew?.name || 'No Crew'
          if (visit.crew?.foremanName) crewLabel = `${crewLabel} (${visit.crew.foremanName})`
          return (
          <React.Fragment key={visit._id}>
            <ListItem 
              button 
              onClick={() => handleExpand(visit._id)}
              className={classes.listItem}
            >
              <ListItemText
                primary={
                  <Box display="flex" alignItems="center">
                    <Typography>
                      {visit.jobName || 'Unnamed Job'} - WO#{visit.jobNumber}
                    </Typography>
                    {getStatusChip(visit)}
                    <Chip 
                      label={visit.client?.displayName || 'No Client'}
                      size="small"
                      className={classes.clientChip}
                    />
                    <Chip 
                      label={crewLabel}
                      size="small"
                      className={classes.crewChip}
                    />
                  </Box>
                }
                secondary={`Created: ${moment(visit.created_at).format('MM/DD/YYYY HH:mm')}`}
              />
              {expanded[visit._id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={expanded[visit._id]} timeout="auto" unmountOnExit>
              <Table className={classes.shiftsTable} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Shift Start</TableCell>
                    <TableCell>Shift End</TableCell>
                    <TableCell align="right">Duration</TableCell>
                    <TableCell align="right">Crew Size</TableCell>
                    <TableCell align="right">Man Hours</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visit.shifts?.map((shift, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {moment(shift.started).format('MM/DD/YYYY HH:mm')}
                      </TableCell>
                      <TableCell>
                        {shift.paused ? 
                          moment(shift.paused).format('MM/DD/YYYY HH:mm') : 
                          'In Progress'}
                      </TableCell>
                      <TableCell align="right">
                        {formatDuration(shift.started, shift.paused)}
                      </TableCell>
                      <TableCell align="right">
                        {shift.numberOfMen}
                      </TableCell>
                      <TableCell align="right">
                        {shift.paused ? 
                          (shift.hoursWorked * shift.numberOfMen).toFixed(2) : 
                          'In Progress'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </React.Fragment>
        )})}
      </List>
    </Paper>
  );
};


const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(ServiceVisitsList))
