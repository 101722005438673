import React, { useCallback } from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import axios from 'axios'
import JobDetailsModal from './JobDetails'
import NewJobModal from './NewJobModal'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import { Paper, CircularProgress } from '@material-ui/core';
import moment from 'moment'
import { Link } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import JobDetailsWLineItems from '../Jobs/JobDetailsWLineItems';

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
    // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'combined', numeric: false, disablePadding: true, label: 'Job Info' },
  { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'location', numeric: false, disablePadding: true, label: 'Site & Schedule' },
  { id: 'project', numeric: false, disablePadding: true, label: 'Project' },
  { id: 'percentComplete', numeric: false, disablePadding: true, label: '% Complete' },
  { id: 'salesRepName', numeric: false, disablePadding: true, label: 'Salesperson' },
  { id: 'jobTotal', numeric: false, disablePadding: true, label: 'Total' }
];

function EnhancedTableHead(props) {
  // console.log('Got our props', props)
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    // console.log('Sort Property: ', property)
    // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#1976d2',
    '&:hover': {
      color: '#2196f3',
    }
  },
  table: {
    minWidth: 400,
    '& td': {
      padding: '8px 16px',
    },
    '& th': {
      padding: '8px 16px',
      fontWeight: 600,
    }
  },
}));

function EnhancedTable(props) {
  // console.log('Job List Props: ', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [jobDetailsOpen, setJobDetailsOpen] = React.useState(false);
  const [showJobDetailsWLineItems, setShowJobDetailsWLineItems] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [job, setJob] = React.useState({});
  const [crewList, setCrewList] = React.useState([]);
  const [serviceList, setServiceList] = React.useState([]);
  const [takeOffList, setTakeOffList] = React.useState([])
  // const [salesPeople, setSalesPeople] = React.useState([])
  const [client, setClient] = React.useState({})
  // setLeads(props.leads)
  // let clients  = []
  // if (clients && clients.length > 0) clients = clients
  // console.log('Clients on table: ', clients)
  // const rows = leads
  const [showNewJobModal, setShowNewJobModal] = React.useState(false)
  const [rows, setRows] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);

  React.useEffect(() => {
    let isMounted = true
  // console.log('Jobs Have  changed', rows)
  // console.log(props)
    if (props.stateData && props.stateData.itemToOpen && props.stateData.itemToOpen === 'Job' && props.stateData.openId && rows && rows.length) {
    // console.log('Open this bitch', props.stateData.openId)
      // handleClick
      let index = rows.findIndex(item => (item._id === props.stateData.openId))
    // console.log('Open Workorder Index', index)
    // console.log(rows[index])
      if (index > -1) {
      // console.log('Weoot mother fuckin woot')
        if (isMounted) {
          openJobModal(rows[index])
        }
      }
    }
    return () => {
      isMounted = false
    }
  }, [rows])


  const getJobsForClient = async (client, callback) => {
    setLoading(true)
    // console.log('Get jobs for this guy: ', client)
    const response = await axios.get(`${props.url}/api/customer/getAllJobs?id=${client._id}`)
     // console.log('Jobs!!', response)
     if (props.client) setClient(props.client)
     if (response && response.data && response.data.jobs) {
      // console.log('This is great!')
      return callback(null, response.data)
     } else {
       return callback(response)
     }
     
  }
  function currencyFormat(num) {
    if (!num) num = 0
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

 React.useEffect(() => {
  let isMounted = true
  // console.log('Use effectset rows: ', client)
  async function getJobsForProject(projectId) {
    setLoading(true)
    // console.log('Get jobs for this guy: ', client)
    const response = await axios.get(`${props.url}/api/projects/getAllJobs?id=${projectId}`)
    if (response && response.data && response.data.serviceList) {
      setClient(response.data.client)
      setRows(response.data.jobs)
      setFilteredRows(response.data.jobs)
      setServiceList(response.data.serviceList)
      setCrewList(response.data.crewList)
      setTakeOffList(response.data.takeOffs)
      setLoading(false)
      // props.updateParent(response.data.jobs)
    }
  }
  if (props.project) {
      getJobsForProject(props.project._id)
  }

  return () => {
    isMounted = false
  }
}, [props.project])

  React.useEffect(() => {
    if (props.client && props.client._id) {
      
      // console.log('Get Jobs for client??')
      getJobsForClient(props.client, (err, data) => {
        if (err) {
          console.log('Error Getting Client jobs...', err)
        }
        if (data) {
          let crewList = data.crewList
          let serviceList = data.serviceList
          let jobs = data.jobs
          // let salesPeople = data.salesPersonList
          // setSalesPeople(props.salesReps)
          setRows(jobs)
          setFilteredRows(jobs)
          setServiceList(serviceList)
          setCrewList(crewList)
          setLoading(false)
          props.updateParent(jobs)
        }
      })
      socket.on(`${client._id}-jobList`, function (msg) {
        // console.log('GOT A SOCKET MESSAGE to the Job List LIST!!: ', msg)
        // setStatementMessage(msg.message)
        getJobsForClient(client, (err, data) => {
          if (err) {
            // console.log('Err getting client jobs after update....', err)
          }
          if (data) {
            // console.log('SOCKET IO JOB LIST REFRESH: ', data)
            let jobs = data.jobs
            // console.log(jobs)
            setLoading(false)
            setRows(jobs);
            setFilteredRows(jobs)
            props.updateParent(jobs)
          }
        })
      })
      return () => {
        socket.off(`${client._id}-jobList`)
      }
    }

  }, [props.client])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    // console.log('handle click ', name)
    // console.log('Index: ', selectedIndex)
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = event => {
  //   setDense(event.target.checked);
  // };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r) => {
    // console.log('FIlter out: ', r)
    let string = searchValue
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // return newArr
    setFilteredRows(newArr)
    // setsearchMatches(newArr.length)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }
  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }

  const openJobModal = (job) => {
    // console.log(event)
    // e.preventDefault;
    // console.log('Open job modal: ', job)
    // props.openJobModal(job)
    setJob(job)
    // if (job?.lineItemStyle) {
    //   setShowJobDetailsWLineItems(true)
    // } else {
    //   setJobDetailsOpen(true)
    // }
    // setJobDetailsOpen(true)
    setShowJobDetailsWLineItems(true)
  }

  const responseFromJobDetails = (s, jobnumber) => {
    if (s === 'Close') setShowJobDetailsWLineItems(false)
    if (s === 'Delete') {
      // console.log('Delete that one...', jobnumber)
      let ourIndex = rows.map(function (e) { 
        // console.log('WHHH?? ', e)
        return e.number
      }).indexOf(jobnumber)
      // console.log('Index: ', ourIndex)
      let newJobList = rows
      newJobList.splice(ourIndex, 1)
      setRows(newJobList)
      setFilteredRows(newJobList)
      setShowJobDetailsWLineItems(false)
    }
  }

  const responseFromJobDetailsWLineItems = (s, jobnumber) => {
    console.log('Job Details REsponse::', s)
    if (s === 'Close') setShowJobDetailsWLineItems(false)
    if (s === 'Delete') {
      // console.log('Delete that one...', jobnumber)
      let ourIndex = rows.map(function (e) { 
        // console.log('WHHH?? ', e)
        return e.number
      }).indexOf(jobnumber)
      // console.log('Index: ', ourIndex)
      let newJobList = rows
      newJobList.splice(ourIndex, 1)
      setRows(newJobList)
      setFilteredRows(newJobList)
      setShowJobDetailsWLineItems(false)
    }
  }

  const createNewJob = () => {
    // console.log('Create New Job')
  }

  const responseFromNewJob = async (d) => {
    // console.log('New Workorder Number', d)
    if (d === 'Close') {
      setShowNewJobModal(false)
    } else {
      setJob(d)
      setShowJobDetailsWLineItems(true)
      setShowNewJobModal(false)
      let jobs = rows
      jobs.unshift(d)
      setRows(jobs)
      // props.updateParent('newJob', )
      setLoading(false)
    }
  }

  const handleDblClick = (jobSelected) => {
    console.log('Double click::', jobSelected)
    setJob(jobSelected)
    // OPen new Job Details Modal
    setShowJobDetailsWLineItems(true)
  }
  // console.log('Takeoffs 674', takeOffList)
  
  return (
    <div className={classes.root}>
      <NewJobModal client={client} jobSites={props.jobSites} jobDetailsResponse={responseFromNewJob} open={showNewJobModal} />
      <JobDetailsModal client={client} deposits={props.deposits} project={props.project} projects={props.projects} job={job} uploads={props.uploads} jobSites={props.jobSites} crewList={crewList} serviceList={serviceList} jobDetailsResponse={responseFromJobDetails} open={jobDetailsOpen} taxLocales={props.taxLocales}/>
      <JobDetailsWLineItems
        client={client}
        deposits={props.deposits} 
        project={props.project} 
        projects={props.projects}
        job={job} 
        uploads={props.uploads}
        jobSites={props.jobSites} 
        crewList={crewList} 
        serviceList={serviceList} 
        jobDetailsResponse={responseFromJobDetailsWLineItems}
        open={showJobDetailsWLineItems} 
        taxLocales={props.taxLocales}
        takeOffList={props?.takeOffs || takeOffList}
      />
      { loading?  <CircularProgress color="primary" size={ 36 } /> : 
      <Paper className={classes.paper}>
        <div style={{margin: '5px'}}>
          <span style={{fontSize: '1.4em'}}>Total Jobs: {rows.length}</span>
          <Button
            variant="contained"
            color="secondary"
            style={{margin: '5px', position: 'absolute', right: '20px'}}
            className={classes.button}
            // startIcon={<CreditCardIcon />}
            size="small"
            disabled={!client || !client.active}
            onClick={() => setShowNewJobModal(true)}
          >
            Create New
          </Button>
        </div>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <div className={classes.search}>
            <div >
              <SearchIcon /> Search
            </div>
            <input name="search" value={ searchValue } onChange={ handleChange('search') } type="text"></input>
          </div>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // console.log('Row map: ', row)
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // setsearchMatches(rows.length)
                  // console.log(row)
                  let project = null
                  if (row.project) project = props?.projects?.find(item => (item._id === row.project ))
                  return (
                    <TableRow
                      hover
                      // onDoubleClick={() => handleDblClick(row)}
                      // onClick={event => handleClick(event, row.number)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                     <TableCell component="td" id={labelId} scope="row" padding="none">
                      <div>
                          <span className={classes.fakeLink} onClick={() => openJobModal(row)}>
                            #{row.number} - {row.name || 'Un-Named Job'}
                          </span>
                          <div style={{ 
                            fontSize: '0.75rem', 
                            color: 'rgba(0, 0, 0, 0.54)',
                            marginTop: '2px'
                          }}>
                            Created {moment(row.created_at).format('MM/DD/YY')} by {row.created_by || 'Unknown'}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        <Chip 
                          size="small" 
                          label={row.status}
                          style={{
                            backgroundColor: row.status === 'Active' ? '#e3f2fd' : 
                                            row.status === 'Complete' ? '#e8f5e9' : '#fafafa'
                          }}
                        />
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        <div style={{ fontSize: '0.875rem' }}>
                          <div>{row.jobSiteAddress}</div>
                          <div style={{ color: '#666', fontSize: '0.8rem' }}>
                            {moment(row.startDate).format('MM/DD/YY (ddd)')}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        {project?.name || ''}
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ 
                            width: '50px',
                            height: '6px',
                            backgroundColor: '#eee',
                            marginRight: '4px'
                          }}>
                            <div style={{
                              width: `${row.percentComplete}%`,
                              height: '100%',
                              backgroundColor: '#accacd'
                            }}/>
                          </div>
                          {row.percentComplete}%
                        </div>
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        {row.salesRepName}
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        {currencyFormat(row.jobTotal || 0)}
                      </TableCell>                
                    </TableRow>
                  );
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      }
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  // console.log('JOB LIST MAP STATE TO PROPS: ', state)
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    activeJob: state.activeJob
  }
}

export default React.memo(connect(mapStateToProps)(EnhancedTable))

