import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SupplyInventoryInputItem from './SupplyInventoryInputItem';
import NewSuppllierModal from './NewSupplierModal';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TaxableDetailsList from './TaxableDetailsList'
import ChooseDepositComponent from './ChooseDepositComponent'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom'
import FollowingComponent from '../Common/FollowingComponent'
import ForecastInputs from './ForecastInputs';
import TaxModule from '../Common/Taxes/TaxModule'
import EditIcon from '@material-ui/icons/Edit';

const statusOptions = [
  'Pending',
  'Pending Design/Sales Approval',
  'Sent to Customer',
  'Pending Revisions',
  'Approved By Customer',
  'Rejected By Customer',
  'Archived'
]

const CloneEstimateModal = ({ open, updateParent, url, estimateList }) => {
  // const [estimateList, setEstimateList] = React.useState([])
  const [state, setState] = React.useState({
    estimateToClone: {}
  })

  const handleClose = () => {
    updateParent('close')
  }

  const updateEstimate = (e, est) => {
    // props.updateParent
    setState({...state, estimateToClone: est })
  }

  const formatOption = (option) => {
    if (option && option._id) {
      return `Estimate #${option.number}   -   ${option.name}     ${option.displayName}   (${option.clientAgId})`
    } else {
      return ''
    }
  }

  const handleConfirm = () => {
    const estimate = state.estimateToClone
    if (window.confirm(`Are you sure you wish to clone the line items from Estimate #${estimate.number} to This Estimate? This Will Overwrite Any Items You Currently Have Saved.`)) {
      updateParent('clone', estimate)
    }
  }
  return (
    <Dialog maxWidth={'sm'} fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Clone Estimate</DialogTitle>
        <DialogContent>
          <DialogContentText>Choose Estimate To Clone</DialogContentText>
          <Autocomplete
            id="EstimateToClone"
            options={estimateList || []}
            getOptionLabel={(option) => (formatOption(option) || '')}
            // style={{ width: 200 }}
            // className={classes.paddedStuff}
            style={{marginBottom: 10, marginTop: 15}}
            onChange={updateEstimate}
            fullWidth
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {state.estimateToClone}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} variant="outlined" label="Estimate Line Items To Clone" />}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            disabled={!state.estimateToClone || !state.estimateToClone._id}
            onClick={handleConfirm}
            >Clone</Button>
        </DialogActions>
    </Dialog>
  )
}

const ConfigurationContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
align-content: center;
text-align: center;
border-radius: 15px;
margin-top: 25px;
width: 100%;
`;

const TopRow = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  align-tems: center;
`;

const TopRowItem = styled.div`
  display: flex;
  align-self: ${props => (props.align === 'start' ? 'flex-start' : 'flex-end')};
`;


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  },
  taxSection: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    marginTop: 20
  },
  modifyButton: {
    marginBottom: theme.spacing(2)
  },
  taxItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  label: {
    fontWeight: 500,
    color: theme.palette.text.secondary
  },
  value: {
    fontWeight: 400
  }
}));


const defaultStateValues = {
  name: '',
  category: '',
  description: '',
  salesRep: {}
}

const defaultValues = {
  name: '',
  taxable: true,
  taxLocale: '',
  taxAmount: 0,
  depositPercent: 0,
  depositAmount: 0,
  summaryPricing: false,
  taxDetails: [],
  taxLocales: [],
  waiveDeposit: false,
  associatedProject: {},
  counter: 0,
  taxDetails: null,
  salesRep: {},
  estimatesForCloning: [],
  status: 'Pending',
  statusOptions: statusOptions,
  duration: 1,
  confidence: 0,
  expectedCloseDate: null
}

const formatOption = (opt) => {
  // console.log('Format this: ', opt)
  if (opt && opt.address) {
    return `${opt.name} - ${opt.address} ${opt.address2 ? opt.address2 : ''} ${opt.city}, ${opt.state} ${opt.zip}`
  } else {
    return ''
  }
}
const formatOptionForProject = (opt) => {
  // console.log('Format this: ', opt)
  if (opt && opt.name) {
    return `${opt.name}`
  } else {
    return ''
  }
}



const TaxLocaleDetails = ({ locs, updateTaxStuff }) => {
  // console.log('REnder TAX LOCALE DETAILS', locs)
  // const handleUpdateTaxableAmt = (e) => {
  // // console.log('Taxable Amt changed')
  //   props.update
  // }
  if (locs && locs.length) {
    return locs.map((item, index) => {
      let taxAmountHelperText = `How Much Of The Estimate is Taxable In This Region?`
      if (!item.taxableAmount || item.taxableAmount === 0) {
        taxAmountHelperText = `How Much Of The Estimate is Taxable In This Region? If it's $0, then it's not taxable in this region so delete it.`
      }
      // console.log(item)
      return (
        <FormControl fullWidth key={index} style={{marginBottom: '15px'}}>
          <Grid container spacing={4}>
            <Grid item lg={6} sm={6} md={6}>
              <TextField
                label="Taxable Area"
                variant="outlined"
                type="text"
                fullWidth
                disabled
                value={`${item.name} - ${item.rate}`}
                // onChange={e => setState({...state, taxAmount: e.target.value})}
                // onFocus={handleFocus}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6}>
              <TextField
                label="Taxable Amount"
                variant="outlined"
                type="text"
                fullWidth
                value={item.taxableAmount}
                // onBlur={handleUpdateTaxableAmt}
                onChange={e => updateTaxStuff(e, 'taxableAmount', index, e.target.value, item)}
                // onFocus={handleFocus}
                helperText={taxAmountHelperText}
              />
            </Grid>
            {/* <Grid item lg={3} sm={3} md={3}>
              <TextField
                label="Tax Amount"
                variant="outlined"
                type="text"
                fullWidth
                disabled
                value={item.taxAmount}
                // onChange={e => setState({...state, taxAmount: e.target.value})}
                // onFocus={handleFocus}
              />
            </Grid> */}
          </Grid>
            
        </FormControl>
      )
    })
  } else {
    return <div></div>
  }
}

const EditEstimateModal = (props) => {
  // // console.log('Edit Estimate: ', props)
  const classes = useStyles();
  const [state, setState] = React.useState(defaultValues)
  const [loading, setLoading] = React.useState(false)
  const [inventoryItem, setInventoryItem] = React.useState({})
  const [estimate, setEstimate] = React.useState({})
  const [taxLocales, setTaxLocales] = React.useState([])
  const [chosenLocales, setChosenLocales] = React.useState([])
  const [theJobSite, setJobSite] = React.useState({})
  const [projectList, setProjectList] = React.useState([])
  const [billingAddress, setBillingAddress] = React.useState({})
  const [associatedProject, setAssociatedProject] = React.useState({ name: 'Please Choose' })
  const [showCloneEstimateModal, setShowCloneEstimateModal] = React.useState(false)
  const [showTaxDetails, setShowTaxDetails] = React.useState(false)

  const inputRef = React.useRef({
    amount: 0,
    percentage: 0
  })
  React.useEffect(() => {
    let isMounted = true
    
    async function fetchData() {
      // console.log('Fetch our Users')
      // setLoading(true)
      const response = await axios.get(`${props.url}/api/settings/listTaxLocales`)
      // console.log(response)
      if (response.data && response.data.length > 0) {
        // console.log('Got Tax Locales!!', response.data)
        if (isMounted) setTaxLocales(response.data)
      }
    }
    fetchData()
    
    return () => {
      isMounted = false
    }
  }, [])

  React.useEffect(() => {
    let isMounted = true
    // console.log('USE EFFECT')
    let newState = {}
    if (props.estimate && props.estimate._id) {
      let jobSite = {}
      if (props.estimate.jobSite) {
        // console.log('We have a jobsite', props.estimate.jobSite)
        // console.log(props.jobSites)
        let index = props.jobSites.findIndex(item => item._id === props.estimate.jobSite)
        // console.log('Index:', index)
        // console.log(props.jobSites[index])
        if (index > -1) {
          // console.log('Index > -1')
          jobSite = props.jobSites[index]
          // console.log('Set this jobsite!!!!!!', jobSite)
          setJobSite(jobSite)
          // setState({...state, jobSite: jobSite})
          newState.jobSite = jobSite
        } else {
          // console.log('Jobsite cannot be found!!!')
        }
      }
      if (props.estimate && props.estimate.status) {
        // console.log('Estimate status:', props.estimate.status)
        let statusIndex = state.statusOptions.findIndex(item => (item === props.estimate.status))
        if (statusIndex > -1) {
          newState.status = state.statusOptions[statusIndex]
        } else {
          let currentStatusOptions = state.statusOptions
          currentStatusOptions.unshift(props.estimate.status)
          newState.statusOptions = currentStatusOptions
          newState.status = currentStatusOptions[currentStatusOptions.length - 1]
          // console.log('Add a status option', newState)
        }
      }
      if (props.estimate.billingAddress) {
        // console.log('Find Estimate Billing Address: ', props.estimate)
        let matchingAddy = {}
        for (const addy of props.addys) {
          // console.log('This ma tch? ', addy)
          if (addy.address === props.estimate.billingAddress && (props.estimate.billingAddress2 ? addy.address2 === props.estimate.billingAddress2 : true) && addy.city === props.estimate.billingCity && addy.zip === props.estimate.billingZip) {
            // console.log('OUR MATCH!!', addy)
            matchingAddy = addy
          }
        }
        if (matchingAddy) {
          // console.log('Set this as our billing address: ', matchingAddy)
          setBillingAddress(matchingAddy)
        }
      }
      setEstimate(props.estimate)
      let theSalesRep = null
      let salesRepId = props.estimate.salesRep
      if (salesRepId && salesRepId._id) salesRepId = props.estimate.salesRep._id
      let salesRepIndex = props.salesReps.findIndex(rep => (rep._id === salesRepId))
      if (salesRepIndex > -1) {
        theSalesRep = props.salesReps[salesRepIndex]
      }
    // console.log('Found the sale rep:', theSalesRep)
      if (props.estimate && props.estimate.taxLocales && props.estimate.taxLocales.length) {
        // setChosenLocales(props.estimate.taxLocales)
        newState.chosenLocales = props.estimate.taxLocales
      }
      newState.name = props.estimate.name
      newState.salesRep = theSalesRep
      newState.accepted = props.estimate.accepted
      newState.jobSite = jobSite
      newState.taxable = props.estimate.taxable
      newState.taxLocale =  props.estimate.taxLocale
      newState.taxableAmount = props?.estimate?.taxableAmount || 0
      newState.taxAmount = props.estimate.taxAmount || 0
      newState.expectedCloseDate = props.estimate?.expectedCloseDate || null
      newState.duration = props.estimate?.duration || 0
      newState.confidence = props.estimate?.confidence || 0
      // console.log('Set State', newState)
      setState({...state, ...newState})
      
      // Set our Chosen Tax Locales Here
    }
    return () => {
      isMounted = false
    }
  }, [props])

  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
      // console.log(e.target)
      if (e.target.id === 'salesperson') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        props.salesReps.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          setState({...state, salesRep: newArr[0]})
        }
      }
    }
  }

  const fetchProjectsAssociatedToEstimate = async (_id) => {
    axios.get(`${props.url}/api/projects/listProjects?clientId=${_id}`).then(response => {
      if (response && response.data && response.data.projects && response.data.projects.length) {
        // console.log('Asociated projects', response.data)
        let ourList = response.data.projects
        ourList.unshift({ name: 'Create New Project', _id: 'new' })
        setProjectList(ourList)
        if (props.estimate && props.estimate.project) {
          let index = response.data.projects.findIndex(item => (item._id === props.estimate.project))
          if (index > -1) {
            setAssociatedProject(response.data.projects[index])
            setState({...state, associatedProject: response.data.projects[index]})
          }
        } else {
          setState({...state, counter: state.counter++ })
        }
      }
    })
  }

  React.useEffect(() => {
    // console.log('Get Projects for this client props.estimate: ', props.estimate)
    let isMounted = true
    if (props.estimate && props.estimate.client) fetchProjectsAssociatedToEstimate(props.estimate.client)
    if (props.estimate && props.estimate.waiveDeposit) setState({...state, waiveDeposit: true })
    return () => {
      isMounted = false
    }
  }, [props.estimate])

  const handleSaveEstimateUpdates = () => {
  // console.log('Save Estimate NOW', state)
    // Add this to our Estimate
    // state.chosenLocales = chosenLocales
    let itemToSend = {
      ...state
    }
    itemToSend.jobSite = theJobSite ? theJobSite._id : null
    itemToSend._id = props.estimate._id
    itemToSend.billingAddress = billingAddress
    if (associatedProject && associatedProject._id === 'new') {
      itemToSend.project = {
        _id: 'new',
        name: state.projectName
      }
    } else {
    // console.log('associated project is normal...')
      itemToSend.project = associatedProject._id || null
    }
    // itemToSend.depositData = inputRef.current
    // setState(defaultValues)
  // console.log('Item to save: , ', itemToSend)
    props.updateEstimateDetails(itemToSend)
  }

  const handleFocus = (e) => {
    e.preventDefault()
    e.target.focus()
    e.target.setSelectionRange(0, e.target.value.length)
  }

  const handleCostChangeUpdate = (e) => {
    let pricePer = 0
    let totalPrice = 0
    let costPer = e.target.value
    pricePer = Number(costPer * 1.4).toFixed(2)
    totalPrice = Number(pricePer * state.qty).toFixed(2)
    setState({...state, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice})
  }

  const handlePriceManualChange = (e) => {
    let pricePer = e.target.value
    let totalPrice = 0
    totalPrice = Number(pricePer * state.qty).toFixed(2)
    setState({...state, pricePer: pricePer, totalPrice: totalPrice})
  }

  const handleQtyUpdate = (e) => {
    let pricePer = state.pricePer
    let totalPrice = 0
    totalPrice = Number(pricePer * e.target.value).toFixed(2)
    setState({...state, qty: e.target.value, totalPrice: totalPrice})
  }

  const handleClose = () => {
    setState(defaultValues)
    props.closeModal()
  }

  const handleDelete = () => {
    props.deleteItem(props.item)
  }

  const handleChangeTaxable = () => {
    const isTaxable = state.taxable
    if (isTaxable) {
      let newEst = estimate
      newEst.taxable = false
      newEst.taxLocales = []
      newEst.taxLocale = ''
      newEst.taxAmount = 0
      // setEstimate(newEst)
      updateTaxesAndDepositInfo(newEst, false, [])
      // setState({...state, taxable: false, taxLocale: '', taxAmount: 0, chosenLocales: [], taxableAmount: 0 })
    } else {
      let newEst = estimate
      newEst.taxable = true
      setEstimate(newEst)
      setState({...state, taxable: true})
    }
  }

  const handleChangePricingDetails = () => {
    const hidePriceDetails = state.summaryPricing
    if (hidePriceDetails) {
      setState({...state, summaryPricing: false})
    } else {
      setState({...state, summaryPricing: true})
    }
  }
  
  const updateTaxesAndDepositInfo = (est, isTaxable, locs) => {
    // console.log('Modify the Tax Amounts and Deposit Info for this estimate:', est)
    // console.log('Taxable: ', isTaxable)
    // console.log('Tax Locales: ', locs)
    let taxAmount = 0
    let taxableAmount = 0
    let newEst = estimate
    let totalEstimatePrice = newEst.totalPrice
    let estimateTotal = newEst.totalPrice
    if (isTaxable) {
      locs.map(item => {
        // console.log('Get Tax stuff from loc 378', item)
        // console.log('ITEM:: Taxable Amount: %s       Rate: %s     Tax Amount: %s ', item.taxableAmount, item.rate, item.taxAmount)
        let itemTaxAmount = parseFloat(parseFloat(item.taxableAmount) * parseFloat(item.rate || 0)).toFixed(2)
        // console.log('Item Tax Amount: ', itemTaxAmount)
        taxAmount += Number(itemTaxAmount)
        let itemTaxableAmt = parseFloat(parseFloat(taxableAmount) - 0 + parseFloat(item.taxableAmount || 0)).toFixed(2)
        // console.log('Item Taxable Amount: ', itemTaxableAmt)
        taxableAmount = Number(itemTaxableAmt)
        // console.log('Taxx Amount: ', taxAmount)
        // console.log('Taxable Amount: ', taxableAmount)
      })
          // console.log('Update Estimate taxable amount: %d and tax amount: %d after modifying TAX LOCALES', taxableAmount, taxAmount)

      newEst.taxable = state.taxable
      newEst.taxAmount = Number(taxAmount)
      newEst.taxableAmount = Number(taxableAmount)
      newEst.taxLocales = locs
      // console.log('New Estaimtate tax rates', newEst)
      // inputRef.current['taxable'] = state.taxable
      inputRef.current['taxableAmount'] = Number(taxableAmount)
      inputRef.current['taxAmount'] = Number(taxAmount)
      inputRef.current['taxLocales'] = locs
      setEstimate(newEst)
      // console.log('Update Estimate and state w/ Tax INFO....', newEst)
      // Calculate Deposit
      let estimateTaxAmount = Number(parseFloat(newEst.taxAmount).toFixed(2))
      totalEstimatePrice = Number(parseFloat(estimateTotal - 0 + estimateTaxAmount).toFixed(2))
      let depAmt = Number(newEst.depositPercent)
      let depositAmount = Number(parseFloat(totalEstimatePrice * (depAmt / 100)).toFixed(2))
      // console.log('Deposit Amount 412', depositAmount)
      let percentFixed = Number(parseFloat(newEst.depositPercent).toFixed(2))
      let newState = {...state, taxable: true, chosenLocales: locs, taxAmount: Number(taxAmount), taxableAmount: Number(taxableAmount), depositAmount: depositAmount, depositPercent: percentFixed }
      // console.log('Set NEw State: 408', newState)
      setState(newState)
    } else {
      let depAmt = Number(newEst.depositPercent)
      let depositAmount = Number(parseFloat(totalEstimatePrice * (depAmt / 100)).toFixed(2))
      // console.log('Deposit Amount 412', depositAmount)
      let percentFixed = Number(parseFloat(newEst.depositPercent).toFixed(2))
      inputRef.current['taxableAmount'] = 0
      inputRef.current['taxAmount'] = 0
      inputRef.current['taxLocales'] = []
      newEst.taxable = false
      newEst.taxAmount = 0
      newEst.taxableAmount = 0
      newEst.taxLocales = []
      setEstimate(newEst)
      let newState = {...state, chosenLocales: [], taxAmount: 0, taxable: false, taxableAmount: 0, depositAmount: depositAmount, depositPercent: percentFixed }
      // console.log('Set NEw State: 412', newState)
      setState(newState)
    }
 
  }

  const handleUpdateUOM = (e, newOne) => {
    // console.log('Update Tax Locales!!!', newOne)
  // console.log(e)
  // console.log(e.target.id)
    // console.log('Update uoom', newOne)
    e.preventDefault()
    updateTaxesAndDepositInfo(estimate, true, newOne)
  }

  const handleTaxDetails = (e, i) => {
    // console.log('Handle details: ', i)
  // console.log(e.target.value)
  // console.log(e)
    // setChosenLocales(i)
  // console.log(chosenLocales.current)
  // console.log(chosenLocales.current.value)
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const updateTaxStuff = (e, type, index, val, locale) => {
    // console.log('Update Tax Stuff: ', type)
    // console.log('VALUE: ', val)
    let locs = state.chosenLocales
    let loc = locs[index]
    // loc[type] = val
    
    if (type === 'taxableAmount') {
      // Calculate Taxes
      // console.log(loc)
      loc.taxableAmount = val
      // taxAbleAmount = val
      let amt = parseFloat(parseFloat(val) * parseFloat(loc.rate || 0)).toFixed(2)
      loc.taxAmount = amt
      // console.log('AMount: ', amt)
    }
    updateTaxesAndDepositInfo(estimate, true, locs)
  }

  const addTaxableArea = () => {
    // console.log('Add Taxable Area')
    let chosen = chosenLocales
    let available = taxLocales
    let newAvail = []
    for (const loc of available) {
      let index = chosen.findIndex(item => (item._id === loc._id))
      if (index > - 1) {

      } else {
        newAvail.push(loc)
      }
    }
    if (newAvail.length) {
      chosen.push({ name: '' })
    // console.log(chosen)
      setChosenLocales(chosen)
    }
    setTaxLocales(newAvail)
    setState({...state, taxLocales: chosen})
  }

  const updateJobSiteAddress = (e, js) => {
    if (js && js._id) {
      setJobSite(js)
      setState({...state, jobSite: js })
    } else {
      setJobSite(null)
      setState({...state, jobSite: null })
    }
  }

  const updateProject = (e, proj) => {
    // console.log('Prev associated: ', associatedProject)
    // console.log('state.associated', state)
    if (proj && proj._id === 'new') {
      // console.log('NEW PROJECT', proj)
      setAssociatedProject(proj)
      setState({...state, associatedProject: proj })
    } else if (proj?._id) {
      // console.log(proj)
      setAssociatedProject(proj)
      setState({...state, associatedProject: proj })
    } else {
      setAssociatedProject({})
      setState({...state, associatedProject: {} })
    }
  }

  const updateSalesRep = (e, rep) => {
    setState({...state, salesRep: rep })
  }

  const updateBillingAddress = (e, addy) => {
    setBillingAddress(addy)
    setState({...state, billingAddress: addy })
  }

  const handleWaiveDeposit = (e) => {
    setState({...state, waiveDeposit: !state.waiveDeposit})
  }

  const handleManualAcception = () => {
    if (state.accepted) {
      // Unaccept
      setState({...state, accepted: false })
    } else {
      // Manually Accept Invoice
      setState({...state, accepted: true })
    }

  }

  const updateAmounts = (pct, amt) => {
    // console.log('Updat eamounts ', pct, amt)
    inputRef.current['amount'] = Number(amt)
    inputRef.current['percentage'] = Number(pct)
    let newEst = estimate
    newEst.depositAmount = Number(amt)
    newEst.depositPercent = Number(pct)
    // console.log('Update Estimate Deposit info 616', newEst)
    setState({...state, depositPercent: Number(pct), depositAmount: Number(amt) })
    // setEstimate(newEst)
    // props.updateEstiamteDepositAndTaxInfo(newEst)
  }

  const handleCloneEstimateFromOtherEstimate = () => {
    setLoading(true)
    if (state.estimatesForCloning && state.estimatesForCloning.length) {
      setLoading(false)
      setShowCloneEstimateModal(true)
    } else {
      axios.get(`${props.url}/api/proposal/getEstimatesForCloning`).then(response => {
        if (response && response.data && response.data.length) {
          setLoading(false)
          setState({...state, estimatesForCloning: response.data })
          setShowCloneEstimateModal(true)
        }
      }) 
    }
  }

  const chooseEstimateToClone = (type, estimateToClone) => {
  // console.log('UPdate Type: ', type)
  // console.log('Estimate to Clone', estimateToClone)
    setShowCloneEstimateModal(false)

    if (estimateToClone && estimateToClone._id) {
      // Go get the line items from that estimate and put them on this estimate
      if (type === 'clone') {
        const data = {
          estimateToCloneTo: estimate,
          estimateToCloneFrom: estimateToClone
        }
        axios.post(`${props.url}/api/proposal/cloneEstimateLineItems`, data).then(response => {
        // console.log('Got our updated estimate with those line items', response.data)
          props.saveClonedEstimateLineItems(response.data)
        })
      }
    }
  }

  const handleChangeStatus = (e, newStatus) => {
    // console.log('New status:', newStatus)
    console.log(e.target.value)
    let statusIndex = state.statusOptions.findIndex(item => (item === e.target.value))
    if (statusIndex > -1) {
      setState({...state, status: state.statusOptions[statusIndex] })
    }
  }

  const handleUpdateTaxes = (d) => {
    setShowTaxDetails(false)
    console.log('Update our taxes....', d)
    let estimateWithTaxDetails = {
      ...props.estimate,
      taxLocales: d.taxLocales,
      taxAmount: d.taxAmount,
      taxableAmount: d.taxableAmount,
      taxable: true
    }
    console.log('Sae this', estimateWithTaxDetails)
    // props.updateEstimateDetails(estimateWithTaxDetails)
    let newEst = estimateWithTaxDetails
    setEstimate(newEst)
    setState({...state, taxable: true, ...d, chosenLocales: d?.taxLocales })
  }

  const isInvalid = (state.taxable && (!state.taxAmount || !state.taxableAmount))
  let taxableHelperText = ''
  if (state.taxable && ((state.chosenLocales && state.chosenLocales.length < 1) || !state.chosenLocales)) taxableHelperText = 'If this is taxable, please choose the tax region(s), and the taxable amount for each region below:'
  const urlParts = props.url.split(':3001')
  const url = urlParts[0] + urlParts[1]
  // console.log('State762:: ', state)
  return (
    <Dialog maxWidth={'md'} fullWidth open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <CloneEstimateModal open={showCloneEstimateModal} estimateList={state.estimatesForCloning} updateParent={chooseEstimateToClone} url={props.url} />
    <form>
    <DialogTitle id="form-dialog-title">Edit Estimate</DialogTitle>
      <DialogContent>
        <TopRow>
          <TopRowItem align="start">
           <FollowingComponent data={props.estimate} type={'Estimate'} _id={props.estimate._id} />
            
          </TopRowItem>
          <TopRowItem align="end">
            <Tooltip title="Clone Estimate" >
            { loading ?<CircularProgress style={{color: '#ACCACD' }} size={ 16 } /> :
              <IconButton edge='end' onClick={handleCloneEstimateFromOtherEstimate} alt="Clone Estimate" aria-label="Clone Estimate">
                <FileCopyIcon />
              </IconButton>
            }
            </Tooltip>
          </TopRowItem>
        </TopRow>
        <DialogContentText>
              Please Make Your Edits:
            </DialogContentText>
       
        <ConfigurationContainer>
      
      <Grid container spacing={2}>
        {/* <Grid item lg={12} xs={12}>
          <LabelText>The following information will show up on the estimate, please type it up accordingly:</LabelText>
        </Grid> */}
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <TextField
              label="Name"
              variant="outlined"
              type="text"
              fullWidth
              value={state.name}
              onChange={e => setState({...state, name: e.target.value})}
              onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <Autocomplete
            id="JobSite"
            options={props.jobSites || ''}
            getOptionLabel={(option) => (formatOption(option) || '')}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            style={{marginBottom: 10, marginTop: 15}}
            onChange={updateJobSiteAddress}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {theJobSite || ''}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} variant="outlined" label="Job Site Address" />}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <Autocomplete
            id="BillingAddress"
            options={props.addys || ''}
            getOptionLabel={(option) => (formatOption(option) || '')}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            style={{marginBottom: 10, marginTop: 15}}
            onChange={updateBillingAddress}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {billingAddress || ''}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} variant="outlined" label="Billing Address" />}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <Autocomplete
            id="Associated Project"
            options={projectList || []}
            getOptionLabel={(option) => (formatOptionForProject(option) || '')}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            style={{marginBottom: 10, marginTop: 15}}
            onChange={updateProject}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {associatedProject || ''}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} variant="outlined" label="Project" />}
          />
          {
            (associatedProject && associatedProject._id === 'new') ? (
              <>
              <TextField
                label="Project Name"
                variant="outlined"
                className={classes.paddedStuff}
                type="text"
                value={state.projectName}
                onChange={e => setState({...state, projectName: e.target.value})}
                fullWidth
              />
              
              
            </>
            ) : null
          }
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <Autocomplete
              id={`salesperson`}
              options={props.salesReps || []}
              className={classes.paddedStuff}
              getOptionLabel={(option) => {
                // console.log('Options: ', option)
                return option.displayName || ''
              }}
              // style={{ width: 200 }}
              // style={{marginBottom: '20px'}}
              onChange={updateSalesRep}
              disabled={loading}
              // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
              value = {state.salesRep}
              // ref={inputRef.salesperson}
              // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
              renderInput={(params) => <TextField {...params} label="Salesperson" helperText="Who Is The Assigned Salesperson?" onKeyDown={handleTabInteraction} variant="outlined" />}
            />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <TextField
            fullWidth
            variant="outlined"
            select
            placeholder='Estimate Status'
            helperText="What is the Status of this Estimate?"
            // className={classes.paddedStuff}
            onChange={handleChangeStatus}
            style={{ textAlign: 'start' }}
            value={state.status}
            type="select">
             {
              state.statusOptions.map((item, index) => {

                return (
                  <MenuItem value={item} key={index}>{item}</MenuItem>
                )
              })
             }
            </TextField>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <ForecastInputs state={state} setState={setState} />
        </Grid>
        <Grid item xs={12} className={classes.taxSection}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.modifyButton}
            onClick={() => setShowTaxDetails(true)}
            disabled={props?.client?.nonTaxable}
            startIcon={<EditIcon />}
          >
            Modify Tax Details
          </Button>
          
          <div className={classes.taxItem}>
            <Typography className={classes.label}>Tax Locale</Typography>
            <Typography className={classes.value}>{estimate?.taxLocales?.length ? `${estimate.taxLocales[0].name}` : estimate?.taxLocale ? estimate.taxLocale : 'None'}</Typography>
          </div>
          
          <div className={classes.taxItem}>
            <Typography className={classes.label}>Taxable Amount</Typography>
            <Typography className={classes.value}>{estimate?.taxableAmount || 0}</Typography>
          </div>
          
          <div className={classes.taxItem}>
            <Typography className={classes.label}>Tax Amount</Typography> 
            <Typography className={classes.value}>{estimate?.taxAmount || 0}</Typography>
          </div>
        </Grid>
         <Grid item lg={12} md={12} sm={12} xs={12}>
          <TaxModule
            estimate={estimate}
            workorder={null}
            client={props.estimate?.client}
            open={showTaxDetails}
            configs={props.configs}
            closeModal={() => setShowTaxDetails(false)}
            onSubmit={handleUpdateTaxes}
          />
        </Grid>
        {/* <Grid item lg={3} md={4} sm={6} xs={12} >
            <FormGroup row>
          <FormControlLabel
            control={<Switch checked={state.taxable} onChange={handleChangeTaxable} name="Taxable" />}
            label="Taxable"
           
          />
       
        </FormGroup>
        </Grid> */}
        <Grid item lg={6} md={6} sm={6} xs={12} >
            
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12} >

        </Grid>
        {/* <Grid item lg={12} md={12} sm={12} xs={12} >
          {taxableHelperText}
        </Grid> */}
       
        {/* <Grid item lg={12} md={12} sm={12} xs={12} >
        { state.taxable ? (

        <FormControl fullWidth>
            <Autocomplete
              id={`taxLocale`}
              fullWidth
              options={taxLocales}
              onChange={handleUpdateUOM}
              // freeSolo
              // autoSelect
              multiple
              value={state.chosenLocales}
              // disableCloseOnSelect
              style={{marginTop: 25}}
              getOptionLabel={(option) => option.name}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Tax Locale(s)" placeholder="Taxable Area(s)" />
              )}
            />
           
        </FormControl>
         ) : null }
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <TaxLocaleDetails locs={state.chosenLocales} updateTaxStuff={updateTaxStuff} />
        </Grid> */}
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <FormGroup row>
            <FormControlLabel
              control={<Switch checked={state.summaryPricing}  onChange={handleChangePricingDetails} name="SummaryPricing" />}
              label="Hide Pricing Details"
            />
          </FormGroup>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <FormGroup row>
            <FormControlLabel
              control={<Switch checked={state.waiveDeposit}  onChange={handleWaiveDeposit} name="WaiveDeposit" />}
              label="Waive Deposit"
            />
          </FormGroup>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          { state.waiveDeposit ? null : <ChooseDepositComponent estimate={estimate} updateAmounts={updateAmounts} taxDetails={state.taxDetails} /> }
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <FormGroup row>
            <FormControlLabel
              control={<Switch checked={state.accepted}  onChange={handleManualAcception} name="AcceptEstimate" />}
              label="Accept Estimate"
            />
          </FormGroup>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          Estimate URL: 
          <Link
            style={{pointer: 'cursor'}}
            target="_blank"
                    to={{
                      pathName: `${url}/estimate/view/${estimate._id}/0%7302`
                    }}
                    >{`${url}/estimate/view/${estimate._id}/0%7302`}</Link>
        </Grid>
      </Grid>
    </ConfigurationContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
        <Button disabled={isInvalid} onClick={handleSaveEstimateUpdates} color="primary">
          Save
        </Button>
      </DialogActions>
    </form>
   </Dialog>
  )
}
const mapStateToProps = (state) => {
  // console.log('Map to Props Edit Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
    salesReps: state.salesReps
  }
}

export default connect(mapStateToProps)(EditEstimateModal)
