import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, IconButton, Button, TextField, List, ListItem, ListItemText, ListItemSecondaryAction, Chip, Typography, CircularProgress, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { Close as CloseIcon, Search as SearchIcon, CheckCircle as CheckCircleIcon, Clear as ClearIcon, ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const Title = styled('h2')({
  flexGrow: 1,
});

const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
  backgroundColor: theme.palette.background.paper,
  padding: 15,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
  width: '100%',
  // border: '1px solid #ccc',
  borderRadius: 15,
  padding: 10
}));

const ChooseEntityContainer = styled('div')(({ theme }) => ({
  margin: 10,
  width: '80%'
}))

const SearchBar = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  width: '75%',
  border: '1px solid #ccc',
  borderRadius: 15,
  padding: 10
}));

const EmployeeList = styled(List)(({ theme }) => ({
  height: 'calc(100% - 120px)',
  overflowY: 'auto',
}));

const StatusChip = styled(Chip)(({ theme, status }) => ({
  marginRight: theme.spacing(1),
  backgroundColor: status === 'checked-in' ? theme.palette.success.light : 
                   status === 'clocked-in' ? theme.palette.primary.light :
                   status === 'absent' ? theme.palette.error.light :
                   theme.palette.grey[300],
}));

const LoadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

const ClearButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

const TimesheetContainer = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
}));

const TimeclockModal = ({ open, onClose, employees, handleClockInOut, loading, url, notification, handleEditRow, user }) => {
  console.log('Timeclock modal', url)
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEmployees, setFilteredEmployees] = useState(employees);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [timesheet, setTimesheet] = useState([]);
  const [chosenEntity, setChosenEntity] = useState({
    _id: 'All',
    name: 'All Assigned'
  })
  // const [filtered]
  useEffect(() => {
    sortAndSetEmployees(employees);
  }, [employees]);

  const sortAndSetEmployees = (emps) => {
    const sorted = [...emps].sort((a, b) => a.lastName.trim().localeCompare(b.lastName.trim()));
    setFilteredEmployees(sorted);
  };

  const handleSearch = (event) => {
    const searchText = event.target.value.toLowerCase().trim();
    setSearchTerm(searchText);
    if (!searchText) {
      sortAndSetEmployees(employees);
    } else {
      const filtered = employees.filter(employee => 
        `${employee.firstName} ${employee.lastName}`.toLowerCase().includes(searchText)
      );
      sortAndSetEmployees(filtered);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    sortAndSetEmployees(employees);
  };
  
  const handleViewTimesheet = async (employee) => {
    setSelectedEmployee(employee);
    console.log('Fetch shifts', employee)
    // Fetch the last 10 shifts for the selected employee
    try {
      const response = await axios.get(`${url}/api/employees/getTimesheet?employeeId=${employee._id}&limit=10`);
      console.log('Times heet data', response.data)
      setTimesheet(response.data);
    } catch (error) {
      console.error('Error fetching timesheet:', error);
      notification({
        title: 'Failed to Find Shifts',
        message: `Unable to find any shifts for ${employee.firstName} ${employee.lastName}`,
        type: 'warning'
      })
      // Handle error (e.g., show an error message to the user)
    }
  };

  const calculateHoursWorked = (clockIn, clockOut) => {
    if (!clockIn || !clockOut) return 'N/A';
    const duration = moment.duration(moment(clockOut).diff(moment(clockIn)));
    return duration.asHours().toFixed(2);
  };

  const formatTime = (time) => {
    return time ? moment(time).format('HH:mm:ss') : 'N/A';
  };

  const handleUpdateVisibleEntity = (d) => {
    console.log('Update entity', d)
    if (d?.name === 'All Assigned') {
      setChosenEntity({
        ...d,
        _id: 'All'
      })
      sortAndSetEmployees(employees)
    } else {
      setChosenEntity(d)
      let entityEmps = employees.filter(emp => (emp.entity === d._id))
      console.log('Employees', employees)
      console.log('entityEmps', entityEmps)
      sortAndSetEmployees(entityEmps)
    }

  }

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <Header>
      <Title>
          {selectedEmployee ? (
            <IconButton color="inherit" onClick={() => setSelectedEmployee(null)}>
              <ArrowBackIcon />
            </IconButton>
          ) : null}
          {selectedEmployee ? `${selectedEmployee.firstName} ${selectedEmployee.lastName}'s Timesheet` : 'Employee Timeclock'}
        </Title>
        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Header>
      <DialogContent>
      {!selectedEmployee && (
        <HeaderContainer>
          <ChooseEntityContainer>
            <ChooseEntityComponent allowAll={true} title={'Visible Entities'} disabled={loading} user={user} updateParent={handleUpdateVisibleEntity} />       
          </ChooseEntityContainer>
         
          <SearchBar>
            <SearchIcon />
            <TextField
              fullWidth
              variant="standard"
              placeholder="Search employees..."
              value={searchTerm}
              onChange={handleSearch}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {searchTerm && (
                      <ClearButton
                        aria-label="clear search"
                        onClick={handleClearSearch}
                        edge="end"
                      >
                        <ClearIcon />
                      </ClearButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            </SearchBar>
            <ChooseEntityContainer>
              {filteredEmployees.length} Matching Employees
            </ChooseEntityContainer>
          </HeaderContainer>
        )}

      
        {loading ? (
          <LoadingContainer>
            <CircularProgress size={64} />
          </LoadingContainer>
        ) : selectedEmployee ? (
          <TimesheetContainer>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Check In</TableCell>
                    <TableCell>Clock In</TableCell>
                    <TableCell>Clock Out</TableCell>
                    <TableCell>Check Out</TableCell>
                    <TableCell>Hours Worked</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timesheet.map((shift) => (
                    <TableRow key={shift._id} onDoubleClick={() => handleEditRow(shift)}>
                      <TableCell>{moment(shift.created_at).format('YYYY-MM-DD')}</TableCell>
                      <TableCell>{formatTime(shift.checkIn)}</TableCell>
                      <TableCell>{formatTime(shift.clockIn)}</TableCell>
                      <TableCell>{formatTime(shift.clockOut)}</TableCell>
                      <TableCell>{formatTime(shift.checkOut)}</TableCell>
                      <TableCell>{calculateHoursWorked(shift.clockIn, shift.clockOut)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TimesheetContainer>
        ) : (
          <EmployeeList>
            {filteredEmployees.map((employee) => {
              const { name, crewInfo } = formatEmployeeName(employee);
              return (
                <ListItem key={employee._id} divider>
                  <ListItemText 
                    primary={<EmployeeName>{name}</EmployeeName>}
                    secondary={
                      <>
                        {employee.isCheckedIn && (
                          <StatusChip
                            size="small"
                            label="Checked In"
                            status="checked-in"
                            icon={<CheckCircleIcon />}
                          />
                        )}
                        {employee.isClockedIn && (
                          <StatusChip
                            size="small"
                            label="Clocked In"
                            status="clocked-in"
                            icon={<CheckCircleIcon />}
                          />
                        )}
                        {employee.absent && (
                          <StatusChip
                            size="small"
                            label="Absent"
                            status="absent"
                          />
                        )}
                        <CrewInfo>{crewInfo}</CrewInfo>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Button
                      variant="contained"
                      color={employee.isClockedIn ? "secondary" : "primary"}
                      onClick={() => handleClockInOut(employee)}
                      disabled={employee.absent}
                      style={{ marginRight: '8px' }}
                    >
                      {employee.isClockedIn ? 'Clock Out' : 'Clock In'}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleViewTimesheet(employee)}
                    >
                      View Timesheet
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </EmployeeList>
        )}
      
      </DialogContent>
    </Dialog>
  );
};

const EmployeeName = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
}));

const CrewInfo = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
}));

const formatEmployeeName = (employee) => {
  let name = `${employee.lastName}, ${employee.firstName}`;
  let crewInfo = '';
  if (employee?.currentCrew?.name) {
    crewInfo += employee.currentCrew.name;
    if (employee?.currentCrew?.foreman?.lastName) {
      crewInfo += ` (${employee.currentCrew.foreman.lastName})`;
    }
  }
  return { name, crewInfo };
};
export default TimeclockModal;