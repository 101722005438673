import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  headerCell: {
    fontWeight: 700,
    backgroundColor: theme.palette.grey[100],
  },
  quantityCell: {
    width: 120,
  },
  nameCell: {
    minWidth: 200,
  },
  optionCell: {
    minWidth: 250,
  },
  mismatchRow: {
    // backgroundColor: theme.palette.warning.light,
  }
}));

const WorkOrderSuppliesTable = ({ assignedSupplies = [], usedSupplies = [] }) => {
  const classes = useStyles();

  // Combine and normalize the data
  const combinedSupplies = React.useMemo(() => {
    const suppliesMap = new Map();

    // Add assigned supplies
    assignedSupplies.forEach(supply => {
      console.log('assigned supplye...', supply)
      const key = `${supply.itemId}-${supply.optionId}`;
      suppliesMap.set(key, {
        id: key,
        name: supply?.name || supply?.description,
        option: supply?.option || supply?.unit,
        assignedQuantity: supply?.quantity || supply?.qty || 0,
        usedQuantity: 0
      });
    });

    // Add/update used supplies
    usedSupplies.forEach(supply => {
      const key = `${supply.itemId}-${supply.optionId}`;
      const existing = suppliesMap.get(key);
      
      if (existing) {
        existing.usedQuantity = supply.quantity;
      } else {
        suppliesMap.set(key, {
          id: key,
          name: supply?.name || supply?.description,
          option: supply?.option || supply?.unit,
          usedQuantity: supply?.quantity || supply?.qty || 0,
          assignedQuantity: 0
        });
        // suppliesMap.set(key, {
        //   id: key,
        //   name: supply.name,
        //   option: supply.option,
        //   assignedQuantity: 0,
        //   usedQuantity: supply.quantity
        // });
      }
    });

    return Array.from(suppliesMap.values());
  }, [assignedSupplies, usedSupplies]);

  if (combinedSupplies.length === 0) {
    return (
      <Alert>
        <Typography>No supplies found for this work order.</Typography>
      </Alert>
    );
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="work order supplies table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={`${classes.headerCell} ${classes.nameCell}`}>Item Name</TableCell>
                <TableCell className={`${classes.headerCell} ${classes.optionCell}`}>Option</TableCell>
                <TableCell className={`${classes.headerCell}`}>Size</TableCell>
                <TableCell className={`${classes.headerCell} ${classes.quantityCell}`} align="right">Assigned</TableCell>
                <TableCell className={`${classes.headerCell} ${classes.quantityCell}`} align="right">Used</TableCell>
                <TableCell className={`${classes.headerCell}`}>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {combinedSupplies.map((supply) => {
                const quantityMismatch = supply.assignedQuantity !== supply.usedQuantity;
                
                return (
                  <TableRow 
                    key={supply.id}
                    className={quantityMismatch ? classes.mismatchRow : ''}
                  >
                    <TableCell className={classes.nameCell}>{supply.name}</TableCell>
                    <TableCell className={classes.optionCell}>{supply.option?.name || supply?.option || 'N/A'}</TableCell>
                    <TableCell>{supply.option?.size || supply?.option || 'N/A'}</TableCell>
                    <TableCell align="right">{supply.assignedQuantity}</TableCell>
                    <TableCell align="right">{supply.usedQuantity}</TableCell>
                    <TableCell>{supply.option?.notes || '-'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

const WorkorderSuppliesModal = ({ open, workorder, handleClose }) => {
  // console.log('WO supplies used...', workorder)
  const supplies = workorder?.supplies || []
  const suppliesUsed = workorder?.suppliesUsed || []
  return (
    <Dialog
      open={open}
      size={'lg'}
      onClose={handleClose}
      maxWidth={true}
      >
        <DialogTitle>Workorder Supplies</DialogTitle>
        <DialogContent>
          <WorkOrderSuppliesTable
            usedSupplies={suppliesUsed}
            assignedSupplies={supplies}
          />
        </DialogContent>
      </Dialog>
   
      
  )
}
export default WorkorderSuppliesModal;
