// ConfirmationContext.js
import React, { createContext, useContext, useCallback, useState, useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      minWidth: 320,
    },
  },
  actions: {
    padding: theme.spacing(2),
  },
}));

const ConfirmationContext = createContext({});

export const ConfirmationProvider = ({ children }) => {
  const classes = useStyles();
  const [state, setState] = useState({ open: false });
  const theme = useTheme();

  const openConfirmation = useCallback(({
    title,
    description,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    confirmColor = 'primary',
    onConfirm,
    maxWidth = 'sm'
  }) => {
    setState({
      open: true,
      title,
      description,
      confirmText,
      cancelText,
      confirmColor,
      onConfirm,
      maxWidth
    });
  }, []);

  const handleClose = useCallback(() => {
    setState({ open: false });
  }, []);

  const handleConfirm = useCallback(() => {
    if (state.onConfirm) {
      state.onConfirm();
    }
    handleClose();
  }, [state.onConfirm, handleClose]);

  const buttonStyle = useMemo(() => {
    if (!state.confirmColor) return {};
    
    // Use the theme palette color if it exists, otherwise fall back to primary
    const color = theme.palette[state.confirmColor] || theme.palette.primary;
    
    return {
      backgroundColor: color.main,
      color: color.contrastText,
      '&:hover': {
        backgroundColor: color.dark,
      }
    };
  }, [state.confirmColor, theme]);
  

  return (
    <ConfirmationContext.Provider value={{ openConfirmation }}>
      {children}
      <Dialog
        open={state.open}
        onClose={handleClose}
        className={classes.dialog}
        maxWidth={state.maxWidth}
        fullWidth
      >
        {state.open && (
          <>
            <DialogTitle>{state.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{state.description}</DialogContentText>
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Button onClick={handleClose} color="primary">
                {state.cancelText}
              </Button>
              <Button
                onClick={handleConfirm}
                style={buttonStyle}
                variant="contained"
                autoFocus
              >
                {state.confirmText}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </ConfirmationContext.Provider>
  );
};

// Custom hook to use the confirmation dialog
export const useConfirmation = () => {
  const context = useContext(ConfirmationContext);
  if (!context) {
    throw new Error('useConfirmation must be used within a ConfirmationProvider');
  }
  return context;
};
