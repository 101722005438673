import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetailList from './ServiceDetailList'
import InvoiceOptionsModal from '../Customer/InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import EditJobModal from '../Customer/EditJobModal'
// import DialogContentText from '@material-ui/core/DialogContentText';
import ProtectedButton from '../../actions/protectedButtons'
import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import MoneyIcon from '@material-ui/icons/AttachMoney';
import FollowingComponent from '../Common/FollowingComponent'
import { Link } from 'react-router-dom'
import WorkorderList from './WorkorderList'
import {
  Add as AddIcon,
} from '@material-ui/icons';
import PunchlistComponent from './PunchListModal'
import ProposalLineItems from './ProposalLineItems'
import WorkOrderPrintModal from '../Customer/WorkOrderPrintModal'
import JobHeaderSection from './JobHeaderSection'
import { useConfirmation } from '../Common/ConfirmationContext'
import TaxModal from '../Common/Taxes/TaxModule'

const calculateOverallProgress = (lineItems = [], invoices = []) => {
  console.log('Calculate our progress...', lineItems)
  console.log('invoices', invoices)
  const totals = lineItems.reduce((acc, item) => {
    const originalAmount = parseFloat(item.totalPrice) || 0;
    const originalQty = parseFloat(item.qty) || 0;
    const usedQty = 0 // calculateUsedQuantity(item._id, workorders);
    const invoicedAmount = calculateInvoicedAmount(item._id, invoices);

    return {
      totalAmount: acc.totalAmount + originalAmount,
      invoicedAmount: acc.invoicedAmount + invoicedAmount,
      totalQty: acc.totalQty + originalQty,
      allocatedQty: acc.allocatedQty + usedQty
    };
  }, { totalAmount: 0, invoicedAmount: 0, totalQty: 0, allocatedQty: 0 });

  return {
    invoiceProgress: (totals.invoicedAmount / totals.totalAmount) * 100,
    workorderProgress: (totals.allocatedQty / totals.totalQty) * 100,
    totalAmount: totals.totalAmount,
    invoicedAmount: totals.invoicedAmount,
  };
};

const calculateInvoicedAmount = (lineItemId, invoices = []) => {
  return invoices.reduce((total, invoice) => {
    const lineItem = invoice.estimateLineItems?.find(i => i._id === lineItemId);
    return total + (lineItem ? parseFloat(lineItem.invoiceAmount) || 0 : 0);
  }, 0);
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  }
}));

const formatLineItems = (lineItems) => {
  console.log('Format some line items')
  let formattedLineItems = []
  for (const item of lineItems) {
    console.log('Format it properly')
    let fixedType = item?.lineItemType?.toLowerCase().replace(/./, (x) => x.toUpperCase()).replace(/[^']\b\w/g, (y) => y.toUpperCase()) || 'Materials'
    console.log('Fixed type', fixedType)
    let properTotal = parseFloat(Number(item.qty * item.pricePer)).toFixed(2)
    let formattedLineItem = {
      ...item,
      type: fixedType,
      lineItemType: fixedType,
      totalPrice: properTotal
      // totalPrice: 'need',
      // lineI
    }
    formattedLineItems.push(formattedLineItem)
  }
  return formattedLineItems
}
// function htmlToText(html){
//   // console.log('Strip html tags: ', html)
//   //remove code brakes and tabs
//   html = html.replace(/\n/g, "");
//   html = html.replace(/\t/g, "");

//   //keep html brakes and tabs
//   html = html.replace(/<table>/g, "");
//   html = html.replace(/<tr>/g, "");
//   html = html.replace(/<td>/g, "");
//   html = html.replace(/<\/td>/g, "\t");
//   html = html.replace(/<\/table>/g, "\n");
//   html = html.replace(/<\/tr>/g, "\n");
//   html = html.replace(/<\/p>/g, "\n");
//   html = html.replace(/<\/div>/g, "\n");
//   html = html.replace(/<div>/g, "");
//   html = html.replace(/<\/h>/g, "\n");
//   html = html.replace(/<br>/g, "\n"); 
//   html = html.replace(/<br( )*\/>/g, "\n");
//   // console.log('Stripped: ', html)
//   //parse html into text
//   // var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
//   // return dom.body.textContent;
//   return html;
// }

const JobDetail = (props) => {
  // console.log('NEW JOB DETAIL MODAL: ', props)
  const classes = useStyles();
  const { openConfirmation } = useConfirmation();

  let servicesSelected = []

  const [loading, setLoading] = React.useState(false)
  const [job, setJob] = React.useState({})
  const [open, setOpen] = React.useState(false)
  // const [jobDetails, setJobDetails] = React.useState([])
  const [crewList, setCrewList] = React.useState(props.crewlist)
  const [serviceList, setServiceList] = React.useState(props.serviceList)
  const [state, setState] = React.useState({
    notes: ' ',
    description: ' ',
    serviceDescription: '',
    serviceNotes: '',
    crew: ' ',
    date: moment(Date.now()).format('YYYY-MM-DD'),
    time: ' ',
    price: ' ',
    job: {},
    pdf: '',
    editJobName: false,
    jobName: props.job.Job,
    showEditJobModal: false,
    workorders: [],
    invoices: [],
    activeWorkorder: {},
    punchlistItems: [],
    showInvoiceOptionsModal: false,
    pdf: null
  })
  const [showPunchListModal, setShowPunchListModal] = React.useState(false)
  const [pdf, setPdf] = React.useState({})
  const [printWOModalOpen, setPrintWOModalOpen] = React.useState(false)
  const [showTaxDetails, setShowTaxDetails] = React.useState(false)

  React.useEffect(() => {
    
    // console.log('Use Effect Job Details')
    setOpen(props.open)
  }, [props.open])

  React.useEffect(() => {
    // console.log('PROPS.job has changed')
    const getJobDetails = (job) => {
      // console.log('Get the Job Details here!!', job)
      if (job && job._id) {
       axios({
         method: 'get',
         url:  `${props.url}/api/customer/jobservicedetails?id=${job._id}&getPunchlist=true`,
         // data: data,
         // validateStatus: (status) => {
         //   // console.log('Validate status: ', status)
         //   if (status && status === 500) {
     
         //   }
         // },

       }).then(response => {
         console.log('JOB DETAILS!!', response.data)
        if (isMounted) {
          setState({...state, invoices: response.data?.invoices, workorders: response.data?.workorders, punchlistItems: response.data.punchlistItems })
        }
        //  // console.log('State: ', state)
       })
      }
    }

    let isMounted = true
    // console.log('Use Effect Job Details', job)
    if (props.job && props.job._id) {
      // if (!job._id)
      getJobDetails(props.job)
      setJob(props.job)
    } 
    // setOpen(props.open)
    setServiceList(props.serviceList)
    setCrewList(props.crewList)
    setState({...state, jobName: props.job.Job})
    servicesSelected = []
    return () => {
      isMounted = false
    }
  }, [props.job])
  

  function currencyFormat(num) {
    if (!num) num = 0
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

const handleClose = () => {
  // console.log('Closing this out - clear the crew and notes')
  // setState({...state, crew: ' ', notes: ' '})
  // setJob({
  //   Job: '',
  //   SiteName: '',
  //   address: ''
  // })
  // setJobDetails([])
  // setOpen(false)
  // // console.log(state)
  props.jobDetailsResponse('Close')
}

const handleConfirm = (data, callback) => {
  // console.log('CONFIRMED!!', data)
  if (data && data.updateType && (data.updateType === 'complete' || data.updateType === 'noInvoice')) {
    data.client = props.client
    data.user = props.user
    if (data.updateType === 'noInvoice') data.noInvoice = true
    // console.log('Complete this job!!')
    delete data.crewList
    axios({
      method: 'post',
      url:  `${props.url}/api/jobs/completeWorkorder`,
      data: data,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Axios response from COMPLETING WORKORDER: ', response.data)
      props.notification({
        type: 'success',
        title: 'Workorder Completed!',
        message: 'Congratulations - You did it!'
      })
      if (data && data.service && data.service.servicetype && data.service.servicetype.Value === '423') {
        // console.log('This is MAINTENANCE NO INVOICE')
      } else if (response.data && response.data.success) {
      // console.log('No invoice...')
        let cleanWOs = state.workorders
        let wo = response.data.workorder
        let index = cleanWOs.findIndex(item => (item._id === wo._id))
        if (index > -1) {
          cleanWOs[index] = response.data.workorder
          setState({...state, workorders: cleanWOs, showInvoiceOptionsModal: false}) 
        }

      } else {
        // console.log('Open window about INVOICING')
        if (response && response.data) {
          // console.log('Display oru PDF!')
          setState({...state, pdf: response.data, workorder: data.workorder, showInvoiceOptionsModal: true})
        } else {
          // console.log('WTF no pdf??')
          setState({...state, workorder: data.workorder, showInvoiceOptionsModal: true}) 
        }
      }
      // props.jobDetailsResponse(response)
      // setOpen(false)
    })
  } else {
    // console.log('Save this job')
    data.client = props.client
    data.project = props.project
    data.user = props.user
    axios({
      method: 'post',
      url:  `${props.url}/api/jobs/updateWorkorder?lineItemStyle=true`,
      data: data,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Axios response: ', response)

      // IF this was new we need to add our WorkNumber
      if (data.service && data.service.number) {
        // console.log('Existin job - dont need to do anything')
        return callback(response)
      } else {
        // console.log('New job -- add the number we got back')
        if (response && response.data && response.data._id) {
          let num =response.data.number
          // console.log('Our new WORK NUM', num)
          props.notification({
            type: 'success',
            title: 'Job Saved!',
            message: `Workorder #${num} has been created.`
          })
          return callback(response.data)
        }
      }
      // props.jobDetailsResponse(response)
      // setOpen(false)
    })
  }
}

const generateAPurchaseOrder = async (data) => {
  console.log('Generate a generateAPurchaseOrder on the backend', data)
  setLoading(true)
  // console.log('invoice these: ', servicesSelected)
  let updateObject = {
    job,
    data,
    updateType: 'createPurchseOrder'
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/jobs/updateWorkorder?lineItemStyle=true`,
    data: updateObject,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    setLoading(false)
    console.log('Axios response to create NEW PO 373: ', response)
    props.notification({
      type: 'success',
      title: 'Purchase Order Generated',
      message: 'We Have Created That PO!'
    })
    if (response?.data?.workorder) {
      const newWO = response.data.workorder
      console.log('Here is our workorder', response.data.workorder)
      console.log('670 - we got a workorder!!!', newWO)
      setState(prev => ({...prev, activeWorkorder: newWO, workorders: [...prev.workorders, newWO] }))
    }
  }).catch(err => {
    console.log('387 error creating the workorder', err)
    props.notification({
      type: 'error',
      title: 'Purchase Order Generation Failed',
      message: 'We Were Unable to Create That Purchase Order!'
    })
  })
}

const generateAWorkorder = async (wo) => {
  console.log('Generate a Workorder on the backend', wo)
  setLoading(true)
  // console.log('invoice these: ', servicesSelected)
  let updateObject = {
    job,
    workorder: wo,
    updateType: 'createWorkorder'
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/jobs/updateWorkorder?lineItemStyle=true`,
    data: updateObject,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    setLoading(false)
    console.log('Axios response to create NEW WORKORDER 373: ', response)
    props.notification({
      type: 'success',
      title: 'Workorder Generated',
      message: 'We Have Created That Workorder!'
    })
    if (response?.data?.workorder) {
      const newWO = response.data.workorder
      console.log('Here is our workorder', response.data.workorder)
      console.log('670 - we got a workorder!!!', newWO)
      setState(prev => ({...prev, activeWorkorder: newWO, workorders: [...prev.workorders, newWO] }))
    }
  }).catch(err => {
    console.log('387 error creating the workorder', err)
    props.notification({
      type: 'error',
      title: 'Workorder Generation Failed',
      message: 'We Were Unable to Create That Workorder!'
    })
  })
  
}

const generateAnInvoice = async (data) => {
  console.log('Generate a Workorder on the backend', data)
  setLoading(true)
  // console.log('invoice these: ', servicesSelected)
  let updateObject = {
    job,
    invoiceData: data,
    updateType: 'createInvoice'
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/jobs/updateWorkorder?lineItemStyle=true`,
    data: updateObject,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    setLoading(false)
    console.log('Axios response to create NEW INVOICE 419: ', response)
    props.notification({
      type: 'success',
      title: 'Invoice Generated',
      message: 'We Have Created That Invoice!'
    })
    if (response?.data?.length) {
      setState({...state, pdf: response.data, showInvoiceOptionsModal: true})
    }
    if (response?.data?.invoice) {
      const newInvoice = response.data.invoice
      console.log('Here is our invoice', response.data.invoice)
      console.log('428 - we got a invoice!!!', newInvoice)
      setState(prev => ({...prev, invoices: [...prev.invoices, newInvoice] }))
    }
  }).catch(err => {
    console.log('432 error creating the invoice', err)
    props.notification({
      type: 'error',
      title: 'Invoice Generation Failed',
      message: 'We Were Unable to Create That Invoice!'
    })
  })  
}

const updateJobScheduledDate = (wo) => {
  // console.log('Update Job Start Date based on this: ', wo)
  let newJob = job
  newJob.startDate = wo.scheduledDate
  // console.log('New Job: ', newJob)
  setJob(newJob)
}

const updateOurJob = (data, updateType, callback) => {
  // console.log('Update the job from Job Details; ', data)
  updateJobScheduledDate(data.workorder)
  // console.log(servicesSelected)
  // console.log('Type of Update:', updateType)
  if (updateType === 'select') {
    servicesSelected.push(data)
  } else if (updateType === 'unselect') {
    // Remove item from selected list
    let index = servicesSelected.findIndex(item =>  (parseInt(item['number']) === parseInt(data['number'])))
    // console.log('Index of item to remvove from serices selected: ', index)
    servicesSelected.splice(index, 1)
  } else {
      // Find this job and update it...
    // console.log(state)
    // console.log(job)
    let updateObject = {
      job,
      workorder: data,
      updateType: updateType
    }
    handleConfirm(updateObject, (num) => {
      // console.log('Got a new workorder num', num)
      // console.log(state)
      // console.log(job)
      return callback(num)
    })
  }

}
const updateFromInvoiceModal = (d) => {
  // console.log('Close invoice modal', d)
  if (d === 'approved') props.jobDetailsResponse('update')
  setState({...state, showInvoiceOptionsModal: false})
}

const handleInvoiceSelected = () => {
  if (servicesSelected.length > 0) {
    setLoading(true)
    // console.log('invoice these: ', servicesSelected)
    let updateObject = {
      job,
      service: servicesSelected,
      updateType: 'invoiceGroup'
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/customer/groupInvoice`,
      data: updateObject,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {

      //   }
      // },
    }).then(response => {
      setLoading(false)
      // console.log('Axios response to bulk invoice: ', response)
      if (response && response.data) {
        // console.log('Display oru PDF!')
        setState({...state, pdf: response.data, showInvoiceOptionsModal: true})
      } else {
        // console.log('WTF no pdf??')
        setState({...state, showInvoiceOptionsModal: true}) 
      }
      props.notification({
        type: 'success',
        title: 'Invoice Generated',
        message: 'We Have Created That Invoice!'
      })
    })
  } else {
    // console.log('No services selected')
    props.notification({
      type: 'warning',
      title: 'Oopsie',
      message: 'You Must Select Services To Invoice First'
    })
  }
}

const saveJob = (newJob) => {
  // console.log('Save this job: ', newJob)
}
const offerJobEditOptions = () => {
  // console.log('Offer ability to edit job now')
  setState({...state, editJobName: true})
}
const saveJobName = () => {
  // console.log('Save the job nam')
  let newJob = job
  newJob.Job = state.jobName
  setJob(newJob)
  setState({...state, editJobName: false})
  saveJob(newJob)
}
const updateJobName = (e) => {
  // console.log('Update Job Name: ', e)
  let name = e.target.value
  // console.log('Change to ', name)
  setState({...state, jobName: name})
}
const JobName = (props) => {
  // console.log('PROPS JOBNAME: ', props)
  if (props?.job?.estimate) {
    return (
      <span>
         {job.name} &nbsp;&nbsp;&nbsp;
        {/* <a href={`/clients/${props.job.client}/Estimates/${props.job.estimate}`}>{job.name}</a> &nbsp;&nbsp;&nbsp; */}
        <Button
        variant="contained"
        // style={{backgroundColor: '#ed4545'}}
        className={classes.button}
        size="small"
        // color="primary"
        startIcon={<EditRoundedIcon />}
        // onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteService() } }
        // onClick={handleDeleteService}
        // onClick={offerJobEditOptions}
        onClick={ () => { setState({...state, showEditJobModal: true})} }
        >
        </Button>
        <Tooltip
          title="View Estimate">
            <Link 
              style={{pointer: 'cursor'}}
              target="_blank"
              to={{
                    pathname: `/clients/${props.job.client}/Estimates/${props.job.estimate}`,
                  }}>
                View Estimate                
            </Link>
          </Tooltip>
       
      </span>
    )
  } else {
    return (
      <span>{job.name} &nbsp;&nbsp;&nbsp;
      <Button
      variant="contained"
      // style={{backgroundColor: '#ed4545'}}
      className={classes.button}
      size="small"
      // color="primary"
      startIcon={<EditRoundedIcon />}
      // onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteService() } }
      // onClick={handleDeleteService}
      // onClick={offerJobEditOptions}
      onClick={ () => { setState({...state, showEditJobModal: true})} }
      >
      </Button>
      </span>
    )
  }

}
const handleDelete = () => {
  if (window.confirm('Are you sure you wish to delete this Job and ALL associated Work Orders?')) {
    // console.log('Delete this workorder!!', job)
    let ourURL =  `${props.url}/api/customer/deleteWorkorder?id=${job._id}`
    // console.log('URL to send to: ', ourURL)
    axios({
      method: 'get',
      url: ourURL,
      // data: data,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {

      //   }
      // },
    }).then(response => {
      // console.log('REsponse from deleting workorder: ', response)
      if (response && response.data && response.data.deletedCount && response.data.deletedCount > 0) {
        props.notification({
          type: 'success',
          title: 'Work Order Deleted!',
          message: 'This Work Order Was Deleted'
        })
        props.jobDetailsResponse('Delete', job.number)
      } else {
        props.notification({
          type: 'warning',
          title: 'Failed To Delete Work Order!',
          message: response.data.Message
        })
      }
      
    }).catch(err => {
      console.log('Error deleting jobs', err)
      props.notification({
        type: 'warning',
        title: 'Failed To Delete Work Order!',
        message: err.message
      })
    })
  }
}
const updateFromEditJobModal = (d, l) => {
  // console.log('updateFromEditJobModal', d)
  if (d === 'Edited') {
    // console.log('Updated our job name - lets go ahead and use the one from there', l)
    // let newJob = job
    // newJob.Job = l
    // console.log('New Job to Save: ', newJob)
    setJob(l)
  }
  setState({...state, showEditJobModal: false})
}
const updateInvoiceStatus = () => {
  // do nothing
  return null
}

const handleInvoiceEntireJob = () => {
  axios({
    method: 'post',
    url:  `${props.url}/api/jobs/invoiceEntireJob`,
    data: {
      jobId: job._id
    },
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response from COMPLETING WORKORDER: ', response)
    props.notification({
      type: 'success',
      title: 'Invoice Created!',
      message: 'Congratulations - You did it!'
    })
  })
}

const handleViewPunchlist = (d) => {
  console.log('View punchlist', d)
  setState(prev => ({...prev, activeWorkorder: d }))
  // fetchPunchListItems(d?._id)
  setTimeout(() => {
    setShowPunchListModal(true)
  }, 200)
 
}
  let matchingDeposits = []
  // props.deposits.forEach(dep => {
  //   // if (dep.jobNumber)
  // })
  // console.log('Props.deposits')
  // for (const dep of props.deposits) {
  //   console.log(dep)
  // }
  const handleAddService = () => {
    console.log('Add Service!!')
    let newJobDetails = {
      description: '',
      serviceDescription: '',
      serviceNotes: '',
      notes: '',
      crew: '',
      date: '',
      time: '',
      name: '',
      servicetype: '',
      number: 'TBD'
    }
    setState(prev => ({...prev, activeWorkorder: newJobDetails, workorders: [...prev.workorders, newJobDetails] }))
  }
const handleClosePunchListModal = () => {
  setShowPunchListModal(false)
}

const handleCreateInvoice = async (data) => {
  console.log('Create invoice', data)
  const {
    items,
    name,
    total,
    notes,
    date
  } = data
  // Handle creating workorder with selected items
  console.log('Creating invoice with:', data);
  console.log('price', total)
  // console.log('Add Service!!')
  const formattedItems = await formatLineItems(items)
  console.log('Formatted items for invoice??', formattedItems)
  let invoiceDetails = {
    items,
    total,
    date,
    notes,
    estimate: job?.estimate || null,
    lineItems: formattedItems,
    job: job?._id || null
  }
  console.log('Create new invoiceDetails:', invoiceDetails)
  generateAnInvoice(invoiceDetails)
}

const addNewWO = () => {
  let newWODetails = {
    description: '',
    serviceDescription: '',
    workorderNotes: '',
    serviceNotes: '',
    notes: '',
    crew: '',
    date: '',
    time: '',
    name,
    serviceType: null,
    number: 'TBD',
    price: 0,
    estimate: job?.estimate || null,
    lineItems: [],
    job: job?._id || null,
    isTandM: true
  }
  console.log('Create new workorder 781:', newWODetails)
  generateAWorkorder(newWODetails)
}

const handleCreateWorkorder = async (data) => {
  const {
    items,
    name,
    total,
    notes,
    serviceNotes,
    serviceType,
    workorderNotes,
    description,
    serviceDescription
  } = data
  // Handle creating workorder with selected items
  console.log('Creating workorder with:', data);
  console.log('price', total)
  console.log('Add Service!!')
  const formattedItems = await formatLineItems(items)
  console.log('Formatted items', formattedItems)
  let newWODetails = {
    description,
    serviceDescription,
    workorderNotes,
    serviceNotes,
    notes: 'Created from Proposal Line Items 629',
    crew: '',
    date: '',
    time: '',
    name,
    serviceType,
    number: 'TBD',
    price: total,
    estimate: job?.estimate || null,
    lineItems: formattedItems,
    job: job?._id || null,
    isTandM: false
  }
  console.log('Create new workorder:', newWODetails)
  generateAWorkorder(newWODetails)
  // console.log('670 - we got a workorder!!!', newWO)
  // setState(prev => ({...prev, activeWorkorder: newWO, workorders: [...prev.workorders, newWO] }))

};

const handleDeleteWorkorder = (wo) => {
  confirmDeletion(wo)
  // openConfirmation({
  //   title: 'Delete Item',
  //   description: `Are you sure you want to delete Workorder #${wo.number}? This is not reversible.`,
  //   confirmText: 'Delete',
  //   confirmColor: 'danger',
  //   onConfirm: () => {
  //     console.log('Delete this bitch...')
  //     confirmDeletion(wo)
  //   }
  // });
}

const confirmDeletion = async (wo) => {
    console.log('Delete this one:', wo)
    let ourURL =  `${props.url}/api/customer/deleteServices`
    // console.log('URL to send to: ', ourURL)
    const data = {
      workorder: wo
    }
    axios({
      method: 'post',
      url: ourURL,
      data: data,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {

      //   }
      // },
    }).then(response => {
      console.log('REsponse from deleting workorder: ', response)
      if (response && response.data && response.data.deletedCount && response.data.deletedCount > 0) {
        props.notification({
          type: 'success',
          title: 'Work Order Deleted!',
          message: 'This Work Order Was Deleted'
        })
        // props.jobDetailsResponse('Delete', job.number)
        let newWOs = [...state.workorders]
        let index = newWOs.findIndex(item => (item._id === wo._id))
        console.log('Index...', index)
        if (index > -1) {
          newWOs.splice(index, 1)
          setState(prev => ({...prev, activeWorkorder: null, workorders: newWOs }))
        }
        
      } else {
        props.notification({
          type: 'warning',
          title: 'Failed To Delete Work Order!',
          message: response.data.Message
        })
      }
      
    }).catch(err => {
      console.log('Error deleting jobs', err)
      props.notification({
        type: 'warning',
        title: 'Failed To Delete Work Order!',
        message: err.message
      })
    })
}

const handlePrintWorkorder = (wo) => {
  console.log('Print it!!', wo)
  axios({
    method: 'post',
    url:  `${props.url}/api/jobs/printWorkOrder?cavStyle=true`,
    data: {
      workorder: wo,
      job: job,
      client: props.client
    }
  }).then(response => {
    // console.log('Axios response from printing: ', response)
    // let ourPDF = encodeURIComponent(response.data)
    // // console.log(response.data)
    if (response && response.data) {
      setPdf(response.data)
      setPrintWOModalOpen(true)
    }
  })
}

const handleWOUpdates = (updatedWO) => {
  console.log('Save thsi shit to teh backend ', updatedWO)
    setState(prev => ({
    ...prev,
    workorders: prev.workorders.map(wo => 
      wo.number === updatedWO.number ? updatedWO : wo
    )
  }))
  updateWorkorderInDB({...updatedWO, job: job?._id })
}

const updateWorkorderInDB = async (wo) => {
  const data = {
    client: props.client,
    updateType: 'updateWorkorder',
    job: wo.job,
    // project: props?.project,
    // user: props.user,
    workorder: wo
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/jobs/updateWorkorder?lineItemStyle=true`,
    data: data,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    console.log('Axios response: ', response)
    if (response?.data?._id || response?.data?.workorder?._id) {
      let newWO = response.data?.workorder?._id ? response.data.workorder : response.data
      if (newWO?.crew?._id) {

      } else {
        let crew = props.crews.find(item => (item._id === newWO.crew))
        // console.log('Crew:', crew)
        if (crew?._id) {
          let crewName = `${crew?.name}${crew?.foremanName ? ` (${crew.foremanName})` : ''}`
          newWO.crew = crew
          newWO.crewName = crewName
        }
      }
      if (newWO?.vendor?._id) {

      } else {
        let vendor = props.vendors.find(item => (item._id === newWO.vendor))
        if (vendor?._id) {
          newWO.vendor = vendor
        }
      }
      console.log('Set this as our Active Workorder', newWO)
      setState(prev => ({
        ...prev,
        workorders: prev.workorders.map(wo => 
          wo.number === newWO.number ? newWO : wo
        ),
        activeWorkorder: newWO
      }))
      props.notification({
        type: 'success',
        title: 'Workorder Saved!',
        message: `Workorder #${wo?.number} has been updated.`
      })
    }
  }).catch(err => {
    console.log('Error updating...', err)
    props.notification({
      type: 'warning',
      title: 'Failed to Save Workorder!',
      message: `Workorder #${wo.number} has failed to save.`
    })
  })
}
const handleWOCompletion = (wo, type) => {
  console.log('Complete this', wo)
  console.log('type', type)
}

const handleEditTaxes = (wo) => {
  console.log('hOpen Edit Taxes modal', wo)
  console.log('Activew o', state.activeWorkorder)
  setState(prev => ({...prev, activeWorkorder: wo }))
  setShowTaxDetails(true)
}
const responseFromWOPrint = (d) => {
  console.log('Repsonse form wo print..', d)
  setPrintWOModalOpen(false)
}
const handleUpdateTaxes = (d) => {
  setShowTaxDetails(false)
  console.log('Tax Details to save', d)
  handleWOUpdates({
    ...state.activeWorkorder,
    ...d
  })
}

const handleCreatePO = (data) => {
  console.log('Create a Purchase order', data)
  console.log('state', state)

  const poFormattedData = {
    ...data,
    notes: data?.supplierNotes,
    amount: data?.total,
    // workorder: data?.workorder?._id,
    project: job?.project,
    job: job?._id,
    jobName: job?.name,
    client: props?.client?._id
  }
  console.log('Build it', poFormattedData)
  generateAPurchaseOrder(poFormattedData)
}

const handleCloseWODetails = () => {
  setState(prev => ({...prev, activeWorkorder: null }))
}

const handleExportLineItems = (wo) => {
  console.log('Export line items...', wo)
  // setState(prev => ({...prev, activeWorkorder: wo }))
  axios({
    method: 'post',
    url:  `${props.url}/api/jobs/exportToCSV`,
    data: wo,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Export response: ', response.data)
    let newBlob = new Blob([response.data], {type: "text/csv"})
    // console.log(newBlob)
    const data = window.URL.createObjectURL(newBlob);
    // console.log(data)
    var link = document.createElement('a');
    link.href = data;
    let fileName = 'workorderLineItemsList' // state.workorder..replace(/[^a-z0-9]/gi, '_').toLowerCase()
    link.download=`${fileName}.csv`;
    link.click();
  })
}

let filteredPunchlistItems = state.punchlistItems?.length ? state.punchlistItems.filter(item => (item.workorder === state?.activeWorkorder?._id)) : []
const progress = calculateOverallProgress(job?.proposalLineItems, state?.invoices || [] );
console.log('progress', progress)
console.log('State 1001', state)
return (
  <div className={classes.root}>
     <PunchlistComponent
      client={props.client}
      workorder={state.activeWorkorder}
      punchlistItems={filteredPunchlistItems} 
      closeModal={handleClosePunchListModal}
      uploads={[]}
      open={showPunchListModal}
    />
    <TaxModal
      workorder={state.activeWorkorder}
      client={props.client}
      open={showTaxDetails}
      closeModal={() => setShowTaxDetails(false)}
      onSubmit={handleUpdateTaxes}
    />
     <WorkOrderPrintModal pdf={pdf} updateParent={responseFromWOPrint} open={printWOModalOpen} />
    <EditJobModal client={props.client} projects={props.projects} jobSites={props.jobSites} job={job} jobDetailsResponse={updateFromEditJobModal} open={state.showEditJobModal} />
    <InvoiceOptionsModal client={props.client} updateInvoiceStatus={updateInvoiceStatus} workorder={state.workorder} pdf={state.pdf} jobDetailsResponse={updateFromInvoiceModal} open={state.showInvoiceOptionsModal} />
    {/* <Button size="small" variant="contained" color="secondary" onClick={() => { handleOpen() }}>Add Location</Button> */}
    <Dialog
      // fullScreen={true}
      maxWidth='xl'
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Job Details ({job.name} - {job.number})</DialogTitle>
      <DialogContent> 
            <Grid container className={classes.grid}>
              <Grid item lg={12} style={{position: 'absolute', right: '50px', top: '20px'}}>
                <ProtectedButton type="Jobs" kind="delete" perms={props.perms || []}>
                  <Button onClick={handleDelete}>
                    Delete Job
                  </Button>
                </ProtectedButton>
              </Grid>
              <JobHeaderSection 
                job={job} 
                FollowingComponent={FollowingComponent} 
                JobName={JobName} 
                progress={progress}
                currencyFormat={currencyFormat}
              />

             
              <Grid item lg={12} xs={12} >
                {
                  (job && job.proposalLineItems && job.proposalLineItems.length) ?  
                  <ProposalLineItems
                    lineItems={job.proposalLineItems}
                    workorders={state.workorders}
                    invoices={state.invoices}
                    vendors={props.vendors}
                    onCreateWorkorder={handleCreateWorkorder}
                    onCreateInvoice={handleCreateInvoice}
                    serviceTypes={props.services}
                    progress={progress}
                    takeOffList={props.takeOffList}
                    onCreatePO={handleCreatePO}
                  /> : null
              }
               

              </Grid>
             
            </Grid>
            {
              (job && job.proposalLineItems && job.proposalLineItems.length) ? null : 
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddService}
              >
                Add Workorder
              </Button>
            }
            <WorkorderList
              client={props.client}
              crews={props.crews}
              vendors={props.vendors}
              url={props.url}
              uploads={props.uploads}
              workorders={state.workorders}
              punchlistItems={state.punchlistItems}
              projects={props.projects}
              updateOurJob={updateOurJob}
              job={job}
              crewList={crewList}
              serviceList={serviceList}
              onViewPunchlist={handleViewPunchlist}
              taxLocales={props.taxLocales}
              activeWorkorder={state.activeWorkorder} // Pass this instead of activeWO
              onWorkorderChange={handleWOUpdates}
              onCompletionChange={handleWOCompletion}
              addNewWOLineItem={addNewWO}
              user={props.user}
              takeOffList={props.takeOffList}
              onEditTaxes={handleEditTaxes}
              onCreatePO={handleCreatePO}
              onCloseWODetails={handleCloseWODetails}
              onExportLineItems={handleExportLineItems}
              // onWorkorderChange={(updatedWorkorder) => {
              //   setState(prev => ({
              //     ...prev,
              //     workorders: prev.workorders.map(wo => 
              //       wo.number === updatedWorkorder.number ? updatedWorkorder : wo
              //     ),
              //     activeWorkorder: null // Clear active workorder after update
              //   }))
              // }}
              serviceTypes={props.services}
              onDeleteWorkorder={handleDeleteWorkorder}
              onPrintWorkorder={handlePrintWorkorder}
            />
              
            {/* <ServiceDetailList client={props.client} uploads={props.uploads} workorders={state.workorders} project={props.project} updateOurJob={updateOurJob} job={job} crewList={crewList} serviceList={serviceList} taxLocales={props.taxLocales} /> */}
    </DialogContent>
    <DialogActions>
    {/* <Button disabled={loading} onClick={handleInvoiceSelected} color="primary">
      { loading ? <CircularProgress color="primary" size={ 36 } /> : 'Invoice Selected'} 
    </Button> */}
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        {/* <Button onClick={ handleConfirm } color="primary">
          Save
        </Button> */}
      </DialogActions>
  </Dialog>
  </div>
  )
  }

  // const ProposalLineItems = ({ job }) => {

  //   return (
  //     <div>
  //      Line ITems From Proposal: {job.proposalLineItems.length || 0}
  //      <div style={{ maxHeight: 400, maxWidth: 500, overflowY: 'scroll' }}>
  //       {JSON.stringify(job.proposalLineItems)}
  //       </div> 
  //     </div>
  //   )
  // }

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      perms: state.perms,
      salesReps: state.salesReps,
      services: state.services,
      crews: state.crews,
      vendors: state.vendors
    }
  }
  
  export default React.memo(connect(mapStateToProps)(JobDetail))
