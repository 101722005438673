import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography
} from '@material-ui/core';

const EstimateConversionModal = ({ open, onClose, onConfirm }) => {
  const [workorderType, setWorkorderType] = useState('manual');

  const handleConfirm = () => {
    onConfirm(workorderType);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Convert Estimate to Job</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Are you sure you want to convert this estimate to a job? 
          This action cannot be undone.
        </Typography>
        
        {/* <Typography variant="subtitle1" style={{ marginTop: 16 }}>
          Workorder Creation
        </Typography>
        <RadioGroup
          value={workorderType}
          onChange={(e) => setWorkorderType(e.target.value)}
        >
         
          <FormControlLabel
            value="manual"
            control={<Radio color="primary" />}
            label="Manually Assign Line Items to Work Orders"
          />
           <FormControlLabel
            value="single"
            control={<Radio color="primary" />}
            label="Convert to Single Work Order (Old School)"
          />
        </RadioGroup> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Convert to Job
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EstimateConversionModal;
