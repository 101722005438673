import React, { useMemo } from 'react';
import { 
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
  makeStyles,
  TextField,
  Button
} from '@material-ui/core';
import { useTax } from '../../../hooks/taxContext';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 800,
    // width: '80%'
    // margin: 'auto',
  },
  table: {
    marginBottom: theme.spacing(3),
  },
  summaryRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  radioGroup: {
    marginTop: theme.spacing(3),
  },
  taxableAmount: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  tableBodyContainer: {
    maxHeight: '30vh',
    overflow: 'auto',
    // backgroundColor: 'red',
    '& table': {
      tableLayout: 'fixed'
    }
  },
  table: {
    width: '100%'
  },
}));

const TaxCalculator = ({ workorder, open = false, closeModal, client, onSubmit, estimate, configs }) => {
  const classes = useStyles();
   const { calculateTaxableAmount } = useTax();

  const [selectedOption, setSelectedOption] = React.useState('option1');
  const [taxLocales, setTaxLocales] = React.useState([])
  const [chosenLocale, setChosenLocale] = React.useState(null)  
  const [taxDetails, setTaxDetails] = React.useState(null)

  const lineItems = workorder?.lineItems || estimate?.lineItems || []
  console.log('Tax Calculator line itesm', lineItems)

  React.useEffect(() => {
    if (workorder?._id) {
      if (workorder?.taxLocales?.length) setChosenLocale(workorder.taxLocales[0])
      let obj = { estimate, workorder, client, lineItems, fetchLocales: true, selectedOption, chosenLocale }
      recalculateOurTotals(obj)
    }
  }, [workorder?._id])

  React.useEffect(() => {
    if (estimate?._id) {
      if (estimate?.taxLocales?.length) setChosenLocale(estimate.taxLocales[0])
      let obj = { estimate, workorder, client, lineItems, fetchLocales: true, selectedOption, chosenLocale }
      recalculateOurTotals(obj)
    }
  }, [estimate?._id])

  
    // Calculate totals
    const calculations = useMemo(() => {
      const totalCost = lineItems.reduce((sum, item) => {
        // console.log('Sum the line items...', item, sum)
        if (item.totalPrice) {
          sum += Number(item.totalPrice)
        } else {
          sum += (Number(item?.qty || 0) * Number(item?.pricePer || 0))
        }
        // console.log('SUM::', sum)
        return sum
      }, 0);
      const materialsCost = lineItems
        .filter(item => item?.lineItemType?.toLowerCase() === 'materials')
        .reduce((sum, item) => {
          // console.log('Sum the materials...', item, sum)
          if (item.totalPrice) {
            sum += Number(item.totalPrice)
          } else {
            sum += (Number(item?.qty || 0) * Number(item?.pricePer || 0))
          }
          // console.log('SUM::', sum)
          return sum
        }, 0);
        // console.log('Total: %d ', totalCost, materialsCost)
      const option1Amount = totalCost * 0.65; // 65% of total
      const option2Amount = materialsCost; // 100% of materials
  
      return {
        totalCost,
        materialsCost,
        option1Amount,
        option2Amount
      };
    }, [lineItems]);

  const recalculateOurTotals = async (obj) => {
   
    const taxDetailsData = await calculateTaxableAmount(obj)
    console.log('Got our tax details!!!', taxDetailsData)
    setTaxDetails(taxDetailsData)
    if (taxDetailsData?.fetchedLocales?.length) {
      setTaxLocales(taxDetailsData.fetchedLocales)
    }
  }

  const handleOptionChange = async (event) => {
    console.log('Update tax stuff', taxLocales)
    setSelectedOption(event.target.value);
    console.log('Caluclations', calculations)
    let newValue = event.target.value
    let obj = { estimate, workorder, client, lineItems, fetchLocales: true, selectedOption: newValue, chosenLocale }
    recalculateOurTotals(obj)
    // const taxDetailsData = await calculateTaxableAmount(obj)
    // console.log('Got our tax details on option change!!!!!', taxDetailsData)
    // setTaxDetails(taxDetailsData)
  };

  const handleUpdateChosenLocale = (e, d) => {
    console.log('Lets use this one', d)
    setChosenLocale(d)
    let obj = { estimate, workorder, client, lineItems, fetchLocales: false, selectedOption, chosenLocale: d }
    recalculateOurTotals(obj)
  }

  const handleSave = () => {
    console.log('Save our tax data...', taxDetails)
    let taxDetailsToReturn = {
      ...taxDetails,
      taxLocales: [chosenLocale]
    }
    onSubmit(taxDetailsToReturn)
  }

  const taxableAmount = selectedOption === 'option1' 
    ? calculations.option1Amount 
    : calculations.option2Amount;
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const taxAmount = taxableAmount > 0 ? Number(taxableAmount) * (chosenLocale?.rate || 0) : 0
  return (
    <Dialog
      size={'lg'}
      fullWidth
      open={open}
      onClose={closeModal}
    >
      <DialogTitle>Tax Detail</DialogTitle>
      <DialogContent>
    <Card className={classes.root}>
      <CardHeader 
        title="Tax Calculation" 
        className={classes.cardHeader}
      />
      <CardContent className={classes.cardContent}>
      <div className={classes.tableBodyContainer}>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell align="right">Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody >
            {lineItems?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell align="right">
                  ${parseFloat(item?.totalPrice)?.toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
        <Paper elevation={0}>
          <div className={classes.summaryRow}>
            <Typography variant="subtitle1">Total Cost:</Typography>
            <Typography variant="subtitle1">
              ${calculations?.totalCost?.toFixed(2)}
            </Typography>
          </div>
          <div className={classes.summaryRow}>
            <Typography variant="subtitle1">Materials Cost:</Typography>
            <Typography variant="subtitle1">
              ${calculations?.materialsCost?.toFixed(2)}
            </Typography>
          </div>
        </Paper>

        <FormControl component="fieldset" className={classes.radioGroup}>
          <FormLabel component="legend">Select Tax Calculation Method</FormLabel>
          <RadioGroup 
            name="taxOption" 
            value={selectedOption} 
            onChange={handleOptionChange}
          >
            <FormControlLabel
              value="option1"
              control={<Radio color="primary" />}
              label={`65% of Total Cost ($${calculations?.option1Amount?.toFixed(2)})`}
            />
            <FormControlLabel
              value="option2"
              control={<Radio color="primary" />}
              label={`100% of Materials Cost ($${calculations?.option2Amount?.toFixed(2)})`}
            />
          </RadioGroup>
          {selectedOption !== taxDetails?.suggestedOption && (
            <Alert 
              severity="info" 
              style={{ 
                marginBottom: 16,
                backgroundColor: '#e3f2fd',
                fontSize: '0.75rem'
              }}
            >
              Based on your data, Option {taxDetails?.suggestedOption.slice(-1)} may be more advantageous
            </Alert>
          )}
        </FormControl>
        <FormControl fullWidth>
            <Autocomplete
              id={`taxLocale`}
              fullWidth
              options={taxLocales || []}
              onChange={handleUpdateChosenLocale}
              // freeSolo
              // autoSelect
              // multiple
              value={chosenLocale}
              // disableCloseOnSelect
              style={{marginTop: 25}}
              getOptionLabel={(option) => option.name}
              renderOption={(option, { selected }) => {
                return option.name
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Tax Locale" placeholder="Taxable Area" />
              )}
            />
           
        </FormControl>
        <Paper className={classes.taxableAmount}>
         
          <div className={classes.summaryRow}>
            <Typography variant="h6">Tax Rate:</Typography>
            <Typography variant="h6">
              {chosenLocale?.rate ? `${parseFloat(chosenLocale.rate * 100).toFixed(1)}%` : ''}
            </Typography>
          </div>
         
          <div className={classes.summaryRow}>
            <Typography variant="h6">Taxable Amount:</Typography>
            <Typography variant="h6">
              ${taxDetails?.taxableAmount?.toFixed(2)}
            </Typography>
          </div>
          <div className={classes.summaryRow}>
            <Typography variant="h6">Tax Collection Fee Amount:</Typography>
            <Typography variant="h6">
              ${taxDetails?.taxAmount?.toFixed(2)}
            </Typography>
          </div>
        </Paper>
      </CardContent>
    </Card>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeModal} color="secondary">
        Close
      </Button>
      <Button disabled={!chosenLocale} onClick={handleSave} color="primary">
        Save
      </Button>
    </DialogActions>
    </Dialog>
  );
};

export default TaxCalculator;
