import React, { useState } from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Button, TextField, Table, TableBody, Tooltip, TableCell, TableHead, TableRow, Checkbox, Paper, IconButton, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import moment from 'moment'
import axios from 'axios'
import AddBoxIcon from '@material-ui/icons/AddBox';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflow:'hidden'
  },
  modal: {
    position:'absolute',
    top:'10%',
    left:'10%',
    overflow:'hidden',
    height:'100%',
    display:'block'
  },
  estimateList: {
    marginTop: '375px'
  },
  estimateListRow: {
    cursor: 'pointer'
  },
  paddedStuff: {
    paddingBottom: 20
  }
}));

const daysOfTheWeek = [
  {
    name: 'No Preference',
    num: 0
  },
  {
    name: 'Sunday',
    num: 1
  },
  {
    name: 'Monday',
    num: 2
  },
  {
    name: 'Tuesday',
    num: 3
  },
  {
    name: 'Wednesday',
    num: 4
  },
  {
    name: 'Thursday',
    num: 5
  },
  {
    name: 'Friday',
    num: 6
  },
  {
    name: 'Saturday',
    num: 7
  }
]

const frequencyOptions = [
  {
    name: 'Weekly',
    num: 1,
    value: 'weekly'
  },
  {
    name: 'Bi-Weekly',
    num: 2,
    value: 'biweekly'
  },
  {
    name: 'Monthly',
    num: 3,
    value: 'monthly'
  }
]

const defaultStateValues = {
  startDate: '',
  preferredDay: 'None',
  frequency: 'Weekly',
  client: {},
  notes: '',
  estMinutes: 0
}
const AddMtcWaitListCustomer = (props) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [date, setDate] = React.useState(new Date())
  const [selected, setSelected] = useState([]);
  const [state, setState] = useState(defaultStateValues)
  const [loading, setLoading] = useState(false)
  const [jobSites, setJobSites] = React.useState([])

  const handleSelect = (id) => {
      if (selected.includes(id)) {
          setSelected((prev) => prev.filter((i) => i !== id));
      } else {
          setSelected((prev) => [...prev, id]);
      }
  };

  const handleSelectAll = () => {
      if (selected.length === props.crews.length) {
          setSelected([]);
      } else {
          setSelected(props.crews.map((crew) => crew._id));
      }
  };

  const handleClose = () => {
    // props.closeModal()
    setOpen(false)
  }

  const handleUpdateDate = (e) => {
    setDate(e.target.value)
    setState({...state, startDate: e.target.value })
  }

  const handleSave = () => {
    console.log('Save this shit', state)
    axios.post(`${props.url}/api/mtcRoutes/createMtcLead`, state).then(response => {
      console.log('createMtcLead Response', response.data)
      // Return this to the parent as its our PDF
      // props.handleShowPDF(response.data)
    }).catch(e => {
      console.log('Error createMtcLead: ', e)
    })
  }
  
  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleTabInteraction = (e) => {
    let string = e.target.value
    let newArr = []
  // console.log('String Tabbed on', string)
    if (e.keyCode == 9) {
      // console.log(e.target)
      if (e.target.id === 'customername') {
        props.clients.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          updateCustomer(e, newArr[0])
        }
      }
    }
  }
  const getJobSites = async (customer) => {
    setLoading(true)
      axios({
    method: 'get',
    url: `${props.url}/api/customer/getAllJobSites?id=${customer._id}`
  }).then(response => {
  // console.log('Got our job sites; ', response.data)
    setLoading(false)
    setJobSites(response.data.jobSites)
  })

}


  const updateCustomer = (e, customer) => {
    // console.log('Update the customer:', customer)
      if (customer && customer._id) {
        // setClient(customer)
        setState({...state, client: customer })
        getJobSites(customer)
        // inputRef.current.client = customer

      }
  }

  const updateJobSiteAddress  = (e, site) => {
    setState({...state, jobSite: site })
  }

  const formatOption = (opt) => {
    // console.log('Format this: ', opt)
    if (opt && opt.address) {
      return `${opt.name} - ${opt.address} ${opt.address2 ? opt.address2 : ''} ${opt.city}, ${opt.state} ${opt.zip}`
    } else {
      return ''
    }
  }
  
  const updateAutoComplete = (id, value) => {
    // console.log('Update our autocomplete: ', id)
    // console.log('Vauel:', value)
    let split = id.split('-')
    // console.log('Split: ', split)
    let optNum = split[2]
    let optItem = split[0]
    // console.log('Update: ', optItem)
    // console.log('To number: ', optNum)
    let newItem = null
    if (optItem === 'preferredDay') {
    newItem = daysOfTheWeek[optNum]
    setState({...state, preferredDay: newItem})
    }
    if (optItem === 'frequency') {
      newItem = frequencyOptions[optNum]
      setState({...state, frequency: newItem})
    }
  
  }

  
  const handleFocus = (e) => e.target.select()
  
  return (
    <>
      <Tooltip title="Add New Mtc Request">
        <IconButton
          onClick={handleOpenModal}
        >
          <AddBoxIcon size={24} />
        </IconButton>
      </Tooltip>
    

      <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add Mtc Lead</DialogTitle>
          <DialogContent style={{ margin: 10, paddingVertical: 15 }}>
          <div style={{ fontSize: '18px', color: '#aaa' }}>Please Provide the Basic Information:</div>
            <Grid container spacing={2}>
            
              <Grid item lg={12} md={12} sm={12} xs={12}>
             
              <Autocomplete
                id={`customername`}
                options={props.clients || []}
                getOptionLabel={(option) => {
                  // console.log('Options: ', option)
                  return option.displayName || ''
                }}
                // style={{ width: 200 }}
                // style={{marginBottom: '20px'}}
                onChange={updateCustomer}
                disabled={loading}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {state.client}
                // ref={inputRef.client}
                // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} label="Customer" helperText="Who Is The Client?" onKeyDown={handleTabInteraction} variant="outlined" />}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
                id="JobSite"
                options={jobSites || []}
                getOptionLabel={(option) => (formatOption(option) || '')}
                // style={{ width: 200 }}
                className={classes.paddedStuff}
                style={{marginBottom: 10, marginTop: 15}}
                onChange={updateJobSiteAddress}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {state.jobSite || {}}
                // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} variant="outlined" label="Job Site Address" />}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <DateChooser date={date} changeDate={handleUpdateDate}/>         
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Autocomplete
                id="frequency"
                options={frequencyOptions}
                getOptionLabel={(option) => option.name}
                className={classes.paddedStuff}
                // style={{ width: 200 }}
                // onChange={updateServiceType}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {state.frequency}
                onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
                // onChange={ e => setState({...state, preferredDay: e.target.value }) }
                renderInput={(params) => <TextField {...params} label="Visit Frequency" variant="standard" />}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Autocomplete
                id="preferredDay"
                options={daysOfTheWeek}
                getOptionLabel={(option) => option.name}
                className={classes.paddedStuff}
                // style={{ width: 200 }}
                // onChange={updateServiceType}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {state.preferredDay}
                onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
                // onChange={ e => setState({...state, preferredDay: e.target.value }) }
                renderInput={(params) => <TextField {...params} label="Preferred Day Of The Week" variant="standard" />}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                  id="estMinutes"
                  label="Estimated Minutes (3 Man Crew)"
                  type="number"
                  fullWidth
                  onFocus={handleFocus}
                  value={state.estMinutes }
                  onChange={ e => setState({...state, estMinutes: e.target.value }) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                id="notes"
                label="Notes"
                type="text"
                fullWidth
                value={state.notes }
                onChange={ e => setState({...state, notes: e.target.value }) }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          </DialogContent>
          <DialogActions>
          <Button color="primary" onClick={handleSave} disabled={(!state.jobSite || !state.jobSite._id) || !state.frequency || (!state.client || !state.client._id) || !state.estMinutes}>Save</Button>
        </DialogActions>
      </Dialog>
    </>

  );
};

const DateChooser = ({ date, changeDate }) => {

  return (
    <TextField
      id="date"
      label="Requested Start Date"
      type="date"
      fullWidth
      value={moment(date).format('YYYY-MM-DD') }
      onChange={ changeDate }
      InputLabelProps={{
        shrink: true,
      }}
    />
  )
}

const mapStateToProps = (state) => {
  // console.log('Add Waitlist', state)
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      salesReps: state.salesReps,
      employees: state.employees,
      entities: state.entities,
      clients: state.clients,
      users: state.users
    }
  }
  
  export default connect(mapStateToProps)(AddMtcWaitListCustomer)