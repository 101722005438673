import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress, Tooltip, MenuItem,  FormControl,
  InputLabel,
  Select,
  Box,
  Typography,
  Chip,
  Divider,
  Collapse
 }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteComponent from '../Common/DeleteComponent'
import FileUploadComponent from '../Uploads/UploadComponent'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
// import DialogContentText from '@material-ui/core/DialogContentText';
import { KeyboardDatePicker } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
import BigImageComponent from '../Uploads/BigImageComponent'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const PunchlistComponent = (props) => {
  // console.log('Punchlist Component Props: ', props)
  // const [open, setOpen] = React.useState(false)
  const open = props.open
  const [loading, setLoading] = React.useState(false)
  const [list, setList] = useState(props.list || []);
  const [projectMilestones, setProjectMilestones] = useState([]);
  const [workorderUploads, setWorkorderUploads] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);

  const workorderId = React.useRef(null)

  useEffect(() => {
    if (props?.workorder?._id) {
      // console.log('WorkorderId', workorderId)
      if (props?.punchlistItems?.length) {
        setList(props.punchlistItems)
        fetchPunchListItems(props?.workorder?._id)
      } else {
        setList([])
      }

    }
  }, [props.workorder]);

  const fetchPunchListItems = async (woId) => {
    // console.log('Fetch Punchlist iTems', woId)
    try {
      const resp = await axios.get(`${props.url}/api/jobs/punchlistItems?woId=${woId}`);
      if (resp && resp.data && resp.data.success) {
        const { punchlistItems, milestones, uploads } = resp.data;
        // setList(punchlistItems);
        setProjectMilestones(milestones);
        setWorkorderUploads(uploads);
      }
    } catch (error) {
      console.error("Error fetching punch list items:", error);
    }
  };
  

  const handleClosePunchListModal = () => {
    // setOpen(false)
    props.closeModal()
  }
  const completedPunchlistItems = 0

  return (
    <>
    <PunchlistModal
      {...props}
      list={list}
      setList={setList}
      activeFilter={activeFilter}
      projectMilestones={projectMilestones}
      setProjectMilestones={setProjectMilestones}
      workorderUploads={workorderUploads}
      setWorkorderUploads={setWorkorderUploads}
      setActiveFilter={setActiveFilter}
      open={open} 
      closeModal={handleClosePunchListModal}
    />
  </>
  )
}
  



const PunchlistModal = (props) => {
  // console.log('Punchlist Modal', props)
   const classes = useStyles();

  //  const [state, setState] = React.useState({
  //    note: '',
  //    list: []
  //  })

  const list = props.list
  const activeFilter = props.activeFilter
  const handleClose = () => props.closeModal('Close');
  const filteredList = props.activeFilter
  ? props.list.filter(item => item.milestone === props.activeFilter)
  : props.list;

  
return (
  <div className={classes.root}>
    
    <Dialog open={props.open} fullScreen={true} onClose={handleClose} maxWidth='md'>
      <div className={classes.headerArea}>
        <DialogTitle id="form-dialog-title">Workorder Punch List</DialogTitle>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </div>
      
      <DialogContent>
        <MainContent 
          list={filteredList}
          projectMilestones={props.projectMilestones}
          workorderUploads={props.workorderUploads}
          workorder={props.workorder}
          activeFilter={props.activeFilter}
          setActiveFilter={props.setActiveFilter}
          setList={props.setList}
          url={props.url}
          employees={props.employees}
          client={props.client}
          notification={props.notification}
        />
      </DialogContent>
    </Dialog>
  </div>
);
};

const MainContent = ({ 
  list, projectMilestones, workorderUploads, workorder, activeFilter, setActiveFilter, 
  url, client, notification, setList, employees
}) => {
  const classes = useStyles();
  const [newItemModalOpen, setNewItemModalOpen] = useState(false);
  const [editItemModalOpen, setEditItemModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState({});
  const [showBigImages, setShowBigImages] = useState(false);

  const handleOpenNewItemModal = () => setNewItemModalOpen(true);
  const handleCloseNewItemModal = () => setNewItemModalOpen(false);
  const handleCloseEditItemModal = () => setEditItemModalOpen(false);
  const handleCloseBigImageModal = () => setShowBigImages(false);

  const handleViewBigImage = (upload) => {
    console.log('Set active item for big image!!', upload)
    setActiveItem(upload);
    setShowBigImages(true);
  };

  const handleSaveNewItem = async (newItem) => {
    setNewItemModalOpen(false);
    try {
      const response = await axios.post(`${url}/api/jobs/addPunchlistItem`, {
        workorder: workorder._id,
        project: workorder.project,
        item: newItem,
        entity: workorder.entity
      });
      if (response.data && response.data._id) {
        setList([response.data, ...list]);
      }
    } catch (error) {
      notification({
        type: 'warning',
        title: 'Failed to Save',
        message: JSON.stringify(error)
      });
    }
  };

  const handleEditItem = (itemToEdit) => {
    setActiveItem(itemToEdit);
    setEditItemModalOpen(true);
  };

  const handleSaveEditedItem = async (editedItem) => {
    setEditItemModalOpen(false);
    try {
      const response = await axios.post(`${url}/api/jobs/updatePunchlistItem`, { item: editedItem });
      if (response.data && response.data._id) {
        const newList = list.map(item => item._id === response.data._id ? response.data : item);
        setList(newList);
      }
    } catch (error) {
      notification({
        type: 'warning',
        title: 'Failed to Save Item',
        message: JSON.stringify(error)
      });
    }
  };

  const handleDeleteItem = (itemToDelete) => {
    const newList = list.filter(item => item._id !== itemToDelete._id);
    setList(newList);
  };
  
  const handlePrint = async () => {
    try {
      const response = await axios.get(`${url}/api/jobs/printPunchlistItems?wo=${workorder._id}`);
      if (response && response.data && !response.data.error) {
        // Handle PDF display logic here
      }
    } catch (error) {
      console.error("Error printing punch list:", error);
    }
  };

  const handleUpdatePhotos = (item, photos) => {
    console.log('Update our item...', item)
    console.log('Update our photos...', photos)
    handleSaveEditedItem({
      ...item,
      photos: [
        ...photos
      ]
    })
    // let newList = [...list].map(item => {
    //   if (item._id === itemId) {
    //     console.log('This one...')
    //     return {
    //       ...item,
    //       photos
    //     }
    //   } else {
    //     return item
    //   }
    // })
    // console.log('Set our list:', newList)
    // setList(newList)
  }

  const sortedList = [...list].sort((a, b) => {
    const aComplete = a.status === 'Complete' || a.complete;
    const bComplete = b.status === 'Complete' || b.complete;
    if (aComplete === bComplete) return 0;
    return aComplete ? 1 : -1;
  });

  let otherPhotos = []
  list.forEach(item => {
    if (item?.photos?.length) {
      otherPhotos = [
        ...otherPhotos,
        ...item.photos
      ]
    }
  })
  let uploadsWithPhotos = [
    ...workorderUploads,
    ...otherPhotos
  ]
  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography variant="h6">
          {workorder?.name} ({workorder?.number}) - {workorder?.jobName}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenNewItemModal}
          startIcon={<PlaylistAddCheckIcon />}
        >
          Add New Item
        </Button>
      </div>

      <div className={classes.mainContent}>
        <div className={classes.leftPanel}>
          {sortedList.map((item) => {
            // console.log('Punchlist item:', item)
            const filteredUploads = workorderUploads.filter(upload => upload.punchlistId === item._id);
            // deal w/ photos also
            let allPhotos  = [
              ...filteredUploads,
              ...item.photos
            ]
            console.log("uploadsWithPhotos", uploadsWithPhotos)
            const associatedMilestone = item.milestone ? 
              projectMilestones.find(m => m._id === item.milestone) : null;
            const assignedTo = item.assignedTo ? 
              item.assignedTo : null;

            return (
              <PunchListItem
                key={item._id}
                item={item}
                uploads={allPhotos}
                milestone={associatedMilestone}
                assignedTo={assignedTo}
                onEdit={handleEditItem}
                onDelete={handleDeleteItem}
                onImageClick={handleViewBigImage}
                updatePhotos={handleUpdatePhotos}
              />
            );
          })}
        </div>

        <div className={classes.rightPanel}>
          <Paper className={classes.filterSection}>
            <Typography variant="subtitle1">Project Milestones ({projectMilestones.length})</Typography>
            <Divider />
            <MilestonesList 
              list={projectMilestones}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
          </Paper>
        </div>
      </div>

      <AddNewPunchListItemModal 
        open={newItemModalOpen}
        employees={employees}
        closeModal={handleCloseNewItemModal} 
        saveItem={handleSaveNewItem}
        milestones={projectMilestones}
      />
      <EditPunchListItemModal 
        open={editItemModalOpen}
        item={activeItem}
        employees={employees}
        closeModal={handleCloseEditItemModal} 
        saveItem={handleSaveEditedItem}
        milestones={projectMilestones}
      />
      <BigImageComponent 
        open={showBigImages}
        item={activeItem}
        images={uploadsWithPhotos}
        closeModal={handleCloseBigImageModal}
      />
    </div>
  );
};

const MilestonesList = ({ list, activeFilter, setActiveFilter }) => {
  const classes = useStyles();

  return (
    <div className={classes.milestonesList}>
      {list.map((item) => (
        <div 
          key={item._id}
          className={`${classes.milestone} ${activeFilter === item._id ? classes.activeMilestone : ''}`}
          onClick={() => setActiveFilter(activeFilter === item._id ? null : item._id)}
        >
          <Typography variant="body2">{item.name}</Typography>
        </div>
      ))}
    </div>
  );
};


  const ClosePDF = (props) => {
    return (
      <div style={{ width: '100%', display: 'flex', alignSelf: 'stretch', justifyContent: 'space-between', flexDirection: 'row'}}>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={props.handleClose}
        >Close PDF</Button>
      </div>
    )
  }

  // const ControlArea = ({ workorder }) => {
  //   console.log('Control area 434', workorder)
  //   return (
  //     <div>
  //       {workorder?.name} ({workorder?.number}) - {workorder?.jobName}
  //     </div>
  //   )
  // }
  
  // const AllItems = (props) => {
  //   const classes = useStyles()
  //   return (
  //     <div className={classes.allItemContainer}>
  //       <div className={classes.allItemHeader}>
  //         <div>
  //         Punch List Items #{props.list.length || 0} 
  //         </div>
         
  //         <Button variant="contained" color="primary" size="small" onClick={props.openNewItemModal}>Add New</Button>
  //       </div>
  //       <div className={classes.allItemContent}>
  //         <OurList list={props.list} employees={props.employees} uploads={props?.uploads || []} projectMilestones={props.projectMilestones} workorderId={props.workorderId} editItem={props.editItem} deleteItem={props.deleteItem} clientId={props.client._id} />
  //       </div>
  //     </div>
  //   )
  // }

  // const OurList = (props) => {
  //   const classes = useStyles()
  //   const [showBigImages, setShowBigImages] = React.useState(false)
  //   const [activeItem, setActiveItem] = React.useState({})

  //   const handleUpdateResponse = (d) => {
  //     console.log('UPdate from our uploads component...', d)
  //   }

  //   const handleCloseBigImageModal = () => {
  //     setShowBigImages(false)
  //   }

  //   const handleViewBigImage = (d) => {
  //     setActiveItem(d)
  //     setShowBigImages(true)
  //   }
  //   // console.log('Our List props', props)
    
  //   return (
  //     <div className={classes.listContainer}>
  //     <BigImageComponent open={showBigImages} item={activeItem} images={props.uploads} closeModal={handleCloseBigImageModal} />
  //         {
  //           props.list.map((item, index) => {
  //             // console.log('Punchlist item', item)
  //             const filteredUploads = props?.uploads.filter(upload => (upload.punchlistId === item._id))
  //             let associatedMilestone = null
  //             if (item.milestone) {
  //               associatedMilestone = props.projectMilestones.find(milestone => (milestone._id === item.milestone))
  //             }
  //             let assignedTo = null
  //             if (item.assignedTo) {
  //               assignedTo = props?.employees.find(emp => (emp._id === item.assignedTo))
  //             }
  //             console.log('Assigned To', assignedTo)
  //             return (
  //               <div className={classes.listItemContainer} key={index}>
                  
  //                 <div className={classes.listItemMainArea}>
  //                   <div className={classes.listItemName}>{item.name}</div>
  //                   <div className={classes.listItemDetails}>{item.details}</div>
                    
  //                 </div>
  //                 <div className={classes.secondRow}>
  //                   <div className={classes.statusArea}>{item.status}</div>
  //                   <div className={classes.listItemControls}>
  //                         <Tooltip
  //                           title="Edit">
  //                           <IconButton
  //                             // color={props.color}
  //                             size="small"
  //                             onClick={() => props.editItem(item)}
  //                           ><EditRoundedIcon  sx={{ fontSize: "10px", color: "#fff" }}  /></IconButton>
  //                         </Tooltip>
                          
  //                           <DeleteComponent
  //                                 type="Tasks"
  //                                 item={item}
  //                                 title="Delete Item?"
  //                                 verificationText={`Are you sure you wish to Delete this punch list item?`}
  //                                 confirmationOfIntentText="This action is irreversible, this item will be gone forever!"
  //                                 confirmationButton="DELETE"
  //                                 denyText="NO"
  //                                 ifApproved={e => props.deleteItem(item)}
  //                                 deleteFunction={'punchlistItem'}
  //                               />
                         
  //                     </div>
  //                   </div>
  //                   <div className={classes.uploadsArea}>
  //                     <div className={classes.uploadsInputArea}>
  //                       <FileUploadComponent clientId={props.clientId} workorderId={props.workorderId} punchlistId={item._id} updateParent={handleUpdateResponse} message={`Add Files Here`}  />
  //                     </div>
  //                     <div>
  //                     Uploads: {filteredUploads?.length}
  //                     <div className={classes.thumbnailsContainer}>
  //                       {filteredUploads.map((upload, index) => (
  //                         <div key={index} className={classes.thumbnail} onClick={ () => handleViewBigImage(upload)}>
  //                           <img src={upload.url} alt={`upload-${index}`} className={classes.thumbnailImage} />
  //                         </div>
  //                       ))}
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className={classes.bottomLineContainer}>
  //                   <div >
  //                     {associatedMilestone?.name || ''}
  //                   </div>
  //                   <div>{assignedTo && `${assignedTo.firstName} ${assignedTo.lastName}`}</div>
  //                 </div>
  //               </div>
  //             )
  //           })
  //         }
       
  //     </div>
  //   )
  // }

  const AddNewPunchListItemModal = ({ open, closeModal, saveItem, milestones, employees }) => {
    const classes = useStyles();
    const [state, setState] = useState({
      name: '',
      details: '',
      status: 'Not Started',
      milestone: null,
      assignedTo: null
    });
  
    const handleChange = (e) => setState({ ...state, [e.target.id]: e.target.value });

    const handleSave = () => {
      saveItem(state);
      setState({ name: '', details: '', status: 'Not Started', milestone: {} });
    };

    const handleChangeStatus = (e) => {
      setState(prevState => ({ ...prevState, status: e.target.value }))
    }

    const handleTabInteraction = (e) => {
      if (e.keyCode == 9) {
      // console.log(e.target)
        if (e.target.id === 'milestone') {
          // window.alert(`Tab on custoemr ${e.target.value}`)
          let newArr = []
          let string = e.target.value
          milestones.filter(o => {
            let str = o['name'].toString().toLowerCase()
            if (str.includes(string.toLowerCase())) {
              return newArr.push(o)
            } else {
              return null
            }
          })
          // console.log(newArr)
          if (newArr && newArr.length) {
            handleChooseMilestone(null, newArr[0])
          }
        }
      }
    }
  
    const handleChooseMilestone = (e, newMilestone) => {
      setState(prevState => ({...prevState, milestone: newMilestone}))
    }
    const handleAssignedToChange = (d) => {
      setState(prevState => ({...prevState, assignedTo: d }))
    }

    const handleUrgencyChange = (e) => {
      setState(prevState => ({...prevState, urgency: e.target.value}));
    }
  
    const handleDueDateChange = (date) => {
      setState(prevState => ({...prevState, dueDate: date}));
    }

    return (
      <Dialog size="md" fullWidth open={open} onClose={closeModal}>
        <DialogTitle>Add New Punch List Item</DialogTitle>
        <DialogContent>
          <TextField
            id="name"
            variant="outlined"
            fullWidth
            label="Item Name"
            className={classes.newItemInput}
            value={state.name}
            onChange={handleChange}
          />
          <TextField
            id="details"
            variant="outlined"
            fullWidth
            label="Item Details"
            multiline
            minRows={4}
            className={classes.newItemInput}
            value={state.details}
            onChange={handleChange}
          />
          <TextField
            id="status"
            variant="outlined"
            fullWidth
            select
            label="Item Status"
            className={classes.newItemInput}
            value={state.status}
            onChange={handleChangeStatus}
          >
            <MenuItem value="Not Started">Not Started</MenuItem>
            <MenuItem value="Pending Materials">Pending Materials</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Complete">Complete</MenuItem>
          </TextField>
          <Autocomplete
            id="milestone"
            options={milestones}
            variant="outlined"
            getOptionLabel={(option) => {
              return `${option.name}`
            }}
            value={state.milestone || ''}
            onChange={handleChooseMilestone}
            className={classes.newItemInput}
            renderInput={(params) => <TextField fullWidth onKeyDown={handleTabInteraction} variant="outlined" {...params} label="Milestone" />}
          />
              <TextField
            id="status"
            variant="outlined"
            fullWidth
            select
            label="Item Status"
            className={classes.newItemInput}
            value={state.status}
            onChange={handleChangeStatus}
          >
            <MenuItem value="Not Started">Not Started</MenuItem>
            <MenuItem value="Pending Materials">Pending Materials</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Complete">Complete</MenuItem>
          </TextField>
          <FormControl variant="outlined" fullWidth className={classes.newItemInput}>
          <InputLabel id="urgency-label">Urgency</InputLabel>
          <Select
            labelId="urgency-label"
            id="urgency"
            value={state.urgency || ''}
            onChange={handleUrgencyChange}
            label="Urgency"
          >
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="High">High</MenuItem>
            <MenuItem value="Critical">Critical</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" fullWidth className={classes.newItemInput}>

            <KeyboardDatePicker
              disableToolbar
              format="MM/dd/yyyy"
              variant="inline"
              margin="normal"
              id="due-date"
              label="Needs to be done by"
              value={state.dueDate || null}
              onChange={handleDueDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              fullWidth
            />
          </FormControl>
          <Autocomplete
            id="assigned-to"
            options={employees || []}
            getOptionLabel={(option) => `${option.firstName || ''} ${option.lastName || ''}`}
            value={state.assignedTo || null}
            onChange={(e, newValue) => handleAssignedToChange(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Assigned To" variant="outlined" fullWidth  className={classes.newItemInput} />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>CLOSE</Button>
          <Button color="primary" onClick={handleSave}>SAVE</Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  const EditPunchListItemModal = ({ open, item, closeModal, saveItem, milestones, employees }) => {
    const classes = useStyles();
    const [state, setState] = useState(item);
  
    useEffect(() => {
      console.log('Set item milestone....', item)
      let milestoneId = item?.milestone
      console.log('Miestone ite', milestoneId)
      let itemToSet = { ...item }
      if (milestoneId) {
        itemToSet.milestone = milestones.find(ms => (ms._id === milestoneId))
        console.log('Setting milestone!!', itemToSet)
      }
      setState(itemToSet);
    }, [item]);
  
    const handleChange = (e) => {
      // console.log('Handle change', e.target.id)
      // console.log('Val', e.target.value)
      setState({ ...state, [e.target.id]: e.target.value });
    }

    const handleChangeStatus = (e) => {
      setState(prevState => ({ ...prevState, status: e.target.value }))
    }

    const handleSave = () => {
      saveItem(state);
      closeModal();
    };
  
    const handleTabInteraction = (e) => {
      if (e.keyCode == 9) {
      // console.log(e.target)
        if (e.target.id === 'milestone') {
          // window.alert(`Tab on custoemr ${e.target.value}`)
          let newArr = []
          let string = e.target.value
          milestones.filter(o => {
            let str = o['name'].toString().toLowerCase()
            if (str.includes(string.toLowerCase())) {
              return newArr.push(o)
            } else {
              return null
            }
          })
          // console.log(newArr)
          if (newArr && newArr.length) {
            handleChooseMilestone(null, newArr[0])
          }
        }
      }
    }
  
    const handleChooseMilestone = (e, newMilestone) => {
      setState(prevState => ({...prevState, milestone: newMilestone}))
    }

    const handleAssignedToChange = (d) => {
      // console.log('Assign to', d)
      setState(prevState => ({...prevState, assignedTo: d }))
    }

    const handleUrgencyChange = (e) => {
      setState(prevState => ({...prevState, urgency: e.target.value}));
    }
  
    const handleDueDateChange = (date) => {
      setState(prevState => ({...prevState, dueDate: date}));
    }

    return (
      <Dialog size="md" fullWidth open={open} onClose={closeModal}>
        <DialogTitle>Edit Punch List Item</DialogTitle>
        <DialogContent>
          <TextField
            id="name"
            variant="outlined"
            fullWidth
            label="Item Name"
            className={classes.newItemInput}
            value={state.name}
            onChange={handleChange}
          />
          <TextField
            id="details"
            variant="outlined"
            fullWidth
            label="Item Details"
            multiline
            minRows={4}
            className={classes.newItemInput}
            value={state.details}
            onChange={handleChange}
          />
          <TextField
            id="status"
            variant="outlined"
            fullWidth
            select
            label="Item Status"
            className={classes.newItemInput}
            value={state.status}
            onChange={handleChangeStatus}
          >
            <MenuItem value="Not Started">Not Started</MenuItem>
            <MenuItem value="Pending Materials">Pending Materials</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Complete">Complete</MenuItem>
          </TextField>
          <FormControl variant="outlined" fullWidth className={classes.newItemInput}>
          <InputLabel id="urgency-label">Urgency</InputLabel>
          <Select
            labelId="urgency-label"
            id="urgency"
            value={state.urgency || ''}
            onChange={handleUrgencyChange}
            label="Urgency"
          >
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="High">High</MenuItem>
            <MenuItem value="Critical">Critical</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" fullWidth className={classes.newItemInput}>
         
            <KeyboardDatePicker
              disableToolbar
              format="MM/dd/yyyy"
              variant="inline"
              margin="normal"
              id="due-date"
              label="Needs to be done by"
              value={state.dueDate || null}
              onChange={handleDueDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              fullWidth
              className={classes.newItemInput}
            />
         
          </FormControl>
          <Autocomplete
            id="milestone"
            options={milestones}
            variant="outlined"
            getOptionLabel={(option) => {
              return `${option.name}`
            }}
            value={state.milestone}
            onChange={handleChooseMilestone}
            className={classes.newItemInput}
            renderInput={(params) => <TextField fullWidth onKeyDown={handleTabInteraction} variant="outlined" {...params} label="Milestone" />}
          />
          <Autocomplete
            id="assigned-to"
            options={employees || []}
            getOptionLabel={(option) => `${option.firstName || ''} ${option.lastName || ''}`}
            value={state.assignedTo || null}
            onChange={(e, newValue) => handleAssignedToChange(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Assigned To" variant="outlined" fullWidth  className={classes.newItemInput} />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>CLOSE</Button>
          <Button color="primary" onClick={handleSave}>UPDATE</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const PunchListItem = ({ item, uploads, milestone, assignedTo, onEdit, onDelete, onImageClick, updatePhotos }) => {
    const classes = useStyles();
    const [showDetails, setShowDetails] = useState(false);
    
    const isComplete = item.status === 'Complete' || item.complete;

    const getStatusColor = (status) => {
      console.log('Get status color', status)
      switch(status) {
        case 'Complete': return '#4caf50';
        case 'In Progress': return '#2196f3';
        case 'Pending Materials': return '#ff9800';
        default: return '#757575';
      }
    };

    const handleUploadResponse = (uploadResponse) => {
      console.log('Upload repsonse', uploadResponse)
      let newPhotos = [...item.photos]
      if (uploadResponse?.duplicateFiles?.length) {
        let dupeFiles = uploadResponse.duplicateFiles[0]['existingFiles']
        console.log('We have dupes we need to deal with...', dupeFiles)
        newPhotos = [...newPhotos, ...dupeFiles]
        console.log('Set our item photos...', newPhotos)
       
      }
      if (uploadResponse?.filesUploaded?.length) {
        newPhotos = [...newPhotos, ...uploadResponse.filesUploaded]
      }
      console.log('Updated photos', newPhotos)
      updatePhotos(item, newPhotos)
    }
  
  return (
    <Paper 
      className={`${classes.listItem} ${isComplete ? classes.completedItem : ''}`} 
      elevation={isComplete ? 0 : 1}
    >
      <div className={classes.itemMainRow}>
        <div 
          className={classes.statusIndicator} 
          style={{ backgroundColor: getStatusColor(item.status) }} 
        />
        
        <div 
          className={`${classes.itemMainContent} ${isComplete ? classes.completedContent : ''}`} 
          onClick={() => setShowDetails(!showDetails)}
        >
          <div className={classes.itemHeader}>
            <Typography 
              variant="subtitle1" 
              className={`${classes.itemTitle} ${isComplete ? classes.completedTitle : ''}`}
            >
              {item.name}
              {isComplete && (
                <CheckCircleOutlineIcon className={classes.completedIcon} />
              )}
            </Typography>
            <Box className={classes.chipContainer}>
              {item.urgency && (
                <Chip
                  label={item.urgency}
                  size="small"
                  className={classes.urgencyChip}
                  style={{
                    backgroundColor: item.urgency === 'Critical' ? '#f44336' : 
                                  item.urgency === 'High' ? '#ff9800' : 
                                  item.urgency === 'Medium' ? '#ffeb3b' : '#4caf50',
                    color: item.urgency === 'Medium' || item.urgency === 'Low' ? '#000' : '#fff',
                    opacity: isComplete ? 0.6 : 1
                  }}
                />
              )}
              {milestone && (
                <Chip
                  label={milestone.name}
                  size="small"
                  variant="outlined"
                  className={`${classes.milestoneChip} ${isComplete ? classes.completedChip : ''}`}
                />
              )}
            </Box>
          </div>
          
          <Box className={classes.metadataRow}>
            {assignedTo && (
              <Typography variant="caption" className={classes.assignedTo}>
                Assigned to: {assignedTo.firstName} {assignedTo.lastName}
              </Typography>
            )}
            {item.dueDate && (
              <Typography variant="caption" className={classes.dueDate}>
                Due: {moment(item.dueDate).format('MMM D, YYYY')}
              </Typography>
            )}
            {item.updated_at && (
              <Typography variant="caption" className={classes.lastUpdated}>
                Updated: {moment(item.updated_at).fromNow()}
              </Typography>
            )}
          </Box>
        </div>
  
        <div className={classes.itemActions}>
          <IconButton size="small" onClick={() => onEdit(item)}>
            <EditRoundedIcon fontSize="small" />
          </IconButton>
          <DeleteComponent
            type="Tasks"
            item={item}
            title="Delete Item?"
            verificationText="Are you sure you wish to Delete this punch list item?"
            confirmationOfIntentText="This action is irreversible!"
            confirmationButton="DELETE"
            denyText="NO"
            ifApproved={() => onDelete(item)}
            deleteFunction="punchlistItem"
          />
        </div>
      </div>
  
      <Collapse in={showDetails}>
        <div className={classes.expandedContent}>
          <Typography variant="body2" className={classes.itemDetails}>
            {item.details}
            {item.notes && (
              <>
                <Divider className={classes.notesDivider} />
                <Typography variant="caption" className={classes.notesLabel}>
                  Additional Notes:
                </Typography>
                <Typography variant="body2" className={classes.notes}>
                  {item.notes}
                </Typography>
              </>
            )}
          </Typography>
          
          <div className={classes.uploadsSection}>
            <div className={classes.uploadHeader}>
              <Typography variant="caption">
                Attachments ({uploads?.length || 0})
                {/* {item.photos?.length > 0 && ` + ${item.photos.length} photos`} */}
              </Typography>
              <div className={classes.uploadAction}>
                <FileUploadComponent punchlistId={item._id} workorderId={item.workorder} updateParent={handleUploadResponse} />
              </div>
            </div>
            {uploads?.length > 0 && (
              <div className={classes.thumbnailStrip}>
                {uploads.map((upload, index) => (
                  <div 
                    key={index}
                    className={classes.thumbnail}
                    onClick={() => onImageClick(upload)}
                  >
                    <img 
                      src={upload.url}
                      alt={`Upload ${index + 1}`}
                      className={classes.thumbnailImg}
                    />
                  </div>
                ))}
              </div>
            )}
            {/* {item?.photos?.length > 0 && (
              <div className={classes.thumbnailStrip}>
                {item.photos.map((upload, index) => (
                  <div 
                    key={index}
                    className={classes.thumbnail}
                    onClick={() => onImageClick(upload)}
                  >
                    <img 
                      src={upload.url}
                      alt={`Upload ${index + 1}`}
                      className={classes.thumbnailImg}
                    />
                  </div>
                ))}
              </div>
            )} */}
          </div>
        </div>
      </Collapse>
    </Paper>
  );
  };

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      employees: state.employees
    }
  }
  
  export default React.memo(connect(mapStateToProps)(PunchlistComponent))

  
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // height: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    display: 'flex',
    flex: 1,
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    backgroundColor: theme.palette.background.default
  },
  leftPanel: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  rightPanel: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  listItem: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[1],
    '&:hover': {
      boxShadow: theme.shadows[3]
    }
  },
  itemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  itemTitle: {
    fontWeight: 'bold',
    fontSize: '1.1rem'
  },
  itemDetails: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2)
  },
  itemMetadata: {
    display: 'flex',
    gap: theme.spacing(1),
    flexWrap: 'wrap'
  },
  statusChip: {
    margin: theme.spacing(0.5)
  },
  uploadsSection: {
    marginTop: theme.spacing(2)
  },
  thumbnailGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  thumbnail: {
    width: '100%',
    paddingTop: '100%',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden'
  },
  thumbnailImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  milestone: {
    padding: theme.spacing(1.5),
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  activeMilestone: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText
  },
  filterBar: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius
  },
  mainContent: {
    display: 'flex',
    flex: 1,
    gap: theme.spacing(2),
    height: 'calc(100vh - 200px)',
    overflow: 'hidden'
  },
  leftPanel: {
    flex: 3,
    overflowY: 'auto',
    padding: theme.spacing(2),
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: '4px'
    }
  },
  rightPanel: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  filterSection: {
    padding: theme.spacing(2)
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2)
  },
  listItem: {
    marginBottom: theme.spacing(1),
    padding: 0,
    '&:hover': {
      boxShadow: theme.shadows[2]
    }
  },
  itemMainRow: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 48,
  },
  statusIndicator: {
    width: 4,
    alignSelf: 'stretch',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius
  },
  itemMainContent: {
    flex: 1,
    padding: theme.spacing(1, 2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  itemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  itemTitle: {
    fontWeight: 500,
  },
  chipContainer: {
    display: 'flex',
    gap: theme.spacing(1)
  },
  urgencyChip: {
    height: 20,
    fontSize: '0.75rem'
  },
  milestoneChip: {
    height: 20,
    fontSize: '0.75rem'
  },
  assignedTo: {
    color: theme.palette.text.secondary
  },
  itemActions: {
    display: 'flex',
    padding: theme.spacing(0, 1)
  },
  expandedContent: {
    padding: theme.spacing(0, 2, 1, 2),
    marginLeft: 4
  },
  itemDetails: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
  },
  uploadHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  thumbnailStrip: {
    display: 'flex',
    gap: theme.spacing(1),
    overflowX: 'auto',
    padding: theme.spacing(0.5, 0),
    '&::-webkit-scrollbar': {
      height: 6
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: 3
    }
  },
  thumbnail: {
    width: 40,
    height: 40,
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    cursor: 'pointer'
  },
  thumbnailImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  completedItem: {
    backgroundColor: fade(theme.palette.success.light, 0.1),
    borderColor: theme.palette.success.light,
    opacity: 0.8,
  },
  completedContent: {
    textDecoration: 'none',
  },
  completedTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  completedIcon: {
    color: theme.palette.success.main,
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
  },
  completedChip: {
    opacity: 0.6,
  },
  metadataRow: {
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
  dueDate: {
    color: theme.palette.text.secondary,
  },
  lastUpdated: {
    color: theme.palette.text.disabled,
  },
  notesDivider: {
    margin: theme.spacing(1, 0),
  },
  notesLabel: {
    color: theme.palette.text.secondary,
    display: 'block',
    marginBottom: theme.spacing(0.5),
  },
  notes: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
  },
  newItemInput: {
    padding: 5,
    margin: 5
  }


}));
