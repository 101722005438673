import React from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import { MoreVert as MoreIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  destructive: {
    color: theme.palette.error.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.error.main,
    }
  }
}));

const MoreActionsMenu = ({ 
  actions, // Array of action objects
  onActionSelect, // Optional callback when action is selected
  tooltipText = "More Actions", // Optional custom tooltip text
  selectedItem, // The currently selected item (optional)
  disabled = false, // Optional disabled state
  size = "small" // Optional size prop for IconButton
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = (action) => {
    handleClose();
    if (action.onClick) {
      action.onClick(selectedItem);
    } else if (onActionSelect) {
      onActionSelect(action.id, selectedItem);
    }
  };

  return (
    <>
      <Tooltip title={tooltipText}>
        <IconButton
          size={size}
          onClick={handleClick}
          disabled={disabled}
        >
          <MoreIcon fontSize={size} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map((action, index) => {
          if (action.divider) {
            return <Divider key={`divider-${index}`} />;
          }

          return (
            <MenuItem
              key={action.id}
              onClick={() => !action.disabled && handleActionClick(action)}
              disabled={action.disabled}
              className={action.destructive ? classes.destructive : ''}
            >
              {action.icon && (
                <ListItemIcon>
                  {action.icon}
                </ListItemIcon>
              )}
              <ListItemText primary={action.label} />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default MoreActionsMenu;
