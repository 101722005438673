import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import AddNewEntityModal from './AddNewEntityModal';
import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LaborDetails from './LaborDetails';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    minHeight: '75vh',
    padding: 10,
    margin: 10
  },
  entityContainer: {
    margin: 10,
    borderRadius: 8,
    border: '3px solid #aaa',
    padding: 10
  },
  logo: {
    margin: '5px',
    padding: '5px',
    maxWidth: 150,
    maxHeight: 110
  }
}));

const defaultStateValue = {
  showNewEntityModal: false,
  dataToUpdate: []
};

const Entities = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState(defaultStateValue);
  const [entities, setEntities] = React.useState([]);

  const fetchEntities = async () => {
    const resp = await axios.get(`${props.url}/api/entities/list`);
    setEntities(resp.data);
  };
  
  React.useEffect(() => {
    fetchEntities();
  }, []);

  const handleUpdateFromNewEntityModal = () => {
    setState({...state, showNewEntityModal: false});
  };

  const handleCloseNewEntModal = () => {
    setState({...state, showNewEntityModal: false});
  };

  const handleFocus = (event) => event.target.select();

  const handleSave = async () => {
    try {
      await axios.post(`${props.url}/api/entities/update`, state.dataToUpdate);
      setState(prev => ({ ...prev, dataToUpdate: [] }));
      // Optionally refetch to ensure sync
      await fetchEntities();
    } catch (error) {
      console.error('Error saving entities:', error);
      // Handle error appropriately
    }
  };


  const updateOurDataToUpdate = (passedData) => {
    const { entityId, data } = passedData;

    setState(prevState => {
      const existingDataToUpdate = [...(prevState.dataToUpdate || [])];
      const existingIndex = existingDataToUpdate.findIndex(item => item._id === entityId);

      if (existingIndex > -1) {
        // Merge new data with existing data
        const existingData = existingDataToUpdate[existingIndex].updatedData;
        const mergedData = mergeDeep(existingData, data);
        
        existingDataToUpdate[existingIndex] = {
          _id: entityId,
          updatedData: mergedData
        };
      } else {
        existingDataToUpdate.push({
          _id: entityId,
          updatedData: data
        });
      }

      return {
        ...prevState,
        dataToUpdate: existingDataToUpdate
      };
    });
  };

  const mergeDeep = (target, source) => {
    const output = { ...target };
    
    if (isObject(target) && isObject(source)) {
      Object.keys(source).forEach(key => {
        if (isObject(source[key])) {
          if (!(key in target)) {
            Object.assign(output, { [key]: source[key] });
          } else {
            output[key] = mergeDeep(target[key], source[key]);
          }
        } else {
          Object.assign(output, { [key]: source[key] });
        }
      });
    }
    return output;
  };

  const isObject = (item) => {
    return item && typeof item === 'object' && !Array.isArray(item);
  };

  const updateOurEntity = (passedData) => {
    const { entityId, data } = passedData;
    
    setEntities(prevEntities => 
      prevEntities.map(entity => {
        if (entity._id === entityId) {
          return mergeDeep(entity, data);
        }
        return entity;
      })
    );

    updateOurDataToUpdate(passedData);
  };


  const handleUpdateLabor = (data) => {
    console.log('Labor update coming in', data)
  }

  const renderEntityFields = (item, handleChange) => (
    <Grid container spacing={2} style={{padding: '10px'}}>
      <Grid item lg={2} md={3} sm={4} xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          id="entityNameFormal"
          onChange={handleChange}
          value={item.entityNameFormal}
          helperText={`What is the Formal Name for ${item.name}?`}
        />
      </Grid>
      <Grid item lg={2} md={3} sm={4} xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          id="name"
          onChange={handleChange}
          value={item.name}
          helperText={`What is the Common Name for ${item.name}?`}
        />
      </Grid>
      <Grid item lg={2} md={3} sm={4} xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          id="returnEmail"
          onChange={handleChange}
          value={item.returnEmail}
          helperText={`What is the Return Email Address for ${item.name}?`}
        />
      </Grid>
      <Grid item lg={2} md={3} sm={4} xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          id="rocNumber"
          onChange={handleChange}
          value={item.rocNumber}
          helperText={`What is the ROC Number for ${item.name}?`}
        />
      </Grid>
    </Grid>
  );

  return (
    <div style={{padding: '5px', margin: '10px'}}>
      <AddNewEntityModal 
        open={state.showNewEntityModal} 
        updateParent={handleUpdateFromNewEntityModal} 
        closeModal={handleCloseNewEntModal} 
      />
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item lg={6} xs={12}>
            <h1>Entities</h1>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setState(prev => ({...prev, showNewEntityModal: !prev.showNewEntityModal}))}
            >
              Add New Entity
            </Button>
          </Grid>
          <Grid item lg={12} xs={12}>
            <List component="div" role="list">
              {entities.map((item, index) => {
                const handleChange = (e) => {
                  updateOurEntity({
                    data: { [e.target.id]: e.target.value },
                    entityId: item._id
                  });
                };

                return (
                  <div className={classes.entityContainer} key={index}>
                    <ListItem role="listitem">
                      <ListItemIcon>
                        <img 
                          alt="Logo" 
                          src={`${process.env.PUBLIC_URL}/${item._id}_Logo.png`}
                          className={classes.logo}
                        />
                      </ListItemIcon>
                      <ListItemText primary={item.name} />
                      <ListItemText primary={item.billingAddress} />
                    </ListItem>
                    {renderEntityFields(item, handleChange)}
                    <LaborDetails 
                      entity={item}
                      handleFocus={handleFocus}
                      updateOurEntity={updateOurEntity}
                    />
                  </div>
                );
              })}
              {entities.length === 0 && (
                <span style={{padding: '10px', margin: 10}}>List Empty</span>
              )}
            </List>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSave}
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  url: state.url,
  user: state.user,
  notification: state.notification
});

export default connect(mapStateToProps)(Entities);