import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  IconButton,
  Divider
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Helper function for currency formatting
const currencyFormat = (num) => {
  return '$' + parseFloat(num || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const handleFocus = (event) => event.target.select();

// WorkorderModal Component
export const WorkorderModal = ({
  open,
  onClose,
  selectedItems,
  serviceTypes,
  workorders,
  onConfirm,
  classes,
  calculateUsedQuantity
}) => {
  // Form state
  const [formData, setFormData] = useState({
    name: '',
    serviceType: null,
    description: '',
    workorderNotes: ''
  });

  // Quantity allocation state
  const [quantities, setQuantities] = useState({});
  const [quantityTypes, setQuantityTypes] = useState({});

  // Initialize quantities when modal opens
  React.useEffect(() => {
    if (open) {
      console.log('Workorders 59:::', workorders)
      const initialQuantities = {};
      const initialTypes = {};
      selectedItems.forEach(item => {
        console.log('item:::', item)
        const remainingQty = getRemainingQty(item, workorders);
        const usedQty = calculateUsedQuantity(item._id, workorders);
        console.log('Remaining Qty', remainingQty)
        initialQuantities[item._id] = remainingQty;
        initialTypes[item._id] = 'quantity';
      });
      console.log('Initial Quantities', initialQuantities)
      setQuantities(initialQuantities);
      setQuantityTypes(initialTypes);
    }
  }, [open, selectedItems, workorders]);

  const handleQuantityChange = (itemId, value) => {
    const item = selectedItems.find(i => i._id === itemId);
    const remainingQty = getRemainingQty(item, workorders);
    
    let newValue = value;
    if (quantityTypes[itemId] === 'percentage') {
      // For percentage, limit to 100%
      newValue = Math.min(100, Math.max(0, value));
    } else {
      // For direct quantity, limit to remaining quantity
      newValue = Math.min(remainingQty, Math.max(0, value));
    }

    setQuantities(prev => ({
      ...prev,
      [itemId]: newValue
    }));
  };

  const handleQuantityTypeChange = (itemId, type) => {
    const item = selectedItems.find(i => i._id === itemId);
    const remainingQty = getRemainingQty(item, workorders);
    
    setQuantityTypes(prev => ({
      ...prev,
      [itemId]: type
    }));
    
    // Reset quantity when changing type
    setQuantities(prev => ({
      ...prev,
      [itemId]: type === 'percentage' ? 100 : remainingQty
    }));
  };

  const calculateActualQuantity = (item) => {
    const type = quantityTypes[item._id];
    const quantity = quantities[item._id] || 0;
    const remainingQty = getRemainingQty(item, workorders);
    
    if (type === 'percentage') {
      // Calculate percentage of remaining quantity
      return (remainingQty * quantity) / 100;
    }
    return Math.min(quantity, remainingQty); // Ensure we don't exceed remaining
  };

  const handleSubmit = () => {
    const workorderItems = selectedItems.map(item => ({
      ...item,
      qty: calculateActualQuantity(item),
      quantityType: quantityTypes[item._id]
    }));

    onConfirm({
      ...formData,
      items: workorderItems
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Create Work Order</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Work Order Name"
              fullWidth
              variant="outlined"
              onFocus={handleFocus}
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              value={formData.serviceType}
              onChange={(_, newValue) => setFormData(prev => ({ ...prev, serviceType: newValue }))}
              options={serviceTypes || []}
              getOptionLabel={(option) => `${option.name} (${option.serviceCode})`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Service Type"
                  variant="outlined"
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={2}
              onFocus={handleFocus}
              variant="outlined"
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              required
              helperText="Description of work order (Customer will see on invoice)"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Work Order Notes"
              fullWidth
              multiline
              rows={3}
              onFocus={handleFocus}
              variant="outlined"
              value={formData.workorderNotes}
              onChange={(e) => setFormData(prev => ({ ...prev, workorderNotes: e.target.value }))}
              helperText="Internal notes (Field workers will see in app)"
            />
          </Grid>
        </Grid>

        <Divider style={{ margin: '24px 0' }} />

        <Typography variant="h6" gutterBottom>
          Line Items and Quantities
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align="right">Available Qty</TableCell>
              <TableCell>Assignment</TableCell>
              <TableCell align="right">To Use</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((item) => {
              const remainingQty = getRemainingQty(item, workorders);
              const usedQty = calculateUsedQuantity(item._id, workorders);

              return (
                <TableRow key={item._id}>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>
                    Original: {item.qty} {item.unit}
                    <br />
                    <Typography variant="caption" color="textSecondary">
                      Used: {usedQty.toFixed(2)} {item.unit}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" style={{ color: 'green' }}>
                    {remainingQty.toFixed(2)} {item.unit}
                  </TableCell>
                  <TableCell>
                    <div className={classes.quantityCell}>
                      <FormControl className={classes.typeSelect}>
                        <Select
                          value={quantityTypes[item._id] || 'quantity'}
                          onChange={(e) => handleQuantityTypeChange(item._id, e.target.value)}
                        >
                          <MenuItem value="quantity">Qty</MenuItem>
                          <MenuItem value="percentage">%</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        type="number"
                        value={quantities[item._id] || ''}
                        onChange={(e) => handleQuantityChange(item._id, parseFloat(e.target.value) || 0)}
                        variant="outlined"
                        size="small"
                        onFocus={handleFocus}
                        className={classes.quantityField}
                        helperText={
                          quantityTypes[item._id] === 'percentage' 
                            ? `${calculateActualQuantity(item).toFixed(2)} ${item.unit}`
                            : `Max: ${remainingQty.toFixed(2)} ${item.unit}`
                        }
                      />
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    {calculateActualQuantity(item).toFixed(2)} {item.unit}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button 
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!formData.name || !formData.serviceType || !formData.description}
        >
          Create Work Order
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// InvoiceModal Component
export const InvoiceModal = ({
  open,
  onClose,
  selectedItems,
  invoices,
  onConfirm,
  classes // Pass the existing classes from parent
}) => {
  // Form state
  const [formData, setFormData] = useState({
    invoiceNumber: '',
    name: '',
    date: new Date().toISOString().split('T')[0],
    notes: ''
  });

  // Amount allocation state
  const [amounts, setAmounts] = useState({});
  const [amountTypes, setAmountTypes] = useState({});

  // Initialize amounts when modal opens
  React.useEffect(() => {
    if (open) {
      const initialAmounts = {};
      const initialTypes = {};
      selectedItems.forEach(item => {
        const remainingAmount = getRemainingAmount(item, invoices);
        initialAmounts[item._id] = remainingAmount;
        initialTypes[item._id] = 'amount';
      });
      setAmounts(initialAmounts);
      setAmountTypes(initialTypes);
    }
  }, [open, selectedItems, invoices]);

  const handleAmountChange = (itemId, value) => {
    const item = selectedItems.find(i => i._id === itemId);
    const remainingAmount = getRemainingAmount(item, invoices);
    
    let newValue = value;
    if (amountTypes[itemId] === 'percentage') {
      newValue = Math.min(100, Math.max(0, value));
    } else {
      newValue = Math.min(remainingAmount, Math.max(0, value));
    }

    setAmounts(prev => ({
      ...prev,
      [itemId]: newValue
    }));
  };

  const handleAmountTypeChange = (itemId, type) => {
    const item = selectedItems.find(i => i._id === itemId);
    const remainingAmount = getRemainingAmount(item, invoices);
    
    setAmountTypes(prev => ({
      ...prev,
      [itemId]: type
    }));
    
    // Reset amount when changing type
    setAmounts(prev => ({
      ...prev,
      [itemId]: type === 'percentage' ? 100 : remainingAmount
    }));
  };

  const calculateFinalAmount = (item) => {
    const type = amountTypes[item._id];
    const amount = amounts[item._id] || 0;
    const remainingAmount = getRemainingAmount(item, invoices);
    
    if (type === 'percentage') {
      return (remainingAmount * amount) / 100;
    }
    return amount;
  };

  const calculateTotal = () => {
    return selectedItems.reduce((total, item) => total + calculateFinalAmount(item), 0);
  };

  const handleSubmit = () => {
    const invoiceItems = selectedItems.map(item => ({
      ...item,
      invoiceAmount: calculateFinalAmount(item),
      amountType: amountTypes[item._id]
    }));

    onConfirm({
      ...formData,
      items: invoiceItems,
      total: calculateTotal()
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Create Invoice</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Invoice Name"
              fullWidth
              variant="outlined"
              onFocus={handleFocus}
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Invoice Date"
              type="date"
              fullWidth
              variant="outlined"
              onFocus={handleFocus}
              value={formData.date}
              onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
              InputLabelProps={{
                shrink: true,
              }}
            
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Notes"
              fullWidth
              multiline
              onFocus={handleFocus}
              rows={3}
              variant="outlined"
              value={formData.notes}
              onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
            />
          </Grid>
        </Grid>

        <Divider style={{ margin: '24px 0' }} />

        <Typography variant="h6" gutterBottom>
          Line Items
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align="right">Total Amount</TableCell>
              <TableCell>Invoice Amount</TableCell>
              <TableCell align="right">Already Invoiced</TableCell>
              <TableCell align="right">To Invoice</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((item) => {
              const invoicedAmount = calculateInvoicedAmount(item._id, invoices);
              const remainingAmount = getRemainingAmount(item, invoices);
              
              return (
                <TableRow key={item._id}>
                  <TableCell>{item.description}</TableCell>
                  <TableCell align="right">{currencyFormat(item.totalPrice)}</TableCell>
                  <TableCell>
                    <div className={classes.quantityCell}>
                      <FormControl className={classes.typeSelect}>
                        <Select
                          value={amountTypes[item._id] || 'amount'}
                          onChange={(e) => handleAmountTypeChange(item._id, e.target.value)}
                        >
                          <MenuItem value="amount">$</MenuItem>
                          <MenuItem value="percentage">%</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        type="number"
                        value={amounts[item._id] || ''}
                        onChange={(e) => handleAmountChange(item._id, parseFloat(e.target.value) || 0)}
                        variant="outlined"
                        size="small"
                        onFocus={handleFocus}
                        className={classes.quantityField}
                      />
                      <Tooltip title={
                        amountTypes[item._id] === 'percentage'
                          ? `Will invoice ${currencyFormat(calculateFinalAmount(item))}`
                          : `Direct amount to invoice`
                      }>
                        <IconButton size="small">
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell align="right">{currencyFormat(invoicedAmount)}</TableCell>
                  <TableCell align="right">{currencyFormat(calculateFinalAmount(item))}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell colSpan={4} align="right">
                <strong>Total to Invoice:</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{currencyFormat(calculateTotal())}</strong>
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell colSpan={4} align="right">
                <strong>Total to Invoice:</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{currencyFormat(calculateTotal())}</strong>
              </TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button 
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={calculateTotal() < 1 || !formData.date}
        >
          Create Invoice
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Helper functions that should be accessible to both modals
const getRemainingQty = (item, workorders = []) => {
  const originalQty = parseFloat(item.qty) || 0;
  const usedQty = workorders.reduce((total, wo) => {
    const lineItem = wo.lineItems?.find(i => i._id === item._id);
    return total + (lineItem ? parseFloat(lineItem.qty) || 0 : 0);
  }, 0);
  console.log('Item remaining: ', originalQty, usedQty, item)
  return Math.max(0, originalQty - usedQty);
};

const calculateInvoicedAmount = (lineItemId, invoices = []) => {
  return invoices.reduce((total, invoice) => {
    const lineItem = invoice.estimateLineItems?.find(i => i._id === lineItemId);
    return total + (lineItem ? parseFloat(lineItem.invoiceAmount) || 0 : 0);
  }, 0);
};

const getRemainingAmount = (item, invoices = []) => {
  const totalAmount = parseFloat(item.totalPrice) || 0;
  const invoicedAmount = calculateInvoicedAmount(item._id, invoices);
  return Math.max(0, totalAmount - invoicedAmount);
};

// export { WorkorderModal, InvoiceModal };
