import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  TextField,
  makeStyles,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import axios from 'axios'
import { connect } from 'react-redux'
import WorkorderTable from './List';

const WorkordersReadyToInvoice = (props) => {
  const [workorders, setWorkorders] = useState([])
  useEffect(() => {
    let isMounted = true

    const fetchWorkorders = async () => {
      console.log('Fetch Workorders...')
      axios({
        method: 'get',
        url:  `${props.url}/api/jobs/getClientWorkorders?specialSearch=readyToInvoice`,
      }).then((response) => {
        console.log('WO ready to invoicedata: ', response.data)
        if (response?.data?.length) {
          setWorkorders(response.data)
        }
      }).catch((err) => {
        console.log('Error fetching invoices ', err)
      })
    }

    fetchWorkorders()
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div>
      <WorkorderTable workorders={workorders} />
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    user: state.user
  }
}

export default connect(mapStateToProps)(WorkordersReadyToInvoice)
