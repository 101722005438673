import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography,
  TextField,
  Box,
  Chip,
  TableSortLabel,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from '@material-ui/core';
import {
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  Build as BuildIcon,
  WbIncandescent as WbIncandescentIcon,
  Opacity as OpacityIcon,
  VpnKey as KeyIcon,
  Notes as NotesIcon,
  MoreVert as MoreVertIcon,
  OpenInNew as OpenInNewIcon,
  TrendingUp,
  TrendingDown
} from '@material-ui/icons';
import { sortBy } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.grey[100],
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    // backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1),
   
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
    
  },
  headerCell: {
   
    fontWeight: 600,
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
    borderBottom: 'none',
    padding: theme.spacing(1, 2),
  },
  cell: {
    backgroundColor: theme.palette.background.paper,
    borderTop: '1px solid',
    // borderBottom: '1px solid',
    borderColor: theme.palette.grey[200],
    padding: theme.spacing(2),
    '&:first-child': {
      borderLeft: '1px solid',
      borderColor: theme.palette.grey[200],
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    '&:last-child': {
      // borderRight: '1px solid',
      borderColor: theme.palette.grey[200],
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
  },
  row: {
    '&:hover td': {
      backgroundColor: theme.palette.action.hover,
      borderColor: theme.palette.grey[300],
    },
  },
  searchBox: {
    marginBottom: theme.spacing(3),
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  clientName: {
    fontWeight: 600,
    fontSize: '0.95rem',
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(0.5),
  },
  address: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
  serviceChecks: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
  },
  serviceCheck: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  serviceIcon: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(0.5),
  },
  serviceIconInactive: {
    color: theme.palette.grey[300],
    marginBottom: theme.spacing(0.5),
  },
  serviceDate: {
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
  },
  crewChip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
  },
  frequencyChip: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  infoChips: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  notes: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  notesText: {
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 1),
  },
  actionCell: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  openButton: {
    minWidth: 'unset',
    padding: theme.spacing(1),
  },
  profitabilityIndicator: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    cursor: 'pointer'
  },
  profitabilityText: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
  profitAboveAvg: {
    color: theme.palette.success.main,
  },
  profitBelowAvg: {
    color: theme.palette.error.main,
  },
  frequencyFilters: {
    display: 'flex',
    gap: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  frequencyChipFilter: {
    '&.MuiChip-root': {
      backgroundColor: '#eee',
      border: `1px solid ${theme.palette.grey[300]}`,
      '&.Mui-selected, &[color="primary"]': {
        // backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: theme.shadows[1],
      },
      '&:hover': {
        backgroundColor: theme.palette.grey[50],
      },
    },
  },
  frequencyChipFilterSelected: {
    // backgroundColor: 'blue',
    '&.MuiChip-root': {
      backgroundColor: '#accacd',
      border: `1px solid ${theme.palette.grey[300]}`,
      '&.Mui-selected, &[color="primary"]': {
        // backgroundColor: 'pink',
        color: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: theme.shadows[1],
      },
      '&:hover': {
        backgroundColor: theme.palette.grey[50],
      },
    },
  },
  toolbarContent: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
  searchAndFilters: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
}));

const frequencies = ['Weekly', 'Bi-Weekly', 'Variable'];

const ProfitabilityIndicator = React.memo(({ profitability, classes }) => {
  if (!profitability?.hourlyRate) return null;

  const profitabilityClass = profitability.isAboveAverage 
    ? classes.profitAboveAvg 
    : classes.profitBelowAvg;
  return (
    <div className={classes.profitabilityIndicator}>
       <Tooltip title={profitability.isAboveAverage ? "Above Average" : "Below Average"}>
      <Typography 
         className={`${classes.profitabilityText} ${profitabilityClass}`}
      >
       
        {profitability.isAboveAverage ? (
         
             <TrendingUp fontSize="small" />
        
         
        ) : (
         
            <TrendingDown fontSize="small" />
        
        )}
        {profitability.percentFromAverage.toFixed(2)}% 
      </Typography>
      </Tooltip>
    </div>
  );
});


const FrequencyFilter = ({ selectedFrequencies, onToggleFrequency }) => {
  const classes = useStyles();
  return (
    <div className={classes.frequencyFilters}>
      {frequencies.map((freq) => {
        const isSelected = selectedFrequencies.includes(freq)
        
        return (
        <Chip
          key={freq}
          label={freq}
          clickable
          onClick={() => onToggleFrequency(freq)}
          // color={ isSelected ? 'primary' : 'secondary'}
          className={isSelected ? classes.frequencyChipFilterSelected : classes.frequencyChipFilter}
        />
      )})}
    </div>
  );
};

const TableRowMemo = React.memo(({ ag, crew, classes, onOpenCustomer, onMenuOpen }) => {
  const frequencyLabel = `${ag?.frequency || ''}${ag?.preferredDay ? `  - ${ag.preferredDay}` : ''}`
  
  return (
  <TableRow className={classes.row}>
    <TableCell className={classes.cell}>
      <Box>
        <div className={classes.status}>
          <Tooltip title="Up to date">
            <CheckCircleIcon className={classes.checkIcon} />
          </Tooltip>
          <Typography className={classes.clientName}>
            {ag.clientName}
          </Typography>
        </div>
        <Typography variant="body2" className={classes.address}>
          {ag.address}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {ag.city}, {ag.state} {ag.zip}
        </Typography>
        <ProfitabilityIndicator profitability={ag.profitability} classes={classes} />
      </Box>
    </TableCell>
    <TableCell className={classes.cell}>
      <div className={classes.serviceChecks}>
        <ServiceCheck
          date={ag.lastIrrigationCheck}
          Icon={BuildIcon}
          label="Irrigation"
        />
        <ServiceCheck
          date={ag.lastWaterFeatureCheck}
          Icon={OpacityIcon}
          label="Water Feature"
        />
        <ServiceCheck
          date={ag.lastLightingCheck}
          Icon={WbIncandescentIcon}
          label="Lighting"
        />
      </div>
    </TableCell>
    <TableCell className={classes.cell}>
      <div className={classes.infoChips}>
        <Chip
          size="small"
          label={crew?.name || 'Unassigned'}
          className={classes.crewChip}
        />
        <Chip
          size="small"
          label={frequencyLabel}
          className={classes.frequencyChip}
        />
      </div>
    </TableCell>
    <TableCell className={classes.cell}>
      <div className={classes.actionCell}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.openButton}
          onClick={() => onOpenCustomer(ag)}
          startIcon={<OpenInNewIcon />}
        >
          Open
        </Button>
        <IconButton
          size="small"
          onClick={(event) => onMenuOpen(event, ag)}
        >
          <MoreVertIcon />
        </IconButton>
      </div>
    </TableCell>
  </TableRow>
)});

const formatFrequency = (frequency) => {
  if (!frequency) return null
  let frequencyToUse = frequency.toLowerCase()
  // console.log('Frequency to use', frequencyToUse)
  if (frequencyToUse === 'biweekly') {
    frequencyToUse = 'Bi-Weekly'
  }
  if (frequencyToUse === 'weekly') {
    frequencyToUse = 'Weekly'
  }
  if (frequencyToUse === 'variable') {
    frequencyToUse = 'Variable'
  }
  return frequencyToUse
}

const ServiceCheck = React.memo(({ date, Icon, label }) => {
  const classes = useStyles();
  const formattedDate = date ? new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  }) : '-';

  return (
    <div className={classes.serviceCheck}>
      <Tooltip title={label}>
        <Icon 
          className={date ? classes.serviceIcon : classes.serviceIconInactive} 
        />
      </Tooltip>
      <Typography className={classes.serviceDate}>
        {formattedDate}
      </Typography>
    </div>
  );
});

const JobSiteList = ({ ags = [], crews = [] }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('client');
  const [order, setOrder] = useState('asc');
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedAg, setSelectedAg] = useState(null);
  const [selectedFrequencies, setSelectedFrequencies] = useState(frequencies);

  const handleToggleFrequency = (frequency) => {
    console.log(frequency)
    console.log(selectedFrequencies)
    setSelectedFrequencies(prev => 
      prev.includes(frequency)
        ? prev.filter(f => f !== frequency)
        : [...prev, frequency]
    );
  };

  const formatDate = (date) => {
    if (!date) return '-';
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getComparator = useMemo(() => {
    return (a, b) => {
      let compareA, compareB;
  
      switch (orderBy) {
        case 'profitability':
          compareA = a.profitability?.percentFromAverage ?? -Infinity;
          compareB = b.profitability?.percentFromAverage ?? -Infinity;
          break;
        case 'clientName':
          compareA = a.clientName?.toLowerCase() || '';
          compareB = b.clientName?.toLowerCase() || '';
          break;
        case 'irrigation':
          compareA = new Date(a.lastIrrigationCheck || 0).getTime();
          compareB = new Date(b.lastIrrigationCheck || 0).getTime();
          break;
        case 'water':
          compareA = new Date(a.lastWaterFeatureCheck || 0).getTime();
          compareB = new Date(b.lastWaterFeatureCheck || 0).getTime();
          break;
        case 'lighting':
          compareA = new Date(a.lastLightingCheck || 0).getTime();
          compareB = new Date(b.lastLightingCheck || 0).getTime();
          break;
        case 'crew':
          const crewA = crews?.find(item => item._id === a.crew)?.name?.toLowerCase() || '';
          const crewB = crews?.find(item => item._id === b.crew)?.name?.toLowerCase() || '';
          compareA = crewA;
          compareB = crewB;
          break;
        default:
          compareA = a[orderBy];
          compareB = b[orderBy];
      }
  
      if (order === 'desc') {
        return compareB < compareA ? -1 : compareB > compareA ? 1 : 0;
      }
      return compareA < compareB ? -1 : compareA > compareB ? 1 : 0;
    };
  }, [orderBy, order, crews]);

  const filteredAndSortedSites = useMemo(() => {
    return ags
      .filter(ag => {
        // Text search filter
        const searchTerms = searchTerm
          .toLowerCase()
          .split(' ')
          .filter(term => term.length > 0);

        const searchableFields = [
          'clientName',
          'address',
          'city',
          'state',
          'zip',
          'siteNotes',
          'gateCode',
          'crew'
        ];

        const searchMatch = searchTerms.length === 0 || searchTerms.every(term =>
          searchableFields.some(field => 
            ag[field]?.toLowerCase().includes(term)
          )
        );
        // console.log("ag.frequency", ag.frequency)
        const formattedFreq = formatFrequency(ag.frequency)
        // console.log("formatted", formattedFreq)
        // Frequency filter
        // const frequencyMatch = selectedFrequencies.length === 0 || 
        const frequencyMatch = selectedFrequencies.includes(ag.frequency);
        // console.log('Frequency match...', frequencyMatch)
        return searchMatch && frequencyMatch;
      })
      .sort(getComparator);
  }, [ags, searchTerm, selectedFrequencies, getComparator]);

  // const ServiceCheck = ({ date, Icon, label }) => (
  //   <div className={classes.serviceCheck}>
  //     <Tooltip title={label}>
  //       <Icon 
  //         className={date ? classes.serviceIcon : classes.serviceIconInactive} 
  //       />
  //     </Tooltip>
  //     <Typography className={classes.serviceDate}>
  //       {formatDate(date)}
  //     </Typography>
  //   </div>
  // );

  const needsAttention = (ag) => {
    return false
  }
  const handleMenuOpen = (event, ag) => {
    setMenuAnchor(event.currentTarget);
    setSelectedAg(ag);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSelectedAg(null);
  };
  const handleOpenCustomer = (ag) => {
    window.open(`/clients/${ag?.clientId ? ag.clientId : ag?.client ? ag.client : ''}${ag?._id ? `/mtcAgreement/${ag?._id }` : ''}`, '_blank');
  };

  const handleCreateKISS = () => {
    // Implement KISS creation logic
    handleMenuClose();
  };

  const handleCreateKICK = () => {
    // Implement KICK creation logic
    handleMenuClose();
  };

  // console.log("sort", sortBy, orderBy)
  console.log('Selected frequencies', selectedFrequencies)
  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <TextField
          className={classes.searchBox}
          variant="outlined"
          size="small"
          placeholder="Search sites..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
         <FrequencyFilter
              selectedFrequencies={selectedFrequencies}
              onToggleFrequency={handleToggleFrequency}
            />
        <Typography variant="subtitle2" color="textSecondary">
          {filteredAndSortedSites.length} / {ags?.length} Sites Match
        </Typography>
      </div>
      
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} stickyHeader>
          <TableHead style={{ backgroundColor: 'rgb(245 245 245)' }}>
            <TableRow>
            <TableCell className={classes.headerCell}>
                <Box>
                  <TableSortLabel
                    active={orderBy === 'clientName'}
                    direction={orderBy === 'clientName' ? order : 'asc'}
                    onClick={() => handleSort('clientName')}
                  >
                    Client & Location
                  </TableSortLabel>
                  <Box mt={1}>
                    <TableSortLabel
                      active={orderBy === 'profitability'}
                      direction={orderBy === 'profitability' ? order : 'asc'}
                      onClick={() => handleSort('profitability')}
                    >
                      <Typography variant="caption" color="textSecondary">
                        Profitability
                      </Typography>
                    </TableSortLabel>
                  </Box>
                </Box>
              </TableCell>
              <TableCell className={classes.headerCell}>
                <Box>
                  Service Checks
                  <Box display="flex" gap={4} mt={1}>
                    <TableSortLabel
                      active={orderBy === 'irrigation'}
                      direction={orderBy === 'irrigation' ? order : 'asc'}
                      onClick={() => handleSort('irrigation')}
                    >
                      <BuildIcon fontSize="small" />
                    </TableSortLabel>
                    <TableSortLabel
                      active={orderBy === 'water'}
                      direction={orderBy === 'water' ? order : 'asc'}
                      onClick={() => handleSort('water')}
                    >
                      <OpacityIcon fontSize="small" />
                    </TableSortLabel>
                    <TableSortLabel
                      active={orderBy === 'lighting'}
                      direction={orderBy === 'lighting' ? order : 'asc'}
                      onClick={() => handleSort('lighting')}
                    >
                      <WbIncandescentIcon fontSize="small" />
                    </TableSortLabel>
                  </Box>
                </Box>
              </TableCell>
              <TableCell className={classes.headerCell}>
                <TableSortLabel
                  active={orderBy === 'crew'}
                  direction={orderBy === 'crew' ? order : 'asc'}
                  onClick={() => handleSort('crew')}
                >
                  Crew & Schedule
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.headerCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedSites.map((ag) => {
              const crew = crews?.find(item => item._id === ag.crew);
              return (
                <TableRowMemo
                  key={ag._id}
                  ag={ag}
                  crew={crew}
                  classes={classes}
                  onOpenCustomer={handleOpenCustomer}
                  onMenuOpen={handleMenuOpen}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleCreateKISS}>Create KISS</MenuItem>
        <MenuItem onClick={handleCreateKICK}>Create KICK</MenuItem>
      </Menu>
    </div>
  );
};

export default JobSiteList;
