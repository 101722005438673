import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetailList from './ServiceDetailList'
import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import EditJobModal from './EditJobModal'
// import DialogContentText from '@material-ui/core/DialogContentText';
import ProtectedButton from '../../actions/protectedButtons'
import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import MoneyIcon from '@material-ui/icons/AttachMoney';
import FollowingComponent from '../Common/FollowingComponent'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  }
}));

// function htmlToText(html){
//   // console.log('Strip html tags: ', html)
//   //remove code brakes and tabs
//   html = html.replace(/\n/g, "");
//   html = html.replace(/\t/g, "");

//   //keep html brakes and tabs
//   html = html.replace(/<table>/g, "");
//   html = html.replace(/<tr>/g, "");
//   html = html.replace(/<td>/g, "");
//   html = html.replace(/<\/td>/g, "\t");
//   html = html.replace(/<\/table>/g, "\n");
//   html = html.replace(/<\/tr>/g, "\n");
//   html = html.replace(/<\/p>/g, "\n");
//   html = html.replace(/<\/div>/g, "\n");
//   html = html.replace(/<div>/g, "");
//   html = html.replace(/<\/h>/g, "\n");
//   html = html.replace(/<br>/g, "\n"); 
//   html = html.replace(/<br( )*\/>/g, "\n");
//   // console.log('Stripped: ', html)
//   //parse html into text
//   // var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
//   // return dom.body.textContent;
//   return html;
// }

const JobDetail = (props) => {
  // console.log('Job Details (Looking for deposits::): ', props)
  const classes = useStyles();
  let servicesSelected = []
  const [loading, setLoading] = React.useState(false)
  const [job, setJob] = React.useState({})
  const [open, setOpen] = React.useState(false)
  // const [jobDetails, setJobDetails] = React.useState([])
  const [crewList, setCrewList] = React.useState(props.crewlist)
  const [serviceList, setServiceList] = React.useState(props.serviceList)
  const [state, setState] = React.useState({
    notes: ' ',
    description: ' ',
    crew: ' ',
    date: moment(Date.now()).format('YYYY-MM-DD'),
    time: ' ',
    price: ' ',
    job: {},
    showInvoiceModal: false,
    pdf: '',
    editJobName: false,
    jobName: props.job.Job,
    showEditJobModal: false,
    workorders: []
  })
  React.useEffect(() => {
    
    // console.log('Use Effect Job Details')
    setOpen(props.open)
  }, [props.open])

  React.useEffect(() => {
    // console.log('PROPS.job has changed')
    const getJobDetails = (job) => {
      // console.log('Get the Job Details here!!', job)
      if (job && job._id) {
       axios({
         method: 'get',
         url:  `${props.url}/api/customer/jobservicedetails?id=${job._id}`,
         // data: data,
         // validateStatus: (status) => {
         //   // console.log('Validate status: ', status)
         //   if (status && status === 500) {
     
         //   }
         // },
       }).then(response => {
         // console.log('JOB DETAILS!!', response.data)
        if (isMounted) setState({...state, workorders: response.data })
        //  // console.log('State: ', state)
       })
      }
    }

    let isMounted = true
    // console.log('Use Effect Job Details', job)
    if (props.job && props.job._id) {
      // if (!job._id)
      getJobDetails(props.job)
      setJob(props.job)
    } 
    // setOpen(props.open)
    setServiceList(props.serviceList)
    setCrewList(props.crewList)
    setState({...state, jobName: props.job.Job})
    servicesSelected = []
    return () => {
      isMounted = false
    }
  }, [props.job])

  function currencyFormat(num) {
    if (!num) num = 0
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

const handleClose = () => {
  // console.log('Closing this out - clear the crew and notes')
  // setState({...state, crew: ' ', notes: ' '})
  // setJob({
  //   Job: '',
  //   SiteName: '',
  //   address: ''
  // })
  // setJobDetails([])
  // setOpen(false)
  // // console.log(state)
  props.jobDetailsResponse('Close')
}

const handleConfirm = (data, callback) => {
  // console.log('CONFIRMED!!', data)
  if (data && data.updateType && (data.updateType === 'complete' || data.updateType === 'noInvoice')) {
    data.client = props.client
    data.user = props.user
    if (data.updateType === 'noInvoice') data.noInvoice = true
    // console.log('Complete this job!!')
    delete data.crewList
    axios({
      method: 'post',
      url:  `${props.url}/api/jobs/completeWorkorder`,
      data: data,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Axios response from COMPLETING WORKORDER: ', response.data)
      props.notification({
        type: 'success',
        title: 'Workorder Completed!',
        message: 'Congratulations - You did it!'
      })
      if (data && data.service && data.service.servicetype && data.service.servicetype.Value === '423') {
        // console.log('This is MAINTENANCE NO INVOICE')
      } else if (response.data && response.data.success) {
      // console.log('No invoice...')
        let cleanWOs = state.workorders
        let wo = response.data.workorder
        let index = cleanWOs.findIndex(item => (item._id === wo._id))
        if (index > -1) {
          cleanWOs[index] = response.data.workorder
          setState({...state, workorders: cleanWOs, showInvoiceModal: false}) 
        }

      } else {
        // console.log('Open window about INVOICING')
        if (response && response.data) {
          // console.log('Display oru PDF!')
          setState({...state, pdf: response.data, workorder: data.workorder, showInvoiceModal: true})
        } else {
          // console.log('WTF no pdf??')
          setState({...state, workorder: data.workorder, showInvoiceModal: true}) 
        }
      }
      // props.jobDetailsResponse(response)
      // setOpen(false)
    })
  } else {
    console.log('Save this job', data)
    data.client = props.client
    data.project = props.project
    data.user = props.user
    console.log('Remove redundant info from wo?? 2025-01-12')
    if (data?.workorder?.crewList) delete data.workorder.crewList
    if (data?.workorder?.serviceList) delete data.workorder.serviceList
    axios({
      method: 'post',
      url:  `${props.url}/api/jobs/updateWorkorder`,
      data: data,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Axios response: ', response)

      // IF this was new we need to add our WorkNumber
      if (data.service && data.service.number) {
        // console.log('Existin job - dont need to do anything')
        return callback(response)
      } else {
        // console.log('New job -- add the number we got back')
        if (response && response.data && response.data._id) {
          let num =response.data.number
          // console.log('Our new WORK NUM', num)
          props.notification({
            type: 'success',
            title: 'Job Saved!',
            message: `Workorder #${num} has been created.`
          })
          return callback(response.data)
        }
      }
      // props.jobDetailsResponse(response)
      // setOpen(false)
    })
  }
}
const updateJobScheduledDate = (wo) => {
  // console.log('Update Job Start Date based on this: ', wo)
  let newJob = job
  newJob.startDate = wo.scheduledDate
  // console.log('New Job: ', newJob)
  setJob(newJob)
}

const updateOurJob = (data, updateType, callback) => {
  // console.log('Update the job from Job Details; ', data)
  updateJobScheduledDate(data.workorder)
  // console.log(servicesSelected)
  // console.log('Type of Update:', updateType)
  if (updateType === 'select') {
    servicesSelected.push(data)
  } else if (updateType === 'unselect') {
    // Remove item from selected list
    let index = servicesSelected.findIndex(item =>  (parseInt(item['number']) === parseInt(data['number'])))
    // console.log('Index of item to remvove from serices selected: ', index)
    servicesSelected.splice(index, 1)
  } else {
      // Find this job and update it...
    // console.log(state)
    // console.log(job)
    let updateObject = {
      job,
      workorder: data,
      updateType: updateType
    }
    handleConfirm(updateObject, (num) => {
      // console.log('Got a new workorder num', num)
      // console.log(state)
      // console.log(job)
      return callback(num)
    })
  }

}
const updateFromInvoiceModal = (d) => {
  // console.log('Close invoice modal', d)
  if (d === 'approved') props.jobDetailsResponse('update')
  setState({...state, showInvoiceModal: false})
}

const handleInvoiceSelected = () => {
  if (servicesSelected.length > 0) {
    setLoading(true)
    // console.log('invoice these: ', servicesSelected)
    let updateObject = {
      job,
      service: servicesSelected,
      updateType: 'invoiceGroup'
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/customer/groupInvoice`,
      data: updateObject,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {

      //   }
      // },
    }).then(response => {
      setLoading(false)
      // console.log('Axios response to bulk invoice: ', response)
      if (response && response.data) {
        // console.log('Display oru PDF!')
        setState({...state, pdf: response.data, showInvoiceModal: true})
      } else {
        // console.log('WTF no pdf??')
        setState({...state, showInvoiceModal: true}) 
      }
      props.notification({
        type: 'success',
        title: 'Invoice Generated',
        message: 'We Have Created That Invoice!'
      })
    })
  } else {
    // console.log('No services selected')
    props.notification({
      type: 'warning',
      title: 'Oopsie',
      message: 'You Must Select Services To Invoice First'
    })
  }
}

const saveJob = (newJob) => {
  // console.log('Save this job: ', newJob)
}
const offerJobEditOptions = () => {
  // console.log('Offer ability to edit job now')
  setState({...state, editJobName: true})
}
const saveJobName = () => {
  // console.log('Save the job nam')
  let newJob = job
  newJob.Job = state.jobName
  setJob(newJob)
  setState({...state, editJobName: false})
  saveJob(newJob)
}
const updateJobName = (e) => {
  // console.log('Update Job Name: ', e)
  let name = e.target.value
  // console.log('Change to ', name)
  setState({...state, jobName: name})
}
const JobName = (props) => {
  // console.log('PROPS JOBNAME: ', props)
  if (props?.job?.estimate) {
    return (
      <span>
         {job.name} &nbsp;&nbsp;&nbsp;
        {/* <a href={`/clients/${props.job.client}/Estimates/${props.job.estimate}`}>{job.name}</a> &nbsp;&nbsp;&nbsp; */}
        <Button
        variant="contained"
        // style={{backgroundColor: '#ed4545'}}
        className={classes.button}
        size="small"
        // color="primary"
        startIcon={<EditRoundedIcon />}
        // onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteService() } }
        // onClick={handleDeleteService}
        // onClick={offerJobEditOptions}
        onClick={ () => { setState({...state, showEditJobModal: true})} }
        >
        </Button>
        <Tooltip
          title="View Estimate">
            <Link 
              style={{pointer: 'cursor'}}
              target="_blank"
              to={{
                    pathname: `/clients/${props.job.client}/Estimates/${props.job.estimate}`,
                  }}>
                View Estimate                
            </Link>
          </Tooltip>
       
      </span>
    )
  } else {
    return (
      <span>{job.name} &nbsp;&nbsp;&nbsp;
      <Button
      variant="contained"
      // style={{backgroundColor: '#ed4545'}}
      className={classes.button}
      size="small"
      // color="primary"
      startIcon={<EditRoundedIcon />}
      // onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteService() } }
      // onClick={handleDeleteService}
      // onClick={offerJobEditOptions}
      onClick={ () => { setState({...state, showEditJobModal: true})} }
      >
      </Button>
      </span>
    )
  }

}
const handleDelete = () => {
  if (window.confirm('Are you sure you wish to delete this Job and ALL associated Work Orders?')) {
    // console.log('Delete this workorder!!', job)
    let ourURL =  `${props.url}/api/customer/deleteWorkorder?id=${job._id}`
    // console.log('URL to send to: ', ourURL)
    axios({
      method: 'get',
      url: ourURL,
      // data: data,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {

      //   }
      // },
    }).then(response => {
      // console.log('REsponse from deleting workorder: ', response)
      if (response && response.data && response.data.deletedCount && response.data.deletedCount > 0) {
        props.notification({
          type: 'success',
          title: 'Work Order Deleted!',
          message: 'This Work Order Was Deleted'
        })
        props.jobDetailsResponse('Delete', job.number)
      } else {
        props.notification({
          type: 'warning',
          title: 'Failed To Delete Work Order!',
          message: response.data.Message
        })
      }
      
    }).catch(err => {
      console.log('Error deleting jobs', err)
      props.notification({
        type: 'warning',
        title: 'Failed To Delete Work Order!',
        message: err.message
      })
    })
  }
}
const updateFromEditJobModal = (d, l) => {
  // console.log('updateFromEditJobModal', d)
  if (d === 'Edited') {
    // console.log('Updated our job name - lets go ahead and use the one from there', l)
    // let newJob = job
    // newJob.Job = l
    // console.log('New Job to Save: ', newJob)
    setJob(l)
  }
  setState({...state, showEditJobModal: false})
}
const updateInvoiceStatus = () => {
  // do nothing
  return null
}

const handleInvoiceEntireJob = () => {
  axios({
    method: 'post',
    url:  `${props.url}/api/jobs/invoiceEntireJob`,
    data: {
      jobId: job._id
    },
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response from COMPLETING WORKORDER: ', response)
    props.notification({
      type: 'success',
      title: 'Invoice Created!',
      message: 'Congratulations - You did it!'
    })
  })
}
  let matchingDeposits = []
  // props.deposits.forEach(dep => {
  //   // if (dep.jobNumber)
  // })
  // console.log('Props.deposits')
  // for (const dep of props.deposits) {
  //   console.log(dep)
  // }
//  console.log('job details', props)
return (
  <div className={classes.root}>
    <EditJobModal client={props.client} jobSites={props.jobSites} projects={props.projects} job={job} jobDetailsResponse={updateFromEditJobModal} open={state.showEditJobModal} />
    <InvoiceOptionsModal client={props.client} updateInvoiceStatus={updateInvoiceStatus} workorder={state.workorder} pdf={state.pdf} jobDetailsResponse={updateFromInvoiceModal} open={state.showInvoiceModal} />
    {/* <Button size="small" variant="contained" color="secondary" onClick={() => { handleOpen() }}>Add Location</Button> */}
    <Dialog
      // fullScreen={true}
      maxWidth='xl'
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Modify Job ({job.number})</DialogTitle>
      <DialogContent> 
            <Grid container className={classes.grid}>
              <Grid item lg={12} style={{position: 'absolute', right: '50px', top: '20px'}}>
                <ProtectedButton type="Jobs" kind="delete" perms={props.perms || []}>
                  <Button onClick={handleDelete}>
                    Delete Job
                  </Button>
                </ProtectedButton>
              </Grid>
              <Grid item lg={4} xs={6} >
                <JobName job={job} /><br/>
                {/* Site Name: {job.SiteName}<br/> */}
                Address: {job.jobSiteAddress}<br/>
                Sales Rep: {job.salesRepName}<br/>
                Scheduled: {moment(job.startDate).format('MM/DD/YYYY (ddd)')}<br/>
                Deposit Amount: {currencyFormat(job.depositAmount)} (Balance: {currencyFormat(job.depositBalance)})
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FollowingComponent data={props.job} type={'Job'} _id={job._id} />
              </Grid>
              {/* <Grid item lg={8} xs={12} >
                {
                  (job && job.proposalLineItems && job.proposalLineItems.length) &&  <ProposalLineItems job={job} />
                }
               

              </Grid> */}
            </Grid>
            <ServiceDetailList client={props.client} uploads={props.uploads} workorders={state.workorders} project={props.project} updateOurJob={updateOurJob} job={job} crewList={crewList} serviceList={serviceList} taxLocales={props.taxLocales} />
    </DialogContent>
    <DialogActions>
    {/* <Button disabled={loading} onClick={handleInvoiceSelected} color="primary">
      { loading ? <CircularProgress color="primary" size={ 36 } /> : 'Invoice Selected'} 
    </Button> */}
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        {/* <Button onClick={ handleConfirm } color="primary">
          Save
        </Button> */}
      </DialogActions>
  </Dialog>
  </div>
  )
  }

  const ProposalLineItems = ({ job }) => {

    return (
      <div>
       Line ITems From Proposal: {job.proposalLineItems.length || 0}
       <div style={{ maxHeight: 400, maxWidth: 500, overflowY: 'scroll' }}>
        {JSON.stringify(job.proposalLineItems)}
        </div> 
      </div>
    )
  }

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      perms: state.perms,
      salesReps: state.salesReps
    }
  }
  
  export default React.memo(connect(mapStateToProps)(JobDetail))