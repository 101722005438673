import React from 'react';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Chip,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LineItemBuilder from '../Common/LineItemBuilder';
import { currencyFormat, currencyFormatWithOutFlair } from '../../services/common'
import { useTax } from '../../hooks/taxContext';
import BuildIcon from '@material-ui/icons/Build';
import LocalShipping from '@material-ui/icons/LocalShipping';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import NoteIcon from '@material-ui/icons/Note';
import StraightenIcon from '@material-ui/icons/Straighten';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  tableContainer: {
    marginTop: theme.spacing(2)
  },
  totalRow: {
    backgroundColor: theme.palette.action.hover
  },
  summaryCard: {
    marginBottom: theme.spacing(2)
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  subtotalSection: {
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    // margin: 10,
    justifyContent: 'space-between',
    // alignContent: 'space-evenly',
    alignItems: 'center',
    alignSelf: 'stretch',
    // backgroundColor:' blue'
  },
  cardHeaderItem: {
    // marginRight: 25
  },
  cardHeaderRight: {
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'flex-end',
    // alignItems: 'flex-end',
    // alignSelf: 'flex-end'
  },
  cardHeaderText: {
    fontSize: '1.1em',
    fontWeight: 600,
  },
  internalNotesAreaDetails: {
    fontWeight: 500,
    color: 'red',
    fontSize: '0.9em'
  },
  chip: {
    margin: theme.spacing(0.75),
    padding: theme.spacing(1),
  },
  materials: {
    backgroundColor: '#E6F2F3', // blue
    color: '#294345'
  },
  equipment: {
    backgroundColor: '#F4E8DC', // orange
    color: '#fff'
  },
  labor: {
    backgroundColor: '#7B9EA0', // green
    color: '#fff'
  },
  subcontractor: {
    backgroundColor: '#FFE6B3', // purple
    color: '#fff'
  },
  notes: {
    backgroundColor: '#B58986', // blue grey
    color: '#fff'
  },
  measurements: {
    backgroundColor: '#4A6C6E', // brown
    color: '#fff'
  },
  fee: {
    backgroundColor: '#ACCACD', // brown
    color: '#fff'
  }
}));

const defaultStateValues = {
  qty: 0,
  unit: null,
  description: '',
  totalPrice: 0,
  costPer: 0,
  pricePer: 0,
  counter: 0,
  editing: false,
  inventoryItem: null,
  item: null,
  internalNotes: '',
  materialBudget: 0,
  laborHours: 0,
  techHours: 0,
  sprayTechHours: 0,
  operatorHours: 0,
  supervisorHours: 0,
  bobcatDays: 0,
  craneDays: 0,
  smallCraneDays: 0,
  bigCraneDays: 0,
  option: null,
  numberOfDays: 0,
  numberOfMen: 0,
  suggestedLaborTotal: 0,
  lineItemType: 'materials',
  subLineItems: [],
  hasSubLineItems: false,
  subLineItemsTotal: 0
}

const WorkorderLineItems = ({ workorder, user, takeOffList, client, onChange, job, vendors }) => {
  const classes = useStyles();
  const { calculateTaxableAmount, taxLocales, loading, error, fetchTaxLocales } = useTax();

  const [state, setState] = React.useState(defaultStateValues)
  const [showLineItemBuilder, setShowLineItemBuilder] = React.useState(false)

  const currencyFormat = (num) => {
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  // Calculate totals
  const getMaterialsTotal = () => {
    if (!workorder?.lineItems?.length) return 0
    return workorder?.lineItems
      .filter(item => item.lineItemType === 'Materials')
      .reduce((sum, item) => sum + parseFloat(item.totalPrice), 0);
  };

  const getLaborTotal = () => {
    if (!workorder?.lineItems?.length) return 0
    return workorder?.lineItems
      .filter(item => item.lineItemType === 'Labor')
      .reduce((sum, item) => sum + parseFloat(item.totalPrice), 0);
  };

  const getSubtotal = () => {
    if (!workorder?.lineItems?.length) return 0
    return workorder?.lineItems?.reduce((sum, item) => sum + parseFloat(item.totalPrice), 0);
  };

  const handleCloseLineItemModal = () => {
    setShowLineItemBuilder(false)
  }

  const handleLineItemUpdate = async (items) => {
    console.log('Save our handleLineItemUpdate items..', items)
    const total = items.reduce((sum, item) => {
      let totalToUse = 0
      if (item.type === 'Labor') {
        totalToUse = (parseFloat(item.totalHours || 0) * parseFloat(item.pricePer || 0))
      } else {
        if (item.totalPrice) {
          totalToUse = parseFloat(item.totalPrice || 0)
        } else {
          totalToUse = (parseFloat(item.qty || 0) * parseFloat(item.pricePer || 0))
        }
      }
      let newTot = sum + totalToUse
      return newTot
    }, 0);
    console.log('Total:', total)
    // console.log('Do we have exsiting items?', workorder)
    console.log('Calculate taxes...')
    const taxDetails = await calculateTaxableAmount({ workorder, client, lineItems: items })
    console.log('Tax Details', taxDetails)
    onChange({
      lineItems: items,
      taxAmount: taxDetails.taxAmount,
      taxableAmount: taxDetails.taxableAmount,
      taxLocales: taxDetails.taxLocales,
      price: currencyFormatWithOutFlair(total),
    })
    // setState(prev => ({
    //   ...prev,
    //   // subLineItems: items,
    //   // hasSubLineItems: items.length > 0,
    //   // subLineItemsTotal: currencyFormatWithOutFlair(total),
    //   totalPrice: currencyFormatWithOutFlair(total),
    //   pricePer: currencyFormatWithOutFlair(total)
    // }));

    setShowLineItemBuilder(false);
  };

  const addNewWOLineItem = () => {
    setShowLineItemBuilder(true)
  }
  console.log('Show the line item builder??', showLineItemBuilder)
  return (
    <div className={classes.root}>
      <LineItemBuilder
        open={showLineItemBuilder}
        onClose={handleCloseLineItemModal}
        onSubmit={handleLineItemUpdate}
        initialLineItems={workorder?.lineItems || []}
        client={client}
        entities={user?.entities}
        takeOffList={takeOffList || []}
      />
      {/* Summary Cards */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card className={classes.summaryCard}>
            <CardHeader title="Materials" />
            <CardContent>
              <Typography variant="h4" color="primary">
                {currencyFormat(getMaterialsTotal())}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {workorder?.lineItems?.filter(item => item.lineItemType === 'Materials').length} items
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Card className={classes.summaryCard}>
            <CardHeader title="Labor" />
            <CardContent>
              <Typography variant="h4" color="primary">
                {currencyFormat(getLaborTotal())}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {workorder?.lineItems?.filter(item => item.lineItemType === 'Labor').length} items
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card className={classes.summaryCard}>
            <CardHeader title="Total" />
            <CardContent>
              <Typography variant="h4" color="primary">
                {currencyFormat(getSubtotal())}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {workorder?.lineItems?.length} total items
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Line Items Table */}
      <Card>
        <div className={classes.cardHeader}>
          <div className={classes.cardHeaderItem}>
            <div className={classes.cardHeaderText}>
              Line Items
            </div>
          </div>
          <div className={classes.cardHeaderItem}>
            <div className={classes.cardHeaderRight}>
              {
                (job && job.proposalLineItems && job.proposalLineItems.length) ? null : <Button
                variant='contained'
                size='small'
                color='primary'
                onClick={addNewWOLineItem}
                startIcon={<AddCircleIcon />}
              >Add Line Item</Button>
              }
             
            </div>
          </div>
        </div>
        <CardContent>
         
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell align="right">Price/Unit</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workorder?.lineItems?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <CustomLineItemTypeChip item={item} classes={classes} />
                    </TableCell>
                    <TableCell><DescriptionArea lineItem={item} /></TableCell>
                    <TableCell align="right">{parseFloat(item.qty).toFixed(2)}</TableCell>
                    <TableCell>{item.unit}</TableCell>
                    <TableCell align="right">{currencyFormat(item.pricePer)}</TableCell>
                    <TableCell align="right">{currencyFormat(item.totalPrice)}</TableCell>
                  </TableRow>
                ))}
                
                {/* Subtotal Row */}
                <TableRow className={classes.totalRow}>
                  <TableCell colSpan={4} />
                  <TableCell align="right">
                    <strong>Subtotal:</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{currencyFormat(getSubtotal())}</strong>
                  </TableCell>
                </TableRow>

                {/* Tax Row - if tax exists */}
                {workorder.taxAmount > 0 && (
                  <TableRow className={classes.totalRow}>
                    <TableCell colSpan={4} />
                    <TableCell align="right">Tax:</TableCell>
                    <TableCell align="right">{currencyFormat(workorder.taxAmount)}</TableCell>
                  </TableRow>
                )}

                {/* Grand Total Row */}
                <TableRow className={classes.totalRow}>
                  <TableCell colSpan={4} />
                  <TableCell align="right">
                    <strong>Total:</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{currencyFormat(getSubtotal() + (workorder.taxAmount || 0))}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  );
};

const CustomLineItemTypeChip = ({ item, classes }) => {
  // const classes = useStyles();

  const getChipConfig = (type) => {
    const config = {
      Materials: {
        icon: <BuildIcon className={classes.materials} />,
        className: classes.materials
      },
      Equipment: {
        icon: <LocalShipping className={classes.equipment} />,
        className: classes.equipment
      },
      Labor: {
        icon: <PeopleIcon className={classes.labor} />,
        className: classes.labor
      },
      Subcontractor: {
        icon: <BusinessIcon className={classes.subcontractor} />,
        className: classes.subcontractor
      },
      Notes: {
        icon: <NoteIcon className={classes.notes} />,
        className: classes.notes
      },
      Measurements: {
        icon: <StraightenIcon className={classes.measurements}  />,
        className: classes.measurements
      },
      Fee: {
        icon: <MonetizationOnIcon className={classes.fee} />,
        className: classes.fee
      }
    };

    return config[type] || {};
  };
  let lineItemType = item.lineItemType === 'Labor' ? item.laborType === 'subContractor' ? 'Subcontractor' : 'Labor' : item.lineItemType
  const chipConfig = getChipConfig(lineItemType);

  return (
    <Chip
      label={lineItemType}
      size="small"
      icon={chipConfig.icon}
      className={`${classes.chip} ${chipConfig.className}`}
    />
  );
};

const DescriptionArea = ({ lineItem }) => {
  const classes = useStyles()
  return (
    <div className={classes.descriptionContainer}>
      <div className={classes.descriptionAreaDetails}>
        {lineItem?.description || ''}
      </div>
        {
          lineItem?.internalNotes &&
          <div className={classes.internalNotesAreaDetails}>
            {lineItem?.internalNotes || ''}
          </div>
        }
    </div>
  )
}

export default WorkorderLineItems;
