import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    width: props => props.size || 40,
    height: props => props.size || 40,
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'inline-block',
  },
  profilePhoto: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}))

const ProfilePhoto = (props) => {
  const classes = useStyles(props)
  if (props.employee?.profile_photo_url) {
    const cleanURL = props.employee.profile_photo_url.includes('https')
      ? props.employee.profile_photo_url
      : `https://${props.employee.profile_photo_url}`;

    return (
      <div className={classes.container}>
        <img src={cleanURL} alt="Profile" className={classes.profilePhoto} />
      </div>
    );
  }

  return null;
};

export default ProfilePhoto;
